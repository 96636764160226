import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";

interface Props {
  open: boolean;
  title: string;
  onClose: () => void;
  isLoading?: boolean;
  onConfirm: () => void;
  onConfirmText: string;
  content?: React.ReactNode | string;
  isPlace?: false;
}

export const NewConfirmScrolltextDialog: React.FC<Props> = ({
  title,
  onClose,
  onConfirm,
  open,
  isLoading = false,
  isPlace = false,
  onConfirmText,
  content = "",
}) => {
  const textoA = `A cada guía se le exigirá proporcionar información fidedigna sobre su identidad y acreditación profesional, acompañado debidamente la documentación exigida por la plataforma. 
  Toda falsificación en su información proporcionada facultará a TRAVE APP para iniciar las acciones civiles y penales correspondientes, además de eliminar o bloquear el perfil en comento. 
  El guía se compromete a mantener su información actualizada y debidamente acreditada por las instituciones correspondientes. 
  De esta forma, el guía confirma que se encuentra registrado en Sernatur, cuenta con certificado de antecedentes intachable, cédula de identidad vigente, tener iniciación de actividades para la emisión de boletas durante el pago de sus comisiones. `;
  const textoB = `Es obligación de cada guía subir una foto de perfil de su autoría que permita identificar su caracterización personal de manera clara y nítida, por tanto, no se admitirán fotografías con público ni en espacios que se presente para confusión respecto de su individualidad. 
  Adicionalmente, el guía deberá crear un link de propinas derivado de la plataforma PAYPAL que le permita poder recaudar los beneficios económicos adicionales a sus servicios prestados. 
  Por su parte, con el ánimo de poder identificar claramente a cada guía deberán subir un video cuya duración no exceda los 60 segundos describiendo su perfil como guía turístico contestando las preguntas ¿quiénes son? ¿a qué le dan énfasis en sus tour? A su vez, deberán seleccionar el idioma en que prestarán sus servicios. 
  A mayor abundamiento, en caso de fallecimiento del guía, este deberá dejar designado a un consanguíneo para que informe directamente a TRAVE APP enviando un correo a help@trave-app.com informando acerca de su fallecimiento y solicitando eliminación de perfil. En el caso de que no exista aviso sobre el deceso, TraveApp sólo podrá dar de baja el perfil y eliminar los tours de dicho guía, en caso de notar su ausencia o verificado su deceso.
  Si el guía decide no seguir participando en TraveApp, debe enviar un correo a help@trave-app.com para indicar su deseo de eliminar su cuenta. Trave se tomará 30 días para gestionar dicho cierre. `;
  const textoC = `Es requisito indispensable agregar una foto de cada tour que van a prestar dentro de la plataforma. 
Dicha fotografía debe ser de su autoría, ya sea mediante la toma fotográfica de la zona a enseñar o la compra de una fotografía con derechos de autor, horizontal y en formato jpg.
Queda expresamente prohibido subir y exhibir fotografías descargadas o cuyos derechos de autor pertenezcan a un tercero. TRAVE APP se exime de toda responsabilidad que pueda generar la exhibición y usufructo de fotografías y creaciones intelectuales de terceros, debiendo el guía asumir todas responsabilidad civil y penal derivada de las acciones incoadas. 
Adicionalmente, es menester que el guía designe un nombre a cada tour que sea indicativo del lugar que se recorrerá. Por su parte, deberá indicar el tipo de tour conforme clasificador, la región en que se ubica el territorio en comento y la duración del mismo en minutos. 
Trave funciona a modo de plataforma o medio de difusión para la entrega de tours guiados, por lo cual, no se responsabiliza por información falsa o errónea, relatos desactualizados, tours incompletos o mal geolocalizados. `;
  const textoD = `Es obligación de cada guía subir un detalle del tour que se prestará en idioma seleccionado. Cada presentación podrá estar en dos idiomas debiendo ser lo más exacta y detallada posible conforme a la realidad. Pudiendo crear guiados en idiomas Español, Inglés y Portugués`;
  const textoE = `Es obligación de cada guía indicar la ubicación geográfica en que se desempeñará el tour, precisando todas las calles en que se transitará con ubicación conforme latitud y longitud. `;
  const textoF = `Es obligación de cada guía subir sus archivos de audio en MP3, videos en MP4, e imágenes en jpg, detallado del tour y circuito en comento. En caso de subir a la plataforma un video en un formato distinto al indicado el guía podrá utilizar el conversor de archivos instalado en el administrador. Cuyo uso generará un cobro de $20 por minuto de conversión conforme condiciones de la plataforma, los cuales serán descontados del pago de sus ventas del mes.`;
  const textoG = `TRAVE APP se encuentra expresamente facultado para bloquear a cada guía o tour dentro de su 
plataforma. Dentro de las causales habilitantes para ello destacan las siguientes, sin que dicha enumeración tenga el carácter de taxativa: 
• Información Incompleta o desactualizada según temporada
• Horarios de apertura o precio de entradas desactualizados
• Coordenadas no concuerdan con archivo
• Archivo no compatible
• Tour incompleto
• No incorpora la comunidad local (establecimientos locales que enriquezcan el recorrido) mínimo 3
• Guiado no cumple con requisitos preestablecidos
• Tour desactualizado 
• Mala calidad de los archivos de audio o video 
• Utiliza lenguaje inapropiado
• Infringe las normas
• Falta completar registro (subir archivos solicitados)
• Otros
(El bloqueo del guía es momentáneo y apunta a que solucione el problema y actualice la información).
  Una vez bloqueado, el guía gozará de un plazo de 7 días corrido para subsanar el bloqueo en comento, so pena ser eliminado de la aplicación de manera permanente o definitiva. 
  A su vez, todo tour se encuentra sujeto a aprobación previa por parte de TRAVE APP. De esta forma, cada vez que el guía introduzca una modificación al tour deberá someterse nuevamente a una aprobación por parte de la administración de TRAVE APP, encontrándose suspendida su implementación y comercialización hasta la aprobación definitiva`;
  const textoH = `Todo tour que sea comercializado y difundido mediante la aplicación TRAVE APP estará sujeta al pago de una comisión cuyo porcentaje podrá variar conforme determinación de TRAVE APP y el convenio desarrollado con cada guía. El guía no puede divulgar las negociaciones que haya realizado con TraveApp, respecto al porcentaje de comisión que se le asigna, debiendo mantener estricta confidencialidad. 
La comisión en comento, será pagadera los primeros 15 días hábiles del mes siguiente al cursado, mediante transferencia bancaria conforme datos bancarios indicados por el guía. Cabe destacar que sólo se efectuarán transferencias y/o pagos a una cuenta propia del guía, no aceptándose cuentas de terceros bajo ningún caso.
Previo al pago, se le comunicará mediante el correo el DETALLE DE VENTA y el monto adeudado en ese mes, debiendo el guía emitir la boleta de honorarios respectiva y enviarla a TraveApp con fecha 30 de cada mes para que TRAVE APP proceda a generar el pago respectivo. 
Será responsabilidad de cada guía verificar la exactitud de la información otorgada en su boleta de honorarios respectiva y el pago de sus impuestos. Ante el escenario, de no emitirse ni cargarse dicho documento en tiempo y forma en la plataforma TRAVE APP queda autorizado a retener su pago hasta el cumplimiento de la obligación en comento. 
Ante cualquier disconformidad que exista sobre la comisión por pagar, el guía deberá comunicarse a la siguiente dirección electrónica help@trave-app.com. La Administración de TRAVE APP abrirá un expediente cursando su solicitud gozando de un plazo de 10 días hábiles para revisar su caso y brindar una respuesta oficial mediante correo electrónico`;
  const textoI = `El guía podrá verificar: 
- Usuarios que han comprado su tour
- Tipo de tour
- Idioma de tour
- Fecha de compra
- Valor y porcentaje de comisión que le corresponde. `;
  const textoJ = `El guía podrá verificar usuarios que lo han calificado conforme los siguientes parámetros: 
-calidad del relato
-duración de tour
-recomendaciones
Información que podrá ser descargada con mayor detalle junto a los comentarios dejados por cada 
usuario.`;
  const textoK = `Queda estrictamente prohibido divulgar parte o la totalidad de la información proporcionada al guía por parte de TraveApp. Toda divulgación en la información proporcionada facultará a TRAVE APP para iniciar las acciones civiles y penales correspondientes, además de eliminar o bloquear el perfil en comento. 
Información prohibida:
• Textos y pautas para preparar guiados.
• Charlas y talleres dictados para inducción y traspaso tecnológico.
• Pdfs y documentos varios.
• Formato de geolocalización.
• Formato de armado de tours.
• Modalidad de preparado de itinerarios
• Toda info respectiva a su participación en TraveApp`;

  const placeTextA = `Se entiende por comercio a todo establecimiento comercial perteneciente a la cadena de valor 
turística, esto es: alojamientos, restaurantes, picadas, negocios locales, experiencias, actividades 
turísticas recreativas, rent a cars, artesanos, galerías de arte, museos, parques, lugares culturales o 
patrimoniales, eventos, etc. Sin que dicha enumeración tenga el carácter de taxativa.
Cada comercio deberá proporcionar la información respectiva en relación a los productos o servicios 
que ofrece, ubicación, precios, horario de atención, domicilio de facturación, personería de 
representante legal, correo y teléfono de contacto acompañado la documentación que lo acredite. 
Es de suma relevancia que la dirección del local sea geolocalizada conforme latitud y longitud en el 
mapa.
Por tanto, es requisito esencial que el comercio cumpla y mantenga la siguiente información al día: 
certificado de iniciación de actividades, registro sernatur, patente comercial vigente, cédula del 
representante legal por ambos lados, TraveApp se reserva el derecho de solicitar en cualquier 
ocasión la totalidad o alguno de los documentos antes mencionados.
Es condición sine qua non que dichos requisitos se mantenga en el tiempo para la mantención del 
comercio en la plataforma TRAVE APP.
Ante el escenario que el comercio deje de cumplir con dichas condiciones o caiga en estado de 
insolvencia TRAVE APP estará facultado para eliminarlo ipso facto de su plataforma sin expresión de 
causa ni notificación alguna.
TRAVE APP se exime de toda responsabilidad que pueda derivar por la falta de veracidad en la 
información proporcionada, disconformidad de los consumidores con sus productos o servicios, 
inobservancia de leyes u otros que deriven en acciones civiles o penales.`;

  const placeTextB = `El comercio podrá subir videos promocionales de manera ilimitada, cuyo objetivo es divulgar el 
contenido de sus productos o servicios. Así, cada vez que un viajero pinche abrir y visualice las 
burbujas posicionadas en el mapa, con la publicidad del comercio, este podrá ser redireccionado 
hasta dicho comercio, siempre que el viajero tenga buena señal en su dispositivo móvil.
Una vez que el viajero salga del comercio, podrá dejar una calificación y un comentario acerca del 
servicio recibido.
Dichos videos deben ser en formato MP4 y ser de su autoría, toda divulgación de propiedad 
intelectual o industrial ajena permitirá a TRAVE APP dar de baja el contenido audiovisual y eliminar 
al comercio de manera inmediata de su plataforma sin expresión de causa ni notificación. Además 
de facultar a TRAVE APP para iniciar las acciones civiles y penales que por derecho le asistieren.
Pues bien, existirá un período de 3 meses naturales- prorrogables por igual período- denominado 
“marcha blanca” en el cual los comercios podrán publicitarse por medio de 10 videos cuya duración 
no puede ser superior a 30 segundos sin costo alguno. Es condición que durante este período dichos 
comercios exhiban contenido gráfico promocional de TRAVE APP en sus instalaciones.
Una vez terminada la marcha blanca se contactará a cada comercio para indicarles los nuevos 
valores por publicidad, en donde cada comercio decidirá si continúa o renuncia a su cuenta en 
TraveApp`;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="dialog-confirm-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          {!isPlace ? (
            <>
              <Typography variant="subtitle2">
                {
                  "1) FORMULARIO DE REGISTRO DE GUÍAS (USUARIO DE LA PLATAFORMA WEB)\n"
                }
              </Typography>
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {textoA}
              </Typography>
              <Typography variant="subtitle2">{"2) MI PERFIL\n"}</Typography>
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {textoB}
              </Typography>
              <Typography variant="subtitle2">{"3) MIS TOURS\n"}</Typography>
              <Typography variant="body1">{"a) AGREGAR UN TOUR\n"}</Typography>
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {textoC}
              </Typography>
              <Typography variant="body1">
                {"b) PRESENTACIÓN EN IDIOMAS\n"}
              </Typography>
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {textoD}
              </Typography>
              <Typography variant="body1">{"c) MAPAS\n"}</Typography>
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {textoE}
              </Typography>
              <Typography variant="body1">{"d) VIDEOS\n"}</Typography>
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {textoF}
              </Typography>
              <Typography variant="subtitle2">
                {"4) BLOQUEO GUÍAS\n"}
              </Typography>
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {textoG}
              </Typography>
              <Typography variant="subtitle2">
                {"5) VENTA DE TOUR\n"}
              </Typography>
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {textoH}
              </Typography>
              <Typography variant="subtitle2">
                {"6) DETALLES DE VENTA\n"}
              </Typography>
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {textoI}
              </Typography>
              <Typography variant="subtitle2">
                {"7) CALIFICACIÓN USUARIOS\n"}
              </Typography>
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {textoJ}
              </Typography>
              <Typography variant="subtitle2">
                {"8) PRIVACIDAD DE LA INFORMACIÓN\n"}
              </Typography>
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {textoK + "\n\n"}
              </Typography>
              <Typography variant="caption" style={{ textAlign: "center" }}>
                {`Nota: estos términos pueden sufrir cambios en cualquier momento con o sin aviso previo, por lo tanto, es obligación del 
usuario mantenerse atento a las actualizaciones en este documento. Si el usuario permanece utilizando esta plataforma 
se deduce que acepta las condiciones y términos del presente documento`}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="subtitle2">
                {"1. FORMULARIO REGISTRO DE COMERCIO\n"}
              </Typography>
              <Typography variant="body2">{placeTextA}</Typography>
              <Typography variant="subtitle2">{"2. VIDEOS\n"}</Typography>
              <Typography variant="body2">{placeTextB}</Typography>
              <Typography variant="subtitle2">
                {"3. PRIVACIDAD DE LA INFORMACIÓN\n"}
              </Typography>
              <Typography variant="body2">{`Queda estrictamente prohibido divulgar parte o la totalidad de la información proporcionada al comercio por 
parte de TraveApp. Toda divulgación en la información proporcionada facultará a TRAVE APP para iniciar las 
acciones civiles y penales correspondientes, además de eliminar o bloquear el perfil en comento. 
Información prohibida:`}</Typography>
              <Typography
                variant="body2"
                style={{ whiteSpace: "pre-line" }}
              >{`• Textos y pautas para preparar guiados.
              • Charlas y talleres dictados para inducción y traspaso tecnológico.
              • Pdfs y documentos varios.
              • Formato de geolocalización.
              • Formato de armado de tours.
              • Modalidad de preparado de itinerarios
              • Toda info respectiva a su participación en TraveApp`}</Typography>
              <Typography
                variant="body2"
                style={{ whiteSpace: "pre-line" }}
              >{`LO QUE ESTA PROHIBIDO EN TRAVEAPP
              • Maltrato animal
              • Infracciones de propiedad intelectual de cualquier tipo
              • Incumplimiento de leyes o restricciones locales
              • Contenido inadecuado y discriminación`}</Typography>
              <Typography variant="subtitle2">
                {"4. CLÁUSULA DE NO COMPETENCIA.\n"}
              </Typography>
              <Typography variant="body2">{`El comercio puede desarrollar negocios, colaboraciones o proyectos similares a TraveApp, con terceros ajenos 
              al presente Acuerdo que se desarrollen en actividades o servicios similares o idénticos al de TraveApp, pero 
              se exige al comercio no utilizar los mismos archivos multimedia subidos en la plataforma de TraveApp. Para 
              lo cual, deberá grabar nuevos archivos para utilizar en proyectos externos o dar de baja sus archivos y/o perfil 
              en la App de Trave.
              Duración de este acuerdo tiene carácter indefinido, pudiéndose dar termino a él, si alguna de las partes 
              considera hacerlo. Bastará con dar aviso con un mes de anticipación, indicando los motivos de término de 
              contrato y este se hará efectivo a partir de un mes posterior al aviso de término de acuerdo.`}</Typography>
              <Typography variant="caption" style={{ textAlign: "center" }}>
                {`Nota: estos términos pueden sufrir cambios en cualquier momento con o sin aviso previo, por lo tanto, es obligación del usuario 
mantenerse atento a las actualizaciones en este documento. Si el usuario permanece utilizando esta plataforma se deduce que acepta 
las condiciones y términos del presente documento`}
              </Typography>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button color="primary" onClick={onConfirm}>
          {onConfirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
