import React from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import {
  IconButton as MuiIconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";

import { signOut } from "../redux/actions/authActions";
import { NewConfirmScrolltextDialog } from "./NewConfirmScrolltextDialog";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function UserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const [openbyGuide, setOpenbyGuide] = React.useState(false);
  const [openbyPlace, setOpenbyPlace] = React.useState(false);
  const handleCloseOpen = () => {
    setOpenbyGuide(false);
    setOpenbyPlace(false);
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const profileMenu = () => {
    if (user?.tipoUsuario === "Guia") {
      history.push(`/perfil/${user.id}`);
    } else {
      setAnchorMenu(null);
    }
  };

  const handleSignOut = async () => {
    await dispatch(signOut());
    history.push("/auth/sign-in");
  };

  const openTyCGuide = () => setOpenbyGuide(true);
  const openTyCPlace = () => setOpenbyPlace(true);

  return (
    <React.Fragment>
      <NewConfirmScrolltextDialog
        open={openbyGuide || openbyPlace}
        isPlace={openbyPlace}
        title={"Términos y Condiciones"}
        content="Ejemplo de Términos y Condiciones..."
        onClose={handleCloseOpen}
        onConfirm={handleCloseOpen}
      />
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {user?.tipoUsuario === "Guia" && (
          <>
            <MenuItem onClick={profileMenu}>Perfil</MenuItem>
            <MenuItem onClick={openTyCGuide}>Terminos y Condiciones</MenuItem>
          </>
        )}
        {user?.tipoUsuario === "Lugar" && (
          <>
            <MenuItem onClick={openTyCPlace}>Terminos y Condiciones</MenuItem>
          </>
        )}
        <MenuItem onClick={handleSignOut}>Cerrar Sesión</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default UserDropdown;
