import firebase from "firebase/app";
import * as types from "../../constants";
import { firestore, storage } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { FormState } from "../../models/form_state";
import { SnackState } from "../../models/snack-state";
import { cleanString } from "../../utils/utils";
import { openSnack } from "./uiActions";
import Axios from "axios";
import { SaveCreationNotif } from "./tourGuidesActions";

const DEFAULT_ORDER_BY = "Nombre";
const DEFAULT_COLLECTION_NAME = "Tours";

export enum TOUR_STATUS {
  Pendiente = "Pendiente",
  Aprobado = "Aprobado",
  Rechazado = "Rechazado",
}

export const getTours = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const { user } = getState().authReducer;
    try {
      let query = firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY)
        .limit(limit);
      let query2 = firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY);
      // .limit(limit);

      if (user.tipoUsuario === "Guia") {
        query = query.where("Guia.Id", "==", user.id);
        query2 = query2.where("Guia.Id", "==", user.id);
      }

      const response = await query.get();
      const responseAll = await query2.get();

      dispatch(
        setTours(
          mapTours(response),
          responseAll.size,
          response.docs[response.docs.length - 1]
        )
      );
      // dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getTourByCode = (
  codigo: any,
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      let query = firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY)
        .limit(limit)
        .where("Codigo", "==", Number(codigo));

      const response = await query.get();

      dispatch(
        setTours(
          mapTours(response),
          response.size,
          response.docs[response.docs.length - 1]
        )
      );
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getToursFiltered = (
  filtro: any = {},
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const { user } = getState().authReducer;
    try {
      let query: firebase.firestore.Query<firebase.firestore.DocumentData> =
        firestore.collection(DEFAULT_COLLECTION_NAME);

      let query2: firebase.firestore.Query<firebase.firestore.DocumentData> =
        firestore.collection(DEFAULT_COLLECTION_NAME);

      if (filtro) {
        if (
          Object.keys(filtro).length === 1 &&
          Object.keys(filtro)[0] === "Guia.Nombre_lower"
        ) {
          query = query
            .orderBy("Guia.Nombre_lower")
            .orderBy(DEFAULT_ORDER_BY)
            .limit(limit);
          query2 = query2
            .orderBy("Guia.Nombre_lower")
            .orderBy(DEFAULT_ORDER_BY);
        } else {
          query = query
            .orderBy("FechaCreacion")
            .orderBy(DEFAULT_ORDER_BY)
            .limit(limit);
          query2 = query2.orderBy("FechaCreacion").orderBy(DEFAULT_ORDER_BY);
        }
        Object.keys(filtro).forEach((key) => {
          let value = filtro[key];

          if (key === "endAt") {
            const miliDate = Date.parse(value);
            query = query.where("FechaCreacion", ">=", new Date(miliDate));
            query2 = query2.where("FechaCreacion", ">=", new Date(miliDate));
            // query = query.startAt(new Date(miliDate));
            // query2 = query2.startAt(new Date(miliDate));
          } else if (key === "startAt") {
            const miliDate = Date.parse(value);
            query = query.where("FechaCreacion", "<=", new Date(miliDate));
            query2 = query2.where("FechaCreacion", "<=", new Date(miliDate));
            // query = query.endAt(new Date(miliDate));
            // query2 = query2.endAt(new Date(miliDate));
          } else {
            if (
              Object.keys(filtro).length === 1 &&
              key === "Guia.Nombre_lower"
            ) {
              query = query.startAt(value).endAt(value + "\uf8ff");
              query2 = query2.startAt(value).endAt(value + "\uf8ff");
            } else {
              query = query.where(key, "==", value);
              query2 = query2.where(key, "==", value);
            }
          }
        });
      }
      if (user.tipoUsuario === "Guia") {
        query = query.where("Guia.Id", "==", user.id);
        query2 = query2.where("Guia.Id", "==", user.id);
      }

      const response_1 = await query.get();
      const response_2 = await query2.get();

      dispatch(
        setTours(
          mapTours(response_1),
          response_2.size,
          response_1.docs[response_1.docs.length - 1]
        )
      );
      dispatch(isLoading(false));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
      dispatch(isLoading(false));
    }
  };
};

export const getMoreToursFiltered = (
  filtro: any = {},
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const { lastDoc, totalDocs, tours } = getState().toursReducer;
    const { user } = getState().authReducer;
    try {
      let query: firebase.firestore.Query<firebase.firestore.DocumentData> =
        firestore.collection(DEFAULT_COLLECTION_NAME);

      if (filtro) {
        if (
          Object.keys(filtro).length === 1 &&
          Object.keys(filtro)[0] === "Guia.Nombre_lower"
        ) {
          query = query
            .orderBy("Guia.Nombre_lower")
            .orderBy(DEFAULT_ORDER_BY)
            .startAfter(lastDoc)
            .limit(limit);
        } else {
          query = query
            .orderBy("FechaCreacion")
            .orderBy(DEFAULT_ORDER_BY)
            .startAfter(lastDoc)
            .limit(limit);
        }
        Object.keys(filtro).forEach((key) => {
          let value = filtro[key];

          if (key === "endAt") {
            const miliDate = Date.parse(value);
            query = query.startAt(new Date(miliDate));
          } else if (key === "startAt") {
            const miliDate = Date.parse(value);
            query = query.endAt(new Date(miliDate));
          } else {
            if (
              Object.keys(filtro).length === 1 &&
              key === "Guia.Nombre_lower"
            ) {
              query = query.startAt(value).endAt(value + "\uf8ff");
            } else {
              query = query.where(key, "==", value);
            }
          }
        });
      }
      if (user.tipoUsuario === "Guia") {
        query = query.where("Guia.Id", "==", user.id);
      }

      const response_1 = await query.get();

      const data = response_1.docs.map((doc: any) => ({
        id: doc.id,
        ...doc.data(),
      }));
      tours.push(...data);
      dispatch({
        type: types.TOURS_KEEP_TOTAL_DOCS,
        payload: totalDocs,
      });
      // dispatch(addMoreToursToState(mapTours(response)));
      dispatch(setLastDoc(response_1.docs[response_1.docs.length - 1]));

      // dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      dispatch(isLoading(false));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const addTourIndexData = (
  data: any,
  file: any,
  id: string,
  idGuia: string
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(addIndexIsLoading(true));
    const { user } = getState().authReducer;
    const {
      edit: { selectedTour },
      index: { selectedIndex },
    } = getState().toursReducer;
    try {
      //----------------------------------------------------------------
      if (user?.tipoUsuario !== "Administrador") {
        const timeStampHere = firebase.firestore.Timestamp.now()
          .toDate()
          .toLocaleDateString("es");
        if (!selectedTour.Modificaciones) {
          selectedTour.Modificaciones = [];
        }
        const changesArr: any[] = [...selectedTour?.Modificaciones];
        if (file) {
          changesArr.push(
            `[${timeStampHere}] Multimedia ` +
              data.Codigo.toUpperCase() +
              ": " +
              data?.Nombre +
              " Posición " +
              data?.Indice +
              " Multimedia Añadida"
          );
        }

        await firestore
          .collection("Tours")
          .doc(id)
          .update({ Estado: "Pendiente", Modificaciones: changesArr });
        selectedTour.Modificaciones = changesArr;
      }

      //---------
      const dateMili = Date.parse(new Date().toString());
      if (file) {
        const loadVideo = (thisfile: any) =>
          new Promise((resolve, reject) => {
            try {
              let video = document.createElement("video");
              video.preload = "metadata";

              video.onloadedmetadata = function () {
                resolve(this);
              };

              video.onerror = function () {
                reject("Invalid video. Please select a video file.");
              };

              video.src = window.URL.createObjectURL(thisfile);
            } catch (e) {
              reject(e);
            }
          });
        const firstClean: string = String(data.Nombre).replaceAll(/\s/g, "");
        const fileName = firstClean.replaceAll(" ", "_");
        // console.log("File name regex......");
        // console.log(String(data.Nombre).replaceAll(/\s/g, ""));

        // console.log(fileName);
        try {
          if (data.TipoMultimedia !== "video") {
            file.type = "audio/mp3";
          }
        } catch {}

        data.size = file.size; // kb Size
        const tipo = data.TipoMultimedia === "video" ? ".mp4" : ".mp3";
        const filePath: string = `${idGuia}/Tour/${id}/${
          data.Codigo
        }/Puntos/${fileName}_${dateMili + tipo}`;

        file.path = filePath;
        const video: any = await loadVideo(file);

        data.duration = video.duration;
        // Fomato carpeta: Id Guía / Indice vinculado Idioma  / Nombre archivo

        const videoRef = storage.ref(
          `${idGuia}/Tour/${id}/${data.Codigo}/Puntos/${fileName}_${
            dateMili + tipo
          }`
        );

        data.Ubicacion = filePath;

        let videoUp: firebase.storage.UploadTask;
        if (data.TipoMultimedia !== "video") {
          var metadata = {
            contentType: "audio/mp3",
          };
          videoUp = videoRef.put(file, metadata);
        } else {
          videoUp = videoRef.put(file);
        }

        // Video Loading
        videoUp.on(
          "state_change",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log("Upload is " + progress.toFixed(1) + "% done");

            dispatch({
              type: types.TOURS_VIDEO_UPLOAD,
              payload: progress,
            });
          },
          (error) => {
            console.log(error);
            dispatch(
              openSnack("Error de subida de elemento", SnackState.ERROR)
            );
          },
          () => {
            videoUp.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              data.link = downloadURL;
              // data.Prosecute = true;
              firestore
                .collection("Tours")
                .doc(id)
                .collection("Puntos")
                .add(data)
                .then(async (res) => {
                  dispatch(
                    openSnack(
                      "Elemento subido exitosamente",
                      SnackState.SUCCESS
                    )
                  );
                  dispatch(getTourIndexData(data.Codigo, id));

                  if (data.Prosecute) {
                    await firestore.collection("ColaConversion").add({
                      FechaCreacion:
                        firebase.firestore.FieldValue.serverTimestamp(),
                      id: res.id,
                      /**
                       * /Tours/{tourId}/Puntos/{pointId}
                       */
                      PointPath: `Tours/${id}/Puntos/${res.id}`,
                      /**
                       * path en el storage
                       */
                      VideoPath: filePath,
                      Language: data.Codigo,
                    });

                    //REGISTER DATA
                    await firestore.collection("VideosConvertidos").add({
                      TourId: id,
                      TourName: selectedTour?.Nombre,
                      TourLenguage: data.Codigo,
                      PointId: res.id,
                      GuideId: idGuia,
                      FechaCreacion:
                        firebase.firestore.FieldValue.serverTimestamp(),
                    });
                  }

                  // Axios.post(
                  //   `https://southamerica-east1-traveapp-fa700.cloudfunctions.net/sendToConvertVideo`,
                  //   {
                  //     pointId: res.id,
                  //     tourId: id,
                  //   },
                  //   {
                  //     headers: {
                  //       "Content-Type": "application/json",
                  //       "Access-Control-Allow-Origin": "*",
                  //     },
                  //   }
                  // );
                });
            });
            dispatch(addIndexIsLoading(false));

            dispatch({
              type: types.TOURS_VIDEO_UPLOAD,
              payload: 0,
            });
          }
        );
      }
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
    }
  };
};

export const updateTourIndexPoint = (
  idTour: string,
  codigoLenguage: string,
  datos: any,
  file?: any
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.TOUR_POINT_UPDATE_IS_SUBMITTING,
    });
    const { user } = getState().authReducer;
    const {
      edit: { selectedTour },
      index: { selectedIndex },
    } = getState().toursReducer;
    try {
      const id = datos.id;
      delete datos.id;

      const vidPosition = selectedIndex.findIndex((x: any) => x.id === id);
      if (user?.tipoUsuario !== "Administrador") {
        if (!selectedTour.Modificaciones) {
          selectedTour.Modificaciones = [];
        }
        const timeStampHere = firebase.firestore.Timestamp.now()
          .toDate()
          .toLocaleDateString("es");
        // console.log(timeStampHere);

        const changesArr: any[] = [...selectedTour?.Modificaciones];
        if (file) {
          changesArr.push(
            `[${timeStampHere}] Multimedia ` +
              codigoLenguage.toUpperCase() +
              ": " +
              selectedIndex[vidPosition]?.Nombre +
              " Posición " +
              selectedIndex[vidPosition]?.Indice +
              " → " +
              datos?.Nombre +
              " Posición " +
              datos?.Indice +
              " Multimedia Modificada"
          );
        } else {
          changesArr.push(
            "Multimedia " +
              codigoLenguage.toUpperCase() +
              ": " +
              selectedIndex[vidPosition]?.Nombre +
              " Posición " +
              selectedIndex[vidPosition]?.Indice +
              " → " +
              datos?.Nombre +
              " Posición " +
              datos?.Indice
          );
        }

        await firestore
          .collection("Tours")
          .doc(idTour)
          .update({ Estado: "Pendiente", Modificaciones: changesArr });
        selectedTour.Modificaciones = changesArr;
      }
      const loadVideo = (thisfile: any) =>
        new Promise((resolve, reject) => {
          try {
            let video = document.createElement("video");
            video.preload = "metadata";
            video.onloadedmetadata = function () {
              resolve(this);
            };
            video.onerror = function () {
              reject("Invalid video. Please select a video file.");
            };
            video.src = window.URL.createObjectURL(thisfile);
          } catch (e) {
            reject(e);
          }
        });
      if (file) {
        datos.size = file.size;
        const video: any = await loadVideo(file);
        datos.duration = video.duration;
        // const fileName = String(datos.Nombre).replaceAll(" ", "_");
        const videoRef = storage.ref(datos.Ubicacion);
        const guideId = String(datos.ubicacion).split("/");
        let videoUp: firebase.storage.UploadTask;
        // selectedIndex[vidPosition]?.
        if (datos.TipoMultimedia !== "video") {
          var metadata = {
            contentType: "audio/mp3",
          };
          videoUp = videoRef.put(file, metadata);
        } else {
          videoUp = videoRef.put(file);
        }
        // const videoUp = videoRef.put(file);
        videoUp.on(
          "state_change",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log("Upload is " + progress.toFixed(1) + "% done");
            dispatch({
              type: types.TOURS_VIDEO_UPLOAD,
              payload: progress,
            });
          },
          (error) => {
            console.log(error);
          },
          () => {
            videoUp.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              datos.link = downloadURL;
              datos.Codigo = codigoLenguage;
              // datos.Prosecute = true;
              firestore
                .collection("Tours")
                .doc(idTour)
                .collection("Puntos")
                .add(datos)
                .then(async (res) => {
                  dispatch({
                    type: types.TOUR_POINT_UPDATE_IS_SUCCESS,
                  });
                  if (datos.Prosecute) {
                    await firestore.collection("ColaConversion").add({
                      FechaCreacion:
                        firebase.firestore.FieldValue.serverTimestamp(),
                      id: res.id,
                      /**
                       * /Tours/{tourId}/Puntos/{pointId}
                       */
                      PointPath: `Tours/${idTour}/Puntos/${res.id}`,
                      /**
                       * path en el storage
                       */
                      VideoPath: datos.Ubicacion,
                      Language: codigoLenguage,
                    });

                    // REGISTER DATA
                    await firestore.collection("VideosConvertidos").add({
                      TourId: idTour,
                      TourName: selectedTour?.Nombre,
                      TourLenguage: codigoLenguage,
                      PointId: res.id,
                      GuideId: guideId[0],
                      FechaCreacion:
                        firebase.firestore.FieldValue.serverTimestamp(),
                    });
                  }
                });
              firestore
                .collection("Tours")
                .doc(idTour)
                .collection("Puntos")
                .doc(id)
                .delete()
                .then(() => dispatch(getTourIndexData(codigoLenguage, idTour)));
            });
          }
        );
      } else {
        await firestore
          .collection("Tours")
          .doc(idTour)
          .collection("Puntos")
          .doc(id)
          .update(datos);
        dispatch({
          type: types.TOUR_POINT_UPDATE_IS_SUCCESS,
        });
        dispatch(getTourIndexData(codigoLenguage, idTour));
      }
    } catch (error: any) {
      dispatch({
        type: types.TOUR_POINT_UPDATE_IS_FAILURE,
        payload: error,
      });
      console.log(error);
    }
  };
};

export const deleteTourIndexPoint = (
  idTour: string,
  codigoLenguage: string,
  datos: any
): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.TOUR_POINT_UPDATE_IS_SUBMITTING,
    });
    try {
      const id = datos.id;
      delete datos.id;
      // const videoRef = storage.ref(datos.Ubicacion);

      await firestore
        .collection("Tours")
        .doc(idTour)
        .collection("Puntos")
        .doc(id)
        .update(datos);
      dispatch({
        type: types.TOUR_POINT_UPDATE_IS_SUCCESS,
      });
      dispatch(getTourIndexData(codigoLenguage, idTour));
    } catch (error: any) {
      dispatch({
        type: types.TOUR_POINT_UPDATE_IS_FAILURE,
        payload: error,
      });
      console.log(error);
    }
  };
};

export const removeTourIndexPoint = (
  idTour: string,
  codigoLenguage: string,
  idPoint: any,
  datos?: any
): AppThunk => {
  return async (dispatch, getState) => {
    // dispatch(indexIsLoading(true));
    const { user } = getState().authReducer;
    const {
      edit: { selectedTour },
    } = getState().toursReducer;
    try {
      if (
        !selectedTour?.Modificaciones &&
        user?.tipoUsuario !== "Administrador"
      ) {
        await firestore
          .collection("Tours")
          .doc(idTour)
          .update({ Estado: "Pendiente", Modificaciones: true });
        selectedTour.Modificaciones = true;
      }

      try {
        const videoRef = storage.ref(datos.Ubicacion);
        await videoRef.delete();
      } catch {}
      await firestore
        .collection("Tours")
        .doc(idTour)
        .collection("Puntos")
        .doc(idPoint)
        .delete();

      dispatch(getTourIndexData(codigoLenguage, idTour));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);

      // dispatch(indexIsLoading(false));
    }
  };
};

export const getTourIndexData = (codigo: string, id: string): AppThunk => {
  return async (dispatch) => {
    dispatch(indexIsLoading(true));
    try {
      const response = await firestore
        .collection("Tours")
        .doc(id)
        .collection("Puntos")
        .where("Codigo", "==", codigo)
        .orderBy("Indice")
        .get();

      dispatch(setIndex(mapIndexes(response)));
      dispatch(indexIsLoading(false));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
      dispatch(indexIsLoading(false));
    }
  };
};

export const getPointLenguageMap = (codigo: string, id: string): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.TOUR_LENGUAGE_POINT_GET_SUBMITTING,
    });
    try {
      const response = await firestore
        .collection("Tours")
        .doc(id)
        .collection("Puntos")
        .where("Codigo", "==", codigo)
        .get();

      dispatch({
        type: types.TOUR_LENGUAGE_POINT_GET_SUCCESS,
        payload: mapIndexes(response),
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.TOUR_LENGUAGE_POINT_GET_FAILURE,
      });
    }
  };
};

export const addTour = (tour: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading(true));
    dispatch(changeFormState(FormState.Submitting));

    // const userId = "LAgD1DWtQ4Mq2VOWTzgdg1cLZbh1";
    const userId = getState().authReducer.user.id || "";

    try {
      const dateMili = Date.parse(new Date().toString());

      const countResponse = await firestore
        .collection("Contadores")
        .doc("Tours")
        .get();
      const count = countResponse?.data()?.Contador;
      const userResponse = await firestore
        .collection("Usuarios")
        .doc(userId)
        .get();
      const user: any = { ...userResponse.data(), id: userResponse.id };

      const uploadData = { ...tour };

      // se elimina el archivo de la data a subir
      delete uploadData.PortadaTour;
      delete uploadData.VideoPresentacion;
      uploadData.Path = [];

      const response = await firestore.collection(DEFAULT_COLLECTION_NAME).add({
        ...uploadData,
        Codigo: dateMili,
        Nombre_lower: cleanString(uploadData.Nombre),
        Numero: count + 1,
        FechaCreacion: firebase.firestore.FieldValue.serverTimestamp(),
        Calificacion: 0,
        Estado: TOUR_STATUS.Pendiente,
        Guia: {
          Apellido: user.Apellido,
          Apellido_lower: user.Apellido_lower,
          Nombre: user.Nombre,
          Nombre_lower: user.Nombre_lower,
          Email: user.Email,
          Id: user.id,
        },
      });

      //subir imagen a store
      if (tour.PortadaTour) {
        const imageRef = storage.ref(
          `${userId}/Tour/${response.id}/PortadaTour.jpeg`
        );

        await imageRef.put(tour.PortadaTour);
        const PortadaTour = await imageRef.getDownloadURL();
        firestore
          .collection("Tours")
          .doc(response.id)
          .update({ PortadaTour: PortadaTour });
      }
      if (tour.VideoPresentacion) {
        const videoRef = storage.ref(
          `${userId}/Tour/${response.id}/VideoPresentacion.mp4`
        );

        await videoRef.put(tour.VideoPresentacion);
        const VideoPresentacion = await videoRef.getDownloadURL();
        firestore
          .collection("Tours")
          .doc(response.id)
          .update({ VideoPresentacion: VideoPresentacion });
      }

      await SaveCreationNotif(
        `Nuevo tour ${uploadData?.Nombre} creado por Guia: ${
          user?.Nombre + " " + user?.Apellido
        } (${user?.Email})`
      );

      // Se incrementa el contador
      await firestore
        .collection("Contadores")
        .doc("Tours")
        .update({ Contador: firebase.firestore.FieldValue.increment(1) });
      dispatch(openSnack("Tour Guardado", SnackState.SUCCESS));
      dispatch(getTours());
      dispatch(changeFormState(FormState.Success));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
      dispatch(changeFormState(FormState.Error));
    } finally {
      dispatch(updateIsLoading(false));
    }
  };
};

export const getTour = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch(updateIsLoading(true));
    try {
      const response = await firestore.collection("Tours").doc(id).get();
      const tour = mapTour(response);
      // console.log(tour);
      dispatch(setSelectedTour(tour));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(updateIsLoading(false));
    }
  };
};

export const getTourCalificaciones = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.TOUR_GET_RATING_IS_SUBMITTING,
    });
    try {
      const response = await firestore
        .collection("Calificaciones")
        .where("TourId", "==", id)
        .get();

      const arrayResponse = response.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      dispatch({
        type: types.TOUR_GET_RATING_SUCCESS,
        payload: arrayResponse,
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.TOUR_GET_RATING_FAILURE,
        payload: error,
      });
    }
  };
};

export const updateTour = (
  tour: any,
  data: any,
  video: any,
  image: any
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading(true));
    const { user } = getState().authReducer;
    const {
      edit: { selectedTour },
    } = getState().toursReducer;
    try {
      //----------------------------------------------------------------
      if (user?.tipoUsuario !== "Administrador") {
        if (!selectedTour.Modificaciones) {
          selectedTour.Modificaciones = [];
        }
        const timeStampHere = firebase.firestore.Timestamp.now()
          .toDate()
          .toLocaleDateString("es");
        const changesArr: any[] = [...selectedTour?.Modificaciones];
        if (video || image) {
          changesArr.push(
            `[${timeStampHere}] Información Básica: Información y/o Multimedia Modificada`
          );
        } else {
          changesArr.push(
            `[${timeStampHere}] Información Básica: Información Modificada`
          );
        }

        data.Estado = "Pendiente";
        data.Modificaciones = changesArr;
        selectedTour.Modificaciones = changesArr;
      } else {
        if (selectedTour?.Modificaciones) {
          data.Modificaciones = false;
          selectedTour.Modificaciones = false;
        }
      }

      //---------

      if (data.Estado === "Aprobado" && data.Estado !== selectedTour.Estado) {
        dispatch(updateBlocked(false, tour.id));
      }
      // console.log(data);
      delete data.VideoPresentacion;
      delete data.PortadaTour;

      // const dateMili = Date.parse(new Date().toString());
      // if (video) {
      //   const videoRef = storage.ref(
      //     `${tour.Guia.Id}/Tour/${tour.id}/VideoPresentacion.mp4`
      //   );
      //   await videoRef.put(video);
      //   data.VideoPresentacion = await videoRef.getDownloadURL();
      // }
      if (image) {
        const videoRef = storage.ref(
          `${tour.Guia.Id}/Tour/${tour.id}/PortadaTour.jpeg`
        );
        await videoRef.put(image);
        data.PortadaTour = await videoRef.getDownloadURL();
      }
      delete data?.Path;
      dispatch(openSnack("Tour Actualizado", SnackState.SUCCESS));
      await firestore.collection("Tours").doc(tour.id).update(data);

      dispatch(getTour(tour.id));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(updateIsLoading(false));
    }
  };
};

export const updateBlocked = (
  block: boolean,
  tourId: string,
  reason?: string
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading(true));
    const {
      edit: { selectedTour },
    } = getState().toursReducer;
    try {
      if (block) {
        await firestore
          .collection("Tours")
          .doc(tourId)
          .update({ Bloqueo: { Motivo: reason }, Estado: "Pendiente" });
        selectedTour.Estado = "Pendiente";
        dispatch(updateIsLoading(false));
      } else {
        await firestore
          .collection("Tours")
          .doc(tourId)
          .update({ Bloqueo: firebase.firestore.FieldValue.delete() });
        dispatch(updateIsLoading(false));
      }
    } catch (error: any) {
      dispatch(setError(error));
      dispatch(updateIsLoading(false));
      console.log(error);
    }
  };
};

export const updateTourVideosSize = (
  tour: any,
  datos: { totalSize: number; distance: number; totalDuration: number },
  lenguage: string
): AppThunk => {
  return async (dispatch) => {
    dispatch(updateIsLoading(true));
    try {
      var updateData: any = {};
      updateData[`PesoVideos.${lenguage}`] = {
        Peso: datos.totalSize,
      };
      updateData[`DuracionVideos.${lenguage}`] = datos.totalDuration;
      updateData[`DistanciaPuntos.${lenguage}`] = datos.distance;
      await firestore.collection("Tours").doc(tour.id).update(updateData);
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(updateIsLoading(false));
    }
  };
};

export const deleteTour = (tour: any): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.TOUR_DELETE_IS_SUBMITTING,
    });
    try {
      // Delete Portada && VideoPresentacion
      let refTour;
      refTour = storage.ref(
        `${tour.Guia.Id}/Tour/${tour.id}/VideoPresentacion.mp4`
      );
      try {
        await refTour.delete();
      } catch (errorDeleteRef) {}
      refTour = storage.ref(`${tour.Guia.Id}/Tour/${tour.id}/PortadaTour.jpeg`);
      try {
        await refTour.delete();
      } catch (errorDeleteRef) {}

      // Delete point videos/audios
      const responsePoints = await firestore
        .collection("Tours")
        .doc(tour.id)
        .collection("Puntos")
        .get();
      responsePoints.docs.map((doc) => {
        const pointData = doc.data();
        const refData = storage.ref(pointData?.Ubicacion);
        try {
          refData.delete();
        } catch (errorDeletePoint) {}
      });
      const traducciones: any = tour.Traducciones || {};
      Object.keys(traducciones).map(async (key: any) => {
        let refTraductionData;
        refTraductionData = storage.ref(
          `${tour.Guia.Id}/Tour/${tour.id}/${key}/video_bienvenida.mp4`
        );
        try {
          await refTraductionData.delete();
        } catch (errorDeleteRef) {}
        refTraductionData = storage.ref(
          `${tour.Guia.Id}/Tour/${tour.id}/${key}/video_finalizacion.mp4`
        );
        try {
          await refTraductionData.delete();
        } catch (errorDeleteTraductionData) {}
      });

      await firestore.collection("Tours").doc(tour.id).update({
        Nombre: firebase.firestore.FieldValue.delete(),
        Estado: "Eliminado",
        Deleted: true,
      });
      dispatch(openSnack("Tour Eliminado", SnackState.SUCCESS));
      dispatch({
        type: types.TOUR_DELETE_SUCCESS,
      });
      dispatch(getTours());
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.TOUR_DELETE_FAILURE,
        payload: error,
      });
    }
  };
};

export const updateTourIdioma = (tour: any, lenguage: any): AppThunk => {
  return async (dispatch) => {
    dispatch(updateIsLoading(true));
    try {
      await firestore
        .collection("Tours")
        .doc(tour)
        .update({
          Idiomas: firebase.firestore.FieldValue.arrayUnion(lenguage.Codigo),
        });

      var traduccionesUpdate: any = {};
      traduccionesUpdate[`Traducciones.${lenguage.Codigo}`] = {
        Idioma: lenguage.Nombre,
        Estado: "Pendiente",
        Itinerario: "",
        Nombre: "",
        VideoBienvenida: "",
      };
      // console.log(myObj);
      await firestore.collection("Tours").doc(tour).update(traduccionesUpdate);

      dispatch(getTour(tour));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(updateIsLoading(false));
    }
  };
};

export const updateTourIdiomaData = (
  tour: any,
  lenguage: any,
  data: any,
  video?: any,
  video2?: any
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading(true));
    const { user } = getState().authReducer;
    const {
      edit: { selectedTour },
    } = getState().toursReducer;
    try {
      //----------------------------------------------------------------
      if (user?.tipoUsuario !== "Administrador") {
        if (!selectedTour.Modificaciones) {
          selectedTour.Modificaciones = [];
        }
        const changesArr: any[] = [...selectedTour?.Modificaciones];
        const timeStampHere = firebase.firestore.Timestamp.now()
          .toDate()
          .toLocaleDateString("es");
        if (video || video2) {
          changesArr.push(
            `[${timeStampHere}] Ficha ` +
              lenguage.toUpperCase() +
              ": " +
              "Información y/o " +
              " Multimedia Modificada"
          );
        } else {
          changesArr.push(
            `[${timeStampHere}] Ficha ` +
              lenguage.toUpperCase() +
              ": " +
              "Información Modificada"
          );
        }

        await firestore
          .collection("Tours")
          .doc(tour.id)
          .update({ Estado: "Pendiente", Modificaciones: changesArr });
        selectedTour.Modificaciones = changesArr;
      }

      //---------
      // const dateMili = Date.parse(new Date().toString());

      if (video) {
        const videoRef = storage.ref(
          `${tour.Guia.Id}/Tour/${tour.id}/${lenguage}/video_bienvenida.mp4`
        );
        await videoRef.put(video);
        data.VideoBienvenida = await videoRef.getDownloadURL();
        data.Referencia = `${tour.Guia.Id}/Tour/${tour.id}/${lenguage}/video_bienvenida.mp4`;
      }
      if (video2) {
        const videoRef = storage.ref(
          `${tour.Guia.Id}/Tour/${tour.id}/${lenguage}/video_finalizacion.mp4`
        );
        await videoRef.put(video2);
        data.VideoFinalizacion = await videoRef.getDownloadURL();
        data.ReferenciaVideoFinalizacion = `${tour.Guia.Id}/Tour/${tour.id}/${lenguage}/video_finalizacion.mp4`;
      }

      var traduccionesUpdate: any = {};
      traduccionesUpdate[`Traducciones.${lenguage}`] = data;

      await firestore
        .collection("Tours")
        .doc(tour.id)
        .update(traduccionesUpdate);

      dispatch(getTour(tour.id));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(updateIsLoading(false));
    }
  };
};

export const deleteTourLenguage = (tour: any, lenguage: any): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.TOUR_LENGUAGE_DELETE_SUBMITTING,
    });
    try {
      let dato: any = tour.Traducciones;
      dato = Object.keys(dato)
        .filter((key) => key !== lenguage)
        .reduce((obj: any, key) => {
          obj[key] = dato[key];
          return obj;
        }, {});
      let peso: any = tour.PesoVideos;
      peso = Object.keys(peso)
        .filter((key) => key !== lenguage)
        .reduce((obj: any, key) => {
          obj[key] = peso[key];
          return obj;
        }, {});
      await firestore
        .collection("Tours")
        .doc(tour.id)
        .update({
          Traducciones: dato,
          Idiomas: firebase.firestore.FieldValue.arrayRemove(lenguage),
          PesoVideos: peso,
        });

      const videoRef_Bienvenida = storage.ref(
        `${tour.Guia.Id}/Tour/${tour.id}/${lenguage}/video_bienvenida.mp4`
      );
      const videoRef_Final = storage.ref(
        `${tour.Guia.Id}/Tour/${tour.id}/${lenguage}/video_finalizacion.mp4`
      );
      try {
        await videoRef_Bienvenida.delete();
      } catch (errorDelete) {}
      try {
        await videoRef_Final.delete();
      } catch (errorDelete) {}

      const responsePuntos = await firestore
        .collection("Tours")
        .doc(tour.id)
        .collection("Puntos")
        .where("Codigo", "==", lenguage)
        .get();
      responsePuntos.docs.map(async (doc) => {
        const punto = doc.data();
        const videoRef = storage.ref(punto?.Ubicacion);
        try {
          await videoRef.delete();
        } catch (errorDeletePoint) {}

        firestore
          .collection("Tours")
          .doc(tour.id)
          .collection("Puntos")
          .doc(doc.id)
          .delete();
      });
      dispatch(getTour(tour.id));
      dispatch({
        type: types.TOUR_LENGUAGE_DELETE_SUCCESS,
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.TOUR_LENGUAGE_DELETE_FAILURE,
      });
    }
  };
};

export const addMoreTours = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));

    const { tours, lastDoc, totalDocs } = getState().toursReducer;
    const { user } = getState().authReducer;
    try {
      let query = firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY)
        .startAfter(lastDoc)
        .limit(limit);

      if (user.tipoUsuario === "Guia") {
        query = query.where("Guia.Id", "==", user.id);
      }

      const response = await query.get();

      const array = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      tours.push(...array);
      dispatch({
        type: types.TOURS_KEEP_TOTAL_DOCS,
        payload: totalDocs,
      });
      // dispatch(addMoreToursToState(mapTours(response)));
      dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const updateTourMap = (
  tourId: string,
  path: any[],
  coordsPoints: any
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.TOUR_UPDATE_MAP_IS_SUBMITTING,
    });
    const { user } = getState().authReducer;
    const {
      edit: { selectedTour },
    } = getState().toursReducer;
    try {
      await Promise.all(
        Object.keys(coordsPoints).map(async (key) => {
          await firestore
            .collection("Tours")
            .doc(tourId)
            .collection("Puntos")
            .doc(key)
            .update({
              lat: coordsPoints[key].lat,
              lng: coordsPoints[key].lng,
            });
        })
      );

      const Path = path.map((data, index) => ({
        lng: data.geometry.coordinates[0],
        lat: data.geometry.coordinates[1],
        routeTo: data.routeTo
          ? data.routeTo.map((r: any) => ({ lng: r[0], lat: r[1] }))
          : [],
      }));

      const data: any = {
        Path: Path,
      };
      //----------------------------------------------------------------
      if (user?.tipoUsuario !== "Administrador") {
        if (!selectedTour.Modificaciones) {
          selectedTour.Modificaciones = [];
        }
        const changesArr: any[] = [...selectedTour?.Modificaciones];
        const timeStampHere = firebase.firestore.Timestamp.now()
          .toDate()
          .toLocaleDateString("es");
        changesArr.push(`[${timeStampHere}] Path Ruta Modificada`);

        data.Estado = "Pendiente";
        data.Modificaciones = changesArr;
        selectedTour.Modificaciones = changesArr;
      }

      //---------
      await firestore.collection("Tours").doc(tourId).update(data);

      selectedTour.Path = data.Path;
      dispatch({
        type: types.TOUR_UPDATE_MAP_SUCCESS,
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.TOUR_UPDATE_MAP_FAILURE,
        payload: error,
      });
    }
  };
};

export const getTotalDocs = (): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await firestore.collection("Guias").get();
      dispatch(setTotalDocs(response.size));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    }
  };
};

export const setSelectedTour = (tour: any): Action => ({
  type: types.TOURS_SET_SELECTED,
  payload: tour,
});

const mapIndexes = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  try {
    let puntos: any[] = [];
    response.docs.forEach((doc) => {
      let data = mapIndex(doc);
      puntos.push(data);
    });
    return puntos;
  } catch (error) {
    throw error;
  }
};

const mapIndex = (doc: any) => {
  let data = doc.data();
  data.id = doc.id;
  return data;
};

const mapTours = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  try {
    let tours: any[] = [];
    response.docs.forEach((doc) => {
      let data = mapTour(doc);
      tours.push(data);
    });
    return tours;
  } catch (error) {
    throw error;
  }
};

const mapTour = (doc: any) => {
  let data = doc.data();
  data.id = doc.id;
  return data;
};

const setTotalDocs = (total: number): Action => ({
  type: types.TOURS_SET_TOTAL_DOCS,
  payload: total,
});

const setTours = (tours: any[], totalDocs: any, lastDoc: any): Action => ({
  type: types.TOURS_GET_DOCS,
  payload: { tours: tours, totalDocs: totalDocs, lastDoc: lastDoc },
});

export const setIndex = (index: any[]): Action => ({
  type: types.TOURS_INDEX_GET_DOCS,
  payload: index,
});

const addMoreToursToState = (tours: any[]): Action => ({
  type: types.TOURS_ADD_DOCS,
  payload: tours,
});

const setLastDoc = (doc: any): Action => ({
  type: types.TOURS_SET_LAST_DOC,
  payload: doc,
});

const updateIsLoading = (isLoading: boolean): Action => ({
  type: types.TOURS_LOADING,
  payload: isLoading,
});

const addIndexIsLoading = (isLoading: boolean): Action => ({
  type: types.TOURS_ADD_INDEX_LOADING,
  payload: isLoading,
});

const indexIsLoading = (isLoading: boolean): Action => ({
  type: types.TOURS_INDEX_LOADING,
  payload: isLoading,
});

export const changeFormState = (formState: FormState): Action => ({
  type: types.TOURS_CHANGE_FORM_STATE,
  payload: formState,
});

const isLoading = (isLoading: boolean): Action => ({
  type: types.TOURS_LOADING,
  payload: isLoading,
});

const setError = (error: string): Action => ({
  type: types.TOURS_FAILURE,
  payload: error,
});

export const restoreGetLenguagePoints = (): Action => ({
  type: types.TOUR_LENGUAGE_POINT_GET_RESTORE,
});
