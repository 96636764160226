import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Link,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import {
  AccountCircle,
  Assignment,
  Call,
  CloudUpload,
  Email,
  LocationOn,
  LinkSharp as LinkIcon,
} from "@material-ui/icons";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { withStyles } from "@material-ui/styles";
import { spacing } from "@material-ui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Download as DownloadIcon,
  Edit,
  File,
  Percent,
  Smile,
  Trash2 as RemoveIcon,
} from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import * as yup from "yup";
import { DialogDetailsConversor } from "../components/DialogDetailsConversor";
import { DialogLoad } from "../components/DialogLoad";
import { NewConfirmDialog } from "../components/NewConfirmDialog";
import { FormState } from "../models/form_state";
import { getRegions } from "../redux/actions/regionsActions";
import { getTourGuide } from "../redux/actions/tourGuidesActions";
import {
  addUserGuideVideo,
  deleteUserGuideVideo,
  updateTourGuideUser,
} from "../redux/actions/usersActions";
import { IAuthState } from "../redux/reducers/authReducer";
import { IRegionsState } from "../redux/reducers/regionsReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { ITourGuidesState } from "../redux/reducers/tourGuidesReducer";
import { IUsersState } from "../redux/reducers/usersReducer";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const CssTextField = withStyles({
  root: {
    "& label": { color: "#004ecb" },
    "& label.Mui-focused": {
      color: "#004ecb",
      fontWeight: "bold",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000",
    },
    "& input": {
      color: "#2979ff",
      "& placeholder": { color: "#ffffff" },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#004ecb",
      },
      "&:hover fieldset": {
        borderColor: "#004ecb",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#004ecb",
      },
    },
  },
})(TextField);

const idiomas = ["Ingles", "Español", "Portugues"];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

const useStyles2 = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 300,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

interface CardProps {
  guide?: any;
  guideId: any;
  isLoading: boolean;
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const ContentCard: React.FC<CardProps> = ({ guide, guideId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // CONVERSOR DETAILS DIALOG
  const [openDialog, setOpenDialog] = useState(false);
  //

  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );
  const { regions } = useSelector<RootState, IRegionsState>(
    (state) => state.regionsReducer
  );
  const {
    guideVideo,
    edit: { isLoading },
  } = useSelector<RootState, IUsersState>((state) => state.usersReducer);
  const [valueRegion, setValueRegion] = React.useState<string | null>(null);
  const [valueIdioma, setValueIdioma] = React.useState<string | null>(
    "Español"
  );
  const [image, setImage] = React.useState<any | undefined>(null);
  const [video, setVideo] = React.useState<any | undefined>(null);
  const [edit, setEdit] = React.useState(false);

  const [certificadoIniciacion, setCertIniciacion] = useState<any>(null);
  const [checkCert1, setCheckCert1] = useState(true);
  const [certificadoRegistroSernatur, setCertRegistroSernatur] =
    useState<any>(null);
  const [checkCert2, setCheckCert2] = useState(true);
  const [patenteComercial, setPatenteComerical] = useState<any>(null);
  const [checkCert3, setCheckCert3] = useState(true);
  const [cedulaRepresentante, setCedulaRepresentante] = useState<any>(null);
  const [checkCert4, setCheckCert4] = useState(true);
  const guideDocuments: any = {};

  const downloadThis = (fileUrl: any) => {
    const link = document.createElement("a");
    link.setAttribute("href", fileUrl);
    link.setAttribute("target", "_blank");
    link.setAttribute("download", "pdfFile");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const restoreInitial = () => {
    setCheckCert1(true);
    setCheckCert2(true);
    setCheckCert3(true);
    setCheckCert4(true);
    setCertIniciacion(null);
    setCertRegistroSernatur(null);
    setPatenteComerical(null);
    setCedulaRepresentante(null);
  };

  useEffect(() => {
    if (isLoading === false) {
      restoreInitial();
      setImage(null);
    }
  }, [isLoading]);

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    resetForm,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      id: "",
      Nombre: "",
      Apellido: "",
      Perfil: "",
      Telefono: "",
      Email: "",
      LinkPropinas: "",
      Region: "",
      Descripcion: "",
      DescripcionEn: "",
      DescripcionPt: "",
      Porcentaje: 50,
      CertificadoAntecedentes: "",
      CedulaIdentidad: "",
      CertificadoTributario: "",
      CertificadoCompetencias: "",
    },
    onSubmit: () => {
      if (certificadoIniciacion) {
        guideDocuments["CertificadoAntecedentes"] = certificadoIniciacion;
      }
      if (certificadoRegistroSernatur) {
        guideDocuments["CedulaIdentidad"] = certificadoRegistroSernatur;
      }
      if (patenteComercial) {
        guideDocuments["CertificadoTributario"] = patenteComercial;
      }
      if (cedulaRepresentante) {
        guideDocuments["CertificadoCompetencias"] = cedulaRepresentante;
      }

      const newValues: any = values;
      if (!values.LinkPropinas) {
        delete newValues.LinkPropinas;
      }
      if (!values.Descripcion) {
        delete newValues.Descripcion;
      }
      if (!values.DescripcionEn) {
        delete newValues.DescripcionEn;
      }
      if (!values.DescripcionPt) {
        delete newValues.DescripcionPt;
      }
      setEdit(false);
      if (image !== null) {
        dispatch(updateTourGuideUser(newValues, image, guideDocuments));
      } else {
        dispatch(updateTourGuideUser(newValues, null, guideDocuments));
      }
    },
    validationSchema: yup.object({
      Descripcion: yup.string().max(280, "Maximo 280 caracteres"),
    }),
  });

  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState("");

  const handleDeleteLenguage = (id: string, idiomas: any, nombre: string) => {
    dispatch(deleteUserGuideVideo(id, idiomas, nombre));
    setOpenConfirm(!openConfirm);
  };

  const setFormValues = () => {
    setValues({
      id: guideId,
      Nombre: guide?.Nombre,
      Apellido: guide?.Apellido,
      Perfil: guide?.Perfil,
      Telefono: guide?.Telefono,
      Email: guide?.Email,
      LinkPropinas: guide?.LinkPropinas,
      Region: guide?.Region,
      Descripcion: guide?.Descripcion || "",
      DescripcionEn: guide?.DescripcionEn || "",
      DescripcionPt: guide?.DescripcionPt || "",
      Porcentaje: guide?.Porcentaje || 50,
      CertificadoAntecedentes: guide?.CertificadoAntecedentes || "",
      CedulaIdentidad: guide?.CedulaIdentidad || "",
      CertificadoTributario: guide?.CertificadoTributario || "",
      CertificadoCompetencias: guide?.CertificadoCompetencias || "",
    });
  };

  useEffect(() => {
    if (guide) {
      setFormValues();
    }
  }, [guide]);
  return (
    <Card mb={6}>
      {user?.tipoUsuario === "Administrador" && (
        <Grid container justify="flex-end">
          <Grid item>
            <div style={{ padding: 20, paddingRight: 15 }}>
              <Button
                size="medium"
                variant="contained"
                color="primary"
                component="label"
                // disabled
                startIcon={<DownloadIcon />}
                onClick={() => setOpenDialog(true)}
              >
                Detalle uso conversor
              </Button>
            </div>
          </Grid>
        </Grid>
      )}

      <CardHeader
        action={
          <Button
            color={edit ? "inherit" : "primary"}
            startIcon={!edit && <Edit />}
            variant="contained"
            onClick={() => {
              if (edit) {
                if (guide) {
                  setFormValues();
                }
              }
              setEdit(!edit);
            }}
          >
            {edit ? "Cancelar" : "Editar"}
          </Button>
        }
      />
      <form noValidate onSubmit={handleSubmit}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} style={{ height: 330 }}>
              <Box display="flex" justifyContent="center">
                {edit && (
                  <input
                    style={{
                      display: "none",
                    }}
                    onChange={(event) => {
                      if (event.target.files) {
                        if (event.target.files[0].size > 543718) {
                          alert(
                            "Archivo demasiado pesado. Debe ser menor a 500kb"
                          );
                        } else {
                          setImage(event.target.files[0]);
                        }
                      }
                    }}
                    id="contained-button-file"
                    type="file"
                  />
                )}
                <Paper
                  elevation={0}
                  variant="outlined"
                  style={{
                    height: 260,
                    width: 260,
                    borderRadius: 260,
                    marginBottom: 10,
                    overflow: "hidden",
                  }}
                >
                  <label
                    style={{
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      alignSelf: "center",
                      justifyContent: "center",
                    }}
                    htmlFor="contained-button-file"
                  >
                    {!image && !values.Perfil ? (
                      <Typography
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: blue[400],
                        }}
                      >
                        <Smile size={20} style={{ marginRight: 5 }} />
                      </Typography>
                    ) : (
                      <img
                        src={
                          image ? URL.createObjectURL(image) : values?.Perfil
                        }
                        style={{
                          height: 260,
                          width: 260,
                          objectFit: "cover",
                        }}
                        alt={"UploadImage"}
                      />
                    )}
                  </label>
                </Paper>
              </Box>
              <Box display="flex" justifyContent="center">
                {edit && (
                  <Button
                    size="small"
                    variant="outlined"
                    component="label"
                    color="primary"
                    disabled={!edit}
                  >
                    Seleccionar Imagen
                    <input
                      type="file"
                      // accept="video/mp4,video/x-m4v,video/*"
                      hidden
                      onChange={(event) => {
                        if (event.target.files) {
                          const file = event.target.files[0];
                          if (file.size > 543718) {
                            alert(
                              "Archivo demasiado pesado. Debe ser menor a 500kb"
                            );
                          } else {
                            setImage(file);
                          }
                        }
                      }}
                    />
                  </Button>
                )}{" "}
              </Box>
            </Grid>
            <Grid item container spacing={2} sm={8} xs={12}>
              <Grid item xs={6} sm={6}>
                <Box display="flex">
                  <Box mt={4} mr={2}>
                    <AccountCircle />
                  </Box>
                  <CssTextField
                    id="Nombre"
                    label="Nombre"
                    // margin="dense"
                    variant="outlined"
                    onChange={handleChange}
                    value={values?.Nombre}
                    fullWidth
                    disabled={!edit}
                    inputProps={{ readOnly: !edit }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <CssTextField
                  id="Apellido"
                  label="Apellido"
                  // margin="dense"
                  variant="outlined"
                  onChange={handleChange}
                  value={values?.Apellido}
                  fullWidth
                  disabled={!edit}
                  inputProps={{ readOnly: !edit }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box display="flex">
                  <Box mt={4} mr={2}>
                    <Email />
                  </Box>
                  <CssTextField
                    id="Email"
                    label="Email"
                    // margin="dense"
                    variant="outlined"
                    onChange={handleChange}
                    value={values?.Email}
                    fullWidth
                    disabled={!edit}
                    inputProps={{ readOnly: !edit }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} sm={12}>
                <Box display="flex">
                  <Box mt={4} mr={2}>
                    <LinkIcon />
                  </Box>
                  <CssTextField
                    disabled={!edit}
                    id="LinkPropinas"
                    label="Link Propinas"
                    variant="outlined"
                    onChange={handleChange}
                    value={values?.LinkPropinas}
                    fullWidth
                    inputProps={{ readOnly: !edit }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex">
                  <Box mt={4} mr={2}>
                    <LocationOn />
                  </Box>
                  <Autocomplete
                    id="region-select"
                    disabled={!edit}
                    value={
                      regions.find(
                        (region) => region.Nombre === values?.Region
                      ) || null
                    }
                    options={regions}
                    getOptionLabel={(option: any) => option?.Nombre}
                    fullWidth
                    onChange={(event: any, newValue: any | null) => {
                      setValueRegion(newValue);
                      if (newValue) {
                        setFieldValue("Region", newValue?.Nombre);
                      }
                    }}
                    renderInput={(params: any) => (
                      <CssTextField
                        disabled={!edit}
                        {...params}
                        label="Región"
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex">
                  <Box mt={4} mr={2}>
                    <Call />
                  </Box>
                  <CssTextField
                    id="Telefono"
                    label="Teléfono"
                    // margin="dense"
                    variant="outlined"
                    onChange={handleChange}
                    value={values?.Telefono}
                    fullWidth
                    disabled={!edit}
                    inputProps={{ readOnly: !edit }}
                  />
                </Box>
              </Grid>
              {guide?.Idiomas?.Español && (
                <Grid item xs={12} sm={12}>
                  <Box display="flex">
                    <Box mt={4} mr={2}>
                      <Assignment />
                    </Box>
                    <CssTextField
                      disabled={!edit}
                      id="Descripcion"
                      label="Descripción Español"
                      multiline
                      InputProps={{
                        style: {
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "100%",
                          color: "#2979ff",
                        },
                      }}
                      rows={4}
                      variant="outlined"
                      onChange={handleChange}
                      value={values?.Descripcion}
                      fullWidth
                      inputProps={{ readOnly: !edit, maxLength: 280 }}
                      error={touched.Descripcion && Boolean(errors.Descripcion)}
                      helperText={touched.Descripcion && errors.Descripcion}
                    />
                  </Box>
                </Grid>
              )}
              {guide?.Idiomas?.Ingles && (
                <Grid item xs={12} sm={12}>
                  <Box display="flex">
                    <Box mt={4} mr={2}>
                      <Assignment />
                    </Box>
                    <CssTextField
                      id="DescripcionEn"
                      label="Descripción Inglés"
                      multiline
                      disabled={!edit}
                      rows={4}
                      variant="outlined"
                      onChange={handleChange}
                      value={values?.DescripcionEn}
                      fullWidth
                      inputProps={{ readOnly: !edit, maxLength: 280 }}
                      error={touched.Descripcion && Boolean(errors.Descripcion)}
                      helperText={touched.Descripcion && errors.Descripcion}
                    />
                  </Box>
                </Grid>
              )}
              {guide?.Idiomas?.Portugues && (
                <Grid item xs={12} sm={12}>
                  <Box display="flex">
                    <Box mt={4} mr={2}>
                      <Assignment />
                    </Box>
                    <CssTextField
                      id="DescripcionPt"
                      label="Descripción Portugues"
                      multiline
                      disabled={!edit}
                      rows={4}
                      variant="outlined"
                      onChange={handleChange}
                      value={values?.DescripcionPt}
                      fullWidth
                      inputProps={{ readOnly: !edit, maxLength: 280 }}
                      error={touched.Descripcion && Boolean(errors.Descripcion)}
                      helperText={touched.Descripcion && errors.Descripcion}
                    />
                  </Box>
                </Grid>
              )}
              {user?.tipoUsuario === "Administrador" && (
                <Grid item xs={12} sm={4}>
                  <Box display="flex">
                    <Box mt={4} mr={2}>
                      <Percent />
                    </Box>
                    <CssTextField
                      id="Porcentaje"
                      label="Porcentaje Comisión Ventas"
                      // margin="dense"
                      type="number"
                      disabled={!edit}
                      variant="outlined"
                      onChange={handleChange}
                      value={values?.Porcentaje}
                      fullWidth
                      inputProps={{ readOnly: !edit }}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              container
              justify="flex-end"
              style={{ height: 40 }}
            >
              {edit && (
                <Button type="submit" variant="contained" disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    "Guardar"
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </form>
      <DialogLoad open={isLoading} onClose={() => {}} />
      <CardContent>
        <Typography variant="h5" component="h5" gutterBottom>
          Archivos
        </Typography>
      </CardContent>
      <VerticalTabs
        guide={guide}
        values={values}
        editable={!edit}
        guideId={guide?.id ? guide?.id : ""}
        idiomasGuia={guide?.Idiomas || {}}
        openConfirm={openConfirm}
        setOpenConfirm={setOpenConfirm}
        setDeleteTarget={setDeleteTarget}
        guideVideoState={guideVideo?.state}
        guideVideoPercentage={guideVideo?.percentage}
        video={video}
        setVideo={setVideo}
        checkCert1={checkCert1}
        checkCert2={checkCert2}
        checkCert3={checkCert3}
        checkCert4={checkCert4}
        setCheckCert1={setCheckCert1}
        setCheckCert2={setCheckCert2}
        setCheckCert3={setCheckCert3}
        setCheckCert4={setCheckCert4}
        certificadoIniciacion={certificadoIniciacion}
        certificadoRegistroSernatur={certificadoRegistroSernatur}
        patenteComercial={patenteComercial}
        cedulaRepresentante={cedulaRepresentante}
        setCertIniciacion={setCertIniciacion}
        setCertRegistroSernatur={setCertRegistroSernatur}
        setPatenteComerical={setPatenteComerical}
        setCedulaRepresentante={setCedulaRepresentante}
        downloadThis={downloadThis}
      />

      <NewConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(!openConfirm)}
        title="¿Desea eliminar este video?"
        onConfirmText="Eliminar"
        onConfirm={() => {
          delete guide?.Idiomas[deleteTarget];
          handleDeleteLenguage(guide.id, guide.Idiomas, deleteTarget);
        }}
      />
      <DialogDetailsConversor
        user={guideId}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </Card>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{
        width: "100%",

        flexFlow: "column",
        flex: "auto",
        overscrollBehavior: "auto",
        overflow: "scroll",
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

interface GuideTabsProps {
  guideId: string;
  guide: any;
  values: any;
  editable: boolean;
  idiomasGuia: any;
  openConfirm: boolean;
  setOpenConfirm: (val: boolean) => void;
  setDeleteTarget: (val: string) => void;
  guideVideoState: any;
  guideVideoPercentage: number;
  video: any | undefined;
  setVideo: (val: any | undefined) => void;
  checkCert1: boolean;
  checkCert2: boolean;
  checkCert3: boolean;
  checkCert4: boolean;
  setCheckCert1: (val: boolean) => void;
  setCheckCert2: (val: boolean) => void;
  setCheckCert3: (val: boolean) => void;
  setCheckCert4: (val: boolean) => void;
  certificadoIniciacion: any;
  certificadoRegistroSernatur: any;
  patenteComercial: any;
  cedulaRepresentante: any;
  setCertIniciacion: (val: any) => void;
  setCertRegistroSernatur: (val: any) => void;
  setPatenteComerical: (val: any) => void;
  setCedulaRepresentante: (val: any) => void;
  downloadThis: (val: any) => void;
}

function VerticalTabs(props: GuideTabsProps) {
  const classes = useStyles2();
  const dispatch = useDispatch();
  const {
    guideId,
    guide,
    editable,
    idiomasGuia,
    openConfirm,
    setOpenConfirm,
    setDeleteTarget,
    guideVideoState,
    guideVideoPercentage,
    video,
    values,
    setVideo,
    checkCert1,
    checkCert2,
    checkCert3,
    checkCert4,
    setCheckCert1,
    setCheckCert2,
    setCheckCert3,
    setCheckCert4,
    certificadoIniciacion,
    certificadoRegistroSernatur,
    patenteComercial,
    cedulaRepresentante,
    setCertIniciacion,
    setCertRegistroSernatur,
    setPatenteComerical,
    setCedulaRepresentante,
    downloadThis,
    ...other
  } = props;
  const [value, setValue] = React.useState(0);
  const [valueIdioma, setValueIdioma] = React.useState<string | null>(
    "Español"
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab style={{ color: "#004ecb" }} label="Videos" {...a11yProps(0)} />
        <Tab
          style={{ color: "#004ecb" }}
          label="Documentos"
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <CardContent
          style={{
            marginTop:
              guideVideoState === FormState.Submitting &&
              guideVideoPercentage > 0
                ? 0
                : -40,
          }}
        >
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12}>
              {guideVideoState === FormState.Submitting &&
                guideVideoPercentage > 0 && (
                  <LinearProgressWithLabel value={guideVideoPercentage} />
                )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                style={{ height: 37 }}
                variant="outlined"
                component="label"
              >
                Subir Video
                <input
                  type="file"
                  hidden
                  onChange={(event) => {
                    if (event.target.files) {
                      setVideo(event.target.files[0]);
                    }
                  }}
                />
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="combo-box-demo"
                value={valueIdioma}
                options={idiomas}
                size="small"
                getOptionLabel={(option) => option}
                onChange={(_, value) => setValueIdioma(value)}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Idiomas" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                size="large"
                variant="contained"
                component="label"
                color="primary"
                disabled={!video}
                onClick={() => {
                  const vid = video;
                  setVideo(null);
                  dispatch(addUserGuideVideo(vid, guideId, valueIdioma));
                }}
              >
                Agregar
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <TableContainer>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Idioma</TableCell>
                    <TableCell align="center">Video</TableCell>
                    <TableCell align="center">Duración (Minutos)</TableCell>
                    <TableCell align="center">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {idiomasGuia
                    ? Object.values(idiomasGuia).map(
                        (value: any, index: number) => (
                          <TableRow hover key={index}>
                            <TableCell>{value?.Nombre}</TableCell>
                            <TableCell align="center">
                              <Button href={value?.Link} target="_blank">
                                Ver Video
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              {(value?.Duracion / 60).toFixed(2)}
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title="Descargar">
                                <IconButton color="primary" size="small">
                                  <a href={value?.Link} download>
                                    <DownloadIcon />
                                  </a>
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Eliminar">
                                <span>
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      setOpenConfirm(!openConfirm);
                                      setDeleteTarget(value.Nombre);
                                    }}
                                  >
                                    <RemoveIcon color={"red"} />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CardContent>
          <Grid container spacing={3}>
            <TableContainer>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Tipo</TableCell>
                    <TableCell align="center">Descarga</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover>
                    <TableCell>
                      <Button
                        size="small"
                        variant="contained"
                        component="label"
                        startIcon={<CloudUpload />}
                        disabled={guide ? editable : false}
                        fullWidth
                      >
                        Certificación Antecedentes.
                        <input
                          type="file"
                          accept="application/pdf"
                          hidden
                          onChange={(event) => {
                            if (event.target.files) {
                              setCheckCert1(true);
                              setCertIniciacion(event.target.files[0]);
                            }
                          }}
                        />
                      </Button>
                      {!checkCert1 && (
                        <Typography color="error">
                          Este documento es requerido.
                        </Typography>
                      )}
                      {certificadoIniciacion && (
                        <Typography>{certificadoIniciacion!.name}</Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {values?.CertificadoAntecedentes && (
                        <Button
                          size="small"
                          variant="outlined"
                          component="label"
                          startIcon={<File />}
                          onClick={() =>
                            downloadThis(values.CertificadoAntecedentes)
                          }
                        >
                          Descargar PDF
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>
                      <Button
                        size="small"
                        variant="contained"
                        component="label"
                        startIcon={<CloudUpload />}
                        disabled={guide ? editable : false}
                        fullWidth
                      >
                        Cédula de Identidad.
                        <input
                          type="file"
                          accept="application/pdf"
                          hidden
                          onChange={(event) => {
                            if (event.target.files) {
                              setCheckCert2(true);
                              setCertRegistroSernatur(event.target.files[0]);
                            }
                          }}
                        />
                      </Button>
                      {!checkCert2 && (
                        <Typography color="error">
                          Este documento es requerido.
                        </Typography>
                      )}
                      {certificadoRegistroSernatur && (
                        <Typography>
                          {certificadoRegistroSernatur!.name}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {values?.CedulaIdentidad && (
                        <Button
                          size="small"
                          variant="outlined"
                          component="label"
                          startIcon={<File />}
                          onClick={() => downloadThis(values.CedulaIdentidad)}
                        >
                          Descargar PDF
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>
                      <Button
                        size="small"
                        variant="contained"
                        component="label"
                        startIcon={<CloudUpload />}
                        disabled={guide ? editable : false}
                        fullWidth
                      >
                        Certificado de Iniciación de Actividades o Cartola
                        Tributaria.
                        <input
                          type="file"
                          accept="application/pdf"
                          hidden
                          onChange={(event) => {
                            if (event.target.files) {
                              setCheckCert3(true);
                              setPatenteComerical(event.target.files[0]);
                            }
                          }}
                        />
                      </Button>
                      {!checkCert3 && (
                        <Typography color="error">
                          Este documento es requerido.
                        </Typography>
                      )}
                      {patenteComercial && (
                        <Typography>{patenteComercial!.name}</Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {values?.CertificadoTributario && (
                        <Button
                          size="small"
                          variant="outlined"
                          component="label"
                          startIcon={<File />}
                          onClick={() =>
                            downloadThis(values.CertificadoTributario)
                          }
                        >
                          Descargar PDF
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>
                      <Button
                        size="small"
                        variant="contained"
                        component="label"
                        startIcon={<CloudUpload />}
                        disabled={guide ? editable : false}
                        fullWidth
                      >
                        Certificado Acreditación Conocimientos y Competencias.
                        <input
                          type="file"
                          accept="application/pdf"
                          hidden
                          onChange={(event) => {
                            if (event.target.files) {
                              setCheckCert4(true);
                              setCedulaRepresentante(event.target.files[0]);
                            }
                          }}
                        />
                      </Button>
                      {!checkCert4 && (
                        <Typography color="error">
                          Este documento es requerido.
                        </Typography>
                      )}
                      {cedulaRepresentante && (
                        <Typography>{cedulaRepresentante!.name}</Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {values?.CertificadoCompetencias && (
                        <Button
                          size="small"
                          variant="outlined"
                          component="label"
                          startIcon={<File />}
                          onClick={() =>
                            downloadThis(values.CertificadoCompetencias)
                          }
                        >
                          Descargar PDF
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Typography color="error" gutterBottom style={{ marginTop: 15 }}>
            * Sólo se admiten documentos en formato PDF
          </Typography>
        </CardContent>
      </TabPanel>
    </div>
  );
}

export const IndexCardTourGuides = () => {
  const dispatch = useDispatch();
  const { guideId } = useParams<any>();

  const {
    edit: { selectedGuide, isLoading },
  } = useSelector<RootState, ITourGuidesState>(
    (state) => state.tourGuidesReducer
  );
  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );
  const { regions } = useSelector<RootState, IRegionsState>(
    (state) => state.regionsReducer
  );

  useEffect(() => {
    dispatch(getTourGuide(guideId));
  }, [dispatch, guideId]);

  useEffect(() => {
    if (regions.length === 0) {
      dispatch(getRegions());
    }
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Ficha Guía" />
      {!isLoading && selectedGuide ? (
        <Typography variant="h3" gutterBottom display="inline">
          {selectedGuide?.Nombre + " " + selectedGuide?.Apellido}
        </Typography>
      ) : (
        <Skeleton height={40} variant="rect" />
      )}

      {!isLoading ? (
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link
            component={NavLink}
            exact
            to={user?.tipoUsuario !== "Guia" ? "/guias" : "/tours"}
          >
            {user?.tipoUsuario !== "Guia" ? "Guías" : "Tours"}
          </Link>
          <Typography>
            {user?.tipoUsuario === "Guia" ? "Perfil " : "Ficha "}
          </Typography>
        </Breadcrumbs>
      ) : (
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} exact to={"Eliminar"}>
            {""}
          </Link>
          <Typography>{""}</Typography>
        </Breadcrumbs>
      )}

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {!isLoading && (
            <ContentCard
              guide={selectedGuide}
              guideId={guideId}
              isLoading={isLoading}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
