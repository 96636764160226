import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  InputAdornment,
  LinearProgress,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IIdiomasState } from "../../redux/reducers/idiomasReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { getIdiomas } from "../../redux/actions/idiomasActions";
import { Autocomplete } from "@material-ui/lab";
import { IToursState } from "../../redux/reducers/toursReducer";
import {
  deleteTourLenguage,
  updateTourIdiomaData,
} from "../../redux/actions/toursActions";
import { IAuthState } from "../../redux/reducers/authReducer";
import { NewConfirmDialog } from "../NewConfirmDialog";
import { Airplay, Monitor } from "react-feather";
import logo from "../../vendor/logoTrave.png";
import { Bookmark } from "@material-ui/icons";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  chipRoot: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: "100%",
    display: "flex",
  },
  chipLabel: {
    overflowWrap: "break-word",
    whiteSpace: "normal",
    textOverflow: "clip",
  },
}));
// VerticalTabs interface
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface Props {
  selectedTour?: any;
  onAddLenguage: (values: any) => void;
}

interface TabsProps {
  tour?: any;
}

interface GridProps {
  data: any;
  tour: any;
  handleDelete: (tour: any, lenguage: any) => void;
}

//
function DataGrid(props: GridProps) {
  const classes = useStyles();
  const { data, tour, handleDelete, ...other } = props;

  const dispatch = useDispatch();

  const { isLoading } = useSelector<RootState, IToursState>(
    (state) => state.toursReducer
  );

  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );

  const [file, setFile] = useState<any | undefined>(null);
  const [file2, setFile2] = useState<any | undefined>(null);

  const [estado, setEstado] = useState(tour.Traducciones[data].Estado);

  const [nombre, setNombre] = useState(tour.Traducciones[data].Nombre);

  const [openConfirm, setOpenConfirm] = useState(false);

  const [itinerario, setItinerario] = useState(
    tour.Traducciones[data].Itinerario
  );
  const defaultRecomendation =
    "* Esta app ha sido creada para ser utilizada por el copiloto o acompañantes, si viajas solo, manipula tu App con el vehículo detenido para así evitar accidentes";
  const defaultRecomendation2 = `* Para optimizar la reproducción de los relatos, te recomendamos conectar el sonido de tu móvil al audio de tu auto o audífonos`;
  const defaultRecomendation3 = `* Una vez que des inicio al tour, tendrás 3 días para reproducirlo las veces que lo desees, luego este quedará como reproducido.`;
  const variantRec2 = `* Para optimizar la reproducción de los relatos, les recomendamos conectar el sonido del móvil al audio del vehículo o audífonos`;
  const textRecomendaciones = (txt: string | undefined = "") => {
    let texto = txt || "";
    if (texto && texto !== undefined) {
      if (
        !texto.includes(defaultRecomendation2) &&
        !texto.includes(variantRec2)
      ) {
        texto = defaultRecomendation2 + "\n" + texto;
      }
      // if (!texto.includes(defaultRecomendation3)) {
      //   texto = defaultRecomendation3 + "\n" + texto;
      // }
      // if (!texto.includes(defaultRecomendation)) {
      //   texto = defaultRecomendation + "\n" + texto;
      // }
    }
    if (texto && texto !== undefined) {
      // if (!texto.includes(defaultRecomendation2)) {
      //   texto = defaultRecomendation2 + "\n" + texto;
      // }
      if (!texto.includes(defaultRecomendation3)) {
        texto = defaultRecomendation3 + "\n" + texto;
      }
      // if (!texto.includes(defaultRecomendation)) {
      //   texto = defaultRecomendation + "\n" + texto;
      // }
    }
    if (texto && texto !== undefined) {
      // if (!texto.includes(defaultRecomendation2)) {
      //   texto = defaultRecomendation2 + "\n" + texto;
      // }
      // if (!texto.includes(defaultRecomendation3)) {
      //   texto = defaultRecomendation3 + "\n" + texto;
      // }
      if (!texto.includes(defaultRecomendation)) {
        texto = defaultRecomendation + "\n" + texto;
      }
    }
    return texto;
  };

  const [recomendaciones, setRecomendaciones] = useState(
    textRecomendaciones(tour.Traducciones[data]?.Recomendaciones) ||
      defaultRecomendation + "\n" + defaultRecomendation2 + "\n"
  );

  const [valueEstado, setValueEstado] = useState([
    "Pendiente",
    "Aprobado",
    "Rechazado",
  ]);

  const SaveData = () => {
    const lenguageData = {
      Idioma: tour.Traducciones[data].Idioma,
      Estado: estado,
      Itinerario: itinerario,
      Recomendaciones: recomendaciones,
      Nombre: nombre,
      VideoBienvenida: tour.Traducciones[data].VideoBienvenida || "",
      VideoFinalizacion: tour.Traducciones[data].VideoFinalizacion || "",
    };
    const vid1 = file;
    const vid2 = file2;
    setFile(null);
    setFile2(null);
    dispatch(updateTourIdiomaData(tour, data, lenguageData, vid1, vid2));
  };

  const handleConfirmDelete = () => {
    // console.log(data);
    handleDelete(tour, data);

    setOpenConfirm(!openConfirm);
  };
  return (
    <>
      <Grid container spacing={2} justify="flex-start">
        {isLoading ? (
          <Grid item sm={12} xs={12}>
            <Box style={{ width: "100%", margin: 20 }}>
              <LinearProgress />
            </Box>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={12} sm={1}>
          <Typography style={{ marginTop: 10 }} variant="subtitle2">
            Estado:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Autocomplete
            id="combo-box-estado"
            disabled={user?.tipoUsuario !== "Administrador" ? true : false}
            value={estado}
            options={valueEstado}
            getOptionLabel={(option) => option}
            onChange={(event: any, newValue: any) => {
              if (newValue) {
                setEstado(newValue);
              }
            }}
            size="small"
            fullWidth
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Grid>
      </Grid>

      <Grid
        style={{ marginTop: 20 }}
        container
        justify="flex-start"
        spacing={2}
      >
        <Grid item sm={6} xs={12}>
          <TextField
            name="Nombre"
            value={nombre}
            label="Nombre Alterno"
            placeholder="Nombre"
            autoComplete="off"
            variant="outlined"
            onChange={(event) => setNombre(event.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextField
            multiline={true}
            rows={8}
            value={itinerario}
            name="Itinerario"
            label="Itinerario"
            autoComplete="off"
            variant="outlined"
            onChange={(event) => setItinerario(event.target.value)}
            fullWidth
          />
        </Grid>
        {/* <Grid item sm={12} xs={12}>
          <Box mb={3}>
            <Chip
              classes={{ root: classes.chipRoot, label: classes.chipLabel }}
              avatar={
                <Bookmark
                  style={{
                    color: "#2979ff",
                  }}
                  color="primary"
                >
                  M
                </Bookmark>
              }
              label={recomendation}
            />
          </Box>
        </Grid> */}
        <Grid item sm={12} xs={12}>
          <TextField
            multiline={true}
            rows={8}
            value={recomendaciones}
            name="Recomendaciones"
            label="Recomendaciones"
            autoComplete="off"
            variant="outlined"
            onChange={(event) => setRecomendaciones(event.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card style={{ maxHeight: 250 }}>
            {tour?.Traducciones[data]?.VideoBienvenida && !file && (
              <CardMedia
                component="video"
                style={{ maxHeight: 250 }}
                title="Video Bienvenida"
                controls
                src={tour?.Traducciones[data]?.VideoBienvenida}
              />
            )}
            {file ? (
              <CardMedia
                component="video"
                style={{ maxHeight: 250 }}
                title="Video Bienvenida"
                controls
                src={URL.createObjectURL(file)}
              />
            ) : (
              !tour?.Traducciones[data]?.VideoBienvenida && (
                <Grid
                  container
                  style={{
                    backgroundColor: "#cccccc",
                    height: 250,
                    width: "100%",
                  }}
                  alignItems="center"
                  justify="center"
                >
                  <Airplay size={50} color="white" />
                </Grid>
              )
            )}
          </Card>
          <Button
            size="small"
            variant="outlined"
            component="label"
            color="primary"
            fullWidth
            style={{ marginBlock: 10 }}
          >
            Seleccionar Video de Presentación
            <input
              type="file"
              accept="video/mp4"
              hidden
              onChange={(event) => {
                if (event.target.files) {
                  setFile(event.target.files[0]);
                }
              }}
            />
          </Button>
          {file ? file!.name : ""}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card style={{ maxHeight: 250 }}>
            {tour?.Traducciones[data]?.VideoFinalizacion && !file2 && (
              <CardMedia
                component="video"
                style={{ maxHeight: 250 }}
                title="Video Finalizacion"
                controls
                src={tour?.Traducciones[data]?.VideoFinalizacion}
              />
            )}
            {file2 ? (
              <CardMedia
                component="video"
                style={{ maxHeight: 250 }}
                title="Video Finalizacion"
                controls
                src={URL.createObjectURL(file2)}
              />
            ) : (
              !tour?.Traducciones[data]?.VideoFinalizacion && (
                <Grid
                  container
                  style={{
                    backgroundColor: "#cccccc",
                    height: 250,
                    width: "100%",
                  }}
                  alignItems="center"
                  justify="center"
                >
                  <Airplay size={50} color="white" />
                </Grid>
              )
            )}
          </Card>
          <Button
            size="small"
            variant="outlined"
            component="label"
            color="primary"
            fullWidth
            style={{ marginBlock: 10 }}
          >
            Seleccionar Video Finalizacion
            <input
              type="file"
              accept="video/mp4" // accept="video/mp4,audio/mp3"
              hidden
              onChange={(event) => {
                if (event.target.files) {
                  setFile2(event.target.files[0]);
                }
              }}
            />
          </Button>
          {file2 ? file2!.name : ""}
        </Grid>

        <Grid
          container
          justify="flex-end"
          spacing={2}
          style={{ marginTop: 40 }}
        >
          <Grid item xs={12} sm={2}>
            <Button
              variant="outlined"
              component="label"
              color="secondary"
              disabled={isLoading}
              onClick={() => setOpenConfirm(!openConfirm)}
              fullWidth
            >
              Eliminar
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              component="label"
              color="primary"
              disabled={isLoading}
              onClick={() => SaveData()}
              fullWidth
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <NewConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(!openConfirm)}
        title="¿Desea eliminar este idioma?"
        onConfirmText="Eliminar"
        onConfirm={handleConfirmDelete}
      />
    </>
  );
}

// VerticalTabs function
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// VerticalTabs function
function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const VerticalTabs: React.FC<TabsProps> = React.memo(({ tour }) => {
  const dispatch = useDispatch();
  // Tabs util
  const [value, setValue] = React.useState(0);

  // Tabs util
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={1}>
        <Tabs
          style={{ width: "70px" }}
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
        >
          {tour.Idiomas
            ? tour?.Idiomas.map((data: any, index: number) => (
                <Tab
                  style={{
                    paddingInlineStart: "20px",
                    marginInlineStart: "-45px",
                  }}
                  label={tour.Traducciones[data].Idioma}
                  {...a11yProps(index)}
                />
              ))
            : ""}
        </Tabs>
      </Grid>
      <Grid item xs={12} sm={11}>
        {tour.Idiomas
          ? tour?.Idiomas.map((data: any, index: number) => {
              return (
                <TabPanel value={value} index={index}>
                  <DataGrid
                    data={data}
                    tour={tour}
                    handleDelete={(tourData, lenguage) => {
                      dispatch(deleteTourLenguage(tourData, lenguage));
                      setValue(0);
                    }}
                  />
                </TabPanel>
              );
            })
          : ""}
      </Grid>
    </Grid>
  );
});

const TourDetail: React.FC<Props> = React.memo(
  ({ selectedTour, onAddLenguage }) => {
    const dispatch = useDispatch();
    const { idiomas } = useSelector<RootState, IIdiomasState>(
      (state) => state.idiomasReducer
    );
    const [valueIdioma, setValueIdioma] = useState<any | null>(null);

    useEffect(() => {
      dispatch(getIdiomas());
    }, []);
    // Not Allowed to add already existing lenguages in the selected tour
    // This array is used to compare the names in it with the options passed to
    // the autocomplete component an disable the selection option for an already existing lenguage
    var [disabledLenguages] = useState<string[]>([]);

    useEffect(() => {
      if (selectedTour.Idiomas) {
        selectedTour?.Idiomas.map((data: any) => {
          //Array only names
          disabledLenguages.push(data);
        });
      }
    }, []);

    return (
      <React.Fragment>
        <CardContent>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs={12} sm={5}>
              <Autocomplete
                id="combo-box-demo"
                value={valueIdioma}
                options={idiomas}
                getOptionLabel={(option) => option.Nombre}
                getOptionDisabled={(option) =>
                  !!disabledLenguages.find(
                    (element) => element === option.Codigo
                  )
                }
                onChange={(event: any, newValue: any | null) => {
                  setValueIdioma(newValue);
                }}
                style={{ height: "20px" }}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Idiomas" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Box display="flex" justifyContent="center">
                <Button
                  fullWidth
                  variant="contained"
                  component="label"
                  color="primary"
                  style={{ width: 170 }}
                  onClick={() => {
                    if (valueIdioma) {
                      disabledLenguages.push(valueIdioma?.Codigo);
                      setValueIdioma(null);
                      onAddLenguage({
                        Estado: "Pendiente",
                        Nombre: valueIdioma?.Nombre,
                        Codigo: valueIdioma?.Codigo,
                      });
                    }
                  }}
                >
                  Agregar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          {selectedTour.Idiomas ? (
            <VerticalTabs tour={selectedTour} />
          ) : (
            <Grid container justify="center">
              <Grid item xs={12} sm={12} container justify="center">
                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                  Añade un idioma y comienza a crear tours. ☺
                </div>
              </Grid>
              <Grid item xs={12} sm={12} container justify="center">
                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                  <img src={logo} alt="logo" />
                </div>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </React.Fragment>
    );
  }
);
export default TourDetail;
