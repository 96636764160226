import * as types from "../../constants";
import { FormState } from "../../models/form_state";

export interface IAuthState {
  user?: {
    id?: string;
    email?: string;
    name?: string;
    image?: string;
    token?: string;
    tipoUsuario?: string;
  };
  resetPassStateEmail: FormState;
  resetEmail: string;
  resetPassStateSetPass: FormState;
}

const initialState = {} as IAuthState;

export default function reducer(state = initialState, actions: any) {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: {
          ...actions,
        },
      };
    //
    case types.AUTH_CHANGE_PASS_GET_EMAIL_IN_REQUEST:
      return {
        ...state,
        resetPassStateEmail: FormState.Submitting,
      };
    case types.AUTH_CHANGE_PASS_GET_EMAIL_SUCCESS:
      return {
        ...state,
        resetPassStateEmail: FormState.Success,
        resetEmail: actions.payload,
      };
    case types.AUTH_CHANGE_PASS_GET_EMAIL_FAILURE:
      return {
        ...state,
        resetPassStateEmail: FormState.Error,
      };
    //
    case types.AUTH_CHANGE_PASS_SET_NEW_IN_REQUEST:
      return {
        ...state,
        resetPassStateSetPass: FormState.Submitting,
      };
    case types.AUTH_CHANGE_PASS_SET_NEW_SUCCESS:
      return {
        ...state,
        resetPassStateSetPass: FormState.Success,
      };
    case types.AUTH_CHANGE_PASS_SET_NEW_FAILURE:
      return {
        ...state,
        resetPassStateSetPass: FormState.Error,
      };

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        user: undefined,
      };

    default:
      return state;
  }
}
