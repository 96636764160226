import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { IClientsState } from "../redux/reducers/clientsReducer";
import { getClient } from "../redux/actions/clientsActions";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";

import {
  Search as SearchIcon,
  AtSign as ArrobaIcon,
  Flag as PaisIcon,
  ArrowRightCircle as ArrowIcon,
  Phone as PhoneIcon,
  Feather as NameIcon,
} from "react-feather";

import { spacing } from "@material-ui/system";
import { Skeleton } from "@material-ui/lab";
import { ISalesState } from "../redux/reducers/salesReducer";
import { useTable } from "../hooks/useTable";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";
import { numberWithDot, timesStampToDateFormatted } from "../utils/utils";
import { TableSkeleton } from "../components/TableSkeleton";
import { useStyles } from "../theme/useStyles";
import { getMoreUserSales, getUserSales } from "../redux/actions/salesAction";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface CardProps {
  client: any;
  clientId: any;
  isLoading: boolean;
}

const ContentCard: React.FC<CardProps> = ({ client, clientId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { userSales, totalDocs, isLoading } = useSelector<
    RootState,
    ISalesState
  >((state) => state.salesReducer);

  const { limit, page, handleLimitChange, handlePageChange } = useTable({
    limit: TABLE_LIMIT_DEFAULT,
    onLimitChange: (event, newLimit) => {
      if (newLimit > userSales.length) {
        dispatch(getUserSales(clientId, newLimit));
      }
    },
    onPageChange: (newPage, oldPage, limit) => {
      if (userSales.length < totalDocs && newPage > oldPage) {
        dispatch(getMoreUserSales(clientId, limit));
      }
    },
  });

  useEffect(() => {
    if (client) {
      dispatch(getUserSales(clientId));
    }
  }, [client]);
  return (
    <Card mb={6}>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Box display="flex" flexWrap="nowrap">
              <Box order={1}>
                <NameIcon />
              </Box>
              <Box m={1} order={2}>
                {client?.Nombre + " " + client?.Apellido}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" flexWrap="nowrap">
              <Box order={1}>
                <ArrobaIcon />
              </Box>
              <Box m={1} order={2}>
                {client?.Email}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Box display="flex" flexWrap="nowrap">
              <Box order={1}>
                <PhoneIcon />
              </Box>
              <Box m={1} order={2}>
                + {String(client?.Telefono).replace("+", "")}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" flexWrap="nowrap">
              <Box order={1}>
                <PaisIcon />
              </Box>
              <Box m={1} order={2}>
                {client?.Pais}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>

      <CardContent>
        <Typography variant="h3" component="h3" gutterBottom>
          Detalles de compras
        </Typography>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={5}>
          <TableContainer>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Tour</TableCell>
                  <TableCell>Guía</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  userSales
                    .slice(page * limit, page * limit + limit)
                    .map((data: any) => (
                      <Fade key={data.id} in={true}>
                        <TableRow hover className={classes.styledRow}>
                          <TableCell>
                            {timesStampToDateFormatted(
                              new Date(data?.FechaCreacion.seconds * 1000)
                            )}
                          </TableCell>
                          <TableCell>{data?.Tour.Nombre}</TableCell>
                          <TableCell>
                            {data?.Tour.Guia.Nombre +
                              " " +
                              data?.Tour.Guia.Apellido}
                          </TableCell>
                          <TableCell>
                            $ {numberWithDot(data.Tour?.Precio)}
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))
                ) : (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalDocs}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_LIMITS}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export const IndexCardClients = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams<any>();

  const {
    edit: { selectedClient, isLoading },
  } = useSelector<RootState, IClientsState>((state) => state.clientsReducer);

  useEffect(() => {
    if (!selectedClient) {
      dispatch(getClient(clientId));
    }
  }, [dispatch, selectedClient, clientId]);

  return (
    <React.Fragment>
      <Helmet title="Ficha Cliente" />
      {selectedClient ? (
        <Typography variant="h3" gutterBottom display="inline">
          Usuario {selectedClient?.Nombre}
        </Typography>
      ) : (
        <Skeleton height={40} variant="rect" />
      )}

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/clientes">
          Usuarios
        </Link>
        <Typography>Ficha</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard
            client={selectedClient}
            clientId={clientId}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
