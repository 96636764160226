import {
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { getMediaInfo } from "../../utils/utils";

const tipoMultimedia: string[] = ["video", "audio"];

interface Props {
  data?: any;
  open: boolean;
  onClose: () => void;
  onAccept: (values: any, file: any) => void;
  title?: string;
  type?: string;
}

export const VideoDetailDialog: React.FC<Props> = React.memo(
  ({ open, onClose, onAccept, type = "new", data, title }) => {
    const [file, setFile] = useState<File | null | any>(null);
    const [existFile, setExistFile] = useState(false);
    const [fileDuration, setFileDuration] = useState(0);
    const [prosecute, setProsecute] = useState(false);
    const {
      handleSubmit,
      values,
      handleChange,
      touched,
      errors,
      setValues,
      resetForm,
      setFieldValue,
    } = useFormik({
      initialValues: {
        id: undefined,
        Nombre: "",
        lat: undefined,
        lng: undefined,
        TipoMultimedia: "video",
        Indice: 0,
        Ubicacion: "",
      },
      onSubmit: (values) => {
        if (type !== "edit") {
          delete values.id;
        }
        if (!file && type !== "edit") {
          setExistFile(true);
        } else {
          onAccept({ ...values, Prosecute: prosecute }, file);
        }
      },
      validationSchema: yup.object({
        Nombre: yup.string().required("Nombre requerido"),
        TipoMultimedia: yup.string().required("Tipo de multimedia requerido"),
        lat: yup
          .number()
          .min(-90, "Coordenadas no válidas")
          .max(90, "Coordenadas no válidas")
          .required("Latitud es requerida"),
        lng: yup
          .number()
          .min(-90, "Coordenadas no válidas")
          .max(90, "Coordenadas no válidas")
          .required("Longitud es requerida"),
        Indice: yup.number().required("Posición Requerida"),
      }),
    });

    useEffect(() => {
      resetForm();
      if (type === "edit") {
        if (data) {
          setValues({
            id: data?.id,
            Nombre: data?.Nombre,
            lat: data?.lat,
            lng: data?.lng,
            TipoMultimedia: data?.TipoMultimedia,
            Indice: data?.Indice,
            Ubicacion: data?.Ubicacion,
          });
        }
      }
      // else {
      //   resetForm();
      // }
    }, [data]);

    useEffect(() => {
      if (!open) {
        setFile(null);
        setProsecute(false);
        setFileDuration(0);
        setExistFile(false);
        setValues({
          id: undefined,
          Nombre: "",
          lat: undefined,
          lng: undefined,
          TipoMultimedia: "video",
          Indice: 0,
          Ubicacion: "",
        });
      }
    }, [open]);

    const handleChangeProsecute = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setProsecute(event.target.checked);
    };

    useEffect(() => {
      if (file) {
        if (String(file.type).includes("video/") && file.type !== "video/mp4") {
          setProsecute(true);
        } else {
          setProsecute(false);
        }
      }
    }, [file]);

    return (
      <>
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              onClose();
            }
          }}
          aria-labelledby="add-and-update-dialog-title"
        >
          <DialogTitle id="add-and-update-dialog-title">
            {type === "edit" ? "Editar " + title : "Agregar " + title}
          </DialogTitle>

          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoFocus
                    id="Nombre"
                    label="Nombre"
                    type="text"
                    value={values.Nombre}
                    name="Nombre"
                    variant="outlined"
                    onChange={handleChange}
                    error={touched.Nombre && Boolean(errors.Nombre)}
                    helperText={touched.Nombre && errors.Nombre}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Autocomplete
                    id="combo-box-demo"
                    value={values.TipoMultimedia}
                    options={tipoMultimedia}
                    getOptionLabel={(option) => option}
                    onChange={(event: any, newValue: any | null) => {
                      if (newValue) {
                        setFieldValue("TipoMultimedia", newValue);
                        if (newValue === "audio") setProsecute(false);
                      }
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo de Multimedia"
                        variant="outlined"
                        error={
                          touched.TipoMultimedia &&
                          Boolean(errors.TipoMultimedia)
                        }
                        helperText={
                          touched.TipoMultimedia && errors.TipoMultimedia
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="Indice"
                    label="Posición"
                    type="number"
                    value={values.Indice}
                    name="Indice"
                    variant="outlined"
                    onChange={handleChange}
                    error={touched.Indice && Boolean(errors.Indice)}
                    helperText={touched.Indice && errors.Indice}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="lat"
                    label="Latitud"
                    type="number"
                    value={values.lat}
                    name="lat"
                    variant="outlined"
                    onChange={handleChange}
                    error={touched.lat && Boolean(errors.lat)}
                    helperText={touched.lat && errors.lat}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="lng"
                    label="Longitud"
                    type="number"
                    value={values.lng}
                    name="lng"
                    variant="outlined"
                    onChange={handleChange}
                    error={touched.lng && Boolean(errors.lng)}
                    helperText={touched.lng && errors.lng}
                    fullWidth
                  />
                </Grid>

                {fileDuration > 130 && (
                  <Grid item container justify="center">
                    <Typography color="error">
                      Duración máxima superada (2 Mins.)
                    </Typography>
                  </Grid>
                )}

                {(file?.size * 0.001) / 1024 > 310 && (
                  <Grid item container justify="center">
                    <Typography color="error">
                      Peso máximo superado (300 MB)
                    </Typography>
                  </Grid>
                )}

                {existFile ? (
                  <Grid item container justify="center">
                    <Typography color="error">
                      Debe seleccionar archivo multimedia
                    </Typography>
                  </Grid>
                ) : file ? (
                  <>
                    <CardMedia
                      component="video"
                      style={{ maxHeight: 250 }}
                      title="Video Presentación"
                      controls
                      src={URL.createObjectURL(file)}
                    />
                  </>
                ) : (
                  ""
                )}
                <Grid item sm={12}>
                  <Button
                    size="small"
                    variant="outlined"
                    component="label"
                    color="primary"
                    fullWidth
                  >
                    Seleccionar Multimedia
                    <input
                      type="file"
                      accept="video/*,audio/mp3"
                      hidden
                      onChange={async (event) => {
                        if (event.target.files && event.target.files[0]) {
                          const file = event.target.files[0];
                          const fileType = file.type;

                          await getMediaInfo(event.target.files[0])
                            .then((res: any) => {
                              if (
                                fileType.includes("video/") ||
                                fileType === "audio/mp3" ||
                                fileType === "audio/mpeg"
                              ) {
                                setFile(file);
                                // console.log(file);

                                setFileDuration(res?.duration);
                              }
                            })
                            .catch(() => setFile(null));
                        } else {
                          // console.log("Not");
                        }
                      }}
                    />
                  </Button>
                </Grid>
                <Typography color="error" variant="caption">
                  {
                    " * Videos con formato distinto a MP4 deben ser convertidos para compatibilidad con App.\nPara los audios, los archivos que no sean MP3 deben ser convertidos."
                  }
                </Typography>
                {values.TipoMultimedia === "video" && (
                  <Grid item container justify="flex-start">
                    <Checkbox
                      checked={prosecute}
                      disabled={file?.type !== "video/mp4"}
                      onChange={handleChangeProsecute}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <Typography color="primary" style={{ marginTop: 11 }}>
                      {"Convertir video \n\n"}
                      <Typography color="error" variant="caption">
                        {"*Costo asociado con uso de conversión ($20 x Minuto)"}
                      </Typography>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancelar
              </Button>
              <Button
                type="submit"
                disabled={
                  fileDuration > 180 || (file?.size * 0.001) / 1024 > 310
                }
                color="primary"
              >
                Guardar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    );
  }
);
