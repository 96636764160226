import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (newCoords: any) => void;
  data: number[];
}

export const MapPointEditDialog: React.FC<Props> = ({
  onClose,
  onConfirm,
  open,
  data,
}) => {
  const [valueLat, setValueLat] = useState<number | null | string>(null);
  const [valueLng, setValueLng] = useState<number | null | string>(null);

  useEffect(() => {
    if (data && data.length !== 0) {
      setValueLat(Number(data[0]));
      setValueLng(Number(data[1]));
    } else {
      setValueLat(null);
      setValueLng(null);
    }
  }, [data]);

  const handleConfirm = () => {
    onConfirm([Number(valueLat).toFixed(6), Number(valueLng).toFixed(6)]);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-edit"
    >
      <DialogTitle id="alert-dialog-title">
        Modificar punto seleccionado
      </DialogTitle>
      <DialogContent>
        <Grid container justify="space-evenly" spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              value={valueLat}
              label="Lat"
              type="number"
              size="small"
              variant="outlined"
              onChange={(e) => {
                if (e) {
                  setValueLat(Number(e.target.value));
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              value={valueLng}
              label="Lng"
              type="number"
              size="small"
              variant="outlined"
              onChange={(e) => {
                if (e) {
                  setValueLng(Number(e.target.value));
                }
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button
          disabled={
            !valueLat ||
            !valueLng ||
            Number(valueLat) > 90 ||
            Number(valueLat) < -90 ||
            Number(valueLng) > 90 ||
            Number(valueLng) < -90
          }
          onClick={handleConfirm}
          color="primary"
          autoFocus
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
