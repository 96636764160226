import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { IAuthState } from "../redux/reducers/authReducer";
import { RootState } from "../redux/reducers/rootReducer";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const idiomas = ["Ingles", "Español", "Portugues"];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

export const Blankguide = () => {
  const history = useHistory();
  // const dispatch = useDispatch();
  // const { guideId } = useParams<any>();

  // const {
  //   edit: { selectedGuide, isLoading },
  // } = useSelector<RootState, ITourGuidesState>(
  //   (state) => state.tourGuidesReducer
  // );
  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );

  useEffect(() => {
    history.push(`/perfil/${user?.id}`);
  }, []);

  // useEffect(() => {
  //   if (!selectedGuide) {
  //     dispatch(getTourGuide(guideId));
  //   }
  // }, [dispatch, selectedGuide, guideId]);

  return (
    <React.Fragment>
      <Helmet title="Ficha Guía" />
      {false ? (
        <Typography variant="h3" gutterBottom display="inline">
          {/* {user?.tipoUsuario !== "Guia" ? "Ficha" : "Perfil"}{" "}
          {selectedGuide?.Nombre + " " + selectedGuide?.Apellido} */}
        </Typography>
      ) : (
        <Skeleton height={40} variant="rect" />
      )}

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={""}>
          {/* {user?.tipoUsuario !== "Guia" ? "Guías" : "Tours"} */}
        </Link>
        <Typography>
          {/* {user?.tipoUsuario !== "Guia" ? "Ficha" : "Perfil"} */}
        </Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {/* <ContentCard
            guide={selectedGuide}
            guideId={guideId}
            isLoading={isLoading}
          /> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
