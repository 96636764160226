import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  Divider as MuiDivider,
  Fade,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { DownloadCloud } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { DialogLoad } from "../components/DialogLoad";
import DetalleVentas from "../components/NewTourComponents/DetalleVentas";
import { FormCard } from "../components/NewTourComponents/FormCard";
import TourDetail from "../components/NewTourComponents/TourDetail";
import TourDetailVideos from "../components/NewTourComponents/TourDetailVideos";
import { TabPanel } from "../components/TabPanel";
import { TourMap } from "../components/ToursComponents/TourMap";
import { TourScore } from "../components/ToursComponents/TourScore";
import { SnackState } from "../models/snack-state";
import { getTourSales, setAllSalesDocs } from "../redux/actions/salesAction";
import { setSelectedTourGuide } from "../redux/actions/tourGuidesActions";
import {
  getTour,
  getTourCalificaciones,
  updateTourIdioma,
} from "../redux/actions/toursActions";
import { getMediaRecomendations } from "../redux/actions/tourTypesActions";
import { openSnack } from "../redux/actions/uiActions";
import { RootState } from "../redux/reducers/rootReducer";
import { ISalesState } from "../redux/reducers/salesReducer";
import { IToursState } from "../redux/reducers/toursReducer";
import { exportToCsv } from "../utils/utils";
import { makeStyles, Theme } from "@material-ui/core/styles";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#2979ff",
    color: "#ffffff",
  },
  indicator: {
    backgroundColor: "#004ecb",
  },
}));

export const EditTour = () => {
  const { tourId } = useParams<any>();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSubmit = (values: any) => {
    // console.log(values);
    // console.log("AAA");
  };

  const handleAddLenguage = (values: any) => {
    dispatch(updateTourIdioma(tourId, values));
  };

  const {
    edit: { selectedTour },
    isLoading,
    selectedRating,
  } = useSelector<RootState, IToursState>((state) => state.toursReducer);

  const { tourSales, totalTourSalesDocs, totalDocs, lastDoc } = useSelector<
    RootState,
    ISalesState
  >((state) => state.salesReducer);

  useEffect(() => {
    // console.log(selectedTour);

    if (!selectedTour) {
      dispatch(getTour(tourId));
    }
  }, [selectedTour]);

  useEffect(() => {
    dispatch(getTourSales(tourId));
    dispatch(getTourCalificaciones(tourId));
  }, [dispatch, tourId]);

  const [tab, setTab] = useState<number>(0);

  useEffect(() => {
    return () => {
      dispatch(setAllSalesDocs([]));
      dispatch(setSelectedTourGuide(null));
    };
  }, []);

  useEffect(() => {
    dispatch(getMediaRecomendations());
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Editar Tour" />
      <Typography variant="h3" gutterBottom display="inline">
        {selectedTour?.Nombre}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/tours">
          Tours
        </Link>
        <Typography>Editar Tour</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Card mb={4}>
        <Tabs
          value={tab}
          classes={{ root: classes.root, indicator: classes.indicator }}
          onChange={(_, newValue) => setTab(newValue)}
          textColor="inherit"
          variant="scrollable"
          scrollButtons="on"
        >
          <Tab label="Información General" />
          <Tab label="Idiomas y Descripción" />
          <Tab label="Multimedia Tour" />
          <Tab label="Mapa Recorrido" />
          <Tab label="Detalle Ventas" />
          <Tab label="Calificaciones" />
        </Tabs>
      </Card>
      <TabPanel value={tab} index={0}>
        <Fade in={tab === 0}>
          <FormCard onSubmit={handleSubmit} tour={selectedTour} />
        </Fade>
      </TabPanel>

      <TabPanel value={tab} index={1}>
        {selectedTour ? (
          // <Grid item xs={12}>
          <Card>
            <TourDetail
              selectedTour={selectedTour}
              onAddLenguage={(value) => handleAddLenguage(value)}
            />
          </Card>
        ) : (
          // </Grid>
          ""
        )}
      </TabPanel>

      <TabPanel value={tab} index={2}>
        {selectedTour ? (
          // <Grid item xs={12}>
          <Card>
            <TourDetailVideos selectedTour={selectedTour} />
          </Card>
        ) : (
          // </Grid>
          ""
        )}
      </TabPanel>
      <TabPanel value={tab} index={3}>
        {selectedTour && (
          <Grid item xs={12}>
            <Card>
              <TourMap tour={selectedTour} save={() => {}} />
            </Card>
          </Grid>
        )}
      </TabPanel>
      <TabPanel value={tab} index={4}>
        <Card>
          <Box
            style={{ margin: 10 }}
            display="flex"
            flexWrap="nowrap"
            justifyContent="flex-end"
          >
            <Box order={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(openSnack("Descargando CSV", SnackState.SUCCESS));
                  exportToCsv(
                    "total_ventas.csv",
                    totalTourSalesDocs,
                    [
                      "FechaCreacion",
                      "Nombre",
                      "Region",
                      "TipoTour",
                      "Idioma",
                      "Estado",
                      "Precio",
                      "NombreGuia",
                      // "EmailGuia",
                      "PrecioGuia",
                      "NombreCliente",
                      // "EmailCliente",
                    ],
                    [
                      "Fecha de Creacion",
                      "Nombre Tour",
                      "Region",
                      "Tipo Tour",
                      "Idioma",
                      "Estado",
                      "Valor",
                      "Nombre Guia",
                      // "Email Guia",
                      "Valor % Guia",
                      "Nombre Cliente",
                      // "Email Cliente",
                    ]
                  );
                }}
              >
                <DownloadCloud />
                Descargar CSV
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(openSnack("Descargando CSV", SnackState.SUCCESS));
                  exportToCsv(
                    "total_ventas.csv",
                    totalTourSalesDocs,
                    [
                      "FechaCreacion",
                      "Nombre",
                      "Region",
                      "TipoTour",
                      "Idioma",
                      "Estado",
                      "Precio",
                      "NombreGuia",
                      // "EmailGuia",
                      "PrecioGuia",
                      "NombreCliente",
                      // "EmailCliente",
                    ],
                    [
                      "Fecha de Creacion",
                      "Nombre Tour",
                      "Region",
                      "Tipo Tour",
                      "Idioma",
                      "Estado",
                      "Valor",
                      "Nombre Guia",
                      // "Email Guia",
                      "Valor % Guia",
                      "Nombre Cliente",
                      // "Email Cliente",
                    ],
                    ";"
                  );
                }}
              >
                <DownloadCloud />
                Descargar CSV2
              </Button>
            </Box>
          </Box>
          <DetalleVentas
            selected={tourSales}
            totalDocs={totalDocs}
            lastDoc={lastDoc}
          />
        </Card>
      </TabPanel>
      <TabPanel value={tab} index={5}>
        {selectedTour && (
          // <Grid item xs={12}>
          <Card>
            <TourScore tour={selectedRating} save={() => {}} />
          </Card>
        )}
      </TabPanel>
      <DialogLoad open={isLoading} onClose={() => {}} />
    </React.Fragment>
  );
};
