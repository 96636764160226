import { Action } from "../../models/action";
import * as types from "../../constants";
import { FormState } from "../../models/form_state";

export interface IMapState {
  place: any;
  places: any[];
  routes: any;
  tourData: any;
  state: FormState;
  routeState: FormState;
  stateTourData: FormState;
  error?: any;
  routeError?: any;
  tourDataError?: any;
}

const initialState = {
  place: null,
  places: [],
  routes: [],
  tourData: null,
  state: FormState.Initial,
  routeState: FormState.Initial,
  stateTourData: FormState.Initial,
} as IMapState;

export const mapReducer = (state = initialState, action: Action): IMapState => {
  switch (action.type) {
    case types.MAP_PLACE_SEARCH_SUCCESS:
      return {
        ...state,
        place: action.payload,
        state: FormState.Success,
      };
    case types.MAP_PLACE_SEARCH_IS_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.MAP_PLACE_SEARCH_FAILURE:
      return {
        ...state,
        state: FormState.Error,
        error: action.payload,
      };
    //
    case types.MAP_PLACES_SEARCH_SUCCESS:
      return {
        ...state,
        places: action.payload,
        state: FormState.Success,
      };
    case types.MAP_PLACES_SEARCH_IS_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.MAP_PLACES_SEARCH_FAILURE:
      return {
        ...state,
        state: FormState.Error,
        error: action.payload,
      };
    //
    case types.MAP_GET_ROUTE_SUCCESS:
      return {
        ...state,
        routes: action.payload,
        routeState: FormState.Success,
      };
    case types.MAP_GET_ROUTE_IS_SUBMITTING:
      return {
        ...state,
        routeState: FormState.Submitting,
        routeError: undefined,
      };
    case types.MAP_GET_ROUTE_FAILURE:
      return {
        ...state,
        routeState: FormState.Error,
        routeError: action.payload,
      };
    //
    case types.MAP_PATH_ROUTE_SUCCESS:
      return {
        ...state,
        tourData: action.payload,
        stateTourData: FormState.Success,
      };
    case types.MAP_PATH_ROUTE_IS_SUBMITTING:
      return {
        ...state,
        stateTourData: FormState.Submitting,
        tourDataError: undefined,
      };
    case types.MAP_PATH_ROUTE_FAILURE:
      return {
        ...state,
        stateTourData: FormState.Error,
        tourDataError: action.payload,
      };
    //
    case types.MAP_PATH_ROUTE_INITIAL_STATE:
      return {
        ...state,
        tourData: null,
        stateTourData: FormState.Initial,
      };
    default:
      return state;
  }
};
