import { Action } from "../../models/action";
import * as types from "../../constants";
import { FormState } from "../../models/form_state";

export interface ISalesState {
  tourSales: any[];
  totalTourSalesDocs: any[];
  userSales: any[];
  totalUserSalesDocs: any[];
  totalSales: any;
  totalSalesDocs: any[];
  state: FormState;
  isLoading: boolean;
  totalDocs: number;
  lastDoc?: any;
  error?: string;
}

const initialState = {
  tourSales: [],
  totalTourSalesDocs: [],
  userSales: [],
  totalUserSalesDocs: [],
  totalSales: null,
  totalSalesDocs: [],
  state: FormState.Initial,
  isLoading: false,
  totalDocs: 0,
} as ISalesState;

export const salesReducer = (
  state = initialState,
  action: Action
): ISalesState => {
  switch (action.type) {
    case types.SALES_TOUR_GET_DOCS:
      return {
        ...state,
        tourSales: action.payload.tourSales,
        totalDocs: action.payload.totalDocs,
        lastDoc: action.payload.lastDoc,
        totalTourSalesDocs: action.payload.totalTourSalesDocs,
      };
    case types.SALES_USER_GET_DOCS:
      return {
        ...state,
        userSales: action.payload.userSales,
        totalDocs: action.payload.totalDocs,
        lastDoc: action.payload.lastDoc,
        totalUserSalesDocs: action.payload.totalUserSalesDocs,
      };
    case types.SALES_SET_LAST_DOC:
      return {
        ...state,
        lastDoc: action.payload,
      };
    case types.SALES_SET_TOTAL_DOCS:
      return {
        ...state,
        totalDocs: action.payload,
      };
    case types.SALES_SET_LIST_ALL_DATA:
      return {
        ...state,
        tourSales: action.payload,
      };
    case types.SALES_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.SALES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case types.SALES_ALL_GET_IS_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.SALES_ALL_GET_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        totalSales: action.payload.totalSales,
        totalSalesDocs: action.payload.totalSalesDocs,
        totalDocs: action.payload.totalDocs,
        lastDoc: action.payload.lastDoc,
      };
    case types.SALES_ALL_GET_FAILURE:
      return {
        ...state,
        state: FormState.Error,
        error: action.payload,
      };
    default:
      return state;
  }
};
