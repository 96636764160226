import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { AppThunk } from "../../models/app-thunk";
import { SnackState } from "../../models/snack-state";
import { openSnack } from "./uiActions";

const DEFAULT_ORDER_BY = "Nombre";
const DEFAULT_COLLECTION_NAME = "TipoLugar";

export const getPlacesTypes = (): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.PLACES_TYPES_IS_SUBMITTING,
    });
    try {
      const response = await firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY)
        .get();

      const dataList = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));
      dispatch({
        type: types.PLACES_TYPES_SUCCESS,
        payload: dataList,
      });
    } catch (error: any) {
      dispatch({
        type: types.PLACES_TYPES_FAILURE,
        payload: error,
      });
      console.log(error);
    }
  };
};

export const createPlacesType = (Nombre: string, Color: string): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.PLACES_TYPES_CREATE_SUBMITTING,
    });
    try {
      if (Nombre) {
        await firestore
          .collection(DEFAULT_COLLECTION_NAME)
          .add({ Nombre: Nombre, Activo: true, Color: Color || "#cccccc" })
          .then((res) =>
            dispatch({
              type: types.PLACES_TYPES_CREATE_SUCCESS,
              payload: {
                Nombre: Nombre,
                Activo: true,
                id: res.id,
                Color: Color,
              },
            })
          );
        dispatch(openSnack("¡Se ha creado con éxito!", SnackState.SUCCESS));
      } else {
        dispatch(openSnack("¡No puede ser un campo vacío!", SnackState.ERROR));
        dispatch({
          type: types.PLACES_TYPES_CREATE_FAILURE,
          payload: "Campo Vacío",
        });
      }
    } catch (error: any) {
      dispatch({
        type: types.PLACES_TYPES_CREATE_FAILURE,
        payload: error,
      });
      console.log(error);
    }
  };
};

export const updatePlacesType = (
  data: any,
  state: boolean,
  color: string
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.PLACES_TYPES_UPDATE_SUBMITTING,
    });
    const { placesTypes } = getState().placesTypesReducer;
    try {
      await firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .doc(data.id)
        .update({ Activo: state, Color: color });
      const index = placesTypes.findIndex((x: any) => x.id === data.id);
      placesTypes[index] = {
        ...placesTypes[index],
        Activo: state,
        Color: color,
      };
      dispatch({
        type: types.PLACES_TYPES_UPDATE_SUCCESS,
        payload: placesTypes,
      });
      dispatch(openSnack("¡Actualizado!", SnackState.SUCCESS));
    } catch (error: any) {
      dispatch({
        type: types.PLACES_TYPES_UPDATE_FAILURE,
        payload: error,
      });
      console.log(error);
    }
  };
};
export const deletePlacesType = (data: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.PLACES_TYPES_UPDATE_SUBMITTING,
    });
    const { placesTypes } = getState().placesTypesReducer;
    try {
      await firestore.collection(DEFAULT_COLLECTION_NAME).doc(data.id).delete();
      const newPlacesTypes = placesTypes.filter((x: any) => x.id !== data.id);
      // placesTypes[index] = {
      //   ...placesTypes[index],
      //   Activo: state,
      //   Color: color,
      // };
      dispatch({
        type: types.PLACES_TYPES_UPDATE_SUCCESS,
        payload: newPlacesTypes,
      });
      dispatch(openSnack("¡Actualizado!", SnackState.SUCCESS));
    } catch (error: any) {
      dispatch({
        type: types.PLACES_TYPES_UPDATE_FAILURE,
        payload: error,
      });
      console.log(error);
    }
  };
};
