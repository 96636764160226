import * as types from "../../constants";
import { Action } from "../../models/action";

interface NotifReducerState {
  guideCount: string[];
  placeCount: string[];
}

const initialState = {
  guideCount: [],
  placeCount: [],
} as NotifReducerState;

export const notifsReducer = (
  state = initialState,
  action: Action
): NotifReducerState => {
  switch (action.type) {
    case types.GET_CREATIONS_NOTIFICATIONS:
      return {
        ...state,
        guideCount: action.payload.guideCount,
        placeCount: action.payload.placeCount,
      };

    default:
      return state;
  }
};
