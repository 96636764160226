import { Action } from "../../models/action";
import * as types from "../../constants";

export interface ITourTypeState {
  tourTypes: any[];
  isLoading: boolean;
  error?: string;
}

const initialState = {
  tourTypes: [],
  isLoading: false,
} as ITourTypeState;

export const tourTypesReducer = (
  state = initialState,
  action: Action
): ITourTypeState => {
  switch (action.type) {
    case types.TOUR_TYPES_GET_DOCS:
      return {
        ...state,
        tourTypes: action.payload,
      };
    case types.TOUR_TYPES_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.TOUR_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case types.TOUR_TYPES_CREATE_SUBMITTING:
      return { ...state, error: undefined };
    case types.TOUR_TYPES_CREATE_SUCCESS:
      return {
        ...state,
        tourTypes: state.tourTypes.concat(action.payload),
      };
    case types.TOUR_TYPES_CREATE_FAILURE:
      return { ...state, error: action.payload };

    case types.TOUR_TYPES_REMOVE_SUBMITTING:
      return { ...state, error: undefined };
    case types.TOUR_TYPES_REMOVE_SUCCESS:
      return {
        ...state,
        tourTypes: state.tourTypes.filter((doc) => doc.id !== action.payload),
      };
    case types.TOUR_TYPES_REMOVE_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};
