import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyCDxUc2ZnW5LD2QoM2BoSH0786qJNx04NM",
  authDomain: "traveapp-fa700.firebaseapp.com",
  projectId: "traveapp-fa700",
  storageBucket: "traveapp-fa700.appspot.com",
  messagingSenderId: "594477246406",
  appId: "1:594477246406:web:a2b1f606e503f540d3f4c7",
  measurementId: "G-VJMFS2BE4N",
};

export const app = firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics();
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
