import { Box, Card, CardContent, Typography } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from "react-google-maps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";

const imageIcon = {
  url: "/static/travepoint.png",
  // This marker is 20 pixels wide by 32 pixels high.
  size: { height: 62, width: 62 },
  // The origin for this image is (0, 0).
  origin: { x: 0, y: 0 },
  // The anchor for this image is the base of the flagpole at (0, 32).
  anchor: { x: 0, y: 32 },
};

const Map = (props: any) => {
  const { path } = props;
  const initOpenInfo = () => {
    let _infoBoxes: any = {};
    path.map((data: any) => (_infoBoxes[data.id] = false));
    return _infoBoxes;
  };
  let openInfo = initOpenInfo();

  return (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{
        lat: path[0].lat,
        lng: path[0].lng,
      }}
      options={{ disableDefaultUI: true, zoomControl: true }}
    >
      {path.map((data: any, index: any) => (
        <Marker
          key={data.id}
          icon={imageIcon as any}
          position={{ lat: data.lat, lng: data.lng }}
          //   onClick={() => {
          //     openInfo[data.id] = !openInfo[data.id];
          //     console.log(data.id, openInfo[data.id]);
          //   }}
        >
          <InfoBox
            // onCloseClick={() => (openInfo[data.id] = !openInfo[data.id])}
            options={{ closeBoxURL: ``, enableEventPropagation: true }}
          >
            <Card
              style={{
                maxHeight: "35px",
                backgroundColor: "#2979ff",
              }}
            >
              <Box mx={1}>
                <Typography variant="caption" style={{ color: "white" }}>
                  <b>{data.Indice}</b>
                </Typography>
              </Box>
            </Card>
          </InfoBox>
        </Marker>
      ))}
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(Map));
