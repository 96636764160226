import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { SnackState } from "../../models/snack-state";
import { openSnack } from "../../redux/actions/uiActions";
import { IAuthState } from "../../redux/reducers/authReducer";
import { RootState } from "../../redux/reducers/rootReducer";
import {
  timesStampFormattedsimple,
  timesStampFormattedsimple2,
  timeStampToDate,
} from "../../utils/utils";

const estadosPromocion: string[] = [
  "En Revision",
  "Pendiente de Pago",
  "Aprobado",
  "Finalizado",
];

interface Props {
  data?: any;
  open: boolean;
  onClose: () => void;
  onAccept: (values: any, type: string) => void;
  type?: string;
}

export const DialogPlaceVideo: React.FC<Props> = React.memo(
  ({ open, onClose, onAccept, data, type = "new" }) => {
    const dispatch = useDispatch();
    const { user } = useSelector<RootState, IAuthState>(
      (state) => state.authReducer
    );
    const [file, setFile] = useState<any | undefined>(null);
    const {
      handleSubmit,
      values,
      handleChange,
      touched,
      errors,
      setValues,
      resetForm,
      setFieldValue,
    } = useFormik({
      initialValues: {
        id: undefined,
        Nombre: "",
        Fecha: "",
        FechaFinal: "",
        HoraInicio: "",
        HoraFinal: "",
        Descripcion: "",
        file: undefined,
        Estado: "En Revision",
        Ubicacion: "",
      },
      onSubmit: (values) => {
        if (type === "new") {
          if (values.file) {
            delete values.id;
            onAccept(values, type);
          } else {
            dispatch(
              openSnack("¡Debe seleccionar un video!", SnackState.ERROR)
            );
          }
        } else {
          onAccept(values, type);
        }
      },
      validationSchema: yup.object({
        Nombre: yup.string().required("Nombre requerido"),
        // Valor: yup.number().positive().required("Valor es requerido"),
        Fecha: yup.string().required("Fecha requerida"),
        FechaFinal: yup.string().required("Fecha requerida"),
        HoraInicio: yup.string().required("Hora inicio requerida"),
        HoraFinal: yup.string().required("Hora final requerida"),
      }),
    });
    useEffect(() => {
      if (type === "edit") {
        if (data) {
          let Date_inicio: any = "";
          if (data?.Fecha) {
            Date_inicio = timesStampFormattedsimple2(
              data?.Fecha.toDate()
            ).split("-");
            Date_inicio =
              Date_inicio[2] + "-" + Date_inicio[1] + "-" + Date_inicio[0];
          }
          let Date_final: any = "";
          if (data?.FechaFinal) {
            Date_final = timesStampFormattedsimple2(
              data?.FechaFinal.toDate()
            ).split("-");
            Date_final =
              Date_final[2] + "-" + Date_final[1] + "-" + Date_final[0];
          }

          setValues({
            id: data?.id,
            Nombre: data?.Nombre,
            Fecha: Date_inicio,
            FechaFinal: Date_final,
            HoraInicio: data?.HoraInicio,
            Descripcion: data?.Descripcion,
            HoraFinal: data?.HoraFinal,
            file: undefined,
            Estado: data?.Estado,
            Ubicacion: data?.Ubicacion,
          });
        }
      } else {
        resetForm();
      }
    }, [type, data]);
    return (
      <>
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              onClose();
            }
          }}
          aria-labelledby="add-and-update-dialog-title"
        >
          <DialogTitle id="add-and-update-dialog-title">
            {type === "edit"
              ? "Editar Video Promocional"
              : "Agregar Video Promocional"}
          </DialogTitle>

          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    autoFocus
                    id="Nombre"
                    label="Nombre"
                    type="text"
                    value={values.Nombre}
                    name="Nombre"
                    variant="outlined"
                    onChange={handleChange}
                    error={touched.Nombre && Boolean(errors.Nombre)}
                    helperText={touched.Nombre && errors.Nombre}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="date"
                    label="Fecha Inicio"
                    value={values.Fecha}
                    type="date"
                    onChange={(e) => {
                      setFieldValue("Fecha", e.target.value);
                      // console.log(e.target.value);
                    }}
                    variant="outlined"
                    error={touched.Fecha && Boolean(errors.Fecha)}
                    helperText={touched.Fecha && errors.Fecha}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="date2"
                    label="Fecha Final"
                    value={values.FechaFinal}
                    type="date"
                    onChange={(e) => {
                      setFieldValue("FechaFinal", e.target.value);
                      // console.log(e.target.value);
                    }}
                    variant="outlined"
                    error={touched.FechaFinal && Boolean(errors.FechaFinal)}
                    helperText={touched.FechaFinal && errors.FechaFinal}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="time"
                    label="Horario (Incio)"
                    type="time"
                    variant="outlined"
                    value={values.HoraInicio}
                    onChange={(e) => {
                      setFieldValue("HoraInicio", e.target.value);
                    }}
                    error={touched.HoraInicio && Boolean(errors.HoraInicio)}
                    helperText={touched.HoraInicio && errors.HoraInicio}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="time"
                    label="Horario (Final)"
                    type="time"
                    variant="outlined"
                    value={values.HoraFinal}
                    onChange={(e) => {
                      setFieldValue("HoraFinal", e.target.value);
                    }}
                    error={touched.HoraFinal && Boolean(errors.HoraFinal)}
                    helperText={touched.HoraFinal && errors.HoraFinal}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </Grid>
                {user?.tipoUsuario !== "Lugar" ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        id="combo-box-estado"
                        value={values.Estado}
                        options={estadosPromocion}
                        getOptionLabel={(option) => option}
                        onChange={(_, value: any) => {
                          if (value) {
                            setFieldValue("Estado", value);
                          }
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Estado"
                            variant="outlined"
                            error={touched.Estado && Boolean(errors.Estado)}
                            helperText={touched.Estado && errors.Estado}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        autoFocus
                        id="Descripcion"
                        label="Descripción corta"
                        type="text"
                        value={values.Descripcion}
                        name="Descripcion"
                        variant="outlined"
                        onChange={handleChange}
                        error={
                          touched.Descripcion && Boolean(errors.Descripcion)
                        }
                        helperText={touched.Descripcion && errors.Descripcion}
                        fullWidth
                      />
                    </Grid>
                  </>
                ) : (
                  ""
                )}
                <Grid item xs={12} sm={12}>
                  <Button
                    size="small"
                    variant="outlined"
                    component="label"
                    color="primary"
                    fullWidth
                  >
                    Seleccionar Video
                    <input
                      type="file"
                      accept="video/mp4,video/x-m4v,video/*"
                      hidden
                      onChange={(event) => {
                        if (event.target.files) {
                          setFieldValue("file", event.target.files[0]);
                        }
                      }}
                    />
                  </Button>
                </Grid>

                {values.file ? (
                  <Grid item xs={12} sm={12}>
                    <Typography>Archivo: {values.file!["name"]}</Typography>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancelar
              </Button>
              <Button type="submit" color="primary">
                Guardar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    );
  }
);
