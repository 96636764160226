import { Button, Dialog, DialogActions } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import MapMapbox from "../Maps/MapboxWithSearch";

interface DialogMapProps {
  myPath?: any;
  open: boolean;
  onClose: () => void;
  onChangeLocation: (location: any) => void;
}
const MapDialog: React.FC<DialogMapProps> = React.memo(
  ({ open, myPath = null, onClose, onChangeLocation }) => {
    const dispatch = useDispatch();

    return (
      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={onClose}>
        <div style={{ padding: 20 }}>
          <MapMapbox
            selected={myPath ? myPath : {}}
            onSave={(coords: any) => {
              onChangeLocation(coords);
            }}
          />
        </div>

        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
export default MapDialog;
