import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Snackbar,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { Alert as MuiAlert, Autocomplete } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { NewConfirmScrolltextDialog } from "../../components/NewConfirmScrolltextDialog";
import { DialogLoad } from "../../components/DialogLoad";
import { CAPTCHA_KEY } from "../../constants";
import { SnackState } from "../../models/snack-state";
import { getRegions } from "../../redux/actions/regionsActions";
import { createTourGuide } from "../../redux/actions/tourGuidesActions";
import { closeSnack } from "../../redux/actions/uiActions";
import { signOut } from "../../redux/actions/authActions";
import logo from "../../vendor/logoTrave.png";
import { Link } from "react-router-dom";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function GuideRegister() {
  const dispatch = useDispatch();
  const history = useHistory();

  const captcha = useRef(null);

  const [validCaptcha, setValidCaptcha] = useState(false);
  const [validFile, setValidFile] = useState(true);
  const [validFile2, setValidFile2] = useState(true);
  const [validFile3, setValidFile3] = useState(true);
  const [validFile4, setValidFile4] = useState(true);
  const [isOpen, setOpen] = useState(false);
  // const [openSnackbar, setOpenSnackbar] = useState(false);

  const [guideData, setGuideData] = useState({});

  const { regions } = useSelector((state) => state.regionsReducer);
  const { isLoading } = useSelector((state) => state.tourGuidesReducer);
  const {
    snack: { snackState, open, text },
  } = useSelector((state) => state.uiReducer);

  const handleCaptchaChange = () => {
    if (captcha.current.getValue()) {
      setValidCaptcha(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // setOpenSnackbar(!openSnackbar);
  };

  const handleAccept = () => {
    dispatch(createTourGuide(guideData));
    // setOpenSnackbar(!openSnackbar);
    setOpen(false);
    // history.push("/auth/sign-in");
  };
  useEffect(() => {
    if (snackState === SnackState.SUCCESS) {
      dispatch(signOut());
      history.push("/auth/sign-in");
    }
  }, [snackState]);
  useEffect(() => {
    dispatch(getRegions());
  }, [dispatch]);

  return (
    <Card style={{ width: "100%" }}>
      <CardContent>
        <NewConfirmScrolltextDialog
          open={isOpen}
          title={"¿Acepta términos y condiciones?"}
          content="Ejemplo de Términos y Condiciones..."
          onClose={() => setOpen(!isOpen)}
          onConfirmText="Aceptar"
          onConfirm={handleAccept}
        />
        <Helmet title="Registro de Guías" />
        <Grid container alignContent="center" spacing={6}>
          <Grid item xs={12} sm={12}>
            <Box m={2} display="flex" justifyContent="center">
              <img src={logo} style={{ maxHeight: 60 }} alt="img-logo" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              Formulario de Registro Guías
            </Typography>
          </Grid>
        </Grid>
        <Formik
          initialValues={{
            Nombre: "",
            Apellido: "",
            Email: "",
            Telefono: 9,
            Region: null,
            CertificadoEspecializacion: null,
            CertificadoLaboral: null,
            CertificacionSernatur: null,
            CertificadoCompetencias: null,
          }}
          validationSchema={Yup.object().shape({
            Email: Yup.string()
              .email("Debe ser un email válido")
              .max(255)
              .required("Email es requerido"),
            Nombre: Yup.string().required("Nombre es requerido"),
            Apellido: Yup.string().required("Apellido es requerido"),
            Telefono: Yup.number()
              .min(900000000, "Número no válido")
              .max(999999999, "Número no válido")
              .required("Número de teléfono es requerido"),
            Region: Yup.string("Región es requerida").required(
              "Región es requerida"
            ),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (
                !values.CertificadoEspecializacion ||
                !values.CertificadoLaboral ||
                !values.CertificacionSernatur ||
                !values.CertificadoCompetencias
              ) {
                if (!values.CertificadoEspecializacion) {
                  setValidFile(false);
                } else {
                  setValidFile(true);
                }
                if (!values.CertificadoLaboral) {
                  setValidFile2(false);
                } else {
                  setValidFile2(true);
                }
                if (!values.CertificacionSernatur) {
                  setValidFile3(false);
                } else {
                  setValidFile3(true);
                }
                if (!values.CertificadoCompetencias) {
                  setValidFile4(false);
                } else {
                  setValidFile4(true);
                }
              } else {
                !validFile && setValidFile(true);
                !validFile2 && setValidFile2(true);
                !validFile3 && setValidFile3(true);
                !validFile4 && setValidFile4(true);
                setGuideData(values);
                setOpen(!isOpen);
              }

              // await dispatch();
            } catch (error) {
              const message = error.message || "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <Grid container spacing={6}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    type="text"
                    name="Nombre"
                    label="Nombre"
                    value={values.Nombre}
                    error={Boolean(touched.Nombre && errors.Nombre)}
                    fullWidth
                    helperText={touched.Nombre && errors.Nombre}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    type="text"
                    name="Apellido"
                    label="Apellido"
                    value={values.Apellido}
                    error={Boolean(touched.Apellido && errors.Apellido)}
                    fullWidth
                    helperText={touched.Apellido && errors.Apellido}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id="combo-box-regions"
                    name="Region"
                    value={values.Region}
                    options={regions}
                    getOptionLabel={(option) => option.Nombre || values.Region}
                    onChange={(event, value) => {
                      if (value) {
                        setFieldValue("Region", value?.Nombre);
                      }
                    }}
                    fullWidth
                    my={2}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Región"
                        variant="outlined"
                        error={Boolean(touched.Region && errors.Region)}
                        helperText={touched.Region && errors.Region}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="Telefono"
                    label="Teléfono"
                    value={values.Telefono}
                    error={Boolean(touched.Telefono && errors.Telefono)}
                    fullWidth
                    helperText={touched.Telefono && errors.Telefono}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+56</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    style={{ marginTop: -1 }}
                    variant="outlined"
                    type="email"
                    name="Email"
                    label="Dirección de Email"
                    value={values.Email}
                    error={Boolean(touched.Email && errors.Email)}
                    fullWidth
                    helperText={touched.Email && errors.Email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                  />
                  * Email para ingreso. Se enviará correo para cambio de
                  contraseña.
                </Grid>
                {/* <Grid item xs={12} sm={12} style={{ marginTop: -18 }}>
                  * Si eres usuario de la aplicación este correo debe ser
                  diferente a tu correo de usuario.
                </Grid> */}
                <Grid item xs={12} sm={12}>
                  <ReCAPTCHA
                    ref={captcha}
                    sitekey={CAPTCHA_KEY}
                    onChange={handleCaptchaChange}
                    onExpired={() => setValidCaptcha(false)}
                  />
                </Grid>
                {/* <Button onClick={() => setOpen(true)}>cliiick</Button> */}
                <Grid item xs={12} sm={12}>
                  <Typography>* Sólo se admiten archivos PDF.</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    size="small"
                    variant="contained"
                    component="label"
                    startIcon={<CloudUpload />}
                  >
                    Certificado de Antecedentes
                    <input
                      type="file"
                      accept="application/pdf"
                      hidden
                      onChange={(event) => {
                        if (event.target.files) {
                          if (event.target.files[0].size > 943718) {
                            alert("Archivo demasiado pesado!");
                          } else {
                            setFieldValue(
                              "CertificadoEspecializacion",
                              event.target.files[0]
                            );
                          }
                        }
                      }}
                    />
                  </Button>
                  {values.CertificadoEspecializacion ? (
                    <Typography>
                      {values.CertificadoEspecializacion.name}
                    </Typography>
                  ) : !validFile ? (
                    <Typography variant="caption" color="error">
                      Certificado es requerido
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    size="small"
                    variant="contained"
                    component="label"
                    startIcon={<CloudUpload />}
                  >
                    Cédula de Identidad Vigente (Ambos lados)
                    <input
                      type="file"
                      accept="application/pdf"
                      hidden
                      onChange={(event) => {
                        if (event.target.files) {
                          if (event.target.files[0].size > 943718) {
                            alert("Archivo demasiado pesado!");
                          } else {
                            setFieldValue(
                              "CertificadoLaboral",
                              event.target.files[0]
                            );
                          }
                        }
                      }}
                    />
                  </Button>
                  {values.CertificadoLaboral ? (
                    <Typography>{values.CertificadoLaboral.name}</Typography>
                  ) : !validFile2 ? (
                    <Typography variant="caption" color="error">
                      Este documento es requerido
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    size="small"
                    variant="contained"
                    component="label"
                    startIcon={<CloudUpload />}
                  >
                    Certificado de Iniciación de Actividades o Cartola
                    Tributaria
                    <input
                      type="file"
                      accept="application/pdf"
                      hidden
                      onChange={(event) => {
                        if (event.target.files) {
                          if (event.target.files[0].size > 943718) {
                            alert("Archivo demasiado pesado!");
                          } else {
                            setFieldValue(
                              "CertificacionSernatur",
                              event.target.files[0]
                            );
                          }
                        }
                      }}
                    />
                  </Button>
                  {values.CertificacionSernatur ? (
                    <Typography>{values.CertificacionSernatur.name}</Typography>
                  ) : !validFile3 ? (
                    <Typography variant="caption" color="error">
                      Este documento es requerido
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Button
                    size="small"
                    variant="contained"
                    component="label"
                    startIcon={<CloudUpload />}
                  >
                    Certificado Acreditación Conocimientos y Competencias
                    <input
                      type="file"
                      accept="application/pdf"
                      hidden
                      onChange={(event) => {
                        if (event.target.files) {
                          if (event.target.files[0].size > 943718) {
                            alert("Archivo demasiado pesado!");
                          } else {
                            setFieldValue(
                              "CertificadoCompetencias",
                              event.target.files[0]
                            );
                          }
                        }
                      }}
                    />
                  </Button>
                  {values.CertificadoCompetencias ? (
                    <Typography>
                      {values.CertificadoCompetencias.name}
                    </Typography>
                  ) : !validFile4 ? (
                    <Typography variant="caption" color="error">
                      Este documento es requerido
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ marginTop: -18, marginBottom: -20 }}
                >
                  * Este certificado puede ser:
                </Grid>
                <Grid item xs={12} sm={12}>
                  Título de carrera, Comprobante de experiencia laboral o
                  Certificación Sernatur
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={!validCaptcha}
                  >
                    {isLoading ? (
                      <CircularProgress color="inherit" size={18} />
                    ) : (
                      "Inscribirse"
                    )}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    component={Link}
                    to="/auth/sign-in"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    {"Volver a Ingreso"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </CardContent>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          dispatch(closeSnack());
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={
            (snackState === SnackState.SUCCESS && "success") ||
            (snackState === SnackState.ERROR && "error") ||
            "success"
          }
        >
          {text}
        </Alert>
      </Snackbar>
      <DialogLoad open={isLoading} onClose={() => {}} />
    </Card>
  );
}

export default GuideRegister;
