import {
  Box,
  CardContent,
  CircularProgress,
  Grid,
  LinearProgress,
  LinearProgressProps,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../../theme/useStyles";
import MapMapbox from "../Maps/MapMapbox";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  getDirectionsPath,
  setInitialDirectionsPath,
} from "../../redux/actions/mapActions";
import { RootState } from "../../redux/reducers/rootReducer";
import { IMapState } from "../../redux/reducers/mapReducer";
import { IToursState } from "../../redux/reducers/toursReducer";
import { FormState } from "../../models/form_state";
import {
  getPointLenguageMap,
  getTourIndexData,
  restoreGetLenguagePoints,
  setIndex,
  updateTourMap,
} from "../../redux/actions/toursActions";
import { Autocomplete } from "@material-ui/lab";
import { IIdiomasState } from "../../redux/reducers/idiomasReducer";
import { getIdiomas } from "../../redux/actions/idiomasActions";
import { useParams } from "react-router-dom";
import { DialogLoad } from "../DialogLoad";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

interface Props {
  tour?: any;
  save?: (values: any) => void;
}

export const TourMap: React.FC<Props> = React.memo(({ tour, save }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { tourId } = useParams<any>();

  const [selectedTourData, setSelectedTourData] = useState<any>({});
  const [valueIdioma, setValueIdioma] = useState<any | null>(null);

  const [loadingSet, setLoadingSet] = useState(true);

  const { stateTourData, tourData } = useSelector<RootState, IMapState>(
    (state) => state.mapReducer
  );

  const { index, updateMapState, stateGetLenguage } = useSelector<
    RootState,
    IToursState
  >((state) => state.toursReducer);

  const { idiomas } = useSelector<RootState, IIdiomasState>(
    (state) => state.idiomasReducer
  );

  useEffect(() => {
    if (tour?.Path?.length > 1 && !tour?.Path[1]?.routeTo) {
      dispatch(getDirectionsPath(tour.Path));
    } else if (tour?.Path?.length > 1 && tour?.Path[1]?.routeTo) {
      setLoadingSet(true);

      const arrayPoints: any[] = [];
      const arrayRoute: any[] = [];
      for (let i = 0; i < tour.Path.length; i++) {
        const routeTo =
          tour.Path[i]?.routeTo?.length >= 1
            ? tour.Path[i].routeTo.map((r: any) => [r.lng, r.lat])
            : [];
        arrayRoute.push(...routeTo);
        arrayPoints.push({
          // feature for Mapbox
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [tour.Path[i].lng, tour.Path[i].lat],
          },
          routeTo:
            tour.Path[i].routeTo.length >= 1
              ? tour.Path[i].routeTo.map((r: any) => [r.lng, r.lat])
              : [],
          properties: {
            title: "",
          },
        });
      }
      selectedTourData.points = arrayPoints;
      selectedTourData.route = arrayRoute;
      setLoadingSet(false);
    } else if (tour?.Path?.length === 1) {
      setLoadingSet(true);
      selectedTourData.route = [];
      const arrayPoints: any[] = [];
      const arrayRoute: any[] = [];
      for (let i = 0; i < tour.Path.length; i++) {
        const routeTo =
          tour.Path[i]?.routeTo?.length >= 1
            ? tour.Path[i].routeTo.map((r: any) => [r.lng, r.lat])
            : [];
        arrayRoute.push(...routeTo);
        arrayPoints.push({
          // feature for Mapbox
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [tour.Path[i].lng, tour.Path[i].lat],
          },
          routeTo:
            tour.Path[i]?.routeTo?.length >= 1
              ? tour.Path[i].routeTo.map((r: any) => [r.lng, r.lat])
              : [],
          properties: {
            title: "",
          },
        });
      }
      selectedTourData.points = arrayPoints;
      selectedTourData.route = arrayRoute;
      setLoadingSet(false);
    }
  }, [tour?.Path]);

  let [disabledLenguages] = useState<string[]>([]);

  useEffect(() => {
    if (idiomas.length === 0) {
      dispatch(getIdiomas());
    }
  }, [idiomas]);

  useEffect(() => {
    if (tour.Idiomas) {
      dispatch(getPointLenguageMap(tour.Idiomas[0], tour.id));
      tour?.Idiomas.map((data: any) => {
        //Array only names
        disabledLenguages.push(data);
      });
    }
  }, []);

  useEffect(() => {
    if (tour.Idiomas) {
      setValueIdioma({ Codigo: tour.Idiomas[0] });
    }
  }, [tour]);

  useEffect(() => {
    return () => {
      dispatch(setInitialDirectionsPath());
      dispatch(restoreGetLenguagePoints());
    };
  }, []);

  const handleSaveChange = (path: any[], route: any[], pointsCoords: any) => {
    dispatch(updateTourMap(tourId, path, pointsCoords));
  };

  return (
    <React.Fragment>
      <CardContent>
        <Grid container justify="space-between">
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                id="combo-box-demo"
                value={
                  valueIdioma
                    ? idiomas.find(
                        (idioma) => idioma?.Codigo === valueIdioma?.Codigo
                      )
                    : null
                }
                options={idiomas}
                getOptionLabel={(option) => option.Nombre}
                getOptionDisabled={(option) =>
                  !disabledLenguages.find(
                    (element) => element === option.Codigo
                  )
                }
                onChange={(event: any, newValue: any | null) => {
                  setValueIdioma(newValue);
                  if (newValue) {
                    dispatch(getPointLenguageMap(newValue.Codigo, tour.id));
                  }
                }}
                style={{ height: "20px" }}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Puntos videos"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
          {stateTourData === FormState.Success &&
            stateGetLenguage === FormState.Success && (
              <>
                <MapMapbox
                  selected={
                    !tour?.Path[1]?.routeTo ? tourData : selectedTourData
                  }
                  mediaPoints={index.selectedIndex}
                  onSave={handleSaveChange}
                />
              </>
            )}
          {!loadingSet && stateGetLenguage === FormState.Success && (
            <MapMapbox
              selected={selectedTourData}
              mediaPoints={index.selectedIndex}
              onSave={handleSaveChange}
            />
          )}
          {stateTourData === FormState.Submitting && (
            <div
              style={{
                marginTop: 20,
                alignContent: "center",
                alignSelf: "center",
                marginLeft: "auto",
                marginRight: "auto",
                height: 450,
              }}
            >
              <CircularProgress />
            </div>
          )}
          {!tour?.Path &&
            (!tour.Idiomas || stateGetLenguage === FormState.Success) && (
              <MapMapbox
                selected={null}
                mediaPoints={index.selectedIndex}
                onSave={handleSaveChange}
              />
            )}
          {tour?.Path?.length === 0 &&
            (!tour.Idiomas || stateGetLenguage === FormState.Success) && (
              <MapMapbox
                selected={null}
                mediaPoints={index.selectedIndex}
                onSave={handleSaveChange}
              />
            )}
        </Grid>
      </CardContent>
      <DialogLoad
        open={updateMapState === FormState.Submitting}
        onClose={() => {}}
      />
    </React.Fragment>
  );
});
