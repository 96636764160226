import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Plus, Edit, Trash2 } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  createPlacesType,
  deletePlacesType,
  getPlacesTypes,
  updatePlacesType,
} from "../../redux/actions/placesTypesActions";
import { IPlacesTypesState } from "../../redux/reducers/placesTypesReducer";
import { RootState } from "../../redux/reducers/rootReducer";
import { HexColorPicker } from "react-colorful";

interface Props {
  open: boolean;
  onClose: () => void;
}

interface ItemListProps {
  data: any;
  onSave: (value: boolean, color: string) => void;
  onDelete: () => void;
}

export const DialogTiposLugares: React.FC<Props> = React.memo(
  ({ open, onClose }) => {
    const dispatch = useDispatch();
    const [valueTxt, setValueTxt] = useState("");
    const [valueColor, setValueColor] = useState("#cccccc");
    const handleChangeTxt = (e: any) => {
      setValueTxt(e.target.value);
    };
    const { placesTypes } = useSelector<RootState, IPlacesTypesState>(
      (state) => state.placesTypesReducer
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {
      if (!placesTypes) {
        dispatch(getPlacesTypes());
      }
    }, [placesTypes]);

    return (
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            onClose();
          }
        }}
        aria-labelledby="add-and-update-dialog-title"
      >
        <DialogTitle id="add-and-update-dialog-title">
          Tipos de Comercios
        </DialogTitle>
        <DialogContent>
          <List>
            {placesTypes?.map((place: any) => (
              <MyItemList
                key={place.id}
                data={place}
                onSave={(value, color) =>
                  dispatch(updatePlacesType(place, value, color))
                }
                onDelete={() => dispatch(deletePlacesType(place))}
              />
            ))}
            <ListItem button alignItems="center">
              <TextField
                style={{ paddingRight: 20 }}
                id="outlined-basic"
                size="small"
                value={valueTxt}
                fullWidth
                variant="outlined"
                onChange={handleChangeTxt}
              />
              <div>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  style={{
                    backgroundColor: valueColor,
                    height: 35,
                    marginRight: 15,
                  }}
                >
                  Color
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <HexColorPicker
                      color={valueColor}
                      onChange={(e) => {
                        setValueColor(e);
                      }}
                    />
                  </MenuItem>
                  <MenuItem>
                    <TextField
                      id="outlined-basic"
                      label="Hex Color"
                      variant="outlined"
                      value={valueColor}
                      onChange={(e) => {
                        if (e.target.value && e.target.value != undefined)
                          setValueColor(e.target.value);
                      }}
                    />
                  </MenuItem>
                </Menu>
              </div>

              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => {
                    dispatch(createPlacesType(valueTxt, valueColor));
                    setValueTxt("");
                  }}
                >
                  <Plus />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

const MyItemList: React.FC<ItemListProps> = React.memo(
  ({ data, onSave, onDelete }) => {
    const [isChecked, setIsChecked] = useState(data.Activo);
    const [valueColor, setValueColor] = useState("#cccccc");

    useEffect(() => {
      setValueColor(data.Color || "#cccccc");
    }, [data.Color]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleSave = () => {
      setAnchorEl(null);
      onSave(isChecked, valueColor);
    };

    const handleDelete = () => onDelete();
    return (
      <ListItem button>
        <ListItemText primary={data.Nombre} />

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>
            <HexColorPicker
              color={valueColor}
              onChange={(e) => {
                setValueColor(e);
              }}
            />
          </MenuItem>
          <MenuItem>
            <TextField
              id="outlined-basic"
              label="Hex Color"
              variant="outlined"
              value={valueColor}
              onChange={(e) => {
                if (e.target.value && e.target.value != undefined)
                  setValueColor(e.target.value);
              }}
            />
          </MenuItem>
          <MenuItem
            style={{
              alignItems: "center",
              alignContent: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Typography>Estado:</Typography>
            <Switch
              id={data.id}
              edge="end"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />
          </MenuItem>
          <MenuItem
            disabled={!valueColor || valueColor === undefined}
            onClick={handleSave}
            style={{
              justifyContent: "center",
            }}
          >
            <Typography style={{ fontWeight: "bold" }}>Guardar</Typography>
          </MenuItem>
        </Menu>
        <ListItemSecondaryAction>
          <IconButton
            aria-controls="color"
            aria-haspopup="false"
            color="primary"
          >
            <div
              style={{
                height: 15,
                width: 15,
                borderRadius: 10,
                backgroundColor: valueColor,
                marginRight: 25,
              }}
            ></div>
          </IconButton>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            onClick={handleClick}
          >
            <Edit />
          </IconButton>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            onClick={handleDelete}
          >
            <Trash2 />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
);
