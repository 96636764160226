import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getGuideVideosConverted,
  restoreGetVidsConvertedData,
} from "../redux/actions/tourGuidesActions";
import { ITourGuidesState } from "../redux/reducers/tourGuidesReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { exportToCsv } from "../utils/utils";

interface Props {
  user: string;
  open: boolean;
  onClose: () => void;
}

export const DialogDetailsConversor: React.FC<Props> = ({
  onClose,
  open,
  user,
}) => {
  const dispatch = useDispatch();
  const [separator, setSeparator] = useState(",");
  const [startAt, setStartAt] = useState("");
  const [endAt, setEndtAt] = useState("");
  const { videosDetails } = useSelector<RootState, ITourGuidesState>(
    (state) => state.tourGuidesReducer
  );
  const handleDownload = async () => {
    if (startAt && endAt) {
      dispatch(getGuideVideosConverted(user, startAt, endAt));
    }
  };
  useEffect(() => {
    if (!open) {
      dispatch(restoreGetVidsConvertedData());
    }
  }, [open]);
  useEffect(() => {
    if (videosDetails?.state === "success") {
      const data: any[] = videosDetails.data || [];
      /// EXECUTE FUNCT TO DOWNLOAD EXCEL
      if (data.length !== 0) {
        exportToCsv(
          "uso_conversor_videos.csv",
          data,
          ["id", "TourName", "FechaCreacion"],
          ["ID", "Tour", "Fecha uso"],
          separator
        );
      }
      onClose();
    }
  }, [videosDetails?.state]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} container justify="center">
            <Typography>Rango de Fechas</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="creacion-start"
              label="Desde"
              size="small"
              type="datetime-local"
              onChange={(event) => {
                setStartAt(event.target.value);
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="creacion-end"
              label="Hasta"
              size="small"
              type="datetime-local"
              onChange={(event) => {
                setEndtAt(event.target.value);
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={videosDetails?.state === "submitting"}
              onClick={() => {
                setSeparator(",");
                handleDownload();
              }}
            >
              {videosDetails?.state === "submitting" ? (
                <CircularProgress color="inherit" size={22} />
              ) : (
                "Descargar Opcion 1"
              )}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={videosDetails?.state === "submitting"}
              onClick={() => {
                setSeparator(";");
                handleDownload();
              }}
            >
              {videosDetails?.state === "submitting" ? (
                <CircularProgress color="inherit" size={22} />
              ) : (
                "Descargar Opcion 2"
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
