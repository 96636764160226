// import firebase from "firebase";
import Axios from "axios";
import firebase from "firebase";
import * as types from "../../constants";
import { firebaseConfig, firestore, storage } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { SnackState } from "../../models/snack-state";
import { cleanString } from "../../utils/utils";
import { editCount, editListNotifs } from "./tourGuidesActions";
import { openSnack } from "./uiActions";

const SaveCreationNotif = async (texto: string): Promise<string> => {
  try {
    const notifRef = firestore
      .collection("AdminNotifications")
      .doc("CreationPlace");

    await editListNotifs(notifRef, texto);

    return "ok";
  } catch (error) {
    console.log(error);
    // throw error;
    return String(error);
  }
};

export const getPlaces = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  pending?: boolean
): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.PLACES_GET_IS_SUBMITTING,
    });
    try {
      let response, total;
      if (pending) {
        response = await firestore
          .collection("Lugares")
          .where("Estado", "==", "En Revisión")
          .orderBy("FechaCreacion", "desc")
          .limit(limit)
          .get();
        total = await firestore
          .collection("Lugares")
          .where("Estado", "==", "En Revisión")
          .get();
      } else {
        response = await firestore
          .collection("Lugares")
          .orderBy("FechaCreacion", "desc")
          .limit(limit)
          .get();
        total = await firestore.collection("Lugares").get();
      }

      const lugares = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.PLACES_GET_SUCCESS,
        payload: {
          places: lugares,
          totalDocs: total.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.PLACES_GET_FAILURE,
        payload: "Ha ocurrido un error obteniendo los lugares",
      });
    }
  };
};

export const getMorePlaces = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  pending?: boolean
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.PLACES_GET_IS_SUBMITTING,
    });
    const lastDoc = getState().placesReducer.lastDoc || "";
    const { totalDocs, places } = getState().placesReducer;
    try {
      let response;

      if (pending)
        response = await firestore
          .collection("Lugares")
          .where("Estado", "==", "En Revisión")
          .orderBy("FechaCreacion", "desc")
          .startAfter(lastDoc)
          .limit(limit)
          .get();
      else
        response = await firestore
          .collection("Lugares")
          .orderBy("FechaCreacion", "desc")
          .startAfter(lastDoc)
          .limit(limit)
          .get();

      const lugares = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.PLACES_GET_SUCCESS,
        payload: {
          Cargos: places.concat(lugares),
          totalDocs: totalDocs,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.PLACES_GET_FAILURE,
        payload: "Ha ocurrido un error obteniendo más lugares",
      });
    }
  };
};

export const getPlacesFiltered = (
  filtro: any = {},
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.PLACES_GET_IS_SUBMITTING,
    });
    try {
      let response = firestore
        .collection("Lugares")
        .orderBy("FechaCreacion")
        .limit(limit);

      let total = firestore.collection("Lugares").orderBy("FechaCreacion");

      if (filtro) {
        Object.keys(filtro).forEach((key) => {
          let value = filtro[key];

          if (key === "endAt") {
            const miliDate = Date.parse(value);

            response = response.startAt(new Date(miliDate));
            total = total.startAt(new Date(miliDate));
          } else if (key === "startAt") {
            const miliDate = Date.parse(value);
            response = response.endAt(new Date(miliDate));
            total = total.endAt(new Date(miliDate));
          } else {
            response = response.where(key, "==", value);
            total = total.where(key, "==", value);
          }
        });
      }

      const res = await response.get();
      const totalRes = await response.get();

      const lugares = res.docs.map((x: any) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.PLACES_GET_SUCCESS,
        payload: {
          places: lugares,
          totalDocs: totalRes.size,
          lastDoc: res.docs[res.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.PLACES_GET_FAILURE,
        payload: "Ha ocurrido un error obteniendo los lugares",
      });
    }
  };
};

export const getMorePlacesFiltered = (
  filtro: any = {},
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.PLACES_GET_IS_SUBMITTING,
    });
    const { lastDoc, totalDocs, places } = getState().placesReducer;
    try {
      let response = firestore
        .collection("Lugares")
        .orderBy("FechaCreacion")
        .startAfter(lastDoc)
        .limit(limit);

      if (filtro) {
        Object.keys(filtro).forEach((key) => {
          let value = filtro[key];

          if (key === "endAt") {
            const miliDate = Date.parse(value);
            response = response.startAt(new Date(miliDate));
          } else if (key === "startAt") {
            const miliDate = Date.parse(value);
            response = response.endAt(new Date(miliDate));
          } else {
            response = response.where(key, "==", value);
          }
        });
      }

      const res = await response.get();

      const lugares = res.docs.map((x: any) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.PLACES_GET_SUCCESS,
        payload: {
          places: places.concat(lugares),
          totalDocs: totalDocs,
          lastDoc: res.docs[res.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.PLACES_GET_FAILURE,
        payload: "Ha ocurrido un error obteniendo los lugares",
      });
    }
  };
};

export const createPlace = (place: any, files?: any): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.PLACES_CREATE_IS_SUBMITTING,
    });
    try {
      const placeCheck = firestore
        .collection("Lugares")
        .where("Rut", "==", place.Rut);
      const EmailCheck = firestore
        .collection("Usuarios")
        .where("Email", "==", place.Email);
      const emailQuerySnapshot = await EmailCheck.get();
      const querySnapshot = await placeCheck.get();
      if (querySnapshot.docs.length === 0) {
        if (emailQuerySnapshot.docs.length === 0) {
          delete place.CertificadoIniciacion;
          delete place.CertificadoRegistroSernatur;
          delete place.PatenteComercial;
          delete place.CedulaRepresentante;
          const responseSave = await firestore.collection("Lugares").add({
            ...place,
            FechaCreacion: firebase.firestore.FieldValue.serverTimestamp(),
            Estado: "En Revisión",
          });

          if (files?.CertificadoIniciacion) {
            const fileRef = storage.ref(
              `${responseSave.id}/documentos/CertificadoIniciacion`
            );
            const fileUpload = await fileRef.put(files.CertificadoIniciacion);
            place.CertificadoIniciacion = await fileUpload.ref.getDownloadURL();
          }
          if (files?.CertificadoRegistroSernatur) {
            const fileRef = storage.ref(
              `${responseSave.id}/documentos/CertificadoRegistroSernatur`
            );
            const fileUpload = await fileRef.put(
              files.CertificadoRegistroSernatur
            );
            place.CertificadoRegistroSernatur =
              await fileUpload.ref.getDownloadURL();
          }
          if (files?.PatenteComercial) {
            const fileRef = storage.ref(
              `${responseSave.id}/documentos/PatenteComercial`
            );
            const fileUpload = await fileRef.put(files.PatenteComercial);
            place.PatenteComercial = await fileUpload.ref.getDownloadURL();
          }
          if (files?.CedulaRepresentante) {
            const fileRef = storage.ref(
              `${responseSave.id}/documentos/CedulaRepresentante`
            );
            const fileUpload = await fileRef.put(files.CedulaRepresentante);
            place.CedulaRepresentante = await fileUpload.ref.getDownloadURL();
          }
          await firestore
            .collection("Lugares")
            .doc(responseSave.id)
            .update(place);

          const responseAxios = await Axios.post(
            `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${firebaseConfig.apiKey}`,
            {
              email: place.Email,
              password: "#$2021#$",
              returnSecureToken: true,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          await Axios.post(
            `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${firebaseConfig.apiKey}`,
            {
              requestType: "PASSWORD_RESET",
              email: place.Email,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const id = responseAxios.data.localId;
          await firestore
            .collection("Usuarios")
            .doc(id)
            .set({
              Nombre: place.Nombre,
              Nombre_lower: cleanString(place.Nombre),
              Apellido: place.Apellido,
              Apellido_lower: cleanString(place.Apellido),
              Email: place.Email,
              Activo: true,
              TerminosYCondiciones: true,
              TipoUsuario: "Lugar",
              LugarId: responseSave.id,
            });

          dispatch(
            openSnack(
              "Comercio ha sido creado. Revisar email.",
              SnackState.SUCCESS
            )
          );
          await SaveCreationNotif(
            `Lugar creado: ${place.Nombre}, Email: ${place.Email}`
          );
          dispatch({
            type: types.PLACES_CREATE_SUCCESS,
          });
        } else {
          dispatch(openSnack("Email ya existe", SnackState.ERROR));
          dispatch({
            type: types.PLACES_CREATE_FAILURE,
            payload: "email ya existe",
          });
        }
      } else {
        dispatch(
          openSnack("Ya existe un comercio con este RUT", SnackState.ERROR)
        );
        dispatch({
          type: types.PLACES_CREATE_FAILURE,
          payload: "rut ya existe",
        });
      }

      // dispatch(getPlaces());
    } catch (error: any) {
      dispatch({
        type: types.PLACES_CREATE_FAILURE,
        payload: "Ha ocurrido un error creando el comercio",
      });
    }
  };
};

export const updatePlace = (
  place: any,
  id: string = "",
  files?: any
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.PLACES_UPDATE_IS_SUBMITTING,
    });
    const { user } = getState().authReducer;
    try {
      let placeId = id;

      delete place.CertificadoIniciacion;
      delete place.CertificadoRegistroSernatur;
      delete place.PatenteComercial;
      delete place.CedulaRepresentante;
      if (user.tipoUsuario !== "Lugar") {
        const usrs = await firestore
          .collection("Usuarios")
          .where("LugarId", "==", id)
          .get();
        usrs.docs.map(async (usr) => {
          await firestore
            .collection("Usuarios")
            .doc(usr.id)
            .update({
              Nombre: place.Nombre,
              Apellido: place.Apellido,
              Nombre_lower: cleanString(place.Nombre),
              Apellido_lower: cleanString(place.Apellido),
              Telefono: place.Telefono,
            })
            .catch((errorUsr) => console.log(errorUsr));
        });
      } else {
        const usr = await firestore.collection("Usuarios").doc(id).get();
        placeId = usr.data()?.LugarId;
        await firestore
          .collection("Usuarios")
          .doc(usr.id)
          .update({
            Nombre: place.Nombre,
            Apellido: place.Apellido,
            Nombre_lower: cleanString(place.Nombre),
            Apellido_lower: cleanString(place.Apellido),
            Telefono: place.Telefono,
          })
          .catch((errorUsr) => console.log(errorUsr));
      }

      if (files?.CertificadoIniciacion) {
        const fileRef = storage.ref(
          `${placeId}/documentos/CertificadoIniciacion`
        );
        const fileUpload = await fileRef.put(files.CertificadoIniciacion);
        place.CertificadoIniciacion = await fileUpload.ref.getDownloadURL();
      }
      if (files?.CertificadoRegistroSernatur) {
        const fileRef = storage.ref(
          `${placeId}/documentos/CertificadoRegistroSernatur`
        );
        const fileUpload = await fileRef.put(files.CertificadoRegistroSernatur);
        place.CertificadoRegistroSernatur =
          await fileUpload.ref.getDownloadURL();
      }
      if (files?.PatenteComercial) {
        const fileRef = storage.ref(`${placeId}/documentos/PatenteComercial`);
        const fileUpload = await fileRef.put(files.PatenteComercial);
        place.PatenteComercial = await fileUpload.ref.getDownloadURL();
      }
      if (files?.CedulaRepresentante) {
        const fileRef = storage.ref(
          `${placeId}/documentos/CedulaRepresentante`
        );
        const fileUpload = await fileRef.put(files.CedulaRepresentante);
        place.CedulaRepresentante = await fileUpload.ref.getDownloadURL();
      }

      await firestore
        .collection("Lugares")
        .doc(placeId)
        .update({
          ...place,
        });

      const updatedPlace = { ...place, id: placeId };
      dispatch({
        type: types.PLACES_UPDATE_SUCCESS,
        payload: updatedPlace,
      });
      dispatch(openSnack("Comercio ha sido actualizado", SnackState.SUCCESS));
      // dispatch(getPlaces());
    } catch (error: any) {
      dispatch({
        type: types.PLACES_UPDATE_FAILURE,
        payload: "Ha ocurrido un error actualizando el comercio",
      });
    }
  };
};

export const getPlaceVideos = (id: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.PLACES_GET_VIDEOS_IS_SUBMITTING,
    });
    try {
      const response = await firestore
        .collection("Lugares")
        .doc(id)
        .collection("Videos")
        .get();

      const videos = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.PLACES_GET_VIDEOS_SUCCESS,
        payload: videos,
      });
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.PLACES_GET_VIDEOS_FAILURE,
        payload: "Ha ocurrido un error obteniendo los Videos",
      });
    }
  };
};

export const getClicksViewsCount = (
  idPlace: string,
  idVideo: string
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.PLACE_GET_VIEW_CLICK_SUBMITTING,
    });
    try {
      const responseClicks = await firestore
        .collection("Lugares")
        .doc(idPlace)
        .collection("Videos")
        .doc(idVideo)
        .collection("Clicks")
        .get();
      const responseViews = await firestore
        .collection("Lugares")
        .doc(idPlace)
        .collection("Videos")
        .doc(idVideo)
        .collection("Visualizaciones")
        .get();

      if (responseClicks.metadata && responseViews.metadata) {
        const clicks: any[] = responseClicks.docs.map((x) => ({
          ...x.data(),
          id: x.id,
        }));
        const views: any[] = responseViews.docs.map((x) => ({
          ...x.data(),
          id: x.id,
        }));

        dispatch({
          type: types.PLACE_GET_VIEW_CLICK_SUCCESS,
          payload: { clickDetails: clicks, viewsDetails: views },
        });
      } else {
        dispatch({
          type: types.PLACE_GET_VIEW_CLICK_FAILURE,
          payload: "Ha ocurrido un error obteniendo los detalles",
        });
      }
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.PLACE_GET_VIEW_CLICK_FAILURE,
        payload: "Ha ocurrido un error obteniendo los detalles",
      });
    }
  };
};

export const addPlaceVideo = (video: any, id: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.PLACES_UPDATE_IS_SUBMITTING,
    });
    const { selectedPlace } = getState().placesReducer;
    try {
      /// to get video duration

      const loadVideo = (thisfile: any) =>
        new Promise((resolve, reject) => {
          try {
            let video = document.createElement("video");
            video.preload = "metadata";

            video.onloadedmetadata = function () {
              resolve(this);
            };

            video.onerror = function () {
              reject("Invalid video. Please select a video file.");
            };

            video.src = window.URL.createObjectURL(thisfile);
          } catch (e) {
            reject(e);
          }
        });

      const videoRef = storage.ref(
        `lugares/${selectedPlace.id}/videos/${video.Nombre}`
      );
      const videoUp = videoRef.put(video.file);
      const videoFile: any = await loadVideo(video.file);
      delete video.file;
      video.Ubicacion = `lugares/${selectedPlace.id}/videos/${video.Nombre}`;
      video.Duracion = videoFile.duration;
      const fecha = new Date(video.Fecha).setHours(25, 0, 0);
      video.Fecha = new Date(fecha);

      const fechaFinal = new Date(video.FechaFinal).setHours(25, 0, 0);
      video.FechaFinal = new Date(fechaFinal);
      videoUp.on(
        "state_change",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          dispatch({
            type: types.PLACES_VIDEO_UPLOAD,
            payload: progress.toFixed(3),
          });
          // console.log(progress.toFixed(3));
        },
        (error) => {
          console.log(error);
          dispatch(
            openSnack(
              "¡Ha ocurrido un error al subir el video!",
              SnackState.ERROR
            )
          );
        },
        () => {
          videoUp.snapshot.ref
            .getDownloadURL()
            .then(async function (downloadURL) {
              firestore
                .collection("Lugares")
                .doc(selectedPlace.id)
                .collection("Videos")
                .add({
                  ...video,
                  Url: downloadURL,
                });
              dispatch({
                type: types.PLACES_UPDATE_SUCCESS,
              });
              dispatch({
                type: types.PLACES_VIDEO_UPLOAD,
                payload: 0,
              });
              await SaveCreationNotif(
                `Nueva publicidad creada por comercio: ${selectedPlace?.NombreLugar}`
              );
              dispatch(openSnack("Se ha subido el video", SnackState.SUCCESS));
              dispatch(getPlaceVideos(selectedPlace.id));
            });
        }
      );
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.PLACES_UPDATE_FAILURE,
        payload: "Ha ocurrido un error subiendo video para lugar",
      });
    }
  };
};

export const getPlaceImages = (idPlace) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.PLACES_GET_IMAGES_IS_SUBMITTING,
    });
    try {
      console.log(idPlace);

      const response = await firestore
        .collection("Lugares")
        .doc(idPlace)
        .collection("Imagenes")
        .get();

      const images = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.PLACES_GET_IMAGES_SUCCESS,
        payload: images,
      });
    } catch (error) {
      console.log(error);

      dispatch({
        type: types.PLACES_GET_IMAGES_FAILURE,
        payload: "Ha ocurrido un error obteniendo las Imagenes",
      });
    }
  };
};

export const updatePlaceVideo = (video: any, id: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.PLACES_UPDATE_IS_SUBMITTING,
    });
    const { selectedPlace } = getState().placesReducer;
    try {
      /// to get video duration
      const loadVideo = (thisfile: any) =>
        new Promise((resolve, reject) => {
          try {
            let video = document.createElement("video");
            video.preload = "metadata";

            video.onloadedmetadata = function () {
              resolve(this);
            };

            video.onerror = function () {
              reject("Invalid video. Please select a video file.");
            };

            video.src = window.URL.createObjectURL(thisfile);
          } catch (e) {
            reject(e);
          }
        });

      if (video.file) {
        const videoRef = storage.ref(video.Ubicacion);
        const videoUp = videoRef.put(video.file);
        const videoFile: any = await loadVideo(video.file);
        delete video.file;
        video.Duracion = videoFile.duration;
        const fecha = new Date(video.Fecha).setHours(25, 0, 0);
        video.Fecha = new Date(fecha);

        const fechaFinal = new Date(video.FechaFinal).setHours(25, 0, 0);
        video.FechaFinal = new Date(fechaFinal);
        videoUp.on(
          "state_change",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            dispatch({
              type: types.PLACES_VIDEO_UPLOAD,
              payload: progress.toFixed(3),
            });
          },
          (error) => {
            console.log(error);
            dispatch(
              openSnack(
                "¡Ha ocurrido un error al subir el video!",
                SnackState.ERROR
              )
            );
          },
          () => {
            videoUp.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              const videoId = video.id;
              video.Url = downloadURL;
              firestore
                .collection("Lugares")
                .doc(selectedPlace.id)
                .collection("Videos")
                .doc(videoId)
                .update(video)
                .then(() => {
                  dispatch(getPlaceVideos(selectedPlace.id));
                });
              dispatch({
                type: types.PLACES_UPDATE_SUCCESS,
              });
              dispatch({
                type: types.PLACES_VIDEO_UPLOAD,
                payload: 0,
              });
              dispatch(
                openSnack("Se han actualizado los datos", SnackState.SUCCESS)
              );
            });
          }
        );
      } else {
        const videoId = video.id;
        delete video.file;
        delete video.id;

        const fecha = new Date(video.Fecha).setHours(25, 0, 0);
        video.Fecha = new Date(fecha);

        const fechaFinal = new Date(video.FechaFinal).setHours(25, 0, 0);
        video.FechaFinal = new Date(fechaFinal);
        await firestore
          .collection("Lugares")
          .doc(selectedPlace.id)
          .collection("Videos")
          .doc(videoId)
          .update({
            ...video,
          });
        dispatch({
          type: types.PLACES_UPDATE_SUCCESS,
        });
        dispatch(openSnack("Se han actualizado los datos", SnackState.SUCCESS));
        dispatch(getPlaceVideos(selectedPlace.id));
      }
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.PLACES_UPDATE_FAILURE,
        payload: "Ha ocurrido un error actualizando datos",
      });
    }
  };
};

export const deletePlaceVideo = (video: any, id: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.PLACES_UPDATE_IS_SUBMITTING,
    });
    const { selectedPlace } = getState().placesReducer;
    try {
      const videoRef = storage.ref(video.Ubicacion);
      await videoRef.delete();
      await firestore
        .collection("Lugares")
        .doc(selectedPlace.id)
        .collection("Videos")
        .doc(id)
        .delete();
      dispatch({
        type: types.PLACES_UPDATE_SUCCESS,
      });
      dispatch(openSnack("Video eliminado correctamente", SnackState.SUCCESS));
      dispatch(getPlaceVideos(selectedPlace.id));
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.PLACES_UPDATE_FAILURE,
        payload: "Ha ocurrido un error eliminando datos",
      });
    }
  };
};

export const deletePlace = (place: any): AppThunk => {
  return async (dispatch) => {
    dispatch({ type: types.PLACES_DELETE_IS_SUBMITTING });
    try {
      const userResponse = await firestore
        .collection("Usuarios")
        .where("LugarId", "==", place.id)
        .get();

      if (userResponse.docs[0].exists) {
        const id: string = userResponse.docs[0].id;
        await firestore
          .collection("Usuarios")
          .doc(userResponse.docs[0].id)
          .update({
            Activo: false,
            Deleted: true,
            Email: firebase.firestore.FieldValue.delete(),
          });
        try {
          await Axios.post(
            `https://identitytoolkit.googleapis.com/v1/accounts:delete?key=${firebaseConfig.apiKey}`,
            {
              idToken: id,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        } catch {
          await Axios.post(
            `https://us-central1-traveapp-fa700.cloudfunctions.net/app/delete/user/auth`,
            {
              email: userResponse.docs[0].data().Email,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }
          ).catch(() => console.log("ERROR"));
        }
      }

      const videosPlaces = await firestore
        .collection("Lugares")
        .doc(place.id)
        .collection("Videos")
        .get();

      await Promise.all(
        videosPlaces.docs.map((video) => {
          const videoRef = storage.ref(video.data().Ubicacion);
          try {
            videoRef.delete();
          } catch (errorDeleteVideoRef) {}
        })
      );

      await firestore.collection("Lugares").doc(place.id).delete();
      dispatch({
        type: types.PLACES_DELETE_SUCCESS,
        payload: place,
      });
      dispatch(openSnack("Lugar ha sido eliminado", SnackState.SUCCESS));
      dispatch(getPlaces());
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.PLACES_DELETE_FAILURE,
        payload: "Ha ocurrido un error eliminando el lugar",
      });
    }
  };
};

export const getOnePlace = (id: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.PLACES_GET_ONE_IS_SUBMITTING,
    });
    const { user } = getState().authReducer;
    try {
      let response: any;
      if (user?.tipoUsuario === "Lugar") {
        const responseUser = await firestore
          .collection("Usuarios")
          .doc(user?.id)
          .get();
        const lugarId = responseUser!.data()!.LugarId;
        response = await firestore.collection("Lugares").doc(lugarId).get();
      } else {
        response = await firestore.collection("Lugares").doc(id).get();
      }
      const data: any = response.data();
      data["id"] = response.id;

      dispatch({
        type: types.PLACES_GET_ONE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.PLACES_GET_ONE_FAILURE,
        payload: "Ha ocurrido un error obteniendo el lugar",
      });
    }
  };
};

export const getPlaceCalificaciones = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.PLACE_GET_RATING_IS_SUBMITTING,
    });
    try {
      const response = await firestore
        .collection("Calificaciones")
        .where("LugarId", "==", id)
        .get();

      const arrayResponse = response.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      dispatch({
        type: types.PLACE_GET_RATING_SUCCESS,
        payload: arrayResponse,
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.PLACE_GET_RATING_FAILURE,
        payload: error,
      });
    }
  };
};

export const setSelectedPlace = (place: any): Action => ({
  type: types.PLACES_SET_SELECTED,
  payload: place,
});
