import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { AppThunk } from "../../models/app-thunk";

export const loadNotification = (): AppThunk => {
  return async (dispatch) => {
    try {
      let guides = [];
      let places = [];
      const guidesRes = await firestore
        .collection("AdminNotifications")
        .doc("CreationGuide")
        .get();
      const placeRes = await firestore
        .collection("AdminNotifications")
        .doc("CreationPlace")
        .get();

      if (guidesRes.exists) {
        guides = guidesRes.data()?.Lista || [];
      }
      if (placeRes.exists) {
        places = placeRes.data()?.Lista || [];
      }

      // await firestore
      //   .collection("AdminNotifications")
      //   .doc("CreationGuide")
      //   .set({ count: 0 })
      //   .catch(() => {});

      // await firestore
      //   .collection("AdminNotifications")
      //   .doc("CreationPlace")
      //   .set({ count: 0 })
      //   .catch(() => {});

      dispatch({
        type: types.GET_CREATIONS_NOTIFICATIONS,
        payload: { guideCount: guides, placeCount: places },
      });
    } catch {}
  };
};

export const cleanGuideNotifications = (): AppThunk => {
  return async (dispatch) => {
    try {
      await firestore
        .collection("AdminNotifications")
        .doc("CreationGuide")
        .set({ Lista: [] })
        .catch(() => {});

      // await firestore
      //   .collection("AdminNotifications")
      //   .doc("CreationPlace")
      //   .set({ count: 0 })
      //   .catch(() => {});
    } catch {}
  };
};
export const cleanPlaceNotifications = (): AppThunk => {
  return async (dispatch) => {
    try {
      // await firestore
      //   .collection("AdminNotifications")
      //   .doc("CreationGuide")
      //   .set({ Lista: [] })
      //   .catch(() => {});

      await firestore
        .collection("AdminNotifications")
        .doc("CreationPlace")
        .set({ Lista: [] })
        .catch(() => {});
    } catch {}
  };
};

// export const closeSnack = () => ({
//   type: types.UI_SNACK_OPEN,
//   payload: false,
// });
