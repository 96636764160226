// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};
export const SNACK_DURATION = 3000;
export const VERSION = "v1.0";
export const TABLE_LIMITS = [5, 10, 20, 30, 50];
export const TABLE_LIMIT_DEFAULT = TABLE_LIMITS[2];

// Google Re captcha key
export const CAPTCHA_KEY = "6LczWCMfAAAAALmxPsqiI-Uy78m7nuVJrOo5JkAh";
//"6LeDHSAdAAAAAPxnwtYJpnVHNIFVMVizaSVABp_Z";
//

//Maps Key
export const MAPS_API_KEY = "AIzaSyCOk8Qr49hgKFBno3_kPh482gpc1h0rctg";
export const MAPBOX_TOKEN =
  "pk.eyJ1IjoidHJhdmVmb3VuZGVyIiwiYSI6ImNsMG1nYm81NzE2OGUzYnJrd2tkOHo5ZnQifQ.3fV-sUICtcOBn6uy4cGyZQ";
// "pk.eyJ1IjoibnhpeHN4ZSIsImEiOiJjbDBpbDA1ZnIwM28wM2JyazY0enVuMm1hIn0.UqfIDDH2_PZqAyXgXl0bzQ";
// "sk.eyJ1IjoiY2VvdHJhdmUiLCJhIjoiY2t6cmV3emk3MmhlZzJvcW81Ym0waDRzNiJ9.R0mrFAMvprinE9Sr6u8oUQ";
// "pk.eyJ1IjoibnhpeHN4ZSIsImEiOiJja211bTM4MzMxMzRyMnVtcG9vd2s3MXk1In0.2Iw-_F4BUJhi1ewDzy1mdQ";

//Redux

//UI
export const UI_SNACK_STATE_CHANGE = "[UI] Snack state change";
export const UI_SNACK_OPEN = "[UI] Snack open change";
export const UI_SNACK_TEXT_CHANGE = "[UI] Snack text change";

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

//
export const AUTH_CHANGE_PASS_GET_EMAIL_IN_REQUEST =
  "AUTH_CHANGE_PASS_GET_EMAIL_IN_REQUEST";
export const AUTH_CHANGE_PASS_GET_EMAIL_SUCCESS =
  "AUTH_CHANGE_PASS_GET_EMAIL_SUCCESS";
export const AUTH_CHANGE_PASS_GET_EMAIL_FAILURE =
  "AUTH_CHANGE_PASS_GET_EMAIL_FAILURE";
export const AUTH_CHANGE_PASS_SET_NEW_IN_REQUEST =
  "AUTH_CHANGE_PASS_SET_NEW_IN_REQUEST";
export const AUTH_CHANGE_PASS_SET_NEW_SUCCESS =
  "AUTH_CHANGE_PASS_SET_NEW_SUCCESS";
export const AUTH_CHANGE_PASS_SET_NEW_FAILURE =
  "AUTH_CHANGE_PASS_SET_NEW_FAILURE";

// Empresa
export const BUSINESS_GET_DOCS = "[Business] Get Docs";
export const BUSINESS_ADD_DOCS = "[Business] Add Docs";
export const BUSINESS_SET_LAST_DOC = "[Business] Set last Doc";
export const BUSINESS_SET_TOTAL_DOCS = "[Business] Set Total Docs";
export const BUSINESS_LOADING = "[Business] Is Loading";
export const BUSINESS_UPDATE_LOADING = "[Business] Is update loading";
export const BUSINESS_UPDATE_SELECTED = "[Business] Update selected business";
export const BUSINESS_FAILURE = "[Business] Failure";
export const BUSINESS_SET_SELECTED = "[Business] Set Selected business";

// Tours
export const TOURS_GET_DOCS = "[Tours] Get Docs";
export const TOURS_INDEX_GET_DOCS = "[Tours] Get Index Docs";
export const TOURS_ADD_DOCS = "[Tours] Add Docs";
export const TOURS_SET_LAST_DOC = "[Tours] Set last Doc";
export const TOURS_SET_TOTAL_DOCS = "[Tours] Set Total Docs";
export const TOURS_KEEP_TOTAL_DOCS = "[Tours] Keep Total Docs";
export const TOURS_LOADING = "[Tours] Is Loading";
export const TOURS_ADD_INDEX_LOADING = "[Tours] Add Index Is Loading";
export const TOURS_INDEX_LOADING = "[Tours] Is Loading Index";
export const TOURS_UPDATE_LOADING = "[Tours] Is update loading";
export const TOURS_CHANGE_FORM_STATE = "[Tours] Change Form State";
export const TOURS_UPDATE_DOC = "[Tours] Update doc";
export const TOURS_REMOVE_DOC = "[Tours] Delete doc";
export const TOURS_UPDATE_SELECTED = "[Tours] Update selected tours";
export const TOURS_FAILURE = "[Tours] Failure";
export const TOURS_SET_SELECTED = "[Tours] Set Selected tours";
export const TOURS_VIDEO_UPLOAD = "[Tours] Upload Video";
export const TOUR_POINT_UPDATE_IS_SUBMITTING =
  "[Tours] Update point is submitting";
export const TOUR_POINT_UPDATE_IS_SUCCESS = "[Tours] Update point is success";
export const TOUR_POINT_UPDATE_IS_FAILURE = "[Tours] Update point is failure";
export const TOUR_LENGUAGE_DELETE_SUBMITTING =
  "[Tours] Delete lenguage is submitting";
export const TOUR_LENGUAGE_DELETE_SUCCESS = "[Tours] Delete lenguage success";
export const TOUR_LENGUAGE_DELETE_FAILURE = "[Tours] Delete lenguage failure";
export const TOUR_DELETE_IS_SUBMITTING = "[Tours] Delete tour is sumitting";
export const TOUR_DELETE_SUCCESS = "[Tours] Delete tour success";
export const TOUR_DELETE_FAILURE = "[Tours] Delete tour failure";
export const TOUR_GET_RATING_IS_SUBMITTING = "[Tours] Get rating is submitting";
export const TOUR_GET_RATING_SUCCESS = "[Tours] Get rating succcess";
export const TOUR_GET_RATING_FAILURE = "[Tours] Get rating failure";
export const TOUR_UPDATE_MAP_IS_SUBMITTING = "[Tours] Update map is submitting";
export const TOUR_UPDATE_MAP_SUCCESS = "[Tours] Update map success";
export const TOUR_UPDATE_MAP_FAILURE = "[Tours] Update map failure";
export const TOUR_LENGUAGE_POINT_GET_SUBMITTING =
  "[Tours] Get point lenguage map submitting";
export const TOUR_LENGUAGE_POINT_GET_SUCCESS =
  "[Tours] Get point lenguage map success";
export const TOUR_LENGUAGE_POINT_GET_FAILURE =
  "[Tours] Get point lenguage map failure";
export const TOUR_LENGUAGE_POINT_GET_RESTORE =
  "[Tours] Get point lenguage map restore redux";

// Regions
export const REGIONS_GET_DOCS = "[Regions] Get Docs";
export const REGIONS_LOADING = "[Regions] Is Loading";
export const REGIONS_FAILURE = "[Regions] Failure";

// Regions
export const IDIOMA_GET_DOCS = "[Idioma] Get Docs";
export const IDIOMA_LOADING = "[Idioma] Is Loading";
export const IDIOMA_FAILURE = "[Idioma] Failure";

// Tour Types
export const TOUR_TYPES_GET_DOCS = "[Tour Types] Get Docs";
export const TOUR_TYPES_LOADING = "[Tour Types] Is Loading";
export const TOUR_TYPES_FAILURE = "[Tour Types] Failure";
export const TOUR_TYPES_CREATE_SUBMITTING =
  "[Tour Types] Create tour type Submitting";
export const TOUR_TYPES_CREATE_SUCCESS =
  "[Tour Types] Create tour type Success";
export const TOUR_TYPES_CREATE_FAILURE =
  "[Tour Types] Create tour type Failure";
export const TOUR_TYPES_REMOVE_SUBMITTING =
  "[Tour Types] Remove tour type Submitting";
export const TOUR_TYPES_REMOVE_SUCCESS =
  "[Tour Types] Remove tour type Success";
export const TOUR_TYPES_REMOVE_FAILURE =
  "[Tour Types] Remove tour type Failure";

// Places Types

// Tour Guides
export const TOUR_GUIDES_GET_DOCS = "[Tour TourGuides] Get Docs";
export const TOUR_GUIDES_ADD_DOCS = "[Tour TourGuides] Add Docs";
export const TOUR_GUIDES_SET_LAST_DOC = "[Tour TourGuides] Set last Doc";
export const TOUR_GUIDES_SET_TOTAL_DOCS = "[Tour TourGuides] Set Total Docs";
export const TOUR_GUIDES_LOADING = "[Tour TourGuides] Is Loading";
export const TOUR_GUIDES_UPDATE_LOADING = "[Tour TourGuides] Is update loading";
export const TOUR_GUIDES_UPDATE_DOC = "[Tour TourGuides] Update doc";
export const TOUR_GUIDES_REMOVE_DOC = "[Tour TourGuides] Delete doc";
export const TOUR_GUIDES_UPDATE_SELECTED =
  "[Tour TourGuides] Update selected business";
export const TOUR_GUIDES_FAILURE = "[Tour TourGuides] Failure";
export const TOUR_GUIDES_SET_SELECTED =
  "[Tour TourGuides] Set Selected business";
export const TOUR_GUIDES_CHANGE_DIALOG_ACTION =
  "[Tour TourGuides] Change Dialog Action";
export const TOUR_GUIDES_IS_DIALOG_OPEN = "[Tour TourGuides] Is Dialog open";
export const TOUR_GUIDES_REGIONS_GET = "[Tour Regions] Get Regions";
export const TOUR_GUIDE_GET_VIDS_CONVERTED_REQUEST =
  "[Tour Guide] Submitting get videos converted data";
export const TOUR_GUIDE_GET_VIDS_CONVERTED_SUCCESS =
  "[Tour Guide] Success get videos converted data";
export const TOUR_GUIDE_GET_VIDS_CONVERTED_FAILURE =
  "[Tour Guide] Failure get videos converted data";
export const TOUR_GUIDE_GET_VIDS_CONVERTED_RESTORE =
  "[Tour Guide] Restore states get videos converted data";

// Clientes
export const CLIENTS_GET_DOCS = "[Clients] Get Docs";
export const CLIENTS_ADD_DOCS = "[Clients] Add Docs";
export const CLIENTS_SET_LAST_DOC = "[Clients] Set last Doc";
export const CLIENTS_SET_TOTAL_DOCS = "[Clients] Set Total Docs";
export const CLIENTS_LOADING = "[Clients] Is Loading";
export const CLIENTS_UPDATE_LOADING = "[Clients] Is update loading";
export const CLIENTS_UPDATE_DOC = "[Clients] Update doc";
export const CLIENTS_REMOVE_DOC = "[Clients] Delete doc";
export const CLIENTS_UPDATE_SELECTED = "[Clients] Update selected client";
export const CLIENTS_FAILURE = "[Clients] Failure";
export const CLIENTS_SET_SELECTED = "[Clients] Set Selected client";
export const CLIENTS_GET_SELECTED = "[Clients] Get Selected client";

// Sales
export const SALES_TOUR_GET_DOCS = "[Sales] Tour Get Docs";
export const SALES_USER_GET_DOCS = "[Sales] User Get Docs";
export const SALESS_ADD_DOCS = "[Sales] Add Docs";
export const SALES_SET_LAST_DOC = "[Sales] Set last Doc";
export const SALES_SET_TOTAL_DOCS = "[Sales] Set Total Docs";
export const SALES_LOADING = "[Sales] Is Loading";
export const SALES_UPDATE_LOADING = "[Sales] Is update loading";
export const SALES_UPDATE_DOC = "[Sales] Update doc";
export const SALES_REMOVE_DOC = "[Sales] Delete doc";
export const SALES_UPDATE_SELECTED = "[Sales] Update selected Sales";
export const SALES_FAILURE = "[Sales] Failure";
export const SALES_SET_SELECTED = "[Sales] Set Selected Sales";
export const SALES_GET_SELECTED = "[Sales] Get Selected Sales";
export const SALES_ALL_GET_IS_SUBMITTING =
  "[Sales] Get all sales is submitting";
export const SALES_ALL_GET_SUCCESS = "[Sales] Get all sales success";
export const SALES_ALL_GET_FAILURE = "[Sales] Get all sales failure";
export const SALES_SET_LIST_ALL_DATA = "[Sales] Set all sales list data";

// Usuarios
export const USERS_GET_DOCS = "[Users] Get Docs";
export const USERS_ADD_DOCS = "[Users] Add Docs";
export const USERS_SET_LAST_DOC = "[Users] Set last Doc";
export const USERS_SET_TOTAL_DOCS = "[Users] Set Total Docs";
export const USERS_LOADING = "[Users] Is Loading";
export const USERS_FAILURE = "[Users] Failure";
export const USERS_EDIT_LOADING = "[Users] Edit is loading";
export const USERS_SET_SELECTED = "[Users] Set selected";
export const USERS_UPDATE_DOC = "[Users] Update doc";
export const USERS_UPDATE_SELECTED_USER = "[Users] Update selected doc";
export const USERS_REMOVE_DOC = "[Users] Delete doc";
export const USERS_GUIDE_VIDEO_IS_SUBMITTING = "[Users] Add video submitting";
export const USERS_GUIDE_VIDEO_SUCCESS = "[Users] Add video success";
export const USERS_GUIDE_VIDEO_FAILURE = "[Users] Add video failure";
export const USERS_GUIDE_VIDEO_PERCENTAGE = "[Users] Add video pecentage";
export const USER_SET_NAME_FILTER = "[Users] Set name filter";

// Bloqueos Guia
export const BLOCK_GUIDES_SET_SELECTED = "[Block] Set selected block guide";

//PLACES
export const PLACES_GET_IS_SUBMITTING = "[Places] Get is submitting";
export const PLACES_GET_SUCCESS = "[Places] Get success";
export const PLACES_GET_FAILURE = "[Places] Get failure";
export const PLACES_DELETE_IS_SUBMITTING = "[Places] Delete is submitting";
export const PLACES_DELETE_SUCCESS = "[Places] Delete success";
export const PLACES_DELETE_FAILURE = "[Places] Delete failure";
export const PLACES_SET_SELECTED = "[Places] Set selected";
export const PLACES_CREATE_IS_SUBMITTING = "[Places] Create is submitting";
export const PLACES_CREATE_SUCCESS = "[Places] Create succcess";
export const PLACES_CREATE_FAILURE = "[Places] Create failure";
export const PLACES_UPDATE_IS_SUBMITTING = "[Places] Update is submitting";
export const PLACES_UPDATE_SUCCESS = "[Places] Update succcess";
export const PLACES_UPDATE_FAILURE = "[Places] Update failure";
export const PLACES_GET_ONE_IS_SUBMITTING = "[Places] Get one is submitting";
export const PLACES_GET_ONE_SUCCESS = "[Places] Get one success";
export const PLACES_GET_ONE_FAILURE = "[Places] Get one failure";
export const PLACES_GET_VIDEOS_IS_SUBMITTING =
  "[Places] Get videos is submitting";
export const PLACES_GET_VIDEOS_SUCCESS = "[Places] Get videos success";
export const PLACES_GET_VIDEOS_FAILURE = "[Places] Get videos failure";
export const PLACES_VIDEO_UPLOAD = "[Places] Get video us uploading";

export const PLACES_GET_IMAGES_IS_SUBMITTING =
  "[Places] Get images is submitting";
export const PLACES_GET_IMAGES_SUCCESS = "[Places] Get images success";
export const PLACES_GET_IMAGES_FAILURE = "[Places] Get images failure";
export const PLACES_GET_IMAGES_UPLOAD = "[Places] Get images us uploading";

export const PLACE_GET_RATING_IS_SUBMITTING =
  "[Places] Get rating is submitting";
export const PLACE_GET_RATING_SUCCESS = "[Places] Get rating succcess";
export const PLACE_GET_RATING_FAILURE = "[Places] Get rating failure";
export const PLACE_GET_VIEW_CLICK_SUBMITTING =
  "[Places] Get views click is submitting";
export const PLACE_GET_VIEW_CLICK_SUCCESS = "[Places] Get views click succcess";
export const PLACE_GET_VIEW_CLICK_FAILURE = "[Places] Get views click failure";

//PLACES TYPES
export const PLACES_TYPES_IS_SUBMITTING =
  "[Places types] Get places types sumitting";
export const PLACES_TYPES_SUCCESS = "[Places types] Get places types success";
export const PLACES_TYPES_FAILURE = "[Places types] Get places types failure";
export const PLACES_TYPES_CREATE_SUBMITTING =
  "[Places] Create type is submitting";
export const PLACES_TYPES_CREATE_SUCCESS = "[Places] Create type success";
export const PLACES_TYPES_CREATE_FAILURE = "[Places] Create type failure";
export const PLACES_TYPES_UPDATE_SUBMITTING =
  "[Places] Update type is submitting";
export const PLACES_TYPES_UPDATE_SUCCESS = "[Places] Update type success";
export const PLACES_TYPES_UPDATE_FAILURE = "[Places] Update type failure";

export const MAP_PLACE_SEARCH_IS_SUBMITTING =
  "[Map] Place search is submitting";
export const MAP_PLACE_SEARCH_SUCCESS = "[Map] Place search success";
export const MAP_PLACE_SEARCH_FAILURE = "[Map] Place search failure";
export const MAP_PLACES_SEARCH_IS_SUBMITTING =
  "[Map] Places mapbox search is submitting";
export const MAP_PLACES_SEARCH_SUCCESS = "[Map] Places mapbox search success";
export const MAP_PLACES_SEARCH_FAILURE = "[Map] Places mapbox search failure";
export const MAP_GET_ROUTE_IS_SUBMITTING = "[Map] Get route is submitting";
export const MAP_GET_ROUTE_SUCCESS = "[Map] Get route success";
export const MAP_GET_ROUTE_FAILURE = "[Map] Get route failure";
export const MAP_PATH_ROUTE_IS_SUBMITTING = "[Map] Path route is submitting";
export const MAP_PATH_ROUTE_SUCCESS = "[Map] Path route is success";
export const MAP_PATH_ROUTE_FAILURE = "[Map] Path route is failure";
export const MAP_PATH_ROUTE_INITIAL_STATE = "[Map] Path route is initial state";

//
export const SUMAR_NUMERO = "SUMAR_NUMERO";
export const RESTAR_NUMERO = "RESTAR_NUMERO";

//
export const GET_MEDIA_RECOMENDATIONS =
  "[Tours] Get links of media recomendations";

export const GET_CREATIONS_NOTIFICATIONS = "GET_CREATIONS_NOTIFICATIONS";
