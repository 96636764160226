import { Action } from "../../models/action";
import * as types from "../../constants";
import { FormState } from "../../models/form_state";

export interface IToursState {
  tours: any[];
  isLoading: boolean;
  totalDocs: number;
  lastDoc?: any;
  error?: string;
  videoUploadProgress: any;
  edit: {
    isLoading: boolean;
    selectedTour?: any;
    formState: FormState;
  };
  index: {
    isLoading: boolean;
    selectedIndex: any[];
  };
  updateMapState: FormState;
  stateGetLenguage: FormState;
  updateMapError?: any;
  updatePointState?: FormState;
  updatePointError?: any;
  deleteTourState?: FormState;
  deleteTourError?: any;
  selectedRating: any[];
  stateGetRating: FormState;
  errorGetRating?: string;
  mediaRecomendations: {
    audio: string;
    video: string;
  };
}

const initialState = {
  tours: [],
  isLoading: false,
  totalDocs: 0,
  videoUploadProgress: 0.0,
  edit: {
    isLoading: false,
    formState: FormState.Initial,
  },
  index: {
    isLoading: false,
    selectedIndex: [],
  },
  stateGetLenguage: FormState.Initial,
  updateMapState: FormState.Initial,
  updatePointState: FormState.Initial,
  deleteTourState: FormState.Initial,
  selectedRating: [],
  stateGetRating: FormState.Initial,
  mediaRecomendations: {
    audio: "",
    video: "",
  },
} as IToursState;

export const toursReducer = (
  state = initialState,
  action: Action
): IToursState => {
  switch (action.type) {
    case types.TOURS_GET_DOCS:
      return {
        ...state,
        tours: action.payload.tours,
        totalDocs: action.payload.totalDocs,
        lastDoc: action.payload.lastDoc,
      };
    case types.TOURS_INDEX_GET_DOCS:
      return {
        ...state,
        index: {
          ...state.index,
          selectedIndex: action.payload,
        },
      };
    case types.TOURS_SET_SELECTED:
      return {
        ...state,
        edit: {
          ...state.edit,
          selectedTour: action.payload,
        },
      };
    case types.TOURS_UPDATE_LOADING:
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: action.payload,
        },
      };
    case types.TOURS_INDEX_LOADING:
      return {
        ...state,
        index: {
          ...state.index,
          isLoading: action.payload,
        },
      };
    case types.TOURS_ADD_INDEX_LOADING:
      return {
        ...state,
        index: {
          ...state.index,
          isLoading: action.payload,
        },
      };
    case types.TOURS_CHANGE_FORM_STATE:
      return {
        ...state,
        edit: {
          ...state.edit,
          formState: action.payload,
        },
      };
    case types.TOURS_ADD_DOCS:
      return {
        ...state,
        tours: [...state.tours, ...action.payload],
      };
    case types.TOURS_UPDATE_DOC:
      return {
        ...state,
        tours: state.tours.map((x) =>
          x.id === action.payload.id ? { ...x, ...action.payload } : x
        ),
      };
    case types.TOURS_REMOVE_DOC:
      return {
        ...state,
        tours: state.tours.filter((x) => x.id !== action.payload.id),
      };
    case types.TOURS_SET_LAST_DOC:
      return {
        ...state,
        lastDoc: action.payload,
      };
    case types.TOURS_SET_TOTAL_DOCS:
      return {
        ...state,
        totalDocs: action.payload,
      };
    case types.TOURS_KEEP_TOTAL_DOCS:
      return {
        ...state,
        totalDocs: action.payload,
      };
    case types.TOURS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.TOURS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case types.TOURS_VIDEO_UPLOAD:
      return {
        ...state,
        videoUploadProgress: action.payload,
      };
    case types.TOUR_POINT_UPDATE_IS_SUBMITTING:
      return {
        ...state,
        updatePointState: FormState.Submitting,
        updatePointError: undefined,
      };
    case types.TOUR_POINT_UPDATE_IS_SUCCESS:
      return {
        ...state,
        updatePointState: FormState.Success,
      };
    case types.TOUR_POINT_UPDATE_IS_FAILURE:
      return {
        ...state,
        updatePointState: FormState.Error,
        updatePointError: action.payload,
      };
    case types.TOUR_LENGUAGE_DELETE_SUBMITTING:
      return {
        ...state,
        isLoading: true,
      };
    case types.TOUR_LENGUAGE_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.TOUR_LENGUAGE_DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.TOUR_DELETE_IS_SUBMITTING:
      return {
        ...state,
        deleteTourState: FormState.Submitting,
        deleteTourError: undefined,
      };
    case types.TOUR_DELETE_SUCCESS:
      return {
        ...state,
        deleteTourState: FormState.Success,
      };
    case types.TOUR_DELETE_FAILURE:
      return {
        ...state,
        deleteTourState: FormState.Error,
        deleteTourError: action.payload,
      };

    // Calificaciones
    case types.TOUR_GET_RATING_IS_SUBMITTING:
      return {
        ...state,
        stateGetRating: FormState.Submitting,
        errorGetRating: undefined,
      };
    case types.TOUR_GET_RATING_SUCCESS:
      return {
        ...state,
        selectedRating: action.payload,
        stateGetRating: FormState.Success,
      };
    case types.TOUR_GET_RATING_FAILURE:
      return {
        ...state,
        stateGetRating: FormState.Error,
        errorGetRating: action.payload,
      };
    //

    case types.TOUR_UPDATE_MAP_IS_SUBMITTING:
      return {
        ...state,
        updateMapState: FormState.Submitting,
        errorGetRating: undefined,
      };
    case types.TOUR_UPDATE_MAP_FAILURE:
      return {
        ...state,
        updateMapState: FormState.Error,
        errorGetRating: action.payload,
      };
    case types.TOUR_UPDATE_MAP_SUCCESS:
      return {
        ...state,
        updateMapState: FormState.Success,
      };

    //
    case types.TOUR_LENGUAGE_POINT_GET_SUBMITTING:
      return {
        ...state,
        stateGetLenguage: FormState.Submitting,
      };
    case types.TOUR_LENGUAGE_POINT_GET_SUCCESS:
      return {
        ...state,
        stateGetLenguage: FormState.Success,
        index: {
          ...state.index,
          selectedIndex: action.payload,
        },
      };
    case types.TOUR_LENGUAGE_POINT_GET_FAILURE:
      return {
        ...state,
        stateGetLenguage: FormState.Error,
      };
    case types.TOUR_LENGUAGE_POINT_GET_RESTORE:
      return {
        ...state,
        stateGetLenguage: FormState.Initial,
        index: {
          ...state.index,
          selectedIndex: [],
        },
      };

    case types.GET_MEDIA_RECOMENDATIONS:
      return {
        ...state,
        mediaRecomendations: {
          audio: action.payload.audio,
          video: action.payload.video,
        },
      };
    default:
      return state;
  }
};
