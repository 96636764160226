import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { DialogLoad } from "../components/DialogLoad";
import { FormCard } from "../components/NewTourComponents/FormCard";
import { FormState } from "../models/form_state";
import { addTour } from "../redux/actions/toursActions";
import { RootState } from "../redux/reducers/rootReducer";
import { IToursState } from "../redux/reducers/toursReducer";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const NewTour = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    edit: { formState },
  } = useSelector<RootState, IToursState>((state) => state.toursReducer);

  const handleSubmit = (values: any) => {
    dispatch(addTour(values));
  };

  useEffect(() => {
    if (formState === FormState.Success) {
      history.push("/");
    }
  }, [formState]);

  return (
    <React.Fragment>
      <Helmet title="Nuevo Tour" />
      <Typography variant="h3" gutterBottom display="inline">
        Nuevo Tour
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/tours">
          Tours
        </Link>
        <Typography>Nuevo Tour</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <FormCard onSubmit={handleSubmit} />
      <DialogLoad
        open={formState === FormState.Submitting}
        onClose={() => {}}
      />
    </React.Fragment>
  );
};
