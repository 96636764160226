import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { resetPassword } from "../../redux/actions/authActions";

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  Grid,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import { resetPaswordTourGuide } from "../../redux/actions/tourGuidesActions";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function Register() {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Wrapper style={{ width: "70%" }}>
      <Helmet title="Registrate en TraveApp" />

      <Typography component="h1" variant="h4" align="center" gutterBottom>
        ¡Únete a TraveApp!
      </Typography>

      <Formik
        initialValues={{
          submit: false,
        }}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
          } catch (error) {
            const message = error.message || "Ha ocurrido un problema";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid
              style={{ marginTop: "10%" }}
              container
              justify="space-between"
              spacing={2}
            >
              <Grid item xs={12} sm={12}>
                <Button
                  component={Link}
                  to="/register/guides"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Trave Guías
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  component={Link}
                  to="/register/place"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Trave Comercios
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  fullWidth
                  component={Link}
                  to="/auth/sign-in"
                  fullWidth
                  color="primary"
                >
                  Iniciar Sesión
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
}

export default Register;
