import {
  Avatar,
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  Collapse,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Tabs,
  Tab,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import Rating from "@material-ui/lab/Rating";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import {
  Edit as EditIcon,
  Key,
  Lock as LockIcon,
  MinusSquare,
  Search,
  Trash2 as DeleteIcon,
  Unlock as UnlockIcon,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../../constants";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { useNewEditDialog } from "../../hooks/useNewEditDialog";
//Hooks
import { useTable } from "../../hooks/useTable";
import { DialogAction } from "../../models/dialog_state";
import { getRegions } from "../../redux/actions/regionsActions";
//Redux
import {
  addMoreTourGuides,
  addMoreTourGuidesFiltered,
  getTourGuides,
  getTourGuidesFiltered,
  resetPaswordTourGuide,
  setSelectedTourGuide,
} from "../../redux/actions/tourGuidesActions";
import { IRegionsState } from "../../redux/reducers/regionsReducer";
import { RootState } from "../../redux/reducers/rootReducer";
import { ITourGuidesState } from "../../redux/reducers/tourGuidesReducer";
import { useStyles } from "../../theme/useStyles";
import { cleanString } from "../../utils/utils";
import { NewConfirmDialog } from "../NewConfirmDialog";
import { TableSkeleton } from "../TableSkeleton";
import { DialogBlockTourGuides } from "./DialogBlockTourGuides";
import { makeStyles, Theme } from "@material-ui/core/styles";
//Dialogs
import { DialogEditAndNew } from "./DialogEditAndNew";
import { withStyles } from "@material-ui/styles";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#ffffff",
      fontWeight: "bold",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000",
    },
    "& input": {
      color: "#ffffff",
      "& placeholder": { color: "#ffffff" },
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ffffff",
      },
      "&:hover fieldset": {
        borderColor: "#ffffff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ffffff",
      },
    },
  },
})(TextField);

interface Props {
  tab?: {
    items: any[];
  };
  isLoading: boolean;
  name: string;
  /**
   * @param values Item seleccionado por las acciones
   * @param action Nuevos datos a crear o actualizar
   * @param selected Action que realiza el Dialogo
   * @param regions Listado de regiones
   */
  onAcceptNewEditDialog: (
    values: any,
    action: DialogAction,
    selected: any
  ) => void;

  onBlockGuideDialog: (values: any, selected: any) => void;

  onConfirmDeleteDialog: (selected: any) => void;
  cardTitle?: string;
  array: any[];
  openNewDialog?: boolean;
  onClose?: () => void;
  totalDocs: number;
}

export const ContentCard: React.FC<Props> = ({
  tab,
  isLoading,
  name,
  onAcceptNewEditDialog,
  onConfirmDeleteDialog,
  onBlockGuideDialog,
  array,
  openNewDialog: openNewEditDialog = null,

  onClose = () => {},
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const { totalDocs } = useSelector<RootState, ITourGuidesState>(
    (state) => state.tourGuidesReducer
  );

  const [filter, setFilter] = useState<any>({});

  const { limit, page, handleLimitChange, handlePageChange } = useTable({
    limit: TABLE_LIMIT_DEFAULT || 5,
    onLimitChange: (e, newLimit) => {
      if (newLimit > array.length) {
        if (filter) {
          dispatch(getTourGuidesFiltered(newLimit, filter));
        } else {
          dispatch(getTourGuides(newLimit));
        }
      }
    },
    onPageChange: (newPage, oldPage, limit) => {
      if (array.length < totalDocs && newPage > oldPage) {
        if (filter) {
          dispatch(addMoreTourGuidesFiltered(limit, filter));
        } else {
          dispatch(addMoreTourGuides(limit));
        }
      }
    },
  });
  const {
    open: newEditDialogOpen,
    setIsLoading: setIsLoadingNewEditDialog,
    selected: newEditDialogSelected,
    action,
    closeDialog,
    openNewDialog,
    openEditDialog,
    onAccept,
  } = useNewEditDialog({
    onAccept: (values, action) =>
      onAcceptNewEditDialog(values, action, newEditDialogSelected),
    onClose: onClose,
  });

  const {
    open: newBlockDialogOpen,
    setIsLoading: setIsLoadingBlockDialog,
    closeDialog: closeBlockDialog,
    openEditDialog: openBlockDialog,
    selected: newBlockDialogSelected,
    onAccept: onAcceptBlock,
  } = useNewEditDialog({
    onAccept: (values, _) => {
      // console.log(values);
      onBlockGuideDialog(values, newBlockDialogSelected);
    },
    onClose: onClose,
  });

  const {
    open: confirmDialogOpen,
    dialogConfirmHandleClose,
    dialogConfirmHandleConfirm,
    openDialog,
    setIsLoading,
    selected: confirmDialogSelected,
  } = useConfirmDialog({
    onConfirm: onConfirmDeleteDialog,
    onClose: onClose,
  });

  useEffect(() => {
    setIsLoading(isLoading);
    setIsLoadingNewEditDialog(isLoading);
    setIsLoadingBlockDialog(isLoading);
  }, [
    isLoading,
    setIsLoading,
    setIsLoadingNewEditDialog,
    setIsLoadingBlockDialog,
  ]);

  useEffect(() => {
    if (openNewEditDialog) {
      openNewDialog();
    }
  }, [openNewEditDialog]);

  const [open, setOpen] = useState(false);

  const handleDeleteBtn = (selected: any) => {
    openDialog(selected);
  };

  const handleGoToIndexCard = (selected: any) => {
    dispatch(setSelectedTourGuide(selected));
    history.push(`guias/${selected.id}/ficha`);
  };

  return (
    <Card mb={6}>
      <FilterSection
        tab={tab}
        limit={limit}
        onFilterChange={(filter) => setFilter(filter)}
      />

      <Divider />
      <CardContent>
        <TableContainer className={classes.tableContainer}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Avatar</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Idiomas</TableCell>
                <TableCell>Región</TableCell>
                <TableCell>Evaluación</TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle2" style={{ color: "#004ecb" }}>
                    Acciones
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading ? (
                array.slice(page * limit, page * limit + limit).map((data) => (
                  <Fade key={data.id} in={true}>
                    <TableRow hover className={classes.styledRow}>
                      <TableCell>
                        <Avatar src={data.Perfil} />
                      </TableCell>
                      <TableCell>{data.Nombre + " " + data.Apellido}</TableCell>
                      <TableCell>{data?.Email}</TableCell>
                      <TableCell>
                        {data?.Idiomas
                          ? Object.keys(data.Idiomas).map(
                              (key) => "[" + key + "] "
                            )
                          : ""}
                      </TableCell>
                      <TableCell>{data.Region}</TableCell>
                      <TableCell>
                        <Tooltip
                          title={Number(data?.Calificacion).toFixed(1) || 5}
                        >
                          <span>
                            <Rating
                              name="read-only"
                              precision={0.5}
                              value={data?.Calificacion || 5}
                              readOnly
                            />
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <Tooltip
                          title={
                            data.Bloqueo?.Bloqueo ? "Desbloquear" : "Bloquear"
                          }
                        >
                          <span>
                            <IconButton
                              color="primary"
                              size="small"
                              onClick={() => openBlockDialog(data)}
                            >
                              {data.Bloqueo?.Bloqueo ? (
                                <LockIcon color="red" />
                              ) : (
                                <UnlockIcon />
                              )}
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Ficha Editar">
                          <span>
                            <IconButton
                              color="primary"
                              size="small"
                              onClick={() => handleGoToIndexCard(data)}
                            >
                              <EditIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Cambiar contraseña">
                          <span>
                            <IconButton
                              color="primary"
                              size="small"
                              onClick={() =>
                                dispatch(resetPaswordTourGuide(data))
                              }
                            >
                              <Key />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Eliminar">
                          <span>
                            <IconButton
                              size="small"
                              onClick={() => handleDeleteBtn(data)}
                            >
                              <DeleteIcon color="red" />
                            </IconButton>
                          </span>
                        </Tooltip>

                        {/* <Tooltip title="Ver Ficha">
                          <IconButton
                            size="small"
                            onClick={() => handleGoToIndexCard(data)}
                          >
                            <RightIcon />
                          </IconButton>
                        </Tooltip> */}
                      </TableCell>
                    </TableRow>
                  </Fade>
                ))
              ) : (
                <TableSkeleton colSpan={10} limit={10} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{ color: "#004ecb", fontWeight: "bold" }}
          component="div"
          count={totalDocs}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={TABLE_LIMITS}
        />
      </CardContent>
      <DialogEditAndNew
        open={newEditDialogOpen}
        onClose={closeDialog}
        onAccept={onAccept}
        isLoading={isLoading}
        action={action}
        object={newEditDialogSelected}
        title={name}
      />
      <DialogBlockTourGuides
        open={newBlockDialogOpen}
        onClose={closeBlockDialog}
        onAccept={(values) => onAcceptBlock(values, "Edit")}
        isLoading={isLoading}
        object={{
          ...newBlockDialogSelected?.Bloqueo,
          id: newBlockDialogSelected?.id,
        }}
        title={`${newBlockDialogSelected?.Nombre}?`}
      />
      <NewConfirmDialog
        open={confirmDialogOpen}
        title={`¿Desea eliminar el ${name} ${confirmDialogSelected?.Nombre}?`}
        onClose={dialogConfirmHandleClose}
        isLoading={isLoading}
        onConfirm={dialogConfirmHandleConfirm}
        onConfirmText={"Eliminar"}
      />
    </Card>
  );
};

interface FilterProps {
  tab?: {
    items: any[];
  };
  limit: number;
  onFilterChange: (value: any) => void;
}
const FilterSection = React.memo((props: FilterProps) => {
  const { onFilterChange, limit, tab, ...other } = props;
  const dispatch = useDispatch();

  const [openFilter, setOpenFilters] = useState(false);
  const [filtersList, setValueFiltersList] = React.useState<any>({});
  const [valueCreacionStart, setValueCreacionStart] = useState<any>(null);
  const [valueCreacionEnd, setValueCreacionEnd] = useState<any>(null);
  const [valueNombre, setValueNombre] = useState("");
  const [valueApellido, setValueApellido] = useState("");
  const [valueTipo, setValueTipo] = React.useState<string | null>(null);
  const [valueRegion, setValueRegion] = React.useState<string | null>(null);
  const [valueEstado, setValueEstado] = React.useState<string | null>(null);
  const [valueCodigo, setValueCodigo] = useState("");

  const estadosTour = ["Aprobado", "Pendiente"];

  const handleChangeNombre = (e: any) => {
    setValueNombre(e.target.value);
  };
  const handleChangeApellido = (e: any) => {
    setValueApellido(e.target.value);
  };
  const handleChangeCodigo = (e: any) => {
    setValueCodigo(e.target.value);
  };

  const { regions } = useSelector<RootState, IRegionsState>(
    (state) => state.regionsReducer
  );

  // const { tourTypes } = useSelector<RootState, ITourTypeState>(
  //   (state) => state.tourTypesReducer
  // );

  useEffect(() => {
    dispatch(getRegions());
  }, []);
  // useEffect(() => {
  //   dispatch(getTourTypes());
  // }, []);

  useEffect(() => {
    onFilterChange(filtersList);
  }, [Object.keys(filtersList)]);

  return (
    <CardContent style={{ backgroundColor: "#2979ff" }}>
      <Grid container justify={tab ? "space-between" : "flex-end"}>
        {tab && (
          <Grid item>
            <Tabs>
              {tab.items.map((item) => (
                <Tab label={item.label} />
              ))}
            </Tabs>
          </Grid>
        )}
        <Grid item>
          <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
            <Typography variant="subtitle2" style={{ color: "#ffffff" }}>
              Filtros
            </Typography>
            {openFilter ? (
              <ExpandLess style={{ color: "#ffffff" }} />
            ) : (
              <ExpandMore style={{ color: "#ffffff" }} />
            )}
          </Button>
        </Grid>
      </Grid>
      <Collapse in={openFilter} timeout="auto" unmountOnExit>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <CssTextField
              id="outlined-basic"
              size="small"
              label="Nombre"
              InputLabelProps={{
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  color: "#ffffff",
                },
              }}
              variant="outlined"
              value={valueNombre}
              onChange={handleChangeNombre}
              fullWidth
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  filtersList["Nombre_lower"] = cleanString(valueNombre);
                  if (!valueNombre) {
                    delete filtersList["Nombre_lower"];
                  }
                  if (Object.keys(filtersList).length > 0 && valueNombre) {
                    dispatch(getTourGuidesFiltered(limit, filtersList));
                  } else {
                    dispatch(getTourGuides());
                  }

                  ev.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <CssTextField
              id="outlined-basic-2"
              size="small"
              label="Apellido"
              InputLabelProps={{
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  color: "#ffffff",
                },
              }}
              variant="outlined"
              value={valueApellido}
              fullWidth
              onChange={handleChangeApellido}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  filtersList["Apellido_lower"] = cleanString(valueApellido);
                  if (!valueApellido) {
                    delete filtersList["Apellido_lower"];
                  }

                  if (Object.keys(filtersList).length > 0 && valueApellido) {
                    dispatch(getTourGuidesFiltered(limit, filtersList));
                  } else {
                    dispatch(getTourGuides());
                  }

                  ev.preventDefault();
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              id="idiomas-filtro"
              size="small"
              value={valueTipo}
              options={["Español", "Inglés", "Portugués"]}
              getOptionLabel={(option: any) => option}
              fullWidth
              onChange={(event: any, newValue: any) => {
                setValueTipo(newValue);
                if (newValue) {
                  if (newValue === "Español") {
                    delete filtersList["Idiomas.Ingles.Nombre"];
                    delete filtersList["Idiomas.Portugues.Nombre"];
                    filtersList["Idiomas.Español.Nombre"] = "Español";
                  } else if (newValue === "Inglés") {
                    delete filtersList["Idiomas.Portugues.Nombre"];
                    delete filtersList["Idiomas.Español.Nombre"];
                    filtersList["Idiomas.Ingles.Nombre"] = "Ingles";
                  } else if (newValue === "Portugués") {
                    delete filtersList["Idiomas.Español.Nombre"];
                    delete filtersList["Idiomas.Ingles.Nombre"];
                    filtersList["Idiomas.Portugues.Nombre"] = "Portugues";
                  }

                  dispatch(getTourGuidesFiltered(limit, filtersList));
                } else {
                  delete filtersList["Idiomas.Español.Nombre"];
                  delete filtersList["Idiomas.Ingles.Nombre"];
                  delete filtersList["Idiomas.Portugues.Nombre"];
                  if (Object.keys(filtersList).length > 0) {
                    dispatch(getTourGuidesFiltered(limit, filtersList));
                  } else {
                    dispatch(getTourGuides());
                  }
                }
              }}
              renderInput={(params: any) => (
                <CssTextField
                  {...params}
                  label="Idiomas"
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      color: "#ffffff",
                    },
                  }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              id="region-filtro"
              size="small"
              value={valueRegion}
              options={regions}
              getOptionLabel={(option: any) => option.Nombre}
              fullWidth
              onChange={(event: any, newValue: any | null) => {
                setValueRegion(newValue);
                if (newValue) {
                  filtersList["Region"] = newValue.Nombre;
                  dispatch(getTourGuidesFiltered(limit, filtersList));
                } else {
                  delete filtersList["Region"];
                  if (filtersList) {
                    dispatch(getTourGuidesFiltered(limit, filtersList));
                  } else {
                    dispatch(getTourGuides());
                  }
                }
              }}
              renderInput={(params: any) => (
                <CssTextField
                  {...params}
                  label="Región"
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      color: "#ffffff",
                    },
                  }}
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Box display="flex">
              <Box mt={1} order={1}>
                <Tooltip title="Buscar">
                  <IconButton
                    style={{ color: "#ffffff" }}
                    size="small"
                    aria-label="Filtrar"
                    onClick={() => {
                      if (!valueNombre) {
                        delete filtersList["Nombre_lower"];
                      } else {
                        filtersList["Nombre_lower"] = valueNombre;
                      }
                      if (!valueApellido) {
                        delete filtersList["Apellido_lower"];
                      } else {
                        filtersList["Apellido_lower"] = valueApellido;
                      }
                      dispatch(getTourGuidesFiltered(limit, filtersList));
                    }}
                  >
                    <Search size={35} style={{ marginTop: -7 }} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box mt={1} order={2}>
                <Tooltip title="Limpiar">
                  <IconButton
                    style={{ color: "#ffffff" }}
                    size="small"
                    aria-label="Borrar filtro"
                    onClick={() => {
                      setValueNombre("");
                      setValueApellido("");
                      setValueTipo(null);
                      setValueRegion(null);
                      setValueEstado(null);
                      setValueCreacionStart("");
                      setValueCreacionEnd("");
                      setValueFiltersList({});

                      dispatch(getTourGuides(limit));
                    }}
                  >
                    <MinusSquare size={35} style={{ marginTop: -7 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </CardContent>
  );
});
