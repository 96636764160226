import * as types from "../../constants";
import { AppThunk } from "../../models/app-thunk";
import {
  resetPassword as authResetPassword,
  signUp as authSignUp,
} from "../../services/authService";
import { auth, firestore } from "../../firebase/firebase";
import { openSnack } from "./uiActions";
import { SnackState } from "../../models/snack-state";
import { loadNotification } from "./notificationsActions";

interface Credentials {
  email: string;
  password: string;
  name?: string;
}

export function signIn(credentials: Credentials): AppThunk {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });
    try {
      await auth
        .signInWithEmailAndPassword(credentials.email, credentials.password)
        .catch((errorLogin) => {
          if (errorLogin.code === "auth/too-many-requests") {
            dispatch(
              openSnack(
                "Demasiados Intentos. Recuperar Contraseña",
                SnackState.ERROR
              )
            );
          } else if (errorLogin.code === "auth/user-not-found") {
            dispatch(openSnack("Credenciales Incorrectas", SnackState.ERROR));
          }
        });

      // if (response.user) {
      //   dispatch({
      //     type: types.AUTH_SIGN_IN_SUCCESS,
      //     id: response.user.uid,
      //     email: response.user.email,
      //     name: response.user.displayName,
      //   });
      // } else {
      //   throw new Error("Error desconocido");
      // }
    } catch (error) {
      dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
    }
  };
}

export function handleResetGetEmail(actionCode: "string"): AppThunk {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_CHANGE_PASS_GET_EMAIL_IN_REQUEST });
    try {
      auth
        .verifyPasswordResetCode(actionCode)
        .then((Email) => {
          dispatch({
            type: types.AUTH_CHANGE_PASS_GET_EMAIL_SUCCESS,
            payload: Email,
          });
        })
        .catch((Err) => {
          dispatch({ type: types.AUTH_CHANGE_PASS_GET_EMAIL_FAILURE });
        });
    } catch (error) {
      console.log(error);

      dispatch({ type: types.AUTH_CHANGE_PASS_GET_EMAIL_FAILURE });
    }
  };
}
export function handleResetSetPassword(
  actionCode: "string",
  newPassword: "string"
): AppThunk {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_CHANGE_PASS_SET_NEW_IN_REQUEST });
    try {
      await auth.confirmPasswordReset(actionCode, newPassword);
      dispatch(openSnack("Contraseña cambiada con éxito", SnackState.SUCCESS));
      dispatch({ type: types.AUTH_CHANGE_PASS_SET_NEW_SUCCESS });
    } catch (error) {
      dispatch({ type: types.AUTH_CHANGE_PASS_SET_NEW_FAILURE });
    }
  };
}

export function validateSession(): AppThunk {
  return (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken(false);
        const userResponse = await firestore
          .collection("Usuarios")
          .where("Email", "==", user.email)
          .get();

        // console.log(userResponse.docs);

        const userDB: any = {
          ...userResponse.docs[0].data(),
          id: userResponse.docs[0].id,
        };
        if (
          userDB?.TipoUsuario === "Cliente" ||
          userDB?.Bloqueo?.Bloqueo ||
          userDB?.Deleted ||
          !userDB?.Activo ||
          !userDB?.Email
        ) {
          dispatch(openSnack("Usuario no permitido", SnackState.ERROR));
          dispatch({
            type: types.AUTH_SIGN_OUT,
          });
        } else {
          if (userDB?.TipoUsuario === "Administrador") {
            dispatch(loadNotification());
          }

          dispatch({
            type: types.AUTH_SIGN_IN_SUCCESS,
            id: userDB.id,
            email: user.email,
            name: `${userDB.Nombre} ${userDB.Apellido}`,
            image: userDB.Perfil || "",
            token,
            tipoUsuario: userDB.TipoUsuario,
          });
        }
      } else {
        dispatch({
          type: types.AUTH_SIGN_OUT,
        });
      }
    });
  };
}

export function signUp(credentials: Credentials): AppThunk {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

    try {
      const response = await auth.createUserWithEmailAndPassword(
        credentials.email,
        credentials.password
      );

      await response.user?.updateProfile({
        displayName: credentials.name,
      });
    } catch (error) {
      dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
    }

    return authSignUp(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
          id: response.id,
          email: response.email,
          name: response.name,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function signOut(): AppThunk {
  return async () => {
    await auth.signOut();
  };
}

export function resetPassword(credentials: Credentials): AppThunk {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

    return authResetPassword(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
          email: response.email,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}
