import {
  Box,
  Button,
  CardContent,
  Grid,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React, { useEffect } from "react";
import { DownloadCloud } from "react-feather";
import { useDispatch } from "react-redux";
import { getPlaceCalificaciones } from "../../redux/actions/placesActions";
import { useStyles } from "../../theme/useStyles";
import { exportToCsv } from "../../utils/utils";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

interface Props {
  tour?: any;
  id?: string;
  save: (values: any) => void;
}

export const TourScore: React.FC<Props> = React.memo(({ tour, save }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  let totalRateCalidadRelato = 5;
  let totalRateDuracionTour = 5;
  // let totalRateClaridadGuia = 5;
  // let totalRateCalidadConsejos = 5;
  let totalRatePromedioAll = 5;

  tour.map((data: any) => {
    totalRateCalidadRelato = totalRateCalidadRelato + data?.CalidadRelato;
    totalRateDuracionTour = totalRateDuracionTour + data?.DuracionTour;
    // totalRateClaridadGuia = totalRateClaridadGuia + data?.ClaridadGuia;
    // totalRateCalidadConsejos = totalRateCalidadConsejos + data?.CalidadConsejos;
    totalRatePromedioAll = totalRatePromedioAll + data?.Promedio || 1;
  });

  const lengthRating = (tour?.length || 0) + 1;

  const resultRateCalidadRelato = totalRateCalidadRelato / lengthRating;
  const resultRateDuracionTour = totalRateDuracionTour / lengthRating;
  // const resultRateClaridadGuia = totalRateClaridadGuia / lengthRating;
  // const resultRateCalidadConsejos = totalRateCalidadConsejos / lengthRating;
  const resultRatePromedioAll = totalRatePromedioAll / lengthRating;

  return (
    <React.Fragment>
      <CardContent>
        <Grid container justify="space-between" spacing={4}>
          <Grid container item xs={12} sm={6} spacing={4}>
            <Grid item xs={12} sm={12}>
              <Typography
                variant="subtitle2"
                style={{
                  textAlign: "center",
                }}
              >
                Calidad del Relato:{" "}
                {Number(resultRateCalidadRelato?.toFixed(1))}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} justify="center">
              <Rating
                name="read-only"
                precision={0.5}
                value={Number(resultRateCalidadRelato?.toFixed(1))}
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography
                variant="subtitle2"
                style={{
                  textAlign: "center",
                }}
              >
                Duración del tour: {Number(resultRateDuracionTour?.toFixed(1))}
              </Typography>
            </Grid>

            <Grid container item xs={12} sm={12} justify="center">
              <Rating
                name="read-only"
                precision={0.5}
                value={Number(resultRateDuracionTour?.toFixed(1))}
                readOnly
              />
            </Grid>

            {/* <Grid item xs={12} sm={12}>
              <Typography
                variant="subtitle2"
                style={{
                  textAlign: "center",
                }}
              >
                Claridad Guia: {Number(resultRateClaridadGuia?.toFixed(1))}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} justify="center">
              <Rating
                name="read-only"
                precision={0.5}
                value={Number(resultRateClaridadGuia?.toFixed(1))}
                readOnly
              />
            </Grid> */}

            {/* <Grid item xs={12} sm={12}>
              <Typography
                variant="subtitle2"
                style={{
                  textAlign: "center",
                }}
              >
                Claridad Consejos:{" "}
                {Number(resultRateCalidadConsejos?.toFixed(1))}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} justify="center">
              <Rating
                name="read-only"
                precision={0.5}
                value={Number(resultRateCalidadConsejos?.toFixed(1))}
                readOnly
              />
            </Grid> */}
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={6}
            spacing={2}
            direction="row"
            justify="center"
          >
            <Grid
              container
              item
              xs={12}
              sm={12}
              direction="row"
              justify="center"
            >
              <Grid item>
                <Typography variant="subtitle2">
                  Promedio General: {Number(resultRatePromedioAll?.toFixed(1))}
                </Typography>
                <Rating
                  size="large"
                  name="read-only"
                  precision={0.5}
                  value={Number(resultRatePromedioAll?.toFixed(1))}
                  readOnly
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle2">
                Numero de calificaciones: {lengthRating - 1}{" "}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<DownloadCloud />}
                disabled={lengthRating - 1 === 0}
                onClick={() => {
                  exportToCsv(
                    "calificaciones_tour.csv",
                    tour,
                    [
                      // "CalidadConsejos",
                      "CalidadRelato",
                      // "ClaridadGuia",
                      "DuracionTour",
                      "Promedio",
                      "Comentario",
                      "NombreCliente",
                      "FechaCreacion",
                    ],
                    [
                      // "Calidad Consejos",
                      "Calidad Relato",
                      // "Claridad Guia",
                      "Duracion Tour",
                      "Promedio",
                      "Comentario",
                      "Nombre Cliente",
                      "Fecha Creacion",
                    ]
                  );
                }}
              >
                Descargar CSV
              </Button>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<DownloadCloud />}
                disabled={lengthRating - 1 === 0}
                onClick={() => {
                  exportToCsv(
                    "calificaciones_tour.csv",
                    tour,
                    [
                      // "CalidadConsejos",
                      "CalidadRelato",
                      // "ClaridadGuia",
                      "DuracionTour",
                      "Promedio",
                      "Comentario",
                      "NombreCliente",
                      "FechaCreacion",
                    ],
                    [
                      // "Calidad Consejos",
                      "Calidad Relato",
                      // "Claridad Guia",
                      "Duracion Tour",
                      "Promedio",
                      "Comentario",
                      "Nombre Cliente",
                      "Fecha Creacion",
                    ],
                    ";"
                  );
                }}
              >
                Descargar CSV2
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </React.Fragment>
  );
});
