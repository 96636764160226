import { AppThunk } from "../../models/app-thunk";
import * as types from "../../constants";
import Axios from "axios";
import { Action } from "../../models/action";

export const searchMapPlace = (input?: string): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.MAP_PLACE_SEARCH_IS_SUBMITTING,
    });
    try {
      let ubicacion: string | null = null;
      if (input) {
        const text = input?.replaceAll(" ", "+");
        try {
          const res: any = await Axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${text}&region=cl&key=${types.MAPS_API_KEY}`,

            {
              headers: {},
              // headers: {
              //   "Access-Control-Allow-Origin": "*",
              //   "Access-Control-Allow-Methods": "GET",
              //   "Access-Control-Allow-Headers":
              //     "Origin, Content-Type, X-Auth-Token",
              // },
            }
          );
          // console.log(res.data.results[0].geometry.location);
          ubicacion = res.data.results[0].geometry.location;
        } catch (errorApi) {
          console.log(errorApi);
        }
      }

      dispatch({
        type: types.MAP_PLACE_SEARCH_SUCCESS,
        payload: ubicacion,
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.MAP_PLACE_SEARCH_FAILURE,
        payload: error,
      });
    }
  };
};

export const searchMapPlaces = (input?: string): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.MAP_PLACES_SEARCH_IS_SUBMITTING,
    });
    try {
      if (input) {
        const text = input?.replaceAll(" ", "%20");
        try {
          const res: any = await Axios.get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?types=address&access_token=${types.MAPBOX_TOKEN}&cachebuster=1621503494761&autocomplete=false&country=cl`,

            {
              headers: {},
            }
          );
          if (res.status === 200) {
            dispatch({
              type: types.MAP_PLACES_SEARCH_SUCCESS,
              payload: res.data.features,
            });
            // res.data.features.map((data: any) => {
            //   console.log(data.place_name);
            // });
          }
        } catch (errorApi) {
          console.log(errorApi);
        }
      }
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.MAP_PLACES_SEARCH_FAILURE,
        payload: error,
      });
    }
  };
};

export const getDirectionsLine = (
  start: any,
  end: any,
  type: "driving" | "walking" = "driving"
): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.MAP_GET_ROUTE_IS_SUBMITTING,
    });
    try {
      if (start && end) {
        try {
          const res: any = await Axios.get(
            `https://api.mapbox.com/directions/v5/mapbox/${type}/${start[0]},${start[1]};${end[0]},${end[1]}?geometries=geojson&overview=full&access_token=${types.MAPBOX_TOKEN}`,

            {
              headers: {},
            }
          );

          if (res?.data?.code === "Ok") {
            const routes = res?.data?.routes[0]?.geometry?.coordinates;

            dispatch({
              type: types.MAP_GET_ROUTE_SUCCESS,
              payload: routes,
            });
          }
        } catch (errorApi) {
          console.log(errorApi);
          dispatch({
            type: types.MAP_GET_ROUTE_FAILURE,
            payload: errorApi,
          });
        }
      }
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.MAP_GET_ROUTE_FAILURE,
        payload: error,
      });
    }
  };
};

export const getThisRouteLine = async (
  start: any,
  end: any,
  type: "driving" | "walking" = "driving"
): Promise<any[] | string> => {
  try {
    const res: any = await Axios.get(
      `https://api.mapbox.com/directions/v5/mapbox/${type}/${start[0]},${start[1]};${end[0]},${end[1]}?geometries=geojson&overview=full&access_token=${types.MAPBOX_TOKEN}`,

      {
        headers: {},
      }
    );
    if (res?.data?.code === "Ok") {
      const routes = res?.data?.routes[0]?.geometry?.coordinates || [];
      return routes;
    } else {
      return "";
    }
  } catch {
    return "error";
  }
};

// export const getThisRouteLine = (
//   start: any,
//   end: any,
//   type: "driving" | "walking" = "driving"
// ): AppThunk => {
//   return async (dispatch) => {
//     dispatch({
//       type: types.MAP_GET_ROUTE_IS_SUBMITTING,
//     });
//     try {
//       if (start && end) {
//         try {
//           const res: any = await Axios.get(
//             `https://api.mapbox.com/directions/v5/mapbox/${type}/${start[0]},${start[1]};${end[0]},${end[1]}?geometries=geojson&overview=full&access_token=${types.MAPBOX_TOKEN}`,

//             {
//               headers: {},
//             }
//           );

//           if (res?.data?.code === "Ok") {
//             const routes = res?.data?.routes[0]?.geometry?.coordinates;

//             dispatch({
//               type: types.MAP_GET_ROUTE_SUCCESS,
//               payload: routes,
//             });
//           }
//         } catch (errorApi) {
//           console.log(errorApi);
//           dispatch({
//             type: types.MAP_GET_ROUTE_FAILURE,
//             payload: errorApi,
//           });
//         }
//       }
//     } catch (error: any) {
//       console.log(error);
//       dispatch({
//         type: types.MAP_GET_ROUTE_FAILURE,
//         payload: error,
//       });
//     }
//   };
// };

export const getDirectionsPath = (
  path: any[] = [],
  type: "driving" | "walking" = "driving"
): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.MAP_PATH_ROUTE_IS_SUBMITTING,
    });
    try {
      const points: any[] = [];
      const route: any[] = [];

      const promises: any[] = [];

      for (let i = 0; i < path.length; i++) {
        points.push({
          // feature for Mapbox
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [path[i].lng, path[i].lat],
          },
          properties: {
            title: "",
          },
        });
      }

      for (let i = 0; i < path.length - 1; i++) {
        //
        try {
          const res: any = await Axios.get(
            `https://api.mapbox.com/directions/v5/mapbox/${type}/${
              path[i].lng
            },${path[i].lat};${path[i + 1].lng},${
              path[i + 1].lat
            }?geometries=geojson&overview=full&access_token=${
              types.MAPBOX_TOKEN
            }`,
            {
              headers: {},
            }
          );
          promises.push(res);
        } catch (errorApi) {
          dispatch({
            type: types.MAP_PATH_ROUTE_FAILURE,
            payload: errorApi,
          });
        }
      }
      Promise.all(promises)
        .then((res) => {
          res.map((data, index) => {
            if (data.data.code === "Ok") {
              const routes = data?.data?.routes[0]?.geometry?.coordinates;
              route.push(...routes);
            }
          });
        })
        .then(() => {
          dispatch({
            type: types.MAP_PATH_ROUTE_SUCCESS,
            payload: { points: points, route: route },
          });
        })
        .catch((err) => {
          dispatch({
            type: types.MAP_PATH_ROUTE_FAILURE,
            payload: err,
          });
        });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.MAP_PATH_ROUTE_FAILURE,
        payload: error,
      });
    }
  };
};

export const setInitialDirectionsPath = (): Action => ({
  type: types.MAP_PATH_ROUTE_INITIAL_STATE,
});
