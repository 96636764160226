import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
  Button,
  CardContent,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { DialogLoad } from "../components/DialogLoad";
import { PlacesEditNew } from "../components/PlacesComponents/PlacesEditNew";
import { PlacesScore } from "../components/PlacesComponents/PlacesScore";
import { PlacesVideos } from "../components/PlacesComponents/PlacesVideos";
import { TabPanel } from "../components/TabPanel";
import { FormState } from "../models/form_state";
import {
  addPlaceVideo,
  createPlace,
  getOnePlace,
  getPlaceCalificaciones,
  getPlaceVideos,
  updatePlace,
  updatePlaceVideo,
} from "../redux/actions/placesActions";
import { getPlacesTypes } from "../redux/actions/placesTypesActions";
import { getRegions } from "../redux/actions/regionsActions";
import { IAuthState } from "../redux/reducers/authReducer";
import { PlacesState } from "../redux/reducers/placesReducer";
import { IPlacesTypesState } from "../redux/reducers/placesTypesReducer";
import { IRegionsState } from "../redux/reducers/regionsReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { THEMES } from "../constants";
import { CloudUpload } from "@material-ui/icons";
import { Edit3 } from "react-feather";
import { PlacesImages } from "./PlacesImages";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#2979ff",
    color: "#ffffff",
  },
  indicator: {
    backgroundColor: "#004ecb",
  },
}));

interface ContentCardProps {
  edit?: boolean;
}

const ContentCard: React.FC<ContentCardProps> = React.memo(({ edit }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [tab, setTab] = useState<number>(0);

  const {
    selectedPlace,
    placesVideos,
    videosState,
    add,
    update,
    selectedRating,
  } = useSelector<RootState, PlacesState>((state) => state.placesReducer);
  const { regions } = useSelector<RootState, IRegionsState>(
    (state) => state.regionsReducer
  );

  const { placesTypes } = useSelector<RootState, IPlacesTypesState>(
    (state) => state.placesTypesReducer
  );

  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );

  const handleSaveData = (place: any, files?: any) => {
    if (!edit) {
      dispatch(createPlace(place, files));
      // history.push(`/places`);
    } else {
      if (user?.tipoUsuario === "Lugar") {
        dispatch(updatePlace(place, user?.id, files));
      } else {
        dispatch(updatePlace(place, location.pathname.split("/")[2], files));
      }
    }
  };

  const handleSaveVideo = (video: any, type: string) => {
    if (type === "new") {
      dispatch(addPlaceVideo(video, location.pathname.split("/")[2]));
    } else {
      dispatch(updatePlaceVideo(video, location.pathname.split("/")[2]));
    }
  };

  useEffect(() => {
    if (edit && !selectedPlace) {
      dispatch(getOnePlace(location.pathname.split("/")[2]));
    }
  }, [dispatch, selectedPlace]);

  useEffect(() => {
    if (regions.length === 0) {
      dispatch(getRegions());
    }
  }, [dispatch, regions]);
  useEffect(() => {
    if (placesTypes === null) {
      dispatch(getPlacesTypes());
    }
  }, [dispatch, placesTypes]);

  useEffect(() => {
    if (selectedPlace) {
      dispatch(getPlaceVideos(selectedPlace.id));
    }
  }, [selectedPlace]);

  useEffect(() => {
    if (selectedPlace) {
      dispatch(getPlaceCalificaciones(selectedPlace.id));
    }
  }, [selectedPlace]);

  return (
    <>
      <Card mb={4}>
        <Tabs
          value={tab}
          classes={{ root: classes.root, indicator: classes.indicator }}
          onChange={(_, newValue) => setTab(newValue)}
          textColor="inherit"
          variant="scrollable"
          indicatorColor="primary"
          scrollButtons="on"
        >
          <Tab label="Información" />
          {edit ? <Tab label="Videos Promocionales" /> : ""}
          {user?.tipoUsuario === "Administrador" && (
            <Tab label="Calificaciones" />
          )}
          {edit && <Tab label="Imagenes Promocionales" />}
        </Tabs>
      </Card>
      <TabPanel value={tab} index={0}>
        <Card mb={6}>
          {edit && selectedPlace ? (
            <PlacesEditNew
              place={selectedPlace}
              id={location.pathname.split("/")[2]}
              save={(place, files) => handleSaveData(place, files)}
            />
          ) : (
            <PlacesEditNew
              save={(place, files) => handleSaveData(place, files)}
            />
          )}
        </Card>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Card mb={6}>
          {edit && selectedPlace && videosState === FormState.Success ? (
            <PlacesVideos
              videos={placesVideos}
              save={(values, type) => handleSaveVideo(values, type)}
            />
          ) : (
            ""
          )}
        </Card>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Card mb={6}>
          {edit && selectedPlace && (
            <PlacesScore place={selectedRating} save={(data) => {}} />
          )}
        </Card>
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <Card mb={6}>
          {selectedPlace && <PlacesImages selectedPlace={selectedPlace} />}
        </Card>
      </TabPanel>
      <DialogLoad
        open={
          add?.state === FormState.Submitting ||
          update?.state === FormState.Submitting
        }
        onClose={() => {}}
      />
    </>
  );
});

export const PlacesIndexCard = () => {
  const location = useLocation();
  // USING LOCATION TO CHECK HOW TO USE VIEWS

  return (
    <React.Fragment>
      <Helmet title="Lugar" />

      <Typography variant="h3" gutterBottom display="inline">
        {location.pathname.includes("Editar") ? "Editar" : "Crear"}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        {!location.pathname.includes("Lugar") ? (
          <Link component={NavLink} exact to="/places">
            Comercios
          </Link>
        ) : (
          ""
        )}
        {/* <Typography>
          {location.pathname.includes("Editar") ? "Editar" : "Crear"}
        </Typography> */}
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard edit={location.pathname.includes("Editar")} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

/*

      <Card
          style={{
            display: "grid",
            height: "80vh",
            gridTemplate: "15% 85% / 50% 50%",
            marginBottom: "0px !important",
          }}
          mb={6}
        >
          <div
            style={{
              gridArea: "1 / 1 / 2 / 3",
              padding: "0px 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>Imagenes Promocionales</h3>

            <Button
              style={{
                border: `1px solid ${THEMES.BLUE}`,
                padding: "5px 25px",
                color: THEMES.BLUE,
              }}
            >
              Guardar
            </Button>
          </div>
          <div
            style={{
              padding: "0px 20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            
          </div>
          <div
            style={{
              padding: "0px 20px",
            }}
          >
            <div
              style={{
                height: "50%",
              }}
            >
              <h3
                style={{
                  fontWeight: 500,
                }}
              >
                Vista previa
              </h3>
              <div
                style={{
                  width: "100%",
                  height: "80%",
                  borderRadius: 10,
                  backgroundSize: "cover",
                  display: "flex",
                  alignItems: "flex-end",
                  border: imagesState.frontImage.image
                    ? "none"
                    : "1px solid silver",
                  backgroundImage: imagesState.frontImage.image
                    ? `url(${imagesState.frontImage.image})`
                    : "none",
                }}
              >
                {imagesState.frontImage.image && (
                  <div
                    style={{
                      height: "35%",
                      width: "100%",
                      background: "rgba(0,0,0,.5)",
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                    }}
                  ></div>
                )}
              </div>
            </div>
           
          </div>
        </Card>

*/
