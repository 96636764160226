import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import { HelpCircle, Search as SearchIcon } from "react-feather";

import {
  AppBar as MuiAppBar,
  Badge,
  Box,
  Button,
  Grid,
  Hidden,
  IconButton as MuiIconButton,
  InputBase,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { Menu as MenuIcon, PersonAdd, Store } from "@material-ui/icons";

import UserDropdown from "./UserDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanGuideNotifications,
  cleanPlaceNotifications,
} from "../redux/actions/notificationsActions";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

const AppBarComponent = ({ onDrawerToggle }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const { guideCount, placeCount } = useSelector(
    (state) => state.notifsReducer
  );

  const handleEmptyGuideNotifs = () => {
    dispatch(cleanGuideNotifications());
  };
  const handleEmptyPlaceNotifs = () => {
    dispatch(cleanPlaceNotifications());
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>

            <Grid item xs />
            {user && user?.tipoUsuario === "Administrador" && (
              <Box display="flex" flexWrap="nowrap">
                <Box mr={10}>
                  <div>
                    <GuideMenu
                      guideCount={guideCount}
                      onViewed={handleEmptyGuideNotifs}
                    />
                  </div>
                </Box>
                <Box mr={10}>
                  <div>
                    <PlaceMenu
                      placeCount={placeCount}
                      onViewed={handleEmptyPlaceNotifs}
                    />
                  </div>
                </Box>
                <Box mr={10}>
                  <Tooltip title="Contadores de guías y lugares creados">
                    <HelpCircle size={15} color="#2979ffff" />
                  </Tooltip>
                </Box>
              </Box>
            )}

            <Grid item>
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

const GuideMenu = ({ guideCount, onViewed }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (guideCount.length !== 0) {
      setAnchorEl(event.currentTarget);
      onViewed();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Tooltip title="Notificaciones Guias">
        <Badge
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          color="primary"
          badgeContent={guideCount.length}
        >
          <PersonAdd />
        </Badge>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {guideCount.map((item, index) => (
          <MenuItem key={index}>
            <Typography>{item}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
const PlaceMenu = ({ placeCount, onViewed }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (placeCount.length !== 0) {
      setAnchorEl(event.currentTarget);
      onViewed();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Tooltip title="Notificaciones Lugares">
        <Badge
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          color="primary"
          badgeContent={placeCount.length}
        >
          <Store />
        </Badge>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {placeCount.map((item, index) => (
          <MenuItem key={index}>
            <Typography>{item}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default withTheme(AppBarComponent);
