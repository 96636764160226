import {
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Edit3, File, MapPin } from "react-feather";
import { useSelector } from "react-redux";
import * as rutUtils from "rut.js";
import * as yup from "yup";
import { IAuthState } from "../../redux/reducers/authReducer";
import { IPlacesTypesState } from "../../redux/reducers/placesTypesReducer";
import { IRegionsState } from "../../redux/reducers/regionsReducer";
import { RootState } from "../../redux/reducers/rootReducer";
import MapMapbox from "../Maps/MapboxWithSearch";

interface Props {
  place?: any;
  id?: string;
  save: (values: any, files?: any) => void;
}

interface DialogMapProps {
  myPath?: any;
  open: boolean;
  onClose: () => void;
  onChangeLocation: (location: any) => void;
}

const tiposLugares = ["Alojamiento", "Restaurant", "Car Rent"];
const Estados = ["En Revisión", "Aprobado"];

export const PlacesEditNew: React.FC<Props> = React.memo(({ place, save }) => {
  const { regions } = useSelector<RootState, IRegionsState>(
    (state) => state.regionsReducer
  );
  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );
  const { placesTypes } = useSelector<RootState, IPlacesTypesState>(
    (state) => state.placesTypesReducer
  );
  const [editable, setEditable] = useState(false);

  const [openMapDialog, setOpenMapDialog] = useState(false);

  const [checkLugar, setCheckLugar] = useState(false);

  const [certificadoIniciacion, setCertIniciacion] = useState<any>(null);
  const [checkCert1, setCheckCert1] = useState(true);
  const [certificadoRegistroSernatur, setCertRegistroSernatur] =
    useState<any>(null);
  const [checkCert2, setCheckCert2] = useState(true);
  const [patenteComercial, setPatenteComerical] = useState<any>(null);
  const [checkCert3, setCheckCert3] = useState(true);
  const [cedulaRepresentante, setCedulaRepresentante] = useState<any>(null);
  const [checkCert4, setCheckCert4] = useState(true);

  const restoreInitial = () => {
    setCheckCert1(true);
    setCheckCert2(true);
    setCheckCert3(true);
    setCheckCert4(true);
  };

  const downloadThis = (fileUrl: any) => {
    const link = document.createElement("a");
    link.setAttribute("href", fileUrl);
    link.setAttribute("target", "_blank");
    link.setAttribute("download", "pdfFile");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      Estado: "En Revisión",
      NombreLugar: "",
      TipoLugar: "Alojamiento",
      Region: null,
      LugarGoogle: "" as any,
      DireccionEscrita: "",
      Descripcion: "",
      RazonSocial: "",
      NombreFantasia: "",
      Rut: "",
      Giro: "",
      DireccionFacturacion: "",
      Ciudad: "",
      Nombre: "",
      Apellido: "",
      Email: "",
      Telefono: 0,
      CertificadoIniciacion: "" as any,
      CertificadoRegistroSernatur: "" as any,
      PatenteComercial: "" as any,
      CedulaRepresentante: "" as any,
      HorarioApertura: "",
      HorarioCierre: "",
      TelefonoComercio: 0,
      InformacionDestacada: "",
    },
    onSubmit: (values) => {
      if (values.LugarGoogle === "" || !values.LugarGoogle) {
        setCheckLugar(!checkLugar);
      } else {
        if (place) {
          save(values, {
            CertificadoIniciacion: certificadoIniciacion,
            CertificadoRegistroSernatur: certificadoRegistroSernatur,
            PatenteComercial: patenteComercial,
            CedulaRepresentante: cedulaRepresentante,
          });
        } else {
          if (!certificadoIniciacion) {
            setCheckCert1(false);
          }
          if (!certificadoRegistroSernatur) {
            setCheckCert2(false);
          }
          if (!patenteComercial) {
            setCheckCert3(false);
          }
          if (!cedulaRepresentante) {
            setCheckCert4(false);
          }
          if (
            certificadoIniciacion &&
            certificadoRegistroSernatur &&
            patenteComercial &&
            cedulaRepresentante
          ) {
            restoreInitial();
            save(values, {
              CertificadoIniciacion: certificadoIniciacion,
              CertificadoRegistroSernatur: certificadoRegistroSernatur,
              PatenteComercial: patenteComercial,
              CedulaRepresentante: cedulaRepresentante,
            });
          }
        }
        setCheckLugar(false);
      }
    },
    validationSchema: yup.object({
      NombreLugar: yup.string().required("Nombre requerido"),
      Descripcion: yup
        .string()
        .max(350, "Maximo 350 caracteres")
        .required("Descripción requerida"),
      RazonSocial: yup.string().required("Razón requerida"),
      NombreFantasia: yup.string().required("Nombre requerido"),
      Rut: yup
        .string()
        .min(11)
        .required("Rut es requerido")
        .test({
          name: "Rut",
          message: "Rut no válido",
          test: (value) => {
            if (!value) return false;
            return rutUtils.validate(value);
          },
        }),
      Giro: yup.string().required("Giro requerido"),
      DireccionFacturacion: yup.string().required("Dirección requerida"),
      Ciudad: yup.string().required("Ciudad requerida"),
      Nombre: yup.string().required("Nombre requerido"),
      Apellido: yup.string().required("Apellido requerido"),
      Email: yup.string().email("Email no válido").required("Email requerido"),
      Telefono: yup
        .number()
        .min(900000000, "Número no válido")
        .max(999999999, "Número no válido")
        .required("Es requerido")
        .required("Número es requerido"),
      TelefonoComercio: yup
        .number()
        .min(900000000, "Número no válido")
        .max(999999999, "Número no válido")
        .required("Es requerido")
        .required("Número es requerido"),
      Region: yup.string().required("Región es requerida"),
      TipoLugar: yup.string().required("Tipo lugar es requerido"),
      HorarioApertura: yup.string().required("Hora requerida"),
      HorarioCierre: yup.string().required("Hora requerida"),
      InformacionDestacada: yup.string().max(50, "Maximo 50 caracteres"),
    }),
  });

  useEffect(() => {
    if (place) {
      let telefono = place?.Telefono;
      let telefonoComercio = place?.TelefonoComercio;

      if (String(place?.Telefono).length <= 8) {
        telefono = "9" + place?.Telefono;
      }
      if (String(place?.TelefonoComercio).length <= 8) {
        telefonoComercio = "9" + place?.TelefonoComercio;
      }

      setValues({
        Estado: place?.Estado || "En Revisión",
        NombreLugar: place?.NombreLugar || "",
        TipoLugar: place?.TipoLugar || "",
        Region: place?.Region || "",
        LugarGoogle: place?.LugarGoogle || null,
        Descripcion: place?.Descripcion || "",
        RazonSocial: place?.RazonSocial || "",
        NombreFantasia: place?.NombreFantasia || "",
        Rut: place?.Rut || "",
        Giro: place?.Giro || "",
        DireccionFacturacion: place?.DireccionFacturacion || "",
        DireccionEscrita: place?.DireccionEscrita || "",
        Ciudad: place?.Ciudad || "",
        Nombre: place?.Nombre || "",
        Apellido: place?.Apellido || "",
        Email: place?.Email || "",
        Telefono: Number(telefono) || 9,
        CertificadoIniciacion: place.CertificadoIniciacion || "",
        CertificadoRegistroSernatur: place.CertificadoRegistroSernatur || "",
        PatenteComercial: place.PatenteComercial || "",
        CedulaRepresentante: place.CedulaRepresentante || "",
        HorarioApertura: place?.HorarioApertura || "",
        HorarioCierre: place?.HorarioCierre || "",
        TelefonoComercio: Number(telefonoComercio) || 9,
        InformacionDestacada: place?.InformacionDestacada || "Sin",
      });
    }
  }, [place]);

  return (
    <React.Fragment>
      {place ? (
        <CardHeader
          subheader={
            editable ? <Typography color="error">Editando</Typography> : ""
          }
          action={
            <>
              {editable ? (
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ marginRight: 10 }}
                  onClick={() => setEditable(!editable)}
                  size="small"
                >
                  Cancelar
                </Button>
              ) : (
                ""
              )}
              <Button
                startIcon={<Edit3 />}
                variant="contained"
                color="primary"
                onClick={() => setEditable(!editable)}
                size="small"
                disabled={editable}
              >
                Editar
              </Button>
            </>
          }
        />
      ) : (
        ""
      )}
      <form onSubmit={handleSubmit}>
        <CardContent>
          {user?.tipoUsuario === "Administrador"
            ? place && (
                <Grid container justify="flex-end">
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      id="Estado"
                      value={values.Estado}
                      options={Estados}
                      getOptionLabel={(option) => option}
                      onChange={(_, value) => setFieldValue("Estado", value)}
                      fullWidth
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Estado"
                          variant="outlined"
                        />
                      )}
                      disabled={place ? !editable : false}
                    />
                  </Grid>
                </Grid>
              )
            : ""}
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" gutterBottom>
                Datos Básicos
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="NombreLugar"
                value={values.NombreLugar}
                label="Nombre Lugar"
                variant="outlined"
                autoComplete="off"
                type="text"
                fullWidth
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ readOnly: place ? !editable : false }}
                error={touched.NombreLugar && Boolean(errors.NombreLugar)}
                helperText={touched.NombreLugar && errors.NombreLugar}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {placesTypes && (
                <Autocomplete
                  id="TipoLugar"
                  value={
                    placesTypes.find(
                      (tipo: any) => tipo.Nombre === values.TipoLugar
                    ) || null
                  }
                  options={placesTypes}
                  getOptionLabel={(option: any) =>
                    option.Nombre || values.TipoLugar
                  }
                  filterOptions={(option) =>
                    placesTypes?.filter((x: any) => x?.Activo)
                  }
                  onChange={(_, value: any) => {
                    if (value) {
                      setFieldValue("TipoLugar", value.Nombre);
                    }
                  }}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo"
                      variant="outlined"
                      error={touched.TipoLugar && Boolean(errors.TipoLugar)}
                    />
                  )}
                  disabled={place ? !editable : false}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="InformacionDestacada"
                value={values.InformacionDestacada}
                label="Información corta"
                variant="outlined"
                autoComplete="off"
                type="text"
                fullWidth
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ readOnly: place ? !editable : false }}
                error={
                  touched.InformacionDestacada &&
                  Boolean(errors.InformacionDestacada)
                }
                helperText={
                  touched.InformacionDestacada && errors.InformacionDestacada
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="DireccionEscrita"
                value={values.DireccionEscrita}
                label="Direccion del lugar"
                placeholder="Direccion del lugar"
                variant="outlined"
                autoComplete="off"
                type="text"
                fullWidth
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ readOnly: place ? !editable : false }}
                error={
                  touched.DireccionEscrita && Boolean(errors.DireccionEscrita)
                }
                helperText={touched.DireccionEscrita && errors.DireccionEscrita}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="Region"
                value={
                  values.Region
                    ? regions.filter((x) => x.Nombre === values?.Region)[0]
                    : null
                }
                options={regions}
                getOptionLabel={(option: any) => option.Nombre || values.Region}
                onChange={(_, value) => {
                  if (value) {
                    setFieldValue("Region", value.Nombre);
                  }
                }}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Region"
                    variant="outlined"
                    error={touched.Region && Boolean(errors.Region)}
                  />
                )}
                disabled={place ? !editable : false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                fullWidth
                startIcon={
                  <MapPin color={!values.LugarGoogle ? "red" : "blue"} />
                }
                disabled={place ? !editable : false}
                onClick={() => setOpenMapDialog(!openMapDialog)}
              >
                {values.LugarGoogle
                  ? "[" +
                    Number(values?.LugarGoogle.lat).toFixed(6) +
                    "," +
                    Number(values?.LugarGoogle.lng).toFixed(6) +
                    "]"
                  : "Seleccionar Lugar"}
              </Button>
              {checkLugar && (
                <Typography color="error">{"Lugar es requerido"}</Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="time"
                label="Horario Apertura"
                type="time"
                variant="outlined"
                value={values.HorarioApertura}
                onChange={(e) => {
                  setFieldValue("HorarioApertura", e.target.value);
                }}
                error={
                  touched.HorarioApertura && Boolean(errors.HorarioApertura)
                }
                helperText={touched.HorarioApertura && errors.HorarioApertura}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                disabled={place ? !editable : false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="time2"
                label="Horario Cierre"
                type="time"
                variant="outlined"
                value={values.HorarioCierre}
                onChange={(e) => {
                  setFieldValue("HorarioCierre", e.target.value);
                }}
                error={touched.HorarioCierre && Boolean(errors.HorarioCierre)}
                helperText={touched.HorarioCierre && errors.HorarioCierre}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                disabled={place ? !editable : false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="TelefonoComercio"
                value={values.TelefonoComercio}
                label="Número de Contacto"
                type="number"
                variant="outlined"
                autoComplete="off"
                fullWidth
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ readOnly: place ? !editable : false }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+56</InputAdornment>
                  ),
                }}
                error={
                  touched.TelefonoComercio && Boolean(errors.TelefonoComercio)
                }
                helperText={touched.TelefonoComercio && errors.TelefonoComercio}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="Descripcion"
                value={values.Descripcion}
                label="Descripción destacada"
                multiline
                rows={6}
                variant="outlined"
                autoComplete="off"
                type="text"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ readOnly: place ? !editable : false }}
                error={touched.Descripcion && Boolean(errors.Descripcion)}
                helperText={touched.Descripcion && errors.Descripcion}
              />
            </Grid>
            <Grid item xs={12} sm={12} style={{ marginTop: -12 }}>
              <Typography style={{ color: "gray" }}>
                * Detallar información relevante que destaque a tu
                negocio/comercio (Especialidad, cantidad de estrellas,
                reconocimientos, etc).
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" gutterBottom>
                Datos Legales
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="RazonSocial"
                value={values.RazonSocial}
                label="Razón Social"
                variant="outlined"
                autoComplete="off"
                type="text"
                fullWidth
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ readOnly: place ? !editable : false }}
                error={touched.RazonSocial && Boolean(errors.RazonSocial)}
                helperText={touched.RazonSocial && errors.RazonSocial}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="NombreFantasia"
                value={values.NombreFantasia}
                label="Nombre Fantasia"
                variant="outlined"
                autoComplete="off"
                type="text"
                fullWidth
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ readOnly: place ? !editable : false }}
                error={touched.NombreFantasia && Boolean(errors.NombreFantasia)}
                helperText={touched.NombreFantasia && errors.NombreFantasia}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Rut"
                value={values.Rut}
                label="Rut"
                variant="outlined"
                autoComplete="off"
                type="text"
                fullWidth
                size="small"
                onChange={(e) => {
                  e.target.value = rutUtils.format(e.target.value);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                inputProps={{ readOnly: place ? !editable : false }}
                error={touched.Rut && Boolean(errors.Rut)}
                helperText={touched.Rut && errors.Rut}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Giro"
                value={values.Giro}
                label="Giro"
                variant="outlined"
                autoComplete="off"
                type="text"
                fullWidth
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ readOnly: place ? !editable : false }}
                error={touched.Giro && Boolean(errors.Giro)}
                helperText={touched.Giro && errors.Giro}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="DireccionFacturacion"
                value={values.DireccionFacturacion}
                label="Dirección Facturación"
                variant="outlined"
                autoComplete="off"
                type="text"
                fullWidth
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ readOnly: place ? !editable : false }}
                error={
                  touched.DireccionFacturacion &&
                  Boolean(errors.DireccionFacturacion)
                }
                helperText={
                  touched.DireccionFacturacion && errors.DireccionFacturacion
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Ciudad"
                value={values.Ciudad}
                label="Ciudad"
                variant="outlined"
                autoComplete="off"
                type="text"
                fullWidth
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ readOnly: place ? !editable : false }}
                error={touched.Ciudad && Boolean(errors.Ciudad)}
                helperText={touched.Ciudad && errors.Ciudad}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" gutterBottom>
                Datos Representante Legal
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Nombre"
                value={values.Nombre}
                label="Nombre Representante Legal"
                variant="outlined"
                autoComplete="off"
                placeholder="Nombre del representante legal"
                type="text"
                fullWidth
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ readOnly: place ? !editable : false }}
                error={touched.Nombre && Boolean(errors.Nombre)}
                helperText={touched.Nombre && errors.Nombre}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Apellido"
                value={values.Apellido}
                label="Apellido"
                variant="outlined"
                autoComplete="off"
                placeholder="Apellido del representante legal"
                type="text"
                fullWidth
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ readOnly: place ? !editable : false }}
                error={touched.Apellido && Boolean(errors.Apellido)}
                helperText={touched.Apellido && errors.Apellido}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Email"
                value={values.Email}
                label="Email"
                type="email"
                variant="outlined"
                autoComplete="off"
                fullWidth
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ readOnly: place ? true : false }} //!editable : false
                error={touched.Email && Boolean(errors.Email)}
                helperText={touched.Email && errors.Email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Telefono"
                value={values.Telefono}
                label="Teléfono"
                type="number"
                variant="outlined"
                autoComplete="off"
                fullWidth
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ readOnly: place ? !editable : false }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+56</InputAdornment>
                  ),
                }}
                error={touched.Telefono && Boolean(errors.Telefono)}
                helperText={touched.Telefono && errors.Telefono}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" gutterBottom>
                Documentos
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography color="error" gutterBottom>
                * Todos los documentos deben estar en formato PDF
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                size="small"
                variant="contained"
                component="label"
                startIcon={<CloudUpload />}
                disabled={place ? !editable : false}
                fullWidth
              >
                Certificación Incio Actividades o Cartola Tributaria.
                <input
                  type="file"
                  accept="application/pdf"
                  hidden
                  onChange={(event) => {
                    if (event.target.files) {
                      setCheckCert1(true);
                      setCertIniciacion(event.target.files[0]);
                    }
                  }}
                />
              </Button>
              {!checkCert1 && (
                <Typography color="error">
                  Este documento es requerido.
                </Typography>
              )}
              {certificadoIniciacion && (
                <Typography>{certificadoIniciacion!.name}</Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {values?.CertificadoIniciacion && (
                <Button
                  size="small"
                  variant="outlined"
                  component="label"
                  startIcon={<File />}
                  onClick={() => downloadThis(values.CertificadoIniciacion)}
                >
                  Descargar PDF
                </Button>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                size="small"
                variant="contained"
                component="label"
                startIcon={<CloudUpload />}
                disabled={place ? !editable : false}
                fullWidth
              >
                Certificado Registro Sernatur.
                <input
                  type="file"
                  accept="application/pdf"
                  hidden
                  onChange={(event) => {
                    if (event.target.files) {
                      setCheckCert2(true);
                      setCertRegistroSernatur(event.target.files[0]);
                    }
                  }}
                />
              </Button>
              {!checkCert2 && (
                <Typography color="error">
                  Este documento es requerido.
                </Typography>
              )}
              {certificadoRegistroSernatur && (
                <Typography>{certificadoRegistroSernatur!.name}</Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {values?.CertificadoRegistroSernatur && (
                <Button
                  size="small"
                  variant="outlined"
                  component="label"
                  startIcon={<File />}
                  onClick={() =>
                    downloadThis(values.CertificadoRegistroSernatur)
                  }
                >
                  Descargar PDF
                </Button>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                size="small"
                variant="contained"
                component="label"
                startIcon={<CloudUpload />}
                disabled={place ? !editable : false}
                fullWidth
              >
                Patente Comercial Vigente.
                <input
                  type="file"
                  accept="application/pdf"
                  hidden
                  onChange={(event) => {
                    if (event.target.files) {
                      setCheckCert3(true);
                      setPatenteComerical(event.target.files[0]);
                    }
                  }}
                />
              </Button>
              {!checkCert3 && (
                <Typography color="error">
                  Este documento es requerido.
                </Typography>
              )}
              {patenteComercial && (
                <Typography>{patenteComercial!.name}</Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {values?.PatenteComercial && (
                <Button
                  size="small"
                  variant="outlined"
                  component="label"
                  startIcon={<File />}
                  onClick={() => downloadThis(values.PatenteComercial)}
                >
                  Descargar PDF
                </Button>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                size="small"
                variant="contained"
                component="label"
                startIcon={<CloudUpload />}
                disabled={place ? !editable : false}
                fullWidth
              >
                Cédula Representante Legal (Por Ambos Lados).
                <input
                  type="file"
                  accept="application/pdf"
                  hidden
                  onChange={(event) => {
                    if (event.target.files) {
                      setCheckCert4(true);
                      setCedulaRepresentante(event.target.files[0]);
                    }
                  }}
                />
              </Button>
              {!checkCert4 && (
                <Typography color="error">
                  Este documento es requerido.
                </Typography>
              )}
              {cedulaRepresentante && (
                <Typography>{cedulaRepresentante!.name}</Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {values?.CedulaRepresentante && (
                <Button
                  size="small"
                  variant="outlined"
                  component="label"
                  startIcon={<File />}
                  onClick={() => downloadThis(values.CedulaRepresentante)}
                >
                  Descargar PDF
                </Button>
              )}
            </Grid>
            <Grid item xs={12} sm={12} container justify="flex-end">
              <Button
                disabled={!place ? false : !editable}
                type="submit"
                variant="contained"
                color="primary"
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
      <MapDialog
        myPath={values.LugarGoogle}
        open={openMapDialog}
        onClose={() => setOpenMapDialog(!openMapDialog)}
        onChangeLocation={(location) => {
          setFieldValue("LugarGoogle", { lng: location[0], lat: location[1] });
          setCheckLugar(false);
        }}
      />
    </React.Fragment>
  );
});

const MapDialog: React.FC<DialogMapProps> = React.memo(
  ({ open, myPath = null, onClose, onChangeLocation }) => {
    return (
      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={onClose}>
        <div style={{ padding: 20 }}>
          <MapMapbox
            selected={myPath ? myPath : {}}
            onSave={(coords: any) => {
              onChangeLocation(coords);
            }}
          />
        </div>

        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
