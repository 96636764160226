import { Action } from "../../models/action";
import * as types from "../../constants";

export interface ITourGuidesState {
  tourGuides: any[];
  toursRegions: any[];
  isLoading: boolean;
  totalDocs: number;
  lastDoc?: any;
  error?: string;
  edit: {
    isLoading: boolean;
    selectedGuide?: any;
  };
  videosDetails?: {
    state: "initial" | "submitting" | "success" | "failure";
    data?: any[];
  };
}

const initialState = {
  tourGuides: [],
  toursRegions: [],
  isLoading: false,
  totalDocs: 0,
  edit: {
    isLoading: false,
  },
} as ITourGuidesState;

export const tourGuidesReducer = (
  state = initialState,
  action: Action
): ITourGuidesState => {
  switch (action.type) {
    case types.TOUR_GUIDES_REGIONS_GET:
      return {
        ...state,
        toursRegions: action.payload,
      };
    case types.TOUR_GUIDES_GET_DOCS:
      return {
        ...state,
        tourGuides: action.payload.tourGuides,
        lastDoc: action.payload.lastDoc,
        totalDocs: action.payload.totalDocs,
      };
    case types.TOUR_GUIDES_SET_SELECTED:
      return {
        ...state,
        edit: {
          ...state.edit,
          selectedGuide: action.payload,
        },
      };
    case types.TOUR_GUIDES_UPDATE_LOADING:
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: action.payload,
        },
      };
    case types.TOUR_GUIDES_ADD_DOCS:
      return {
        ...state,
        tourGuides: [...state.tourGuides, ...action.payload],
      };
    case types.TOUR_GUIDES_UPDATE_DOC:
      return {
        ...state,
        tourGuides: state.tourGuides.map((x) =>
          x.id === action.payload.id ? { ...x, ...action.payload } : x
        ),
      };
    case types.TOUR_GUIDES_REMOVE_DOC:
      return {
        ...state,
        tourGuides: state.tourGuides.filter((x) => x.id !== action.payload.id),
      };
    case types.TOUR_GUIDES_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.TOUR_GUIDES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case types.TOUR_GUIDE_GET_VIDS_CONVERTED_REQUEST:
      return {
        ...state,
        videosDetails: {
          state: "submitting",
        },
      };
    case types.TOUR_GUIDE_GET_VIDS_CONVERTED_FAILURE:
      return {
        ...state,
        videosDetails: {
          state: "failure",
        },
      };
    case types.TOUR_GUIDE_GET_VIDS_CONVERTED_RESTORE:
      return {
        ...state,
        videosDetails: {
          state: "initial",
          data: [],
        },
      };
    case types.TOUR_GUIDE_GET_VIDS_CONVERTED_SUCCESS:
      return {
        ...state,
        videosDetails: {
          state: "success",
          data: action.payload,
        },
      };
    default:
      return state;
  }
};
