import React, { useEffect } from "react";
import * as yup from "yup";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";

type ObjectType = {
  id: string;
  Nombre: string;
  Bloqueo: boolean;
  Causa: string;
  Descripcion: string;
  Termino: any;
};

interface DialogProps {
  open: boolean;
  onClose: () => void;
  onAccept: (values: any) => void;
  isLoading: boolean;
  object?: ObjectType;
  title?: string;
}
const causes = ["A", "B", "C", "D"];

export const DialogBlockTourGuides: React.FC<DialogProps> = ({
  open,
  onClose,
  onAccept,
  isLoading,
  object,

  title = "",
}) => {
  const reasons = [
    "Información incompleta o desactualizada",
    "Horarios mal indicados",
    "Coordenadas no concuerdan con archivo",
    "Tour incompleto",
    "No incorpora comunidad local",
    "Archivos no compatibles",
    "Tour desactualizado",
    "Mala calidad de los archivos",
    "Lenguaje inapropiado",
    "Infringe las normas y condiciones",
    "Falta completar registro",
    "Otro",
  ];
  const { handleSubmit, touched, errors, values, setValues, handleChange } =
    useFormik({
      initialValues: {
        Termino: "",
        Causa: "Causa",
        Descripcion: "",
        Bloqueo: false,
      },
      onSubmit: (values) => {
        if (values.Bloqueo) {
          values.Bloqueo = false;
        } else {
          values.Bloqueo = true;
        }
        onAccept(values);
      },
      validationSchema: yup.object({
        Termino: yup.date().required("Fecha requerida"),
        Causa: yup.string().required("Causa requerida"),
        Descripcion: yup.string().required("Descripción requerida"),
      }),
    });

  useEffect(() => {
    if (object) {
      setValues({
        Bloqueo: object.Bloqueo,
        Causa: object.Causa,
        Descripcion: object.Descripcion,
        Termino: object.Termino,
      });
    }
  }, [open, setValues, object]);

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      aria-labelledby="add-and-update-dialog-title"
    >
      <DialogTitle id="dialog-confirm-title">
        {`¿Desea ` +
          (values.Bloqueo === true ? "desbloquear" : "bloquear") +
          ` a ` +
          title}
      </DialogTitle>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={10}>
              <TextField
                onChange={handleChange}
                id="Termino"
                label="Termino del bloqueo"
                type="date"
                name="Termino"
                value={values.Termino}
                InputLabelProps={{
                  shrink: true,
                }}
                error={touched.Termino && Boolean(errors.Termino)}
                helperText={touched.Termino && errors.Termino}
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <Select
                onChange={handleChange}
                id="Causa"
                name="Causa"
                value={values.Causa ? values.Causa : "Causa"}
                error={touched.Causa && Boolean(errors.Causa)}
                fullWidth
              >
                <MenuItem value="Causa" disabled>
                  Causa
                </MenuItem>
                {reasons.map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Chip
                size="small"
                style={
                  values.Bloqueo === true
                    ? { backgroundColor: "red" }
                    : { backgroundColor: "primary" }
                }
                color="secondary"
                label={values.Bloqueo === true ? "Bloqueado" : "Desbloqueado"}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                onChange={handleChange}
                id="Descripcion"
                label="Descripción"
                autoComplete="off"
                name="Descripcion"
                value={values.Descripcion}
                multiline
                rows={4}
                variant="outlined"
                error={touched.Descripcion && Boolean(errors.Descripcion)}
                helperText={touched.Descripcion && errors.Descripcion}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancelar
          </Button>
          <Button type="submit" color="primary" disabled={isLoading}>
            {values.Bloqueo === true ? "Desbloquear" : "Bloquear"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
