import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { PostAuthRegister } from "../FirebaseAuthRegister";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import { DialogAction } from "../../models/dialog_state";
import { RootState } from "../../redux/reducers/rootReducer";
import { ITourGuidesState } from "../../redux/reducers/tourGuidesReducer";
import { getTourGuideRegions } from "../../redux/actions/tourGuidesActions";
import { useDispatch, useSelector } from "react-redux";
import {
  phoneValidation,
  timesStampFormattedsimple,
  timeStampToDate,
} from "../../utils/utils";

type ObjectType = {
  Nombre: string;
  Apellido: string;
  Pais: string;
  Telefono: string;
  Email: string;
  FechaNacimiento: any;
};

interface DialogProps {
  open: boolean;
  onClose: () => void;
  onAccept: (values: any, action: DialogAction) => void;
  isLoading: boolean;
  action: DialogAction;
  object?: ObjectType;
  title?: string;
}

export const DialogEditAndNew: React.FC<DialogProps> = ({
  open,
  onClose,
  onAccept,
  isLoading,
  action,
  object,
  title = "",
}) => {
  const dispatch = useDispatch();
  const { toursRegions } = useSelector<RootState, ITourGuidesState>(
    (state) => state.tourGuidesReducer
  );
  const {
    handleSubmit,
    values,
    handleChange,
    touched,
    errors,
    resetForm,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      id: "",
      Nombre: "",
      Apellido: "",
      Pais: "",
      Telefono: 0,
      Email: "",
      FechaNacimiento: "" as any,
    },
    onSubmit: (values) => {
      const numCode = "569";
      const num = String(values.Telefono);
      values.Telefono = Number.parseInt(numCode + num);

      onAccept(values, action);
    },
    validationSchema: yup.object({
      Nombre: yup.string().required("Primer nombre requerido"),
      Apellido: yup.string().required("Apellido es requerido"),
      Telefono: yup
        .number()
        .test("len", "Número no válido", (val: any) => {
          try {
            return val.toString().length === 12;
          } catch {
            return false;
          }
        })
        .required("Número es requerido"),
      Email: yup
        .string()
        .email("Requiere correo valido")
        .required("Email es requerido"),
      Pais: yup.string().required("País es requerido"),
      FechaNacimiento: yup.string().required("Fecha requerida"),
    }),
  });

  useEffect(() => {
    dispatch(getTourGuideRegions());
  }, [dispatch]);

  useEffect(() => {
    if (action === "New") {
      resetForm();
    } else if (action === "Edit" && object) {
      // const myDate =
      //   timesStampFormattedsimple(object?.FechaNacimiento?.toDate()).split(
      //     "/"
      //   ) || "";
      // console.log(myDate);
      // console.log(timeStampToDate(object?.FechaNacimiento));

      setValues({
        Nombre: object.Nombre,
        Apellido: object.Apellido,
        Telefono: Number.parseInt(String(object.Telefono).replace("+","")),
        Email: object.Email,
        Pais: object.Pais,
        id: "",
        FechaNacimiento: object?.FechaNacimiento || "",
      });
    } else {
      console.log("Error: ", action, object);
    }
  }, [open, resetForm, setValues, object, action]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            onClose();
          }
        }}
        aria-labelledby="add-and-update-dialog-title"
      >
        <DialogTitle id="add-and-update-dialog-title">
          {action === "Edit" ? `Editar ${title}` : `Nuevo ${title}`}
        </DialogTitle>

        <form noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  id="Nombre"
                  label="Nombre"
                  type="text"
                  autoComplete="off"
                  value={values.Nombre}
                  name="Nombre"
                  onChange={handleChange}
                  error={touched.Nombre && Boolean(errors.Nombre)}
                  helperText={touched.Nombre && errors.Nombre}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Apellido"
                  label="Apellido"
                  type="text"
                  autoComplete="off"
                  value={values.Apellido}
                  name="Apellido"
                  onChange={handleChange}
                  error={touched.Apellido && Boolean(errors.Apellido)}
                  helperText={touched.Apellido && errors.Apellido}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Email"
                  label="Email"
                  type="text"
                  autoComplete="off"
                  value={values.Email}
                  name="Email"
                  onChange={handleChange}
                  error={touched.Email && Boolean(errors.Email)}
                  helperText={touched.Email && errors.Email}
                  fullWidth
                  variant="outlined"
                  disabled={action === "Edit"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Telefono"
                  label="Teléfono"
                  type="number"
                  autoComplete="off"
                  placeholder="XXXXXXXX"
                  value={values.Telefono === 0 ? null : values.Telefono}
                  name="Telefono"
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+</InputAdornment>
                    ),
                  }}
                  error={touched.Telefono && Boolean(errors.Telefono)}
                  helperText={touched.Telefono && errors.Telefono}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Pais"
                  label="Pais"
                  type="text"
                  autoComplete="off"
                  value={values.Pais}
                  name="Pais"
                  onChange={handleChange}
                  error={touched.Pais && Boolean(errors.Pais)}
                  helperText={touched.Pais && errors.Pais}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="date"
                  label="Fecha nacimiento"
                  value={values.FechaNacimiento}
                  type="date"
                  onChange={(e) => {
                    setFieldValue("FechaNacimiento", e.target.value);
                    // console.log(e.target.value);
                  }}
                  variant="outlined"
                  error={
                    touched.FechaNacimiento && Boolean(errors.FechaNacimiento)
                  }
                  helperText={touched.FechaNacimiento && errors.FechaNacimiento}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={isLoading}>
              {action === "Edit" ? "Editar" : "Agregar"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};
