import { Action } from "../../models/action";
import * as types from "../../constants";

export interface IRegionsState {
  regions: any[];
  isLoading: boolean;
  error?: string;
}

const initialState = {
  regions: [],
  isLoading: false,
} as IRegionsState;

export const regionsReducer = (
  state = initialState,
  action: Action
): IRegionsState => {
  switch (action.type) {
    case types.REGIONS_GET_DOCS:
      return {
        ...state,
        regions: action.payload,
      };
    case types.REGIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.REGIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
