import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef } from "react";
import {
  ChevronRight,
  Crosshair,
  Delete,
  Edit,
  RefreshCw,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { MAPBOX_TOKEN } from "../../constants";
import { FormState } from "../../models/form_state";
import {
  getDirectionsLine,
  getThisRouteLine,
  searchMapPlaces,
} from "../../redux/actions/mapActions";
import { IMapState } from "../../redux/reducers/mapReducer";
import { RootState } from "../../redux/reducers/rootReducer";
import { MapPointEditDialog } from "../MapPointEditDialog";
/* eslint import/no-webpack-loader-syntax: off */
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

mapboxgl.accessToken = MAPBOX_TOKEN;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingRight: 5,
      maxWidth: 360,
      borderRadius: 10,
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
      maxHeight: 430,
    },

    ul: {
      backgroundColor: "inherit",
      padding: 0,
    },
  })
);

const Map = React.memo((props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selected, mediaPoints, onSave } = props;

  const map = useRef<any>(undefined);
  const mapContainer = useRef<any>(undefined);

  const [disableSave, setDisableSave] = React.useState(false);

  // Map Point elements ////////////////////////////////////////////////////
  const [showExtra, setShowExtra] = React.useState(false);
  const [selectedShownPoint, setSelectedShownPoint] = React.useState<
    number | null
  >(null);
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [pointEdit, setPointEdit] = React.useState<mapboxgl.Marker | null>(
    null
  );
  //////////////////////////////////////////////////////////////////////////

  // Flag Point Img ////////////////////////////////////////////////////////
  const [flagImg, setFlagImg] = React.useState(document.createElement("div"));
  flagImg.style.backgroundImage = "url(/static/beachflag.png)";
  flagImg.style.backgroundSize = "contain";
  flagImg.style.width = "17px";
  flagImg.style.height = "60px";
  flagImg.style.cursor = "pointer";
  flagImg.style.backgroundRepeat = "no-repeat";
  flagImg.style.backgroundPositionY = "-2px";
  //////////////////////////////////////////////////////////////////////////

  const { routes, places, routeState } = useSelector<RootState, IMapState>(
    (state) => state.mapReducer
  );

  // Map Elements
  const [lng, setLng] = React.useState(
    selected ? selected?.points[0]?.geometry?.coordinates[0] : -70.8839759435348
  );
  const [lat, setLat] = React.useState(
    selected
      ? selected?.points[0]?.geometry?.coordinates[1]
      : -33.43262156024644
  );

  const [mediaMarkers, setMediaMarkers] = React.useState<any[]>([]);
  const [path, setPointsPath] = React.useState<any[]>([]);

  const [options, setOptions] = React.useState<any[]>([]);
  const [valueOption, setValueOption] = React.useState<any>(null);

  const [valueLat, setValueLat] = React.useState<number | null | string>(null);
  const [valueLng, setValueLng] = React.useState<number | null | string>(null);

  // Hooks Unused by now ////////////////////////////////////////////////////
  const [mediaPathChanges, setMediaPathChanges] = React.useState<any>({});
  // const [dialogOpen, setDialogOpen] = React.useState(false);
  //////////////////////////////////////////////////////////////////////////

  // Map mapbox width requires this ////////////////////////////////////////
  const [valueWidth, setValueWidth] = React.useState<Number>(window.innerWidth);
  function updateDimensions() {
    setValueWidth(window.innerWidth);
  }
  window.addEventListener("resize", updateDimensions);
  //////////////////////////////////////////////////////////////////////////

  const handleSetLine = (lineArr: [number, number][]) => {
    if (map.current.getSource("route")) {
      map.current.getSource("route").setData({
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: lineArr,
        },
      });
    }
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: 8,
    });

    map.current.addControl(new mapboxgl.NavigationControl());

    map.current.on("click", (e: any) => {
      handleToLinePoint([e?.lngLat?.lng, e?.lngLat?.lat]);
    });
    map.current.on("load", () => {
      // THIS SHOWS MARKERS OF THE MULTIMEDIA UBICATIONS
      handleShowMediaPoints();

      map.current.addSource("route", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: [],
          },
        },
      });
      map.current.addLayer({
        id: "route",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#FC33FF",
          "line-width": 8,
          "line-opacity": 0.8,
        },
      });

      // THIS LOADS THE LINE PATH
      handleDrawLine();
    });
  });

  const handleDrawLine = () => {
    if (selected && selected?.route && selected?.points && map.current) {
      const thisRoute: any[] = selected?.route || [];
      for (const item of selected?.points) {
        const elem = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: item.geometry.coordinates,
          },
          routeTo: item?.routeTo,
          properties: {
            title: item.title,
          },
        };
        path.push(elem);
      }

      handleSetLine(thisRoute);
    }
  };

  const arrayRouteHandler = (
    arr: {
      type: string;
      geometry: {
        type: string;
        coordinates: any[];
      };
      routeTo?: any[];
      properties: {
        title?: string;
      };
      marker?: mapboxgl.Marker;
    }[]
  ) => {
    const line: any[] = [];

    for (const item of arr) {
      line.push(...(item.routeTo || []));
    }
    return line;
  };

  const handleShowMediaPoints = () => {
    if (Array.isArray(mediaPoints) && map.current) {
      for (const mediaPoint of mediaPoints) {
        const img = document.createElement("div");
        img.style.backgroundImage = "url(/static/mapIcon2.png)";
        img.style.backgroundSize = "contain";
        img.style.width = "20px";
        img.style.height = "70px";
        img.style.cursor = "pointer";
        img.style.backgroundRepeat = "no-repeat";
        img.style.backgroundPositionY = "-2px";

        const marker = new mapboxgl.Marker(img)
          .setLngLat([mediaPoint.lng, mediaPoint.lat])
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(
                `<center><h3>${mediaPoint.Indice}</h3></center><p>${
                  mediaPoint.TipoMultimedia === "video" ? "Video" : "Audio"
                }:${mediaPoint.Nombre}</p>`
              )
          )
          .addTo(map.current)
          .setDraggable(false);

        const markerDiv = marker.getElement();
        markerDiv.addEventListener("mouseenter", () => marker.togglePopup());
        markerDiv.addEventListener("mouseleave", () => marker.togglePopup());

        const pointelem = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [mediaPoint.lng, mediaPoint.lat],
          },
          properties: {
            title: mediaPoint.title,
          },
          marker: marker,
        };

        mediaMarkers.push(pointelem);
      }
    }
  };

  const handleHideMediaPoints = () => {
    if (mediaMarkers.length !== 0 && map.current) {
      for (const mediaElem of mediaMarkers) {
        const marker: mapboxgl.Marker = mediaElem.marker;
        marker.remove();
      }
      setMediaMarkers([]);
    }
  };

  const handleGetGeolocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      map.current.flyTo({
        center: [position.coords.longitude, position.coords.latitude],
      });
    });
  };

  const handleResetAll = async () => {
    if (path.length === 0) return;
    for (let i = path.length - 1; i >= 0; i--) {
      const lastElem: {
        type: string;
        geometry: {
          type: string;
          coordinates: any[];
        };
        routeTo?: any[];
        properties: {
          title?: string;
        };
        marker?: mapboxgl.Marker;
      } = path[i];
      if (lastElem.marker) {
        lastElem.marker.remove();
      }
      path.pop();
    }
    path.pop();
    handleSetLine([]);
  };

  // useEffect(() => {
  //   if (path.length > 1) {
  //     disableSave && setDisableSave(false);
  //   } else {
  //     !disableSave && setDisableSave(true);
  //   }
  // }, [path.length]);

  const handleRemoveLast = async () => {
    if (path.length !== 0) {
      const lastElem: {
        type: string;
        geometry: {
          type: string;
          coordinates: any[];
        };
        routeTo?: any[];
        properties: {
          title?: string;
        };
        marker?: mapboxgl.Marker;
      } = path[path.length - 1];
      if (lastElem.marker) {
        lastElem.marker.remove();
      }
      const newPath = path.filter((item) => item !== lastElem);
      path.pop();
      if (newPath.length === 1) {
        path.pop();
      }

      const routeLine = arrayRouteHandler(newPath);
      handleSetLine(routeLine);
    } else {
      try {
        path.pop();
        handleSetLine([]);
      } catch {}
    }
  };

  // Directions autocomplete logic ///////////////////////////////////////////////
  useEffect(() => {
    setOptions(places);
  }, [places]);

  const handleInputChange = (e: any, v: any) => {
    if (v) {
      dispatch(searchMapPlaces(v || ""));
    } else {
      setOptions([]);
    }
  };
  //////////////////////////////////////////////////////////////////////////////

  const handleSearchSelect = (values: any) => handleToLinePoint(values?.center);

  const handleAddPrecision = () => {
    if (
      !valueLat ||
      !valueLng ||
      valueLat === undefined ||
      valueLng === undefined ||
      typeof valueLat === "string" ||
      typeof valueLng === "string"
    ) {
      return;
    }
    if (
      valueLat >= 91 ||
      valueLat <= -91 ||
      valueLng >= 91 ||
      valueLng <= -91 ||
      valueLat === 0 ||
      valueLng === 0
    ) {
      return;
    }
    const center = [Number(valueLng?.toFixed(6)), Number(valueLat?.toFixed(6))];
    setValueLat("");
    setValueLng("");

    handleToLinePoint([center[0], center[1]]);
  };

  const handleToLinePoint = async (center: any) => {
    let routeTo: any[] = [];
    const centerA = Number(Number(center[0]).toFixed(6));
    const centerB = Number(Number(center[1]).toFixed(6));

    const coords: mapboxgl.LngLatLike = [centerA, centerB];

    if (path.length === 1) {
      const startCoords = path[0].geometry?.coordinates;
      const res = await getThisRouteLine(startCoords, coords);
      if (typeof res !== "string" && res.length > 0) {
        routeTo = res;
      }
    } else if (path.length > 1) {
      const startCoords = path[path.length - 1].geometry?.coordinates;
      const res = await getThisRouteLine(startCoords, coords);
      if (typeof res !== "string" && res.length > 0) {
        routeTo = res;
      }
    }

    const marker = new mapboxgl.Marker(flagImg)
      .setLngLat(coords)
      .addTo(map.current);

    const newElem = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: coords,
      },
      routeTo: routeTo,
      properties: {
        title: "Punto Ruta",
      },
      marker: marker,
    };

    path.push(newElem);

    const routeLine = arrayRouteHandler(path);
    handleSetLine(routeLine);
  };

  const handleSavePath = () => {
    const routeLine = arrayRouteHandler(path);
    if (routeLine.length > 1) {
      setDisableSave(false);
      onSave(path, routeLine, mediaPathChanges);
    } else {
      setDisableSave(true);
    }
  };

  // Dialog events
  // const handleClickOpen = () => {
  //   setDialogOpen(true);
  // };

  // const handleClose = () => {
  //   setDialogOpen(false);
  // };

  // const handleIgnore = () => {
  //   setDialogOpen(false);
  //   onSave(path, routePath, {});
  //   Object.keys(mediaPathChanges).forEach(
  //     (key) => delete mediaPathChanges[key]
  //   );
  //   setMediaPathChanges({});
  // };

  // const handleDialogSave = () => {
  //   console.log(mediaPathChanges);

  //   // setDialogOpen(false);
  //   // onSave(path, routePath, mediaPathChanges);
  //   // Object.keys(mediaPathChanges).forEach(
  //   //   (key) => delete mediaPathChanges[key]
  //   // );
  //   // setMediaPathChanges({});
  // };

  // const readData = (data: any) => {
  //   const values = mediaPathChanges[data];
  //   return "Indice: " + values.indice + " - " + values.nombre;
  // };

  // const validateDisable = (): boolean => {
  //   if (routePath.length === 0 && path.length === 0) {
  //     return true;
  //   } else if (path.length > 1) {
  //     return false;
  //   } else {
  //     return false;
  //   }
  // };

  // Opens modal to edit one point of the ones that make the route
  const handleChangeShowExtra = () => {
    if (selectedShownPoint) setSelectedShownPoint(null);
    if (showExtra) {
      setShowExtra(false);
      setShowEditModal(false);
      console.log("should show map media points again...");
      if (pointEdit) pointEdit.remove();
      handleShowMediaPoints();
    } else {
      setShowExtra(true);
      if (showEditModal) setShowEditModal(false);
      console.log("should hide map media points");
      handleHideMediaPoints();
    }
  };
  ////////////////////////////////////////////////////////////

  // Logic to select one point
  const handleRoutePointClick = (item: any, index: number) => {
    if (index === selectedShownPoint) {
      setShowEditModal(false);
      setSelectedShownPoint(null);
      if (pointEdit) pointEdit.remove();
      return;
    }
    setSelectedShownPoint(index);

    if (pointEdit) pointEdit.remove();
    const coords = item?.geometry?.coordinates;
    setPointEdit(
      new mapboxgl.Marker({
        color: "#2979ff",
        draggable: true,
      })
        .setLngLat(coords)
        .addTo(map.current)
    );
  };

  const hanldeConfirmEditModal = async (newCoords: any) => {
    setShowEditModal(false);

    if (path.length > 0 && (selectedShownPoint || selectedShownPoint === 0)) {
      if (selectedShownPoint === 0) {
        const endCoords = path[1]?.geometry?.coordinates;

        // Should only recalculate next route

        const res = await getThisRouteLine(newCoords, endCoords);
        if (typeof res !== "string" && res.length > 0) {
          path[1].routeTo = res;
          path[selectedShownPoint].geometry = {
            ...path[selectedShownPoint].geometry,
            coordinates: newCoords,
          };
          const routeLine = arrayRouteHandler(path);
          handleSetLine(routeLine);
        }
      } else if (path.length - 1 === selectedShownPoint) {
        const startCoords = path[selectedShownPoint - 1]?.geometry?.coordinates;

        // Should only recalculate previous route

        const res = await getThisRouteLine(startCoords, newCoords);
        if (typeof res !== "string" && res.length > 0) {
          path[selectedShownPoint].routeTo = res;
          path[selectedShownPoint].geometry = {
            ...path[selectedShownPoint].geometry,
            coordinates: newCoords,
          };
          const routeLine = arrayRouteHandler(path);
          handleSetLine(routeLine);
        }
      } else {
        const initCoords = path[selectedShownPoint - 1]?.geometry?.coordinates;
        const lastCoords = path[selectedShownPoint + 1]?.geometry?.coordinates;

        // Should  recalculate previous and next route

        const res1 = await getThisRouteLine(initCoords, newCoords);

        if (typeof res1 !== "string" && res1.length > 0) {
          path[selectedShownPoint].routeTo = res1;
        }
        const res2 = await getThisRouteLine(newCoords, lastCoords);
        if (typeof res2 !== "string" && res2.length > 0) {
          path[selectedShownPoint + 1].routeTo = res2;
        }

        path[selectedShownPoint].geometry = {
          ...path[selectedShownPoint].geometry,
          coordinates: newCoords,
        };

        const routeLine = arrayRouteHandler(path);
        handleSetLine(routeLine);
      }

      if (pointEdit) pointEdit.remove();
      const coords = newCoords;
      setPointEdit(
        new mapboxgl.Marker({
          color: "#2979ff",
          draggable: true,
        })
          .setLngLat(coords)
          .addTo(map.current)
      );
    }
  };

  return (
    <>
      <Grid container justify="space-between" spacing={2}>
        <Grid item xs={12} sm={3}>
          <Button
            variant="outlined"
            onClick={handleResetAll}
            startIcon={<RefreshCw />}
            fullWidth
            size="medium"
          >
            Borrar todo
          </Button>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="outlined"
            onClick={handleRemoveLast}
            startIcon={<Delete />}
            fullWidth
            size="medium"
          >
            Borrar último
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            size="medium"
            startIcon={<Crosshair />}
            fullWidth
            onClick={handleGetGeolocation}
          >
            Mi Ubicación
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} style={{ marginTop: 10 }}>
          <Autocomplete
            options={options}
            value={valueOption}
            filterOptions={(x) => x}
            autoComplete
            includeInputInList
            filterSelectedOptions
            onInputChange={(event, value) => handleInputChange(event, value)}
            onChange={(event: any, newValue: any | null) => {
              setValueOption(newValue);
              if (newValue) {
                handleSearchSelect(newValue);
              }
            }}
            getOptionLabel={(option) => option?.place_name}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar ubicación..."
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} style={{ marginTop: 10 }}>
          <Typography variant="caption">Añadir por coordenadas:</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} style={{ marginTop: 10 }}>
          <TextField
            id="outlined-basic"
            value={valueLat}
            label="Latitud"
            type="number"
            variant="outlined"
            onChange={(e) => {
              if (e) {
                setValueLat(Number(e.target.value));
              }
            }}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ marginTop: 10 }}>
          <TextField
            id="outlined-basic"
            value={valueLng}
            label="Longitud"
            type="number"
            variant="outlined"
            onChange={(e) => {
              if (e) {
                setValueLng(Number(e.target.value));
              }
            }}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2} style={{ marginTop: 10 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAddPrecision}
            fullWidth
          >
            Añadir
          </Button>
        </Grid>
        <Grid item xs={12} sm={2} style={{ marginTop: 10 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleChangeShowExtra}
            fullWidth
          >
            {showExtra ? "Ocultar Puntos Linea" : "Ver Puntos Linea"}
          </Button>
        </Grid>
      </Grid>

      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 20,
        }}
      >
        {showExtra && (
          <div
            style={{
              position: "absolute",
              backgroundColor: "white",
              margin: 5,
              zIndex: 1,
              maxHeight: 480,
              borderRadius: 10,
            }}
          >
            <List className={classes.root}>
              {path.map((item, index) => (
                <li key={`section-${index}`}>
                  <ListItem
                    button
                    style={{
                      backgroundColor:
                        selectedShownPoint === index ? "#2979ffff" : "white",
                    }}
                  >
                    <ListItemIcon
                      onClick={() => {
                        if (selectedShownPoint === index)
                          setShowEditModal(true);
                      }}
                    >
                      {selectedShownPoint === index ? (
                        <Edit color="#fff" />
                      ) : (
                        <ChevronRight />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      onClick={() => handleRoutePointClick(item, index)}
                      primary={`${item?.geometry?.coordinates}`}
                    />
                  </ListItem>
                </li>
              ))}
            </List>
          </div>
        )}
        <div
          ref={mapContainer}
          style={{
            alignContent: "center",
            alignSelf: "center",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 8,
            height: 490,
            width:
              Number(valueWidth) > 970
                ? Number((Number(valueWidth) / 1.6)?.toFixed(0))
                : Number((Number(valueWidth) / 1.2)?.toFixed(0)),
          }}
        />
      </div>

      <Grid container justify="flex-end">
        <Grid item xs={12} sm={4} style={{ marginTop: 20 }}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            fullWidth
            onClick={
              // if (Object.keys(mediaPathChanges).length === 0) {
              handleSavePath
              // } else {
              //   handleClickOpen();
              // }
            }
          >
            Guardar
          </Button>
        </Grid>
        {disableSave && (
          <Grid
            item
            xs={12}
            sm={12}
            container
            alignContent="flex-end"
            justify="flex-end"
          >
            <Typography color="error" variant="subtitle2">
              {"* El mapa debe contar con ruta."}
            </Typography>
          </Grid>
        )}
      </Grid>

      {/* Dialog in case changes in media points */}
      {/* <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            "Se modficarán las coordenadas para los siguientes archivos multimedia"
          }
        </DialogTitle>
        <DialogContent>
          <List aria-label="secondary media points">
            {Object.keys(mediaPathChanges).map((data: any) => (
              <ListItem key={data}>
                <ListItemText primary={readData(data)} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleIgnore} color="primary">
            Ignorar
          </Button>
          <Button onClick={handleDialogSave} color="primary" autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog> */}

      {(selectedShownPoint || selectedShownPoint === 0) && showEditModal && (
        <MapPointEditDialog
          data={path[selectedShownPoint]?.geometry?.coordinates}
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          onConfirm={hanldeConfirmEditModal}
        />
      )}
    </>
  );
});
export default Map;
