import * as types from "../../constants";
import { Action } from "../../models/action";
import { FormState } from "../../models/form_state";

export interface PlacesState {
  places: any[];
  state: FormState;
  error?: string;
  placesVideos?: any;
  placesImages?: undefined;
  imagesState?: FormState;
  imagesError?: string;
  imagesUpload?: any;
  videosState?: FormState;
  videosError?: string;
  videoUpload?: any;
  totalDocs: any;
  lastDoc: any;
  selectedPlace?: any;
  selectedRating: any[];
  stateGetRating: FormState;
  errorGetRating?: string;
  selectedVideoDetails: {
    state: FormState;
    error?: string;
    clickDetails: any[];
    viewsDetails: any[];
  };
  delete?: {
    state: FormState;
    error?: any;
  };
  update?: {
    state: FormState;
    error?: any;
  };
  add?: {
    state: FormState;
    error?: any;
  };
  selected?: {
    state: FormState;
    error?: any;
  };
}

const initialState = {
  places: [],
  state: FormState.Initial,
  placesVideos: null,
  placesImages: null,
  videosState: FormState.Initial,
  imagesState: FormState.Initial,
  totalDocs: undefined,
  lastDoc: undefined,
  selectePlace: null,
  selectedRating: [],
  stateGetRating: FormState.Initial,
  add: { state: FormState.Initial },
  delete: {
    state: FormState.Initial,
  },
  selected: {
    state: FormState.Initial,
  },
  selectedVideoDetails: {
    state: FormState.Initial,
    clickDetails: [],
    viewsDetails: [],
  },
} as PlacesState;

export const placesReducer = (
  state = initialState,
  action: Action
): PlacesState => {
  switch (action.type) {
    case types.PLACES_GET_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        places: action.payload.places,
        totalDocs: action.payload.totalDocs,
        lastDoc: action.payload.lastDoc,
      };
    case types.PLACES_GET_FAILURE:
      return {
        ...state,
        state: FormState.Error,
        error: action.payload,
      };
    case types.PLACES_GET_IS_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.PLACES_DELETE_IS_SUBMITTING:
      return {
        ...state,
        delete: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.PLACES_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          state: FormState.Success,
        },
        places: state.places.filter((x) => x.id !== action.payload.id),
      };
    case types.PLACES_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          state: FormState.Error,
          error: action.payload,
        },
      };
    case types.PLACES_UPDATE_IS_SUBMITTING:
      return {
        ...state,
        update: { state: FormState.Submitting, error: undefined },
      };
    case types.PLACES_UPDATE_SUCCESS:
      return {
        ...state,
        update: { state: FormState.Success },
        selectedPlace: action.payload,
      };
    case types.PLACES_UPDATE_FAILURE:
      return {
        ...state,
        update: { state: FormState.Error, error: action.payload },
      };
    case types.PLACES_SET_SELECTED:
      return {
        ...state,
        selectedPlace: action.payload,
      };
    case types.PLACES_GET_ONE_IS_SUBMITTING:
      return {
        ...state,
        selected: { state: FormState.Submitting, error: undefined },
      };
    case types.PLACES_GET_ONE_SUCCESS:
      return {
        ...state,
        selected: { state: FormState.Success },
        selectedPlace: action.payload,
      };
    case types.PLACES_GET_ONE_FAILURE:
      return {
        ...state,
        selected: { state: FormState.Error, error: action.payload },
      };
    // Calificaciones
    case types.PLACE_GET_RATING_IS_SUBMITTING:
      return {
        ...state,
        stateGetRating: FormState.Submitting,
        errorGetRating: undefined,
      };
    case types.PLACE_GET_RATING_SUCCESS:
      return {
        ...state,
        selectedRating: action.payload,
        stateGetRating: FormState.Success,
      };
    case types.PLACE_GET_RATING_FAILURE:
      return {
        ...state,
        stateGetRating: FormState.Error,
        errorGetRating: action.payload,
      };
    //
    case types.PLACES_CREATE_IS_SUBMITTING:
      return {
        ...state,
        add: { state: FormState.Submitting, error: undefined },
      };
    case types.PLACES_CREATE_SUCCESS:
      return {
        ...state,
        add: { state: FormState.Success },
      };
    case types.PLACES_CREATE_FAILURE:
      return {
        ...state,
        add: { state: FormState.Error, error: action.payload },
      };
    case types.PLACES_GET_VIDEOS_IS_SUBMITTING:
      return {
        ...state,
        videosState: FormState.Submitting,
        videosError: undefined,
      };
    case types.PLACES_GET_VIDEOS_SUCCESS:
      return {
        ...state,
        videosState: FormState.Success,
        placesVideos: action.payload,
      };
    case types.PLACES_GET_VIDEOS_FAILURE:
      return {
        ...state,
        videosState: FormState.Error,
        videosError: action.payload,
      };
    case types.PLACES_VIDEO_UPLOAD:
      return {
        ...state,
        videoUpload: action.payload,
      };
    case types.PLACES_GET_IMAGES_IS_SUBMITTING:
      return {
        ...state,
        imagesState: FormState.Submitting,
        imagesError: undefined,
      };
    case types.PLACES_GET_IMAGES_SUCCESS:
      return {
        ...state,
        imagesState: FormState.Success,
        placesImages: action.payload,
      };
    case types.PLACES_GET_IMAGES_FAILURE:
      return {
        ...state,
        imagesState: FormState.Error,
        imagesError: action.payload,
      };
    case types.PLACES_GET_IMAGES_UPLOAD:
      return {
        ...state,
        imagesUpload: action.payload,
      };
    // Places selected video details
    case types.PLACE_GET_VIEW_CLICK_SUBMITTING:
      return {
        ...state,
        selectedVideoDetails: {
          state: FormState.Submitting,
          error: undefined,
          clickDetails: [],
          viewsDetails: [],
        },
      };
    case types.PLACE_GET_VIEW_CLICK_SUCCESS:
      return {
        ...state,
        selectedVideoDetails: {
          state: FormState.Success,
          clickDetails: action.payload.clickDetails,
          viewsDetails: action.payload.viewsDetails,
        },
      };
    case types.PLACE_GET_VIEW_CLICK_FAILURE:
      return {
        ...state,
        selectedVideoDetails: {
          state: FormState.Error,
          error: action.payload,
          clickDetails: [],
          viewsDetails: [],
        },
      };
    default:
      return state;
  }
};
