import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { phoneValidation } from "../../utils/utils";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { DialogAction } from "../../models/dialog_state";
import { RootState } from "../../redux/reducers/rootReducer";
import { ITourGuidesState } from "../../redux/reducers/tourGuidesReducer";
import { getTourGuideRegions } from "../../redux/actions/tourGuidesActions";
import { useDispatch, useSelector } from "react-redux";
import { number } from "yup-locales/dist/locales/pt";
import { Autocomplete } from "@material-ui/lab";

type ObjectType = {
  Nombre: string;
  Apellido: string;
  Codigo: string;
  Region: string;
  Telefono: string;
  Email: string;
  Descripcion: string;
};

interface DialogProps {
  open: boolean;
  onClose: () => void;
  onAccept: (values: any, action: DialogAction) => void;
  isLoading: boolean;
  action: DialogAction;
  object?: ObjectType;
  title?: string;
}

export const DialogEditAndNew: React.FC<DialogProps> = ({
  open,
  onClose,
  onAccept,
  isLoading,
  action,
  object,
  title = "",
}) => {
  const dispatch = useDispatch();
  const { toursRegions } = useSelector<RootState, ITourGuidesState>(
    (state) => state.tourGuidesReducer
  );
  const [valueRegion, setValueRegion] = useState<string | null>(null);
  const {
    handleSubmit,
    values,
    handleChange,
    touched,
    errors,
    resetForm,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      Nombre: "",
      Apellido: "",
      Telefono: 0,
      Email: "",
      Descripcion: "",
      Region: "",
    },
    onSubmit: (values) => {
      const numCode = "569";
      const num = String(values.Telefono);
      values.Telefono = Number.parseInt(numCode + num);
      onAccept(values, action);
    },
    validationSchema: yup.object({
      Nombre: yup.string().required("Primer nombre requerido"),
      Apellido: yup.string().required("Apellido es requerido"),
      Telefono: yup
        .number()
        .test("len", "Número no válido", (val: any) => {
          try {
            return val.toString().length === 8;
          } catch {
            return false;
          }
        })
        .required("Número es requerido"),
      Email: yup
        .string()
        .email("Requiere correo válido")
        .required("Email es requerido"),
      Descripcion: yup.string().required("Descripción es requerida"),
      Region: yup.string().required("Región es requerida"),
    }),
  });

  useEffect(() => {
    dispatch(getTourGuideRegions());
  }, [dispatch]);

  useEffect(() => {
    if (action === "New") {
      resetForm();
    } else if (action === "Edit" && object) {
      setValues({
        Nombre: object.Nombre,
        Apellido: object.Apellido,
        Telefono: Number.parseInt(String(object.Telefono).substring(3)),
        Email: object.Email,
        Descripcion: object.Descripcion,
        Region: object.Region,
      });
    } else {
      console.log("Error: ", action, object);
    }
  }, [open, resetForm, setValues, object, action]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            onClose();
          }
        }}
        aria-labelledby="add-and-update-dialog-title"
      >
        <DialogTitle id="add-and-update-dialog-title">
          {action === "Edit" ? `Editar ${title}` : `Nuevo ${title}`}
        </DialogTitle>

        <form noValidate onSubmit={handleSubmit}>
          <DialogContent>
            {!isLoading ? (
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoFocus
                    id="Nombre"
                    label="Nombre"
                    type="text"
                    autoComplete="off"
                    value={values.Nombre}
                    name="Nombre"
                    onChange={handleChange}
                    error={touched.Nombre && Boolean(errors.Nombre)}
                    helperText={touched.Nombre && errors.Nombre}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Apellido"
                    label="Apellido"
                    type="text"
                    autoComplete="off"
                    value={values.Apellido}
                    name="Apellido"
                    onChange={handleChange}
                    error={touched.Apellido && Boolean(errors.Apellido)}
                    helperText={touched.Apellido && errors.Apellido}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id="combo-box-demo"
                    value={
                      object
                        ? toursRegions.find((x) => x.Nombre === object?.Region)
                        : valueRegion
                    }
                    options={toursRegions}
                    getOptionLabel={(option) => option.Nombre}
                    onChange={(event: any, newValue: any | null) => {
                      setValueRegion(newValue);
                      if (newValue) {
                        setFieldValue("Region", newValue.Nombre);
                      } else {
                        setFieldValue("Region", "");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Regiones"
                        variant="outlined"
                        error={touched.Region && Boolean(errors.Region)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Telefono"
                    label="Teléfono"
                    type="number"
                    autoComplete="off"
                    placeholder="XXXXXXXX"
                    value={values.Telefono === 0 ? null : values.Telefono}
                    name="Telefono"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+569</InputAdornment>
                      ),
                    }}
                    error={touched.Telefono && Boolean(errors.Telefono)}
                    helperText={touched.Telefono && errors.Telefono}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="email"
                    label="Email"
                    type="email"
                    autoComplete="off"
                    value={values.Email}
                    name="Email"
                    onChange={handleChange}
                    error={touched.Email && Boolean(errors.Email)}
                    helperText={touched.Email && errors.Email}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="descripcion"
                    label="Descripción"
                    autoComplete="off"
                    name="Descripcion"
                    multiline
                    rows={4}
                    value={values.Descripcion}
                    onChange={handleChange}
                    fullWidth
                    error={touched.Descripcion && Boolean(errors.Descripcion)}
                    helperText={touched.Descripcion && errors.Descripcion}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            ) : (
              <CircularProgress
                disableShrink
                style={{
                  marginTop: "15%",
                  marginLeft: "35%",
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={isLoading}>
              {action === "Edit" ? "Editar" : "Agregar"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};
