import { Action } from "../../models/action";
import * as types from "../../constants";

export interface IIdiomasState {
  idiomas: any[];
  isLoading: boolean;
  error?: string;
}

const initialState = {
  idiomas: [],
  isLoading: false,
} as IIdiomasState;

export const idiomasReducer = (
  state = initialState,
  action: Action
): IIdiomasState => {
  switch (action.type) {
    case types.IDIOMA_GET_DOCS:
      return {
        ...state,
        idiomas: action.payload,
      };
    case types.IDIOMA_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.IDIOMA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
