import {
  CardContent,
  Fade,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../../constants";
import { useTable } from "../../hooks/useTable";
import {
  getMoreTourSales,
  getTourSales,
} from "../../redux/actions/salesAction";
import { getTourGuide } from "../../redux/actions/tourGuidesActions";
import { IAuthState } from "../../redux/reducers/authReducer";
import { RootState } from "../../redux/reducers/rootReducer";
import { ITourGuidesState } from "../../redux/reducers/tourGuidesReducer";
import { IToursState } from "../../redux/reducers/toursReducer";
import { useStyles } from "../../theme/useStyles";
import { numberWithDot, timesStampToDateFormatted } from "../../utils/utils";
import { TableSkeleton } from "../TableSkeleton";

interface Props {
  selected: any;
  totalDocs: any;
  lastDoc: any;
}

const DetalleVentas: React.FC<Props> = React.memo(
  ({ selected, totalDocs, lastDoc }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const { user } = useSelector<RootState, IAuthState>(
      (state) => state.authReducer
    );
    const {
      edit: { selectedTour },
    } = useSelector<RootState, IToursState>((state) => state.toursReducer);
    const {
      edit: { selectedGuide },
    } = useSelector<RootState, ITourGuidesState>(
      (state) => state.tourGuidesReducer
    );
    const { limit, page, handleLimitChange, handlePageChange } = useTable({
      limit: TABLE_LIMIT_DEFAULT,
      onLimitChange: (event, newLimit) => {
        if (newLimit > selected.length) {
          dispatch(getTourSales(newLimit));
        }
      },
      onPageChange: (newPage, oldPage, limit) => {
        if (selected.length < totalDocs && newPage > oldPage) {
          dispatch(getMoreTourSales(limit));
        }
      },
    });

    useEffect(() => {
      dispatch(getTourGuide(selectedTour.Guia.Id));
    }, [dispatch]);

    return (
      <React.Fragment>
        <CardContent>
          <TableContainer className={classes.tableContainer}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Usuario</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Idioma</TableCell>
                  <TableCell>Fecha de Compra</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Valor Comisión</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!false ? (
                  selected
                    .slice(page * limit, page * limit + limit)
                    .map((data: any) => (
                      <Fade key={data.id} in={true}>
                        <TableRow hover className={classes.styledRow}>
                          <TableCell>
                            {data.Usuario?.Nombre +
                              " " +
                              data.Usuario?.Apellido}
                          </TableCell>
                          <TableCell>{data?.Tour?.TipoTour}</TableCell>
                          <TableCell>
                            {data?.Tour?.Idioma || "Español"}
                          </TableCell>
                          <TableCell>
                            {timesStampToDateFormatted(
                              new Date(data.FechaCreacion.seconds * 1000)
                            )}
                          </TableCell>
                          <TableCell>
                            {data?.Tour?.Precio === 0 ||
                            data?.Tour?.Precio === "0" ||
                            !data?.Tour?.Precio
                              ? "Gratuito"
                              : data?.Estado}
                          </TableCell>
                          <TableCell>
                            {data?.Estado === "Pendiente"
                              ? "No Aplica"
                              : user?.tipoUsuario === "Guia"
                              ? numberWithDot(
                                  (data?.Tour?.Precio *
                                    (selectedGuide?.Porcentaje || 50)) /
                                    100
                                ) === 0
                                ? numberWithDot(
                                    (data?.Tour?.Precio *
                                      (selectedGuide?.Porcentaje || 50)) /
                                      100
                                  )
                                : "No Aplica"
                              : "Comisión " +
                                (numberWithDot(
                                  (data?.Tour?.Precio *
                                    (selectedGuide?.Porcentaje || 50)) /
                                    100
                                ) === 0
                                  ? numberWithDot(
                                      (data?.Tour?.Precio *
                                        (selectedGuide?.Porcentaje || 50)) /
                                        100
                                    )
                                  : "No Aplica")}
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))
                ) : (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalDocs}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_LIMITS}
          />
        </CardContent>
      </React.Fragment>
    );
  }
);
export default DetalleVentas;
