import {
  Box,
  Button,
  CardContent,
  Grid,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React, { useEffect } from "react";
import { DownloadCloud } from "react-feather";
import { useDispatch } from "react-redux";
import { getPlaceCalificaciones } from "../../redux/actions/placesActions";
import { useStyles } from "../../theme/useStyles";
import { exportToCsv } from "../../utils/utils";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

interface Props {
  place?: any;
  id?: string;
  save: (values: any) => void;
}

export const PlacesScore: React.FC<Props> = React.memo(({ place, save }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  let totalRateServicio = 5;
  let totalRateHigiene = 5;
  let totalRateCalidadPrecio = 5;
  let totalRatePromedioAll = 5;

  place.map((data: any) => {
    totalRateServicio = totalRateServicio + data?.CalidadServicio;
    totalRateHigiene = totalRateHigiene + data?.Higiene;
    totalRateCalidadPrecio = totalRateCalidadPrecio + data?.CalidadPrecio;
    totalRatePromedioAll = totalRatePromedioAll + data?.Promedio;
  });

  const lengthRating = (place?.length || 0) + 1;

  const resultRateServicio = totalRateServicio / lengthRating;
  const resultRateHigiene = totalRateHigiene / lengthRating;
  const resultRateCalidadPrecio = totalRateCalidadPrecio / lengthRating;
  const resultRatePromedioAll = totalRatePromedioAll / lengthRating;

  return (
    <React.Fragment>
      <CardContent>
        <Grid container justify="space-between" spacing={4}>
          <Grid container item xs={12} sm={6} spacing={4}>
            <Grid item xs={12} sm={12}>
              <Typography
                variant="subtitle2"
                style={{
                  textAlign: "center",
                }}
              >
                Calidad del Servicio: {Number(resultRateServicio?.toFixed(1))}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} justify="center">
              <Rating
                name="read-only"
                precision={0.5}
                value={Number(resultRateServicio?.toFixed(1))}
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography
                variant="subtitle2"
                style={{
                  textAlign: "center",
                }}
              >
                Higiene: {Number(resultRateHigiene?.toFixed(1))}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} justify="center">
              <Rating
                name="read-only"
                precision={0.5}
                value={Number(resultRateHigiene?.toFixed(1))}
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography
                variant="subtitle2"
                style={{
                  textAlign: "center",
                }}
              >
                Calidad Precio: {Number(resultRateCalidadPrecio?.toFixed(1))}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} justify="center">
              <Rating
                name="read-only"
                precision={0.5}
                value={Number(resultRateCalidadPrecio?.toFixed(1))}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={6}
            spacing={2}
            direction="row"
            justify="center"
          >
            <Grid
              container
              item
              xs={12}
              sm={12}
              direction="row"
              justify="center"
            >
              <Grid item>
                <Typography variant="subtitle2">
                  Promedio General: {Number(resultRatePromedioAll?.toFixed(1))}
                </Typography>
                <Rating
                  size="large"
                  name="read-only"
                  precision={0.5}
                  value={Number(resultRatePromedioAll?.toFixed(1))}
                  readOnly
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle2">
                Numero de calificaciones: {lengthRating - 1}{" "}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<DownloadCloud />}
                disabled={lengthRating - 1 === 0}
                onClick={() => {
                  exportToCsv(
                    "calificaciones_comercio.csv",
                    place,
                    [
                      "CalidadServicio",
                      "Higiene",
                      "CalidadPrecio",
                      "Promedio",
                      "Comentario",
                      "NombreCliente",
                      "FechaCreacion",
                    ],
                    [
                      "Calidad Servicio",
                      "Higiene",
                      "Calidad Precio",
                      "Promedio",
                      "Comentario",
                      "Nombre Cliente",
                      "Fecha Creacion",
                    ]
                  );
                }}
              >
                Descargar CSV
              </Button>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<DownloadCloud />}
                disabled={lengthRating - 1 === 0}
                onClick={() => {
                  exportToCsv(
                    "calificaciones_comercio.csv",
                    place,
                    [
                      "CalidadServicio",
                      "Higiene",
                      "CalidadPrecio",
                      "Promedio",
                      "Comentario",
                      "NombreCliente",
                      "FechaCreacion",
                    ],
                    [
                      "Calidad Servicio",
                      "Higiene",
                      "Calidad Precio",
                      "Promedio",
                      "Comentario",
                      "Nombre Cliente",
                      "Fecha Creacion",
                    ],
                    ";"
                  );
                }}
              >
                Descargar CSV 2
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </React.Fragment>
  );
});
