import React from "react";
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { PlacesState } from "../redux/reducers/placesReducer";
import { FormState } from "../models/form_state";
import { Eye, MousePointer } from "react-feather";

interface Props {
  open: boolean;
  title: string;
  onClose: () => void;
}

export const ViewsClicksDialog: React.FC<Props> = ({
  title,
  onClose,
  open,
}) => {
  const {
    selectedVideoDetails: { state, viewsDetails, clickDetails },
  } = useSelector<RootState, PlacesState>((state) => state.placesReducer);
  return (
    <Dialog open={open} onClose={onClose} maxWidth={"xs"} fullWidth={true}>
      {state === FormState.Submitting ? (
        <DialogContent>
          <div
            style={{
              alignItems: "center",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CircularProgress />
          </div>
        </DialogContent>
      ) : (
        <>
          <DialogTitle id="dialog-confirm-title">{title}</DialogTitle>
          <List>
            <ListItem
              button
              // onClick={() => handleListItemClick(email)}
            >
              <ListItemAvatar>
                <Avatar>
                  <Eye />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                style={{ marginLeft: 15 }}
                primary={" Vistas: " + viewsDetails.length}
              />
            </ListItem>
            <ListItem
              button
              // onClick={() => handleListItemClick(email)}
            >
              <ListItemAvatar>
                <Avatar>
                  <MousePointer />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                style={{ marginLeft: 15 }}
                primary={" Clicks: " + clickDetails.length}
              />
            </ListItem>
          </List>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
