import { AppThunk } from "../../models/app-thunk";
import * as types from "../../constants";
import { Action } from "../../models/action";
import { firestore } from "../../firebase/firebase";
import firebase from "firebase/app";

const DEFAULT_ORDER_BY = "Nombre";
const DEFAULT_COLLECTION_NAME = "Regiones";

export const getRegions = (): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY)
        .get();

      dispatch(setRegions(mapRegions(response)));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

const mapRegions = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  try {
    let regions: any[] = [];
    response.docs.forEach((doc) => {
      let data = mapRegion(doc);
      regions.push(data);
    });
    return regions;
  } catch (error) {
    throw error;
  }
};

const mapRegion = (doc: any) => {
  let data = doc.data();
  data.id = doc.id;
  return data;
};

const setRegions = (regions: any[]): Action => ({
  type: types.REGIONS_GET_DOCS,
  payload: regions,
});

const isLoading = (isLoading: boolean): Action => ({
  type: types.REGIONS_LOADING,
  payload: isLoading,
});

const setError = (error: string): Action => ({
  type: types.REGIONS_FAILURE,
  payload: error,
});
