import { Action } from "../../models/action";
import * as types from "../../constants";

export interface IClientsState {
  clients: any[];
  isLoading: boolean;
  totalDocs: number;
  lastDoc?: any;
  error?: string;
  edit: {
    isLoading: boolean;
    selectedClient?: any;
  };
  filtroNombre: string;
}

const initialState = {
  clients: [],
  isLoading: false,
  totalDocs: 0,
  edit: {
    isLoading: false,
  },
  filtroNombre: "",
} as IClientsState;

export const clientsReducer = (
  state = initialState,
  action: Action
): IClientsState => {
  switch (action.type) {
    case types.CLIENTS_GET_DOCS:
      return {
        ...state,
        clients: action.payload,
      };
    case types.CLIENTS_SET_SELECTED:
      return {
        ...state,
        edit: {
          ...state.edit,
          selectedClient: action.payload,
        },
      };
    case types.CLIENTS_UPDATE_LOADING:
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: action.payload,
        },
      };
    case types.CLIENTS_ADD_DOCS:
      return {
        ...state,
        clients: [...state.clients, ...action.payload],
      };
    case types.CLIENTS_UPDATE_DOC:
      return {
        ...state,
        clients: state.clients.map((x) =>
          x.id === action.payload.id ? { ...x, ...action.payload } : x
        ),
      };
    case types.CLIENTS_REMOVE_DOC:
      return {
        ...state,
        clients: state.clients.filter((x) => x.id !== action.payload.id),
      };
    case types.CLIENTS_SET_LAST_DOC:
      return {
        ...state,
        lastDoc: action.payload,
      };
    case types.CLIENTS_SET_TOTAL_DOCS:
      return {
        ...state,
        totalDocs: action.payload,
      };
    case types.CLIENTS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.CLIENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case types.USER_SET_NAME_FILTER:
      return {
        ...state,
        filtroNombre: action.payload,
      };
    default:
      return state;
  }
};
