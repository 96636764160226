import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Fade,
  Grid,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import ExtensionIcon from "@material-ui/icons/Extension";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import React, { useEffect, useState } from "react";
import {
  AlertCircle,
  Edit as EditIcon,
  PlayCircle,
  Trash2 as DeleteIcon,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { MAPS_API_KEY } from "../../constants";
import { FormState } from "../../models/form_state";
import {
  addTourIndexData,
  getTourIndexData,
  removeTourIndexPoint,
  updateTourIndexPoint,
  updateTourVideosSize,
} from "../../redux/actions/toursActions";
import { RootState } from "../../redux/reducers/rootReducer";
import { IToursState } from "../../redux/reducers/toursReducer";
import { useStyles as TableStyles } from "../../theme/useStyles";
import logo from "../../vendor/logoTrave.png";
import { ConfirmDialog } from "../ConfirmDialog";
import Map from "../Maps/NewMap";
import { TableSkeleton } from "../TableSkeleton";
import { VideoDetailDialog } from "./VideoDetailDialog";
import { getDistance } from "geolib";

// VerticalTabs interface
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface Props {
  selectedTour?: any;
}

interface TabsProps {
  tour?: any;
}
interface TableProps {
  tourId: string;
  codigoLenguage: string;
}

interface DialogMapProps {
  open: boolean;
  onClose: () => void;
}

// VerticalTabs function
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

//
function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

// VerticalTabs function
function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const DataTable: React.FC<TableProps> = React.memo(
  ({ tourId, codigoLenguage }) => {
    const dispatch = useDispatch();
    const tableClasses = TableStyles();

    const [openDialog, setOpenDialog] = useState(false);
    const [dataUpdate, setDataUpdate] = useState<any>(null);
    const [isDelete, setIsDelete] = useState<any | undefined>(null);

    const [openConfirm, setOpenConfirm] = useState(false);

    const [openPointsMap, setOpenPointMap] = useState(false);

    const [openMultimediaDialog, setOpenMultimediaDialog] = useState(false);

    const [selectedData, setSelectedData] = useState<any | undefined>(
      undefined
    );

    let totalSize = 0; //MB
    let totalDuration = 0; //Seconds

    const handleUpdate = (values: any, file: any) => {
      dispatch(updateTourIndexPoint(tourId, codigoLenguage, values, file));
      setOpenDialog(false);
    };

    const handleDelete = () => {
      setOpenConfirm(false);
      dispatch(
        removeTourIndexPoint(tourId, codigoLenguage, isDelete.id, isDelete)
      );
    };

    const {
      index: { selectedIndex, isLoading },
    } = useSelector<RootState, IToursState>((state) => state.toursReducer);

    useEffect(() => {
      dispatch(getTourIndexData(codigoLenguage, tourId));
    }, [codigoLenguage]);

    useEffect(() => {
      selectedIndex.forEach((x) => {
        totalSize = totalSize + x.size;
        totalDuration = totalDuration + x.duration;
      });

      let distance = 0; // Save meters

      if (selectedIndex.length > 1) {
        distance = getDistance(
          {
            latitude: selectedIndex[0].lat,
            longitude: selectedIndex[0].lng,
          },
          {
            latitude: selectedIndex[selectedIndex.length - 1].lat,
            longitude: selectedIndex[selectedIndex.length - 1].lng,
          }
        );
      }

      dispatch(
        updateTourVideosSize(
          { id: tourId },
          {
            totalSize: totalSize,
            distance: distance,
            totalDuration: totalDuration,
          },
          codigoLenguage
        )
      );
    }, [selectedIndex]);
    return (
      <>
        <TableContainer className={tableClasses.tableContainer}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Posición</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Tramo</TableCell>
                <TableCell>Duración</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading ? (
                selectedIndex
                  .sort((a, b) => a.Indice - b.Indice)
                  // .slice(page * limit, page * limit + limit)
                  .map((data: any) => (
                    <Fade key={data.id} in={true}>
                      <TableRow hover className={tableClasses.styledRow}>
                        <TableCell>
                          <Box display="flex">
                            <Box>
                              <Typography style={{ fontSize: 12 }}>
                                {data?.Indice}
                              </Typography>
                            </Box>

                            <Box>
                              {data?.Prosecute && (
                                <Tooltip title="Video Convertido">
                                  <AlertCircle
                                    color="orange"
                                    style={{
                                      height: 17,
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>{data.Nombre}</TableCell>
                        <TableCell>{data?.TipoMultimedia}</TableCell>
                        <TableCell>
                          <Box>Latitud: {data.lat}</Box>
                          <Box>Longitud: {data.lng}</Box>
                        </TableCell>
                        <TableCell>
                          {(data.duration / 60).toFixed(1)} Minutos
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Editar">
                            <IconButton
                              color="primary"
                              size="small"
                              onClick={() => {
                                setDataUpdate(data);
                                setOpenDialog(true);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Reproducir">
                            <IconButton
                              color="primary"
                              size="small"
                              onClick={() => {
                                setOpenMultimediaDialog(!openMultimediaDialog);
                                setSelectedData(data);
                              }}
                            >
                              <PlayCircle />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Eliminar">
                            <IconButton
                              size="small"
                              onClick={() => {
                                setIsDelete(data);
                                setOpenConfirm(true);
                              }}
                            >
                              <DeleteIcon color="red" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </Fade>
                  ))
              ) : (
                <TableSkeleton colSpan={10} limit={10} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Grid
          container
          justify="flex-end"
          item
          xs={12}
          sm={12}
          style={{ marginTop: 30 }}
        >
          <Button
            disabled={selectedIndex.length === 0}
            variant="outlined"
            onClick={() => setOpenPointMap(!openPointsMap)}
          >
            Desplegar mapa con los puntos
          </Button>
        </Grid> */}
        <VideoDetailDialog
          open={openDialog}
          data={dataUpdate}
          title={"Multimedia"}
          type="edit"
          onClose={() => {
            setOpenDialog(false);
            setDataUpdate(null);
          }}
          onAccept={(values, file) => handleUpdate(values, file)}
        />
        <ConfirmDialog
          title={"¿Desea eliminar multimedia?"}
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
          onConfirmText={"Eliminar"}
          onConfirm={() => handleDelete()}
        />
        {/* <MapDialog
          open={openPointsMap}
          onClose={() => setOpenPointMap(!openPointsMap)}
        /> */}
        <Dialog
          open={openMultimediaDialog}
          onClose={() => setOpenMultimediaDialog(!openMultimediaDialog)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <video width="500" height="300" controls>
            <source src={selectedData?.link} />
            Your browser does not support the video tag.
          </video>

          <Button
            onClick={() => setOpenMultimediaDialog(!openMultimediaDialog)}
            color="primary"
            autoFocus
          >
            Cerrar
          </Button>
        </Dialog>
      </>
    );
  }
);

const VerticalTabs: React.FC<TabsProps> = React.memo(({ tour }) => {
  const dispatch = useDispatch();
  // Tabs util
  const [value, setValue] = React.useState(0);

  // Exception loading data
  const {
    index: { isLoading },
    videoUploadProgress,
    updatePointState,
  } = useSelector<RootState, IToursState>((state) => state.toursReducer);

  const [uploadValue, setUploadValue] = useState<any | number>(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [tourData, setTourData] = useState({
    Codigo: "",
    TourId: "",
    GuideId: "",
  });

  const handleUploadVideo = (values: any, file: any) => {
    setOpenDialog(false);
    values.Codigo = tourData?.Codigo;
    dispatch(addTourIndexData(values, file, tourData.TourId, tourData.GuideId));
  };

  // Tabs util
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setUploadValue(videoUploadProgress);
  }, [videoUploadProgress]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={1}>
          <Tabs
            style={{ width: "80px" }}
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
          >
            {tour.Idiomas
              ? tour?.Idiomas.map((data: any, index: number) => (
                  <Tab
                    style={{
                      paddingInlineStart: "20px",
                      marginInlineStart: "-45px",
                    }}
                    label={tour.Traducciones[data].Idioma}
                    {...a11yProps(index)}
                  />
                ))
              : ""}
          </Tabs>
        </Grid>
        <Grid item sm={11}>
          {tour.Idiomas
            ? tour?.Idiomas.map((data: any, index: number) => (
                <TabPanel value={value} index={index}>
                  {isLoading || updatePointState === FormState.Submitting ? (
                    <Grid container item sm={12}>
                      <div style={{ width: "100%" }}>
                        <LinearProgressWithLabel value={uploadValue} />
                      </div>
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid
                    style={{ marginTop: 20 }}
                    container
                    justify="flex-end"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={3} style={{ margin: 10 }}>
                      <Button
                        id="AddPunto"
                        fullWidth
                        variant="contained"
                        component="label"
                        color="primary"
                        onClick={() => {
                          setTourData({
                            Codigo: data,
                            TourId: tour.id,
                            GuideId: tour.Guia.Id,
                          });
                          setOpenDialog(true);
                        }}
                      >
                        {!isLoading ? (
                          "Subir Multimedia"
                        ) : (
                          <CircularProgress color="inherit" size={23} />
                        )}
                      </Button>
                      <Typography color="error">
                        *Solo se adminten formatos MP4 o MP3
                      </Typography>
                    </Grid>
                  </Grid>
                  <CardContent>
                    <DataTable tourId={tour.id} codigoLenguage={data} />
                  </CardContent>
                </TabPanel>
              ))
            : ""}
        </Grid>
      </Grid>
      <VideoDetailDialog
        open={openDialog}
        title={"Multimedia"}
        onClose={() => {
          setOpenDialog(false);
        }}
        onAccept={(values, file) => handleUploadVideo(values, file)}
      />
    </>
  );
});

const TourDetailVideos: React.FC<Props> = React.memo(({ selectedTour }) => {
  const { mediaRecomendations } = useSelector<RootState, IToursState>(
    (state) => state.toursReducer
  );
  return (
    <React.Fragment>
      <CardContent>
        {selectedTour.Idiomas ? (
          <>
            <Card>
              <Box mt={0} mb={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} container justify="flex-start">
                    <Chip
                      icon={<AudiotrackIcon />}
                      label="Recomendaciones para Audios"
                      clickable
                      color="primary"
                      component="a"
                      href={mediaRecomendations?.audio}
                      target="_blank"
                      variant="outlined"
                      size="medium"
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} container justify="flex-start">
                    <Chip
                      icon={<OndemandVideoIcon />}
                      label="Recomendaciones para Videos"
                      clickable
                      color="primary"
                      component="a"
                      href={mediaRecomendations?.video}
                      target="_blank"
                      variant="outlined"
                      size="medium"
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} container justify="flex-start">
                    <Chip
                      icon={<ExtensionIcon />}
                      label="Herramientas Recomendadas"
                      clickable
                      color="primary"
                      component="a"
                      href="https://www.adobe.com/es/express/feature"
                      target="_blank"
                      variant="outlined"
                      size="medium"
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>
            <VerticalTabs tour={selectedTour} />
          </>
        ) : (
          <Grid container justify="center">
            <Grid item xs={12} sm={12} container justify="center">
              <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                Añade un idioma y comienza a crear tours. ☺
              </div>
            </Grid>
            <Grid item xs={12} sm={12} container justify="center">
              <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                <img src={logo} alt="logo" />
              </div>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </React.Fragment>
  );
});
export default TourDetailVideos;

const MapDialog: React.FC<DialogMapProps> = React.memo(({ open, onClose }) => {
  const {
    index: { selectedIndex },
  } = useSelector<RootState, IToursState>((state) => state.toursReducer);

  return (
    <Dialog fullWidth={true} maxWidth="md" open={open}>
      <DialogContent>
        <Map
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${MAPS_API_KEY}&libraries=geometry,google&callback=google`}
          containerElement={
            <div style={{ height: 420, bottom: 0, left: 0 }}></div>
          }
          mapElement={<div style={{ height: "100%" }}></div>}
          loadingElement={<p>Cargando...</p>}
          path={selectedIndex}
        />
        * Número indica índice del punto.
      </DialogContent>
      <Button onClick={onClose} color="primary">
        Cerrar
      </Button>
    </Dialog>
  );
});
