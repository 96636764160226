import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";

import { ContentCard } from "../components/TourGuidesComponent/ContentCard";
import { Add as AddIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { ITourGuidesState } from "../redux/reducers/tourGuidesReducer";
import {
  getTotalDocs,
  getTourGuides,
  updateTourGuide,
  updateTourGuideBlock,
  deleteTourGuide,
  createTourGuide,
} from "../redux/actions/tourGuidesActions";
import { openSnack } from "../redux/actions/uiActions";
import { SnackState } from "../models/snack-state";
import { exportToCsv } from "../utils/utils";
import { DownloadCloud } from "react-feather";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const TourGuides = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { isLoading, tourGuides, totalDocs } = useSelector<
    RootState,
    ITourGuidesState
  >((state) => state.tourGuidesReducer);

  useEffect(() => {
    dispatch(getTourGuides());
    // dispatch(getTotalDocs());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Helmet title="Guias" />
      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Guías
          </Typography>
          {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Guías</Typography>
          </Breadcrumbs> */}
        </Grid>
        <Grid item>
          <Box display="flex">
            <Box order={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(openSnack("Descargando CSV", SnackState.SUCCESS));
                  exportToCsv(
                    "guiasdetours.csv",
                    tourGuides,
                    [
                      "Nombre",
                      "Apellido",
                      "Calificacion",
                      "Region",
                      "Email",
                      "Telefono",
                    ],
                    [
                      "Nombre",
                      "Apellido",
                      "Calificacion",
                      "Region",
                      "Email",
                      "Telefono",
                    ]
                  );
                }}
              >
                <DownloadCloud />
                Descargar CSV
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(openSnack("Descargando CSV", SnackState.SUCCESS));
                  exportToCsv(
                    "guiasdetours.csv",
                    tourGuides,
                    [
                      "Nombre",
                      "Apellido",
                      "Calificacion",
                      "Region",
                      "Email",
                      "Telefono",
                    ],
                    [
                      "Nombre",
                      "Apellido",
                      "Calificacion",
                      "Region",
                      "Email",
                      "Telefono",
                    ],
                    ";"
                  );
                }}
              >
                <DownloadCloud />
                Descargar CSV2
              </Button>
            </Box>
            <Box order={2} ml={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
              >
                <AddIcon />
                Nuevo Guía
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard
            key="TourGuides"
            isLoading={isLoading}
            name="TourGuide"
            array={tourGuides}
            totalDocs={totalDocs}
            openNewDialog={open}
            onClose={() => setOpen(false)}
            onAcceptNewEditDialog={(values, action, selected) => {
              if (action === "New") {
                dispatch(createTourGuide(values));
              } else if (action === "Edit") {
                dispatch(updateTourGuide(values, selected.id));
              }
            }}
            onBlockGuideDialog={(values, selected) => {
              dispatch(updateTourGuideBlock(values, selected.id));
            }}
            onConfirmDeleteDialog={(selected) => {
              dispatch(deleteTourGuide(selected));
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
