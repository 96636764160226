import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import mapboxgl from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import { Crosshair } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { MAPBOX_TOKEN } from "../../constants";
import { searchMapPlaces } from "../../redux/actions/mapActions";
import { IMapState } from "../../redux/reducers/mapReducer";
import { RootState } from "../../redux/reducers/rootReducer";

mapboxgl.accessToken = MAPBOX_TOKEN;

const Map = (props: any) => {
  const { selected, onSave } = props;
  const dispatch = useDispatch();

  let img = document.createElement("div");
  img.style.backgroundImage = "url(/static/mapIcon2.png)";
  img.style.backgroundSize = "contain";
  img.style.width = "20px";
  img.style.height = "70px";
  img.style.cursor = "pointer";
  img.style.backgroundRepeat = "no-repeat";
  img.style.backgroundPositionY = "-2px";

  const { places } = useSelector<RootState, IMapState>(
    (state) => state.mapReducer
  );

  const mapContainer = useRef<any>(undefined);
  const map = useRef<any>(undefined);
  const [lng, setLng] = useState(
    selected?.lng ? selected?.lng : -70.8839759435348
  );
  const [lat, setLat] = useState(
    selected?.lat ? selected?.lat : -33.43262156024644
  );
  const zoom = 8;

  const [marker, setMarker] = useState<mapboxgl.Marker>();

  const [options, setOptions] = useState<any[]>([]);
  const [valueOption, setValueOption] = useState<any>(null);

  const [valueLat, setValueLat] = useState<number | undefined>(undefined);
  const [valueLng, setValueLng] = useState<number | undefined>(undefined);

  const [valueWidth, setValueWidth] = useState<Number>(window.innerWidth);
  function updateDimensions() {
    setValueWidth(window.innerWidth);
  }
  window.addEventListener("resize", updateDimensions);

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });

    map.current.addControl(new mapboxgl.NavigationControl());

    map.current.on("load", () => {
      if (selected?.lng && selected?.lat) {
        changeMarker([lng, lat]);
      }
    });

    map.current.on("click", (e: any) => {
      onSave([e?.lngLat?.lng, e?.lngLat?.lat]);

      changeMarker([e?.lngLat?.lng, e?.lngLat?.lat]);
    });
  });

  const changeMarker = (lngLat: mapboxgl.LngLatLike) => {
    if (marker) marker.remove();
    setMarker(new mapboxgl.Marker(img).setLngLat(lngLat).addTo(map.current));
  };

  const handleGetGeolocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const center: any = [position.coords.longitude, position.coords.latitude];
      changeMarker(center);
      map.current.flyTo({
        center: center,
      });
      onSave(center);
    });
  };

  useEffect(() => {
    setOptions(places);
  }, [places]);

  const handleInputChange = (e: any, v: any) => {
    if (v) {
      dispatch(searchMapPlaces(v || ""));
    } else {
      setOptions([]);
    }
  };

  const handleSearchSelect = (values: any) => {
    map.current.flyTo({ center: values.center });
    if (values && values?.center && values.center instanceof Array) {
      changeMarker([values.center[0], values.center[1]]);
      onSave([values.center[0], values.center[1]]);
    }
  };

  const handleAddPrecision = () => {
    if (!valueLat || !valueLng) return;
    if (
      valueLat >= 91 ||
      valueLat <= -91 ||
      valueLng >= 91 ||
      valueLng <= -91 ||
      valueLat === 0 ||
      valueLng === 0
    ) {
      return;
    }
    const center = [
      Number(Number(valueLng).toFixed(6)),
      Number(Number(valueLat).toFixed(6)),
    ];

    changeMarker([center[0], center[1]]);

    map.current.flyTo({ center: center });
    onSave([center[0], center[1]]);
    setValueLat(undefined);
    setValueLng(undefined);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            options={options}
            value={valueOption}
            filterOptions={(x) => x}
            autoComplete
            includeInputInList
            filterSelectedOptions
            onInputChange={(event, value) => handleInputChange(event, value)}
            onChange={(event: any, newValue: any | null) => {
              setValueOption(newValue);
              if (newValue) {
                handleSearchSelect(newValue);
              }
            }}
            getOptionLabel={(option) => option?.place_name}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar ubicación..."
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="caption">Añadir por coordenadas:</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="outlined-basic"
            value={valueLat}
            label="Latitud"
            type="number"
            variant="outlined"
            onChange={(e) => {
              if (e) {
                setValueLat(Number(e.target.value));
              }
            }}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="outlined-basic"
            value={valueLng}
            label="Longitud"
            type="number"
            variant="outlined"
            onChange={(e) => {
              if (e) {
                setValueLng(Number(e.target.value));
              }
            }}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            onClick={handleAddPrecision}
            fullWidth
          >
            Fijar
          </Button>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            startIcon={<Crosshair />}
            fullWidth
            onClick={handleGetGeolocation}
          >
            Mi Ubicación
          </Button>
        </Grid>
      </Grid>

      <div
        ref={mapContainer}
        style={{
          alignContent: "center",
          alignSelf: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 20,
          borderRadius: 8,
          height: 490,
          width:
            Number(valueWidth) > 970
              ? Number((Number(valueWidth) / 1.7)?.toFixed(0))
              : Number((Number(valueWidth) / 1.5)?.toFixed(0)),
        }}
      />
    </>
  );
};
export default Map;
