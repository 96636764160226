import { Action } from "../../models/action";
import * as types from "../../constants";
import { FormState } from "../../models/form_state";

export interface IPlacesTypesState {
  placesTypes: any;
  state: FormState;
  error?: string;
}

const initialState = {
  placesTypes: null,
  state: FormState.Initial,
} as IPlacesTypesState;

export const placesTypesReducer = (
  state = initialState,
  action: Action
): IPlacesTypesState => {
  switch (action.type) {
    case types.PLACES_TYPES_IS_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.PLACES_TYPES_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        placesTypes: action.payload,
      };
    case types.PLACES_TYPES_FAILURE:
      return {
        ...state,
        state: FormState.Error,
        error: action.payload,
      };
    case types.PLACES_TYPES_CREATE_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.PLACES_TYPES_CREATE_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        placesTypes: [...state.placesTypes, action.payload],
      };
    case types.PLACES_TYPES_CREATE_FAILURE:
      return {
        ...state,
        state: FormState.Error,
        error: action.payload,
      };
    case types.PLACES_TYPES_UPDATE_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.PLACES_TYPES_UPDATE_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        placesTypes: action.payload,
      };
    case types.PLACES_TYPES_UPDATE_FAILURE:
      return {
        ...state,
        state: FormState.Error,
        error: action.payload,
      };
    default:
      return state;
  }
};
