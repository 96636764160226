import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  Collapse,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { TableSkeleton } from "../components/TableSkeleton";
import { useTable } from "../hooks/useTable";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../theme/useStyles";
import { RootState } from "../redux/reducers/rootReducer";
import { ISalesState } from "../redux/reducers/salesReducer";
import { FormState } from "../models/form_state";
import {
  getMoreSales,
  getMoreSalesFiltered,
  getSales,
  getSalesFiltered,
} from "../redux/actions/salesAction";
import {
  cleanString,
  exportToCsv,
  numberWithDot,
  timesStampFormattedsimple,
  timesStampToDateFormatted,
} from "../utils/utils";
import { openSnack } from "../redux/actions/uiActions";
import { SnackState } from "../models/snack-state";
import { DownloadCloud, MinusSquare, Search } from "react-feather";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { getTourTypes } from "../redux/actions/tourTypesActions";
import { getRegions } from "../redux/actions/regionsActions";
import { ITourTypeState } from "../redux/reducers/tourTypesReducer";
import { IRegionsState } from "../redux/reducers/regionsReducer";
import { withStyles } from "@material-ui/styles";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#ffffff",
      fontWeight: "bold",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000",
    },
    "& input": {
      color: "#ffffff",
      "& placeholder": { color: "#ffffff" },
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ffffff",
      },
      "&:hover fieldset": {
        borderColor: "#ffffff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ffffff",
      },
    },
  },
})(TextField);

const ContentCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { totalSales, totalDocs, state } = useSelector<RootState, ISalesState>(
    (state) => state.salesReducer
  );
  const [filter, setFilter] = useState<any>({});

  const { limit, page, handleLimitChange, handlePageChange } = useTable({
    limit: TABLE_LIMIT_DEFAULT,
    onLimitChange: (event, newLimit) => {
      if (newLimit > totalSales?.length) {
        if (filter) {
          dispatch(getSalesFiltered(limit, filter));
        } else {
          dispatch(getSales(newLimit));
        }
      }
    },
    onPageChange: (newPage, oldPage, limit) => {
      if (totalSales?.length < totalDocs && newPage > oldPage) {
        dispatch(getMoreSalesFiltered(limit, filter));
        if (filter) {
        } else {
          dispatch(getMoreSales(limit));
        }
      }
    },
  });

  useEffect(() => {
    dispatch(getSales());
  }, [dispatch]);

  return (
    <Card mb={6}>
      <FilterSection
        limit={limit}
        onFilterChange={(filter) => setFilter(filter)}
      />
      <Divider />
      <CardContent>
        <TableContainer className={classes.tableContainer}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Tour</TableCell>
                <TableCell>Región</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Idioma</TableCell>
                <TableCell>Guia</TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Comisión Guía</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Valor</TableCell>
                {/* <TableCell align="center">Acciones</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {state === FormState.Success ? (
                totalSales
                  .slice(page * limit, page * limit + limit)
                  .map((data: any) => (
                    <Fade key={data.id} in={true}>
                      <TableRow hover className={classes.styledRow}>
                        <TableCell>{data?.Tour?.Nombre}</TableCell>
                        <TableCell>{data?.Tour?.Region}</TableCell>
                        <TableCell>{data?.Tour?.TipoTour}</TableCell>
                        <TableCell>{data?.Tour?.Idioma || "Español"}</TableCell>
                        <TableCell>
                          {data?.Tour?.Guia?.Nombre +
                            " " +
                            data?.Tour?.Guia?.Apellido}
                        </TableCell>
                        <TableCell>
                          {data?.Usuario?.Nombre +
                            " " +
                            data?.Usuario?.Apellido}
                        </TableCell>
                        <TableCell>
                          {timesStampFormattedsimple(
                            data?.FechaCreacion?.toDate()
                          )}
                        </TableCell>
                        <TableCell>
                          {/* {data?.Tour?.Guia?.Porcentaje || 50}% */}
                          {/* {user?.tipoUsuario === "Guia" */}

                          {data?.Tour?.Precio === 0 ||
                          data?.Tour?.Precio === "0" ||
                          !data?.Tour?.Precio ||
                          data?.Estado === "Pendiente"
                            ? "No Aplica"
                            : `${data?.Tour?.Guia?.Porcentaje || 50}% \n ${
                                false
                                  ? numberWithDot(
                                      (data?.Tour?.Precio *
                                        (data?.Tour?.Guia?.Porcentaje || 50)) /
                                        100
                                    )
                                  : numberWithDot(
                                      (data?.Tour?.Precio *
                                        (data?.Tour?.Guia?.Porcentaje || 50)) /
                                        100
                                    )
                              }`}
                        </TableCell>
                        <TableCell>
                          {data?.Tour?.Precio === 0 ||
                          data?.Tour?.Precio === "0" ||
                          !data?.Tour?.Precio
                            ? "Gratuito"
                            : data?.Estado}
                        </TableCell>
                        <TableCell>
                          {numberWithDot(data?.Tour?.Precio)}
                        </TableCell>
                      </TableRow>
                    </Fade>
                  ))
              ) : (
                <TableSkeleton colSpan={10} limit={10} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{ color: "#004ecb", fontWeight: "bold" }}
          component="div"
          count={totalDocs}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={TABLE_LIMITS}
        />
      </CardContent>
    </Card>
  );
};
interface FilterProps {
  limit?: number;
  onFilterChange: (value: any) => void;
}
const FilterSection = React.memo((props: FilterProps) => {
  const { onFilterChange, limit, ...other } = props;
  const dispatch = useDispatch();

  const [openFilter, setOpenFilters] = useState(false);
  const [filtersList, setValueFiltersList] = React.useState<any>({});
  const [valueCreacionStart, setValueCreacionStart] = useState<any>(null);
  const [valueNombre, setValueNombre] = useState("");
  const [valueNombreGuia, setValueNombreGuia] = useState("");
  const [valueApellidoGuia, setValueApellidoGuia] = useState("");
  const [valueTipo, setValueTipo] = React.useState<string | null>(null);
  const [valueRegion, setValueRegion] = React.useState<string | null>(null);

  const handleChangeNombre = (e: any) => {
    setValueNombre(e.target.value);
  };

  const handleChangeNombreGuia = (e: any) => {
    setValueNombreGuia(e.target.value);
  };

  const handleChangeApellidoGuia = (e: any) => {
    setValueApellidoGuia(e.target.value);
  };

  const { regions } = useSelector<RootState, IRegionsState>(
    (state) => state.regionsReducer
  );

  const { tourTypes } = useSelector<RootState, ITourTypeState>(
    (state) => state.tourTypesReducer
  );

  useEffect(() => {
    dispatch(getRegions());
  }, []);
  useEffect(() => {
    dispatch(getTourTypes());
  }, []);

  useEffect(() => {
    onFilterChange(filtersList);
  }, [Object.keys(filtersList)]);

  return (
    <CardContent style={{ backgroundColor: "#2979ff" }}>
      <Grid container justify="flex-end">
        <Grid item>
          <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
            <Typography variant="subtitle2" style={{ color: "#ffffff" }}>
              Filtros{" "}
            </Typography>
            {openFilter ? (
              <ExpandLess style={{ color: "#ffffff" }} />
            ) : (
              <ExpandMore style={{ color: "#ffffff" }} />
            )}
          </Button>
        </Grid>
      </Grid>
      <Collapse in={openFilter} timeout="auto" unmountOnExit>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3} style={{ marginBottom: 5 }}>
            <CssTextField
              id="outlined-basic"
              size="small"
              label="Nombre Tour"
              InputLabelProps={{
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  color: "#ffffff",
                },
              }}
              fullWidth
              variant="outlined"
              value={valueNombre}
              onChange={handleChangeNombre}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  filtersList["Tour.Nombre_lower"] = cleanString(valueNombre);
                  if (!valueNombre) {
                    delete filtersList["Tour.Nombre_lower"];
                  }
                  if (Object.keys(filtersList).length > 0 && valueNombre) {
                    dispatch(getSalesFiltered(limit, filtersList));
                  } else {
                    dispatch(getSales());
                  }

                  ev.preventDefault();
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2} style={{ marginBottom: 5 }}>
            <CssTextField
              id="outlined-basic-2"
              size="small"
              label="Nombre Guía"
              InputLabelProps={{
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  color: "#ffffff",
                },
              }}
              variant="outlined"
              value={valueNombreGuia}
              onChange={handleChangeNombreGuia}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  filtersList["Tour.Guia.Nombre_lower"] =
                    cleanString(valueNombreGuia);
                  if (!valueNombreGuia) {
                    delete filtersList["Tour.Guia.Nombre_lower"];
                  }
                  if (Object.keys(filtersList).length > 0 && valueNombreGuia) {
                    dispatch(getSalesFiltered(limit, filtersList));
                  } else {
                    dispatch(getSales());
                  }

                  ev.preventDefault();
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2} style={{ marginBottom: 5 }}>
            <CssTextField
              id="outlined-basic-3"
              size="small"
              label="Apellido Guía"
              InputLabelProps={{
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  color: "#ffffff",
                },
              }}
              variant="outlined"
              value={valueApellidoGuia}
              onChange={handleChangeApellidoGuia}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  filtersList["Tour.Guia.Apellido_lower"] =
                    cleanString(valueApellidoGuia);
                  if (!valueApellidoGuia) {
                    delete filtersList["Tour.Guia.Apellido_lower"];
                  }
                  if (
                    Object.keys(filtersList).length > 0 &&
                    valueApellidoGuia
                  ) {
                    dispatch(getSalesFiltered(limit, filtersList));
                  } else {
                    dispatch(getSales());
                  }

                  ev.preventDefault();
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2} style={{ marginBottom: 5 }}>
            <Autocomplete
              id="tipos-filtro"
              size="small"
              value={valueTipo}
              options={tourTypes}
              getOptionLabel={(option: any) => option.Nombre}
              fullWidth
              onChange={(event: any, newValue: any | null) => {
                setValueTipo(newValue);
                if (newValue) {
                  filtersList["Tour.TipoTour"] = newValue.Nombre;
                  dispatch(getSalesFiltered(limit, filtersList));
                } else {
                  delete filtersList["Tour.TipoTour"];
                  if (Object.keys(filtersList).length > 0) {
                    dispatch(getSalesFiltered(limit, filtersList));
                  } else {
                    dispatch(getSales());
                  }
                }
              }}
              renderInput={(params: any) => (
                <CssTextField
                  {...params}
                  label="Tipos de tour"
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      color: "#ffffff",
                    },
                  }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginBottom: 5 }}>
            <Autocomplete
              id="region-filtro"
              size="small"
              value={valueRegion}
              options={regions}
              getOptionLabel={(option: any) => option.Nombre}
              fullWidth
              onChange={(event: any, newValue: any | null) => {
                setValueRegion(newValue);
                if (newValue) {
                  filtersList["Tour.Region"] = newValue.Nombre;
                  dispatch(getSalesFiltered(limit, filtersList));
                } else {
                  delete filtersList["Tour.Region"];
                  if (filtersList) {
                    dispatch(getSalesFiltered(limit, filtersList));
                  } else {
                    dispatch(getSales());
                  }
                }
              }}
              renderInput={(params: any) => (
                <CssTextField
                  {...params}
                  label="Región"
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      color: "#ffffff",
                    },
                  }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginBottom: 5 }}>
            <CssTextField
              id="creacion-start"
              label="Fecha Creación: Inicio"
              InputLabelProps={{
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  color: "#ffffff",
                },
                shrink: true,
              }}
              size="small"
              type="datetime-local"
              value={valueCreacionStart}
              onChange={(event) => {
                setValueCreacionStart(event.target.value);
                filtersList["endAt"] = event.target.value;
              }}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={2} style={{ marginBottom: 5 }}>
            <Box display="flex">
              <Box mt={1} order={1}>
                <Tooltip title="Buscar">
                  <IconButton
                    style={{ color: "#ffffff" }}
                    size="small"
                    aria-label="Filtrar"
                    onClick={() => {
                      if (!valueNombre) {
                        delete filtersList["Tour.Nombre_lower"];
                      } else {
                        filtersList["Tour.Nombre_lower"] = valueNombre;
                      }

                      if (!valueNombreGuia) {
                        delete filtersList["Tour.Guia.Nombre_lower"];
                      } else {
                        filtersList["Tour.Guia.Nombre_lower"] = valueNombreGuia;
                      }

                      if (!valueApellidoGuia) {
                        delete filtersList["Tour.Guia.Apellido_lower"];
                      } else {
                        filtersList["Tour.Guia.Apellido_lower"] =
                          valueApellidoGuia;
                      }

                      dispatch(getSalesFiltered(limit, filtersList));
                    }}
                  >
                    <Search size={35} style={{ marginTop: -7 }} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box mt={1} order={2}>
                <Tooltip title="Limpiar">
                  <IconButton
                    style={{ color: "#ffffff" }}
                    size="small"
                    aria-label="Borrar filtro"
                    onClick={() => {
                      setValueNombre("");
                      setValueNombreGuia("");
                      setValueApellidoGuia("");
                      setValueTipo(null);
                      setValueRegion(null);
                      setValueCreacionStart("");
                      setValueFiltersList({});
                      dispatch(getSales());
                    }}
                  >
                    <MinusSquare size={35} style={{ marginTop: -7 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </CardContent>
  );
});
export const Sales = () => {
  const dispatch = useDispatch();
  const { totalSalesDocs } = useSelector<RootState, ISalesState>(
    (state) => state.salesReducer
  );
  return (
    <React.Fragment>
      <Helmet title="Ventas" />
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Ventas
          </Typography>

          {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Ventas</Typography>
          </Breadcrumbs> */}
        </Grid>
        <Grid item>
          <Box display="flex">
            <Box order={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(openSnack("Descargando CSV", SnackState.SUCCESS));
                  exportToCsv(
                    "total_ventas.csv",
                    totalSalesDocs,
                    [
                      "FechaCreacion",
                      "Nombre",
                      "Region",
                      "TipoTour",
                      "Idioma",
                      "Estado",
                      "Precio",
                      "NombreGuia",
                      "EmailGuia",
                      "PrecioGuia",
                      "NombreCliente",
                      "EmailCliente",
                    ],
                    [
                      "Fecha de Creacion",
                      "Nombre Tour",
                      "Region",
                      "Tipo Tour",
                      "Idioma",
                      "Estado",
                      "Valor",
                      "Nombre Guia",
                      "Email Guia",
                      "Valor % Guia",
                      "Nombre Cliente",
                      "Email Cliente",
                    ]
                  );
                }}
              >
                <DownloadCloud />
                Descargar CSV
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(openSnack("Descargando CSV", SnackState.SUCCESS));
                  exportToCsv(
                    "total_ventas.csv",
                    totalSalesDocs,
                    [
                      "FechaCreacion",
                      "Nombre",
                      "Region",
                      "TipoTour",
                      "Idioma",
                      "Estado",
                      "Precio",
                      "NombreGuia",
                      "EmailGuia",
                      "PrecioGuia",
                      "NombreCliente",
                      "EmailCliente",
                    ],
                    [
                      "Fecha de Creacion",
                      "Nombre Tour",
                      "Region",
                      "Tipo Tour",
                      "Idioma",
                      "Estado",
                      "Valor",
                      "Nombre Guia",
                      "Email Guia",
                      "Valor % Guia",
                      "Nombre Cliente",
                      "Email Cliente",
                    ],
                    ";"
                  );
                }}
              >
                <DownloadCloud />
                Descargar CSV2
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
