import { Action } from "../../models/action";
import * as types from "../../constants";

export interface SumarRestarState {
  number: number;
}

const initialState = {
  number: 0,
} as SumarRestarState;

export const sumarRestarReducer = (
  state = initialState,
  action: Action
): SumarRestarState => {
  switch (action.type) {
    case types.SUMAR_NUMERO:
      return {
        ...state,
        number: state.number + 1,
      };
    case types.RESTAR_NUMERO:
      return {
        ...state,
        number: state.number - 1,
      };
    default:
      return state;
  }
};
