import Axios from "axios";
import firebase from "firebase/app";
import * as types from "../../constants";
import { firebaseConfig, firestore } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { SnackState } from "../../models/snack-state";
import { cleanString, exportToCsv } from "../../utils/utils";
import { openSnack } from "./uiActions";

export const getTotalDocs = (): AppThunk => {
  return async (dispatch, getState) => {
    const { filtroNombre } = getState().clientsReducer;
    try {
      let query = firestore.collection("Usuarios").orderBy("Nombre_lower");

      if (filtroNombre) {
        const separatedName = filtroNombre.split(" ");
        const name = separatedName[0];
        let surname = "";
        if (separatedName.length > 1) {
          for (let i = 1; i < separatedName.length; i++) {
            if (i === 1) {
              surname = surname + separatedName[i];
            } else {
              surname = surname + ` ${separatedName[i]}`;
            }
          }
        }
        query = query.startAt(name).endAt(name + "\uf8ff");

        if (surname) {
          query = query.where("Apellido_lower", "==", surname);
        }
      }

      const response = await query
        .where("Activo", "==", true)
        .where("TipoUsuario", "==", "Cliente")
        .get();
      dispatch(setTotalDocs(response.size));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    }
  };
};

export const downloadClientsCSV = (
  separator: string,
  isFilter: boolean = false
): AppThunk => {
  return async (dispatch, getState) => {
    const { filtroNombre } = getState().clientsReducer;
    try {
      let query = firestore.collection("Usuarios").orderBy("Nombre_lower");

      if (filtroNombre && isFilter) {
        const separatedName = filtroNombre.split(" ");
        const name = separatedName[0];
        let surname = "";
        if (separatedName.length > 1) {
          for (let i = 1; i < separatedName.length; i++) {
            if (i === 1) {
              surname = surname + separatedName[i];
            } else {
              surname = surname + ` ${separatedName[i]}`;
            }
          }
        }
        query = query.startAt(name).endAt(name + "\uf8ff");

        if (surname) {
          query = query.where("Apellido_lower", "==", surname);
        }
      }

      const response = await query
        .where("Activo", "==", true)
        .where("TipoUsuario", "==", "Cliente")
        .get();

      const clients = mapClients(response);

      exportToCsv(
        "observaciones.csv",
        clients,
        [
          "Nombre",
          "Apellido",
          "Genero",
          "Pais",
          "FechaNacimiento",
          "Email",
          "Telefono",
        ],
        [
          "Nombre",
          "Apellido",
          "Genero",
          "Pais",
          "Fecha de Nacimiento",
          "Email",
          "Telefono",
        ],
        separator
      );
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const getClients = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const { filtroNombre } = getState().clientsReducer;
    try {
      let query = firestore.collection("Usuarios").orderBy("Nombre_lower");

      if (filtroNombre) {
        const separatedName = filtroNombre.split(" ");
        const name = separatedName[0];
        let surname = "";
        if (separatedName.length > 1) {
          for (let i = 1; i < separatedName.length; i++) {
            if (i === 1) {
              surname = surname + separatedName[i];
            } else {
              surname = surname + ` ${separatedName[i]}`;
            }
          }
        }
        query = query.startAt(name).endAt(name + "\uf8ff");

        if (surname) {
          query = query.where("Apellido_lower", "==", surname);
        }
      }

      const response = await query
        .where("Activo", "==", true)
        .where("TipoUsuario", "==", "Cliente")
        .limit(limit)
        .get();

      dispatch(setClients(mapClients(response)));
      dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const addMoreClients = (limit: number = 10): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));

    const { lastDoc, filtroNombre } = getState().clientsReducer;
    try {
      let query = firestore.collection("Usuarios").orderBy("Nombre_lower");

      if (filtroNombre) {
        const separatedName = filtroNombre.split(" ");
        const name = separatedName[0];
        let surname = "";
        if (separatedName.length > 1) {
          for (let i = 1; i < separatedName.length; i++) {
            if (i === 1) {
              surname = surname + separatedName[i];
            } else {
              surname = surname + ` ${separatedName[i]}`;
            }
          }
        }
        query = query.startAt(name).endAt(name + "\uf8ff");

        if (surname) {
          query = query.where("Apellido_lower", "==", surname);
        }
      }

      const response = await query
        .where("Activo", "==", true)
        .where("TipoUsuario", "==", "Cliente")
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      dispatch(addClients(mapClients(response)));
      dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getClient = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch(updateIsLoading(true));
    try {
      const response = await firestore.collection("Usuarios").doc(id).get();

      dispatch(setSelectedClient(mapClient(response)));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(updateIsLoading(false));
    }
  };
};

export const createClient = (client: any): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      console.log(client);

      const res: any = await Axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${firebaseConfig.apiKey}`,
        {
          email: client.Email,
          password: "Test-1234",
          returnSecureToken: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).catch((errorapi) => console.log(errorapi?.response.data.error.message));

      await firestore
        .collection("Usuarios")
        .doc(res.data.localId)
        .set({
          Nombre: client.Nombre,
          Nombre_lower: cleanString(client.Nombre),
          Apellido: client.Apellido,
          Apellido_lower: cleanString(client.Apellido),
          Telefono: client.Telefono,
          Email: client.Email,
          Pais: client.Pais,
          Activo: true,
          TipoUsuario: "Cliente",
        });

      dispatch(openSnack("Cliente creado", SnackState.SUCCESS));
      dispatch(getClients());
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const updateClient = (client: any, id: any): AppThunk => {
  return async (dispatch) => {
    dispatch(updateIsLoading(true));
    try {
      await firestore
        .collection("Usuarios")
        .doc(id)
        .update({
          Nombre: client.Nombre,
          Nombre_lower: cleanString(client.Nombre),
          Apellido: client.Apellido,
          Apellido_lower: cleanString(client.Apellido),
          Telefono: client.Telefono,
          Pais: client.Pais,
          FechaNacimiento: client?.FechaNacimiento,
        });

      dispatch(getClients());
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(updateIsLoading(false));
    }
  };
};

export const deleteClient = (selected: any): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      await firestore.collection("Usuarios").doc(selected.id).update({
        Activo: false,
        Email: firebase.firestore.FieldValue.delete(),
      });
      try {
        await Axios.post(
          `https://identitytoolkit.googleapis.com/v1/accounts:delete?key=AIzaSyCDxUc2ZnW5LD2QoM2BoSH0786qJNx04NM`,
          {
            idToken: selected.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } catch {
        await Axios.post(
          `https://us-central1-traveapp-fa700.cloudfunctions.net/app/delete/user/auth`,
          {
            email: selected.Email,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        ).catch(() => console.log("ERROR"));
      }
      const id = selected.id;
      dispatch(openSnack("Se ha eliminado", SnackState.SUCCESS));
      dispatch(removeClientStore({ id }));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export type ByKey = "Nombre_lower";

export const searchClients = (
  query: string | string[],
  byKey: ByKey
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));

    try {
      let response: any;

      if (byKey === "Nombre_lower") {
        response = await firestore
          .collection("Usuarios")
          .where("TipoUsuario", "==", "Cliente")
          .orderBy(byKey)
          .startAt(query)
          .endAt(query + "\uf8ff")
          .where("Activo", "==", true)
          .get();
      }
      dispatch(setClients(mapClients(response)));
      dispatch(setTotalDocs(response.size));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

const mapClients = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  try {
    let Clients: any[] = [];
    response.docs.forEach((doc) => {
      let data = mapClient(doc);
      Clients.push(data);
    });
    return Clients;
  } catch (error: any) {
    throw error;
  }
};

const mapClient = (doc: any) => {
  let data = doc.data();
  data.id = doc.id;
  return data;
};

const removeClientStore = (priority: any): Action => ({
  type: types.CLIENTS_REMOVE_DOC,
  payload: priority,
});

export const setSelectedClient = (client: any): Action => ({
  type: types.CLIENTS_SET_SELECTED,
  payload: client,
});

export const setClient = (client: any): Action => ({
  type: types.CLIENTS_GET_SELECTED,
  payload: client,
});

const updateIsLoading = (isLoading: boolean): Action => ({
  type: types.CLIENTS_UPDATE_LOADING,
  payload: isLoading,
});

// const updateClientStore = (tourGuide: any): Action => ({
//   type: types.CLIENTS_UPDATE_DOC,
//   payload: tourGuide,
// });

const setClients = (CLIENTS: any[]): Action => ({
  type: types.CLIENTS_GET_DOCS,
  payload: CLIENTS,
});

const setLastDoc = (doc: any): Action => ({
  type: types.CLIENTS_SET_LAST_DOC,
  payload: doc,
});

const addClients = (CLIENTS: any[]): Action => ({
  type: types.CLIENTS_ADD_DOCS,
  payload: CLIENTS,
});

const isLoading = (isloading: boolean): Action => ({
  type: types.CLIENTS_LOADING,
  payload: isloading,
});

const setError = (error: string): Action => ({
  type: types.CLIENTS_FAILURE,
  payload: error,
});

const setTotalDocs = (total: number): Action => ({
  type: types.CLIENTS_SET_TOTAL_DOCS,
  payload: total,
});

export const setClientNameFilter = (name: string): Action => ({
  type: types.USER_SET_NAME_FILTER,
  payload: name,
});
