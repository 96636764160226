/* eslint-disable import/first */

// import async from "../components/Async";

import {
  Book,
  Clipboard,
  Compass,
  DollarSign,
  MapPin,
  Monitor as MonitorIcon,
  Navigation as NavigationIcon,
  ShoppingBag,
  User,
  UserCheck,
  Users as UsersIcon,
  ThumbsUp,
} from "react-feather";
// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Guards
import AuthGuard from "../components/AuthGuard";
import AuthGuardAdmin from "../components/AuthGuardAdmin";
import AuthGuardLugar from "../components/AuthGuardLugar";
import ChangePassword from "../pages/auth/ChangePassword";
import GuideRegister from "../pages/auth/GuideRegister";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import PlaceRegister from "../pages/auth/PlaceRegister";
import Register from "../pages/auth/Register";
import ResetPassword from "../pages/auth/ResetPassword";
// Auth components
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import { Blankguide } from "../pages/blankguide";
import { Businesses } from "../pages/Businesses";
import { Clients } from "../pages/Clients";
// import { Dashboard } from "../pages/Dashboard";
import { EditTour } from "../pages/EditTour";
import { IndexCardClients } from "../pages/IndexCardClients";
import { IndexCardTour } from "../pages/IndexCardTour";
import { IndexCardTourGuides } from "../pages/IndexCardTourGuides";
import { NewTour } from "../pages/NewTour";
import { Places } from "../pages/Places";
import { PlacesIndexCard } from "../pages/PlacesIndexCard";
import { Sales } from "../pages/Sales";
import { TourGuides } from "../pages/TourGuides";
import { Tours } from "../pages/Tours";

import { DeleteAccount } from "../pages/DeleteAccount";

// Dashboards components
// const Default = async(() => import("../pages/dashboards/Default"));
// Pages components
// const dashboardRoutes = {
//   id: "Inicio",
//   path: "/inicio",
//   icon: <Book />,
//   guard: AuthGuard,
//   component: Dashboard,
//   children: null,
// };

const guidesRoutes = {
  id: "Guías Turísticos",
  path: "/guias",
  icon: <Compass />,
  guard: AuthGuardAdmin,
  component: TourGuides,
  tipoUser: "Administrador",
  children: null,
};

const guideRoute = {
  id: "Mi Perfil",
  path: "/perfil/",
  icon: <MonitorIcon />,
  guard: AuthGuard,
  component: Blankguide,
  tipoUser: "Guia",
  children: null,
};
const guidesSubRoutes = {
  path: "/guias",
  component: null,
  children: [
    {
      path: "/guias/:guideId/ficha",
      name: "Ficha Guia",
      guard: AuthGuard,
      component: IndexCardTourGuides,
    },
  ],
};

const toursRoutes = {
  id: "Tours",
  path: "/tours",
  icon: <NavigationIcon />,
  guard: AuthGuard,
  tipoUser: "Administrador",
  component: Tours,
  children: null,
};

const profileGuide = {
  id: "Perfil",
  path: "/perfil/:guideId",
  icon: <User />,
  guard: AuthGuard,
  tipoUser: "Guia",
  component: IndexCardTourGuides,
  children: null,
};

const toursRoutesGuide = {
  id: "Mis Tours",
  path: "/tours",
  icon: <NavigationIcon />,
  guard: AuthGuard,
  tipoUser: "Guia",
  component: Tours,
  children: null,
};

const toursSubRoutes = {
  path: "/tours",
  component: null,
  children: [
    {
      path: "/tours/:tourId/ficha",
      name: "Ficha Tour",
      guard: AuthGuard,
      component: IndexCardTour,
    },
    {
      path: "/tours/:tourId/editar",
      name: "Editar Tour",
      guard: AuthGuard,
      component: EditTour,
    },
    {
      path: "/tours/nuevo",
      name: "Nuevo Tour",
      guard: AuthGuard,
      component: NewTour,
    },
  ],
};

const businessesRoutes = {
  id: "Empresas",
  path: "/empresas",
  icon: <Book />,
  guard: AuthGuard,
  component: Businesses,
  tipoUser: "Administrador",
  children: null,
};

const salesRoutes = {
  id: "Ventas",
  path: "/ventas",
  icon: <DollarSign />,
  guard: AuthGuard,
  component: Sales,
  tipoUser: "Administrador",
  children: null,
};

const clientsRoutes = {
  id: "Usuarios",
  path: "/clientes",
  icon: <UsersIcon />,
  component: Clients,
  guard: AuthGuard,
  tipoUser: "Administrador",
  children: null,
};

const clientsSubRoutes = {
  path: "/clientes",
  component: null,
  children: [
    {
      path: "/clientes/:clientId/ficha",
      name: "Ficha Cliente",
      guard: AuthGuard,
      component: IndexCardClients,
    },
  ],
};

const placesRoutes = {
  id: "Comercios",
  path: "/places",
  icon: <ShoppingBag />,
  guard: AuthGuard,
  component: Places,
  tipoUser: "Administrador",
  children: null,
};

const placeRoute = {
  id: "Mis Datos",
  path: "/Editar/Lugar",
  icon: <MapPin />,
  guard: AuthGuardLugar,
  component: PlacesIndexCard,
  tipoUser: "Lugar",
  children: null,
};

const placesSubRoutes = {
  path: "/places",
  component: null,
  children: [
    {
      path: "/places/:placeId/Editar",
      name: "Editar",
      guard: AuthGuard,
      component: PlacesIndexCard,
    },
    {
      path: "/places/Crear",
      name: "Crear",
      guard: AuthGuard,
      component: PlacesIndexCard,
    },
  ],
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <UsersIcon />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/delete-account",
      name: "Delete Account",
      component: DeleteAccount,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/change-password",
      name: "Change Password",
      component: ChangePassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
    {
      path: "/auth/register",
      name: "Registrate",
      component: Register,
    },
    {
      path: "/register/guides",
      name: "Registro Guías",
      component: GuideRegister,
    },
    {
      path: "/register/place",
      name: "Registro Comercio",
      component: PlaceRegister,
    },
  ],
  component: null,
};

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes that are protected
export const protectedRoutes = [
  // dashboardRoutes,
  guidesRoutes,
  guidesSubRoutes,
  toursRoutes,
  profileGuide,
  toursRoutesGuide,
  toursSubRoutes,
  businessesRoutes,
  salesRoutes,
  clientsRoutes,
  clientsSubRoutes,
  placesRoutes,
  placesSubRoutes,
  placeRoute,
  guideRoute,
];

// Routes visible in the sidebar
export const sidebarRoutes = [
  guidesRoutes,
  // profileGuide,
  toursRoutesGuide,
  toursRoutes,
  guideRoute,
  // businessesRoutes,
  salesRoutes,
  clientsRoutes,
  placesRoutes,
  placeRoute,
];
