import {
  Box,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import React, { useEffect } from "react";
import {
  ChevronRight as RightIcon,
  Edit as EditIcon,
  Key,
  Trash2 as DeleteIcon,
} from "react-feather";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../../constants";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { useNewEditDialog } from "../../hooks/useNewEditDialog";
//Hooks
import { useTable } from "../../hooks/useTable";
import { DialogAction } from "../../models/dialog_state";
//Redux
import {
  addMoreClients,
  getClients,
  searchClients,
  setClientNameFilter,
  setSelectedClient,
} from "../../redux/actions/clientsActions";
import { resetPaswordTourGuide } from "../../redux/actions/tourGuidesActions";
import { useStyles } from "../../theme/useStyles";
import { cleanString } from "../../utils/utils";
import { NewConfirmDialog } from "../NewConfirmDialog";
//Components
import { SearchInput } from "../SearchInput";
import { TableSkeleton } from "../TableSkeleton";
import { DatosFicha } from "./DatosFicha";
//Dialogs
import { DialogEditAndNew } from "./DialogEditAndNew";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

interface Props {
  isLoading: boolean;
  name: string;
  /**
   * @param values Item seleccionado por las acciones
   * @param action Nuevos datos a crear o actualizar
   * @param selected Action que realiza el Dialogo
   */
  onAcceptNewEditDialog: (
    values: any,
    action: DialogAction,
    selected: any
  ) => void;

  onFichaDialog: (values: any, selected: any) => void;

  onConfirmDeleteDialog: (selected: any) => void;
  cardTitle?: string;
  array: any[];
  openNewDialog?: boolean;
  onClose?: () => void;
  totalDocs: number;
}

export const ContentCard: React.FC<Props> = ({
  isLoading,
  name,
  onAcceptNewEditDialog,
  onConfirmDeleteDialog,
  onFichaDialog,
  cardTitle = "",
  array,
  openNewDialog: openNewEditDialog = null,
  totalDocs,
  onClose = () => {},
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const { limit, page, handleLimitChange, handlePageChange, handleResetPage } =
    useTable({
      limit: TABLE_LIMIT_DEFAULT || 5,
      onLimitChange: (e, newLimit) => {
        if (newLimit > array.length) {
          dispatch(getClients(newLimit));
        }
      },
      onPageChange: (newPage, oldPage, limit) => {
        if (array.length < totalDocs && newPage > oldPage) {
          dispatch(addMoreClients(limit));
        }
      },
    });

  const {
    open: newEditDialogOpen,
    setIsLoading: setIsLoadingNewEditDialog,
    selected: newEditDialogSelected,
    action,
    closeDialog,
    openNewDialog,
    openEditDialog,
    onAccept,
  } = useNewEditDialog({
    onAccept: (values, action) =>
      onAcceptNewEditDialog(values, action, newEditDialogSelected),
    onClose: onClose,
  });

  const {
    open: confirmFichaOpen,
    setIsLoading: setIsLoadingFicha,
    closeDialog: closeFichaDialog,
    openEditDialog: openFichaDialog,
    selected: newFichaDialogSelected,
    onAccept: onAcceptFicha,
  } = useNewEditDialog({
    onAccept: (values, _) => {
      console.log(values);
      onFichaDialog(values, newFichaDialogSelected);
    },
    onClose: onClose,
  });

  const {
    open: confirmDialogOpen,
    dialogConfirmHandleClose,
    dialogConfirmHandleConfirm,
    openDialog,
    setIsLoading,
    selected: confirmDialogSelected,
  } = useConfirmDialog({
    onConfirm: onConfirmDeleteDialog,
    onClose: onClose,
  });

  useEffect(() => {
    setIsLoading(isLoading);
    setIsLoadingNewEditDialog(isLoading);
  }, [isLoading, setIsLoading, setIsLoadingNewEditDialog]);

  // HOOKS
  useEffect(() => {
    if (openNewEditDialog) {
      openNewDialog();
    }
  }, [openNewEditDialog]);

  const handleDeleteBtn = (selected: any) => {
    openDialog(selected);
  };

  const handleGoToIndexCard = (selected: any) => {
    dispatch(setSelectedClient(selected));
    history.push(`clientes/${selected.id}/ficha`);
  };

  return (
    <Card mb={6}>
      <Grid container spacing={1} style={{ backgroundColor: "#2979ff" }}>
        <Grid item sm={3}>
          <Box m={2}>
            <SearchInput
              sendSearch={(value) => {
                // dispatch(searchClients(cleanString(value), "Nombre_lower"));
                dispatch(setClientNameFilter(cleanString(value)));
                if (page) handleResetPage();
              }}
              name={"SearchName"}
              placeholder={"Buscar por Nombre"}
            />
          </Box>
        </Grid>
      </Grid>

      <Divider />
      <CardContent>
        <TableContainer className={classes.tableContainer}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Telefono</TableCell>
                <TableCell>Pais</TableCell>
                <TableCell>Fecha de Nacimiento</TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle2" style={{ color: "#004ecb" }}>
                    Acciones
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading ? (
                array.slice(page * limit, page * limit + limit).map((data) => (
                  <Fade key={data.id} in={true}>
                    <TableRow hover className={classes.styledRow}>
                      <TableCell>{data.Nombre + " " + data.Apellido}</TableCell>
                      <TableCell>{data.Email}</TableCell>
                      <TableCell>
                        {"+" + String(data.Telefono).replace("+", "")}
                      </TableCell>
                      <TableCell>{data.Pais}</TableCell>
                      <TableCell>{data?.FechaNacimiento}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Editar">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => openEditDialog(data)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cambiar contraseña">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() =>
                              dispatch(resetPaswordTourGuide(data))
                            }
                          >
                            <Key />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Eliminar">
                          <IconButton
                            size="small"
                            onClick={() => handleDeleteBtn(data)}
                          >
                            <DeleteIcon color="red" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Ver Ficha">
                          <IconButton
                            size="small"
                            onClick={() => handleGoToIndexCard(data)}
                          >
                            <RightIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </Fade>
                ))
              ) : (
                <TableSkeleton colSpan={10} limit={10} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{ color: "#004ecb", fontWeight: "bold" }}
          component="div"
          count={totalDocs}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={TABLE_LIMITS}
        />
      </CardContent>
      <DialogEditAndNew
        open={newEditDialogOpen}
        onClose={closeDialog}
        onAccept={onAccept}
        isLoading={isLoading}
        action={action}
        object={newEditDialogSelected}
        title={name}
      />
      <DatosFicha
        open={confirmFichaOpen}
        onClose={closeFichaDialog}
        onAccept={(values) => onAcceptFicha(values, "Edit")}
        isLoading={isLoading}
        object={newFichaDialogSelected}
      />
      <NewConfirmDialog
        open={confirmDialogOpen}
        title={`¿Desea eliminar el usuario ${confirmDialogSelected?.Nombre}?`}
        onClose={dialogConfirmHandleClose}
        isLoading={isLoading}
        onConfirm={dialogConfirmHandleConfirm}
        onConfirmText={"Eliminar"}
      />
    </Card>
  );
};
