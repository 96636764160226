import React, { useState } from "react";
import {
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { blue } from "@material-ui/core/colors";
import { Camera } from "react-feather";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { ITourTypeState } from "../../redux/reducers/tourTypesReducer";
import { IRegionsState } from "../../redux/reducers/regionsReducer";
import { Autocomplete } from "@material-ui/lab";
import { IAuthState } from "../../redux/reducers/authReducer";
import { NumberFormatCustom } from "../NewTourComponents/FormCard";
import { format } from "date-fns";
import * as yup from "yup";
import { IToursState } from "../../redux/reducers/toursReducer";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (values: Tour) => void;
}

interface Tour {
  Nombre: string;
  Region: string;
  TipoTour: string;
  Precio: number;
  Path: any[];
  Distancia: number;
  Duracion: number;
  PortadaTour: any | null;
  Estado: string;
}
export const NewTourDialog: React.FC<Props> = ({
  onClose,
  onConfirm,
  open,
}) => {
  const [disabled, setDisabled] = useState(false);
  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      Nombre: "",
      Region: "",
      TipoTour: "",
      Precio: 0,
      Path: [],
      Distancia: 0,
      Duracion: 0,
      PortadaTour: null as any | null,
      Estado: "Pendiente",
    },
    onSubmit: (values) => {
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
      }, 2000);
      onConfirm(values);
    },
    validationSchema: yup.object({
      Nombre: yup.string().required("Nombre requerido"),
      Region: yup.string().required("Region requerida"),
      TipoTour: yup.string().required("Tipo Tour requerido"),
    }),
  });
  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );
  const { isLoading } = useSelector<RootState, IToursState>(
    (state) => state.toursReducer
  );
  const tourStates = ["Aprobado", "Pendiente"];
  const { tourTypes } = useSelector<RootState, ITourTypeState>(
    (state) => state.tourTypesReducer
  );
  const { regions } = useSelector<RootState, IRegionsState>(
    (state) => state.regionsReducer
  );
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <DialogTitle id="dialog-confirm-title">Nuevo Tour</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <input
                style={{
                  display: "none",
                }}
                accept="image/*"
                onChange={(event) => {
                  if (event.target.files) {
                    if (event.target.files[0].size > 543718) {
                      alert("Archivo demasiado pesado. Debe ser menor a 500kb");
                    } else {
                      setFieldValue("PortadaTour", event.target.files[0]);
                    }
                  }
                }}
                id="contained-button-file"
                type="file"
              />
              <Paper
                elevation={0}
                variant="outlined"
                style={{
                  height: 240,
                  width: "100%",
                }}
              >
                <label
                  style={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  htmlFor="contained-button-file"
                >
                  {values.PortadaTour ? (
                    <CardMedia
                      component="img"
                      alt={"UploadImage"}
                      style={{ maxHeight: 240, objectFit: "contain" }}
                      image={URL.createObjectURL(values.PortadaTour)}
                      title="Portada del Tour"
                    />
                  ) : (
                    <Typography
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: blue[400],
                      }}
                    >
                      <Camera size={20} style={{ marginRight: 5 }} />
                      Cargar Portada
                    </Typography>
                  )}
                </label>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="Nombre"
                label="Nombre Tour"
                autoComplete="off"
                type="text"
                value={values.Nombre}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Nombre && Boolean(errors.Nombre)}
                helperText={touched.Nombre && errors.Nombre}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="TipoTour"
                value={
                  values?.TipoTour
                    ? tourTypes.find(
                        (tour) => tour?.Nombre === values?.TipoTour
                      )
                    : null
                }
                options={tourTypes}
                getOptionLabel={(option) => option.Nombre}
                onChange={(_: any, newValue: any | null) => {
                  if (newValue) {
                    setFieldValue("TipoTour", newValue.Nombre);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tipo tour"
                    error={touched.TipoTour && Boolean(errors.TipoTour)}
                    helperText={touched.TipoTour && errors.TipoTour}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="Region"
                value={
                  values?.Region
                    ? regions.find((r) => r.Nombre === values?.Region)
                    : null
                }
                options={regions}
                getOptionLabel={(option) => option.Nombre}
                onChange={(event: any, newValue: any | null) => {
                  if (newValue) {
                    setFieldValue("Region", newValue.Nombre);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Regiones"
                    error={touched.Region && Boolean(errors.Region)}
                    helperText={touched.Region && errors.Region}
                  />
                )}
              />
            </Grid>
            {user?.tipoUsuario === "Administrador" && (
              <Grid item xs={12} md={6}>
                <TextField
                  id="Precio"
                  label="Precio Tour"
                  autoComplete="off"
                  value={values.Precio}
                  disabled={user.tipoUsuario !== "Administrador"}
                  variant="outlined"
                  name="Precio"
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Precio && Boolean(errors.Precio)}
                  helperText={touched.Precio && errors.Precio}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                id="Duracion"
                label="Duracion Tour Aprox. (Minutos)"
                autoComplete="off"
                type="number"
                value={values.Duracion}
                name="Duracion"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Duracion && Boolean(errors.Duracion)}
                helperText={touched.Duracion && errors.Duracion}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="caption">
                Fecha Creación: {format(new Date(), "dd/MM/yyyy")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancelar
          </Button>
          <Button
            disabled={isLoading || disabled}
            color="primary"
            type="submit"
          >
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
