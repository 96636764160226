import React, { useEffect } from "react";
import {
  Box,
  GridList,
  GridListTile,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Paper,
} from "@material-ui/core";
import { useFormik } from "formik";
import {
  Search as SearchIcon,
  ArrowRightCircle as ArrowIcon,
} from "react-feather";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../../constants";
import { useTable } from "../../hooks/useTable";
import { useDispatch, useSelector } from "react-redux";
import { ISalesState } from "../../redux/reducers/salesReducer";
import { RootState } from "../../redux/reducers/rootReducer";
import { timesStampToDateFormatted } from "../../utils/utils";
import { TableSkeleton } from "../TableSkeleton";
import { useStyles } from "../../theme/useStyles";
import { getUserSales } from "../../redux/actions/salesAction";

type ObjectType = {
  id: string;
  Nombre: string;
  Apellido: string;
  Email: string;
  Telefono: string;
  Pais: string;
};

interface DialogProps {
  open: boolean;
  onClose: () => void;
  onAccept: (values: any) => void;
  isLoading: boolean;
  object?: ObjectType;
  title?: string;
}
export const DatosFicha: React.FC<DialogProps> = ({
  open,
  onClose,
  onAccept,
  object,
  title = "",
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { handleSubmit, values, setValues } = useFormik({
    initialValues: {
      id: "",
      Nombre: "",
      Email: "",
      Telefono: "",
      Pais: "",
    },
    onSubmit: (values) => {},
  });

  const { userSales, totalDocs, isLoading } = useSelector<
    RootState,
    ISalesState
  >((state) => state.salesReducer);

  const { limit, page, handleLimitChange, handlePageChange } = useTable({
    limit: TABLE_LIMIT_DEFAULT,
    onLimitChange: (event, newLimit) => {
      if (newLimit > userSales.length) {
        // dispatch(getTourSales(newLimit));
      }
    },
    onPageChange: (newPage, oldPage, limit) => {
      if (userSales.length < totalDocs && newPage > oldPage) {
        // dispatch(getMoreTourSales(limit));
      }
    },
  });

  useEffect(() => {
    if (object) {
      setValues({
        id: object?.id,
        Nombre: object?.Nombre + " " + object?.Apellido,
        Email: object?.Email + "",
        Telefono: object?.Telefono + "",
        Pais: object?.Pais + "",
      });
    }
  }, [open, setValues, object]);

  useEffect(() => {
    if (object) {
      // dispatch(getUserSales(object.id));
    }
  }, [object, dispatch]);

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      aria-labelledby="add-and-update-dialog-title"
    >
      <DialogTitle id="dialog-confirm-title">
        {`Usuario ` + values.Nombre}
      </DialogTitle>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Box display="flex" flexWrap="nowrap">
                <Box order={1}>
                  <ArrowIcon />
                </Box>
                <Box m={1} order={2}>
                  {values.Nombre}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" flexWrap="nowrap">
                <Box order={1}>
                  <ArrowIcon />
                </Box>
                <Box m={1} order={2}>
                  {values.Email}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Box display="flex" flexWrap="nowrap">
                <Box order={1}>
                  <ArrowIcon />
                </Box>
                <Box m={1} order={2}>
                  {values.Telefono}++++
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" flexWrap="nowrap">
                <Box order={1}>
                  <ArrowIcon />
                </Box>
                <Box m={1} order={2}>
                  {values.Pais}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Card>
            <Grid container spacing={4}>
              <CardContent>
                <TableContainer>
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Tour</TableCell>
                        <TableCell>Guía</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>N° Venta</TableCell>
                        <TableCell>Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!isLoading ? (
                        userSales
                          .slice(page * limit, page * limit + limit)
                          .map((data: any) => (
                            <Fade key={data.id} in={true}>
                              <TableRow hover className={classes.styledRow}>
                                <TableCell>
                                  {data.Usuario?.Nombre +
                                    " " +
                                    data.Usuario?.Apellido}
                                </TableCell>
                                <TableCell>
                                  {timesStampToDateFormatted(
                                    new Date(data.FechaCreacion.seconds * 1000)
                                  )}
                                </TableCell>
                                <TableCell>{data.Tour?.Precio}</TableCell>
                              </TableRow>
                            </Fade>
                          ))
                      ) : (
                        <TableSkeleton colSpan={10} limit={10} />
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={totalDocs}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={TABLE_LIMITS}
                />
              </CardContent>
            </Grid>
          </Card>
        </DialogContent>
      </form>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
