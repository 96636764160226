import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Wrapper } from "@material-ui/pickers/wrappers/Wrapper";
import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import * as Yup from "yup";
import { THEMES } from "../constants";

import { useHistory } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";

import { firestore, firebaseConfig, storage, auth } from "../firebase/firebase";

export const DeleteAccount = () => {
  const [success, setSuccess] = useState(false);

  const history = useHistory();

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setErrors,
    setFieldValue,
    touched,
    values,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      submit: false,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Debe ser un email válido")
        .max(255)
        .required("Email es requerido"),
      password: Yup.string().max(255).required("Contraseña es requerida"),
    }),
    onSubmit: async (values) => {
      if (values.submit) {
        await auth
          .signInWithEmailAndPassword(values.email, values.password)
          .then(async (data) => {
            const uid = auth.currentUser?.uid;

            await firestore.collection("Usuarios").doc(uid).update({
              Email: null,
              google: null,
            });

            await axios.post(
              "https://us-central1-traveapp-fa700.cloudfunctions.net/app/delete/user/auth",
              {
                email: auth.currentUser?.email,
              }
            );

            setSuccess(true);
          })
          .catch((error) => {
            setErrors({
              submit: error.message,
            });
          });
      } else {
        setErrors({
          submit: "Debes aceptar las condiciones antes de continuar",
        });
      }
    },
  });

  useEffect(() => {
    console.log(values);
  }, [values]);

  return (
    <Box
      style={{
        maxWidth: "520px",
        background: "white",
        padding: 20,
        boxShadow: "1px 1px 3px rgba(0,0,0,.5)",
      }}
    >
      {isSubmitting ? (
        <Loader />
      ) : (
        <>
          <button
            onClick={() => {
              axios
                .post(
                  `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${firebaseConfig.apiKey}`,
                  {
                    requestType: "PASSWORD_RESET",
                    email: "juancristobaldeveloper@gmail.com",
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                )
                .then(async (data) => {
                  console.log(data);
                });
            }}
          >
            enviar correo
          </button>
          <Helmet title="Eliminar cuenta" />
          {/* <img src={logo} style={{ maxHeight: 50 }} alt="logo-img" /> */}
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Eliminar Cuenta
          </Typography>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              history.push("/auth/sign-in");
            }}
          >
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="email"
              name="email"
              label="Dirección de Email"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <TextField
              type="password"
              name="password"
              label="Contraseña"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            {/* TODO: dejar funcionando el recordarme */}
            <FormControlLabel
              style={{
                marginTop: 15,
              }}
              control={
                <Checkbox
                  value="submit"
                  checked={values.submit}
                  onChange={(event) => {
                    setFieldValue("submit", !values.submit);
                  }}
                  color="primary"
                />
              }
              label="Entiendo que esta accion es irreversible y se borraran todos mis datos relacionados a TraveApp"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={false}
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              Eliminar cuenta
            </Button>
          </form>
        </>
      )}
      <Snackbar
        open={success}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          setSuccess(false);
          history.push("/sign-in");
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert elevation={6} variant="filled">
          <p>Usuario eliminado con exito</p>
        </Alert>
      </Snackbar>
    </Box>
  );
};
