import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

interface Props {
  open: boolean;
  title: string;
  onClose: () => void;
  onConfirm: (reason: string) => void;
  content?: React.ReactNode | string;
}

export const BlockTourDialog: React.FC<Props> = ({
  title,
  onClose,
  onConfirm,
  open,
  content = "",
}) => {
  const [textReason, setTextReason] = useState("");
  const [optionReason, setOptionReason] = useState("");

  const reasons = [
    "Información incompleta o desactualizada",
    "Horarios mal indicados",
    "Coordenadas no concuerdan con archivo",
    "Tour incompleto",
    "Guia no registrado en sernatur",
    "Archivos no compatibles",
    "Tour desactualizado",
    "Mala calidad de los archivos",
    "Lenguaje inapropiado",
    "Infringe las normas y condiciones",
    "Otro",
  ];
  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle id="dialog-confirm-title">{title}</DialogTitle>
      <DialogContent>
        <Autocomplete
          id="TipoTour"
          value={
            optionReason
              ? reasons.find((reason) => reason === optionReason)
              : null
          }
          options={reasons}
          getOptionLabel={(option: any) => option}
          onChange={(_: any, newValue: any | null) => {
            if (newValue) {
              setOptionReason(newValue);
            } else {
              setOptionReason("");
            }
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Motivos" />
          )}
        />
        <div style={{ height: 10 }} />
        <TextField
          id="outlined-multiline-static"
          label="Detalles"
          onChange={(e) => {
            if (e) setTextReason(e.target.value);
          }}
          multiline
          rows={4}
          variant="outlined"
          inputProps={{ maxLength: 300 }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => onConfirm(optionReason + `\n` + textReason)}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
