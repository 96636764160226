import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Plus, Trash2 } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { FormState } from "../../models/form_state";
import {
  createPlacesType,
  getPlacesTypes,
  updatePlacesType,
} from "../../redux/actions/placesTypesActions";
import {
  createTourType,
  getTourTypes,
  removeTourType,
} from "../../redux/actions/tourTypesActions";
import { IPlacesTypesState } from "../../redux/reducers/placesTypesReducer";
import { RootState } from "../../redux/reducers/rootReducer";
import { ITourTypeState } from "../../redux/reducers/tourTypesReducer";

interface Props {
  open: boolean;
  onClose: () => void;
}

interface ItemListProps {
  data: any;
  onChange: (value: string) => void;
}

export const DialogTiposTours: React.FC<Props> = React.memo(
  ({ open, onClose }) => {
    const dispatch = useDispatch();
    const [valueTxt, setValueTxt] = useState("");
    const handleChangeTxt = (e: any) => {
      setValueTxt(e.target.value);
    };
    const { tourTypes } = useSelector<RootState, ITourTypeState>(
      (state) => state.tourTypesReducer
    );

    useEffect(() => {
      dispatch(getTourTypes());
    }, []);
    return (
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            onClose();
          }
        }}
        aria-labelledby="add-and-update-dialog-title"
      >
        <DialogTitle id="add-and-update-dialog-title">
          Tipos de Tours
        </DialogTitle>
        <DialogContent>
          <List>
            {tourTypes?.map((place: any) => (
              <MyItemList
                key={place.id}
                data={place}
                onChange={(value) => dispatch(removeTourType(value))}
              />
            ))}
            <ListItem button alignItems="center">
              <TextField
                style={{ paddingRight: 20 }}
                id="outlined-basic"
                size="small"
                value={valueTxt}
                fullWidth
                variant="outlined"
                onChange={handleChangeTxt}
              />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => {
                    dispatch(createTourType(valueTxt));
                    setValueTxt("");
                  }}
                >
                  <Plus />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

const MyItemList: React.FC<ItemListProps> = React.memo(({ data, onChange }) => {
  const [isChecked, setIsChecked] = useState(data.Activo);

  const handleChangeChecked = () => {
    setIsChecked(!isChecked);
    // onChange(!isChecked);
  };
  return (
    <ListItem button>
      <ListItemText primary={data.Nombre} />
      <ListItemSecondaryAction>
        <Tooltip title="Eliminar">
          <span>
            <IconButton size="small" onClick={() => onChange(data.id)}>
              <Trash2 color="red" />
            </IconButton>
          </span>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
});
