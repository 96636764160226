import {
  Button,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Edit3 } from "react-feather";
import { THEMES } from "../constants";
import { firestore, storage } from "../firebase/firebase";
import { useDispatch, useSelector } from "react-redux";
import { SnackState } from "../models/snack-state";
import { openSnack } from "../redux/actions/uiActions";
import { getPlaceImages } from "../redux/actions/placesActions";
import { SaveCreationNotif } from "../redux/actions/tourGuidesActions";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import * as types from "../constants/index";

export const PlacesImages = ({ selectedPlace }) => {
  const { placesImages } = useSelector((state) => state.placesReducer);

  const [editImages, setEditImages] = useState(false);
  const [loading, setLoading] = useState({
    loadingUpload: false,
    loadingDelete: false,
  });

  const [displayImages, setDisplayImages] = useState({
    frontImage: {
      name: "",
      image: "",
    },
    galleryImages: [],
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPlaceImages(selectedPlace.id));
  }, [loading]);

  useEffect(() => {
    if (placesImages) {
      let state = {
        frontImage: {},
        galleryImages: [],
      };

      placesImages.forEach((image) => {
        if (image.Type === "Portada") {
          state.frontImage = {
            source: "Firebase",
            path: image.Url,
            name: image.name,
            id: image.id,
          };
        } else {
          state.galleryImages.push({
            source: "Firebase",
            path: image.Url,
            name: image.name,
            id: image.id,
          });
        }
      });
      setDisplayImages(state);
    }
  }, [placesImages]);

  console.log(loading);

  useEffect(() => {
    console.log(displayImages);
  }, [displayImages]);

  const deleteImage = async (
    selectedPlace,
    imageId,
    imagePath,
    refetch = true
  ) => {
    setLoading({ ...loading, loadingDelete: true });
    const lastSlashIndex = imagePath.lastIndexOf("/") + 1;
    const storagePath = decodeURIComponent(imagePath.substring(lastSlashIndex));

    console.log(storagePath);
    const errors = [];

    await firestore
      .collection("Lugares")
      .doc(selectedPlace.id)
      .collection("Imagenes")
      .doc(imageId)
      .delete()
      .catch((error) => {
        errors.push(error);
      });

    // Eliminar el archivo de Firebase Storage
    if (!errors.length) {
      const storageRef = storage.ref();
      await storageRef
        .child(storagePath)
        .delete()
        .catch((error) => {
          errors.push(error);
        });
    }

    if (errors.length) {
      dispatch({
        type: types.PLACES_UPDATE_FAILURE,
        payload: "Ha ocurrido un error eliminando una imagen para este lugar",
      });
    } else if (refetch) {
      dispatch({
        type: types.PLACES_UPDATE_SUCCESS,
      });

      dispatch(
        openSnack(
          "Se han actualizado las imagenes publicitarias",
          SnackState.SUCCESS
        )
      );
      dispatch(getPlaceImages(selectedPlace.id));
    }

    setLoading({ ...loading, loadingDelete: false });
  };

  const handleUpload = async () => {
    setLoading({ ...loading, loadingUpload: true });
    let errors = [];

    const { frontImage, galleryImages } = displayImages;
    const imagesArray = [frontImage, ...galleryImages];

    const getImagesByType = async (type) => {
      const response = await firestore
        .collection("Lugares")
        .doc(selectedPlace.id)
        .collection("Imagenes")
        .where("Type", "==", type)
        .get();

      const images = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      return images;
    };

    if (galleryImages.length) {
      if (galleryImages[0].source === "Local") {
        const imagesDBGallery = await getImagesByType("Galeria");

        if (imagesDBGallery.length) {
          await imagesDBGallery.forEach(async (image) => {
            const lastSlashIndex = image.Url.lastIndexOf("/") + 1;
            const storagePath = decodeURIComponent(
              image.Url.substring(lastSlashIndex)
            );

            await firestore
              .collection("Lugares")
              .doc(selectedPlace.id)
              .collection("Imagenes")
              .doc(image.id)
              .delete()
              .catch((error) => {
                errors.push(error);
              });

            // Eliminar el archivo de Firebase Storage
            if (!errors.length) {
              const storageRef = storage.ref();
              await storageRef
                .child(storagePath)
                .delete()
                .catch((error) => {
                  errors.push(error);
                });
            }
          });
        }

        galleryImages.forEach(async (image) => {
          const imageRef = storage.ref(
            `lugares/${selectedPlace.id}/images/${image.name}`
          );
          await imageRef.put(image.file);

          await imageRef
            .getDownloadURL()
            .then(async (downloadURL) => {
              firestore
                .collection("Lugares")
                .doc(selectedPlace.id)
                .collection("Imagenes")
                .add({
                  Type: image.type,
                  Url: downloadURL,
                });
              dispatch(getPlaceImages(selectedPlace.id));
            })
            .catch((error) => {
              error = error;
            });
        });
      }
    }

    if (frontImage.path) {
      if (frontImage.source === "Local") {
        const frontImageDB = await getImagesByType("Portada");

        console.log(frontImageDB);

        if (frontImageDB.length) {
          const lastSlashIndex = frontImageDB[0].Url.lastIndexOf("/") + 1;
          const storagePath = decodeURIComponent(
            frontImageDB[0].Url.substring(lastSlashIndex)
          );

          await firestore
            .collection("Lugares")
            .doc(selectedPlace.id)
            .collection("Imagenes")
            .doc(frontImageDB[0].id)
            .delete()
            .catch((error) => {
              errors.push(error);
            });

          // Eliminar el archivo de Firebase Storage
          if (!errors.length) {
            const storageRef = storage.ref();
            await storageRef
              .child(storagePath)
              .delete()
              .catch((error) => {
                errors.push(error);
              });
          }
        }

        const imageRef = storage.ref(
          `lugares/${selectedPlace.id}/images/${frontImage.name}`
        );
        await imageRef.put(frontImage.file);

        await imageRef
          .getDownloadURL()
          .then(async (downloadURL) => {
            firestore
              .collection("Lugares")
              .doc(selectedPlace.id)
              .collection("Imagenes")
              .add({
                Type: frontImage.type,
                Url: downloadURL,
              });
            dispatch(getPlaceImages(selectedPlace.id));
          })
          .catch((error) => {
            error = error;
          });
      }
    }

    if (errors.length) {
      dispatch({
        type: types.PLACES_UPDATE_FAILURE,
        payload: "Ha ocurrido un error subiendo imagen para lugar",
      });
    } else {
      dispatch({
        type: types.PLACES_UPDATE_SUCCESS,
      });

      await SaveCreationNotif(
        `${selectedPlace?.NombreLugar}: Ha actualizado sus imagenes publicitarias`
      );
      await dispatch(
        openSnack(
          "Se han actualizado las imagenes publicitarias",
          SnackState.SUCCESS
        )
      );
    }
    setLoading({ ...loading, loadingUpload: false });
  };
  /*
    if (galleryImages.length) {
      galleryImages.forEach(async (image) => {
        if (image.source == "Local") {
          const imageRef = storage.ref(
            `lugares/${selectedPlace.id}/images/${image.name}`
          );
          await imageRef.put(image.file);

          await imageRef
            .getDownloadURL()
            .then(async (downloadURL) => {
              firestore
                .collection("Lugares")
                .doc(selectedPlace.id)
                .collection("Imagenes")
                .add({
                  Type: image.type,
                  Url: downloadURL,
                });
              dispatch(getPlaceImages(selectedPlace.id));
            })
            .catch((error) => {
              error = error;
            });
        }
      });
    }

    if (error) {
      dispatch({
        type: types.PLACES_UPDATE_FAILURE,
        payload: "Ha ocurrido un error subiendo imagen para lugar",
      });
    } else {
      dispatch({
        type: types.PLACES_UPDATE_SUCCESS,
      });

      await SaveCreationNotif(
        `${selectedPlace?.NombreLugar}: Ha actualizado sus imagenes publicitarias`
      );
      await dispatch(
        openSnack(
          "Se han actualizado las imagenes publicitarias",
          SnackState.SUCCESS
        )
      );
    }
    setLoading(false);
  };
  */

  const selectImages = (event, type) => {
    const files = event.target.files;

    const readerImage = (file) => {
      return {
        source: "Local",
        path: URL.createObjectURL(file),
        name: file.name,
        file: file,
        type,
      };
    };

    const imagesArray = Array.from(files);

    if (imagesArray.length) {
      const images = imagesArray.map((file) => readerImage(file));

      if (type === "Portada")
        setDisplayImages({
          ...displayImages,

          frontImage: images[0],
        });
      else
        setDisplayImages({
          ...displayImages,
          source: "Local",
          galleryImages: images,
        });
    }
  };

  return (
    <>
      <CardContent>
        <Grid spacing={3} container>
          <Grid item container>
            <Grid justify="flex-start" xs={6} item>
              <Typography
                align="left"
                variant="h5"
                style={{
                  fontWeight: 700,
                }}
              >
                Imagenes promocionales
              </Typography>
            </Grid>
            <Grid
              style={{
                display: "flex",
              }}
              xs={6}
              justify="flex-end"
              item
            >
              <Button
                startIcon={<Edit3 />}
                variant="contained"
                color="primary"
                onClick={() => setEditImages(!editImages)}
                size="small"
                disabled={editImages}
              >
                Editar
              </Button>
            </Grid>
          </Grid>
          <Grid xs={12} md={6} sm={6} item>
            <Typography
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              variant="h6"
            >
              Subir imagenes
            </Typography>
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button
                disabled={!editImages || loading.loadingUpload}
                size="small"
                variant="contained"
                component="label"
                startIcon={<CloudUpload />}
                style={{
                  width: "80%",
                }}
              >
                Imagen de portada
                <input
                  type="file"
                  disabled={!editImages || loading.loadingUpload}
                  hidden
                  accept=".jpg, .jpeg"
                  onChange={(event) => selectImages(event, "Portada")}
                />
              </Button>
              {displayImages.frontImage.name && (
                <p>{displayImages.frontImage.name}</p>
              )}
              <Button
                disabled={!editImages || loading.loadingUpload}
                style={{
                  marginTop: displayImages.frontImage.name ? 0 : 20,
                  width: "80%",
                }}
                size="small"
                variant="contained"
                component="label"
                startIcon={<CloudUpload />}
              >
                Imagenes de la galeria
                <input
                  disabled={!editImages || loading.loadingUpload}
                  type="file"
                  multiple
                  hidden
                  accept=".jpg, .jpeg"
                  onChange={async (event) => selectImages(event, "Galeria")}
                />
              </Button>
              {displayImages.galleryImages.length
                ? displayImages.galleryImages.map((image, index) => (
                    <p key={index}>{image.name}</p>
                  ))
                : ""}
            </Grid>
          </Grid>
          <Grid xs={12} md={6} sm={6} item>
            <Grid item>
              <Typography
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
                variant="h6"
              >
                Vista previa
              </Typography>
              <Typography
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
              >
                Imagen de portada
              </Typography>
              <div
                style={{
                  width: "100%",
                  height: 200,
                  borderRadius: 10,
                  backgroundSize: "cover",

                  border: displayImages.frontImage.path
                    ? "none"
                    : "1px solid silver",
                  backgroundImage: displayImages.frontImage.path
                    ? `url(${displayImages.frontImage.path})`
                    : "none",
                }}
              >
                {displayImages.frontImage.source === "Firebase" && (
                  <Button
                    disabled={!editImages || loading.loadingDelete}
                    style={{
                      opacity: !editImages ? 0.75 : 1,
                      background: "white",
                      margin: 5,
                    }}
                    onClick={() => {
                      deleteImage(
                        selectedPlace,
                        displayImages.frontImage.id,
                        displayImages.frontImage.path
                      );
                    }}
                  >
                    {loading.loadingDelete ? (
                      <CircularProgress />
                    ) : (
                      <DeleteForeverIcon htmlColor={`rgb(0, 78, 203)`} />
                    )}
                  </Button>
                )}
              </div>
              <div>
                <h3
                  style={{
                    fontWeight: 500,
                  }}
                >
                  Galeria de imagenes
                </h3>
                <div
                  style={{
                    height: 200,
                    padding: 15,
                    paddingLeft: 0,
                    display: "flex",
                    overflow: "auto",
                  }}
                >
                  {displayImages.galleryImages.length ? (
                    displayImages.galleryImages.map((image, index) => (
                      <div
                        key={index}
                        style={{
                          borderRadius: 10,
                          minWidth: "80%",
                          height: "100%",
                          background: "whitesmoke",
                          marginRight: 10,
                          backgroundImage: `url(${image.path})`,
                          backgroundSize: "cover",
                        }}
                      >
                        {image.source === "Firebase" && (
                          <Button
                            disabled={!editImages || loading.loadingDelete}
                            style={{
                              opacity: editImages ? 1 : 0.75,
                              background: "white",
                              margin: 5,
                            }}
                            onClick={() =>
                              deleteImage(selectedPlace, image.id, image.path)
                            }
                          >
                            {loading.loadingDelete ? (
                              <CircularProgress />
                            ) : (
                              <DeleteForeverIcon
                                htmlColor={`rgb(0, 78, 203)`}
                              />
                            )}
                          </Button>
                        )}
                      </div>
                    ))
                  ) : (
                    <div
                      style={{
                        background: "whitesmoke",
                        borderRadius: 10,
                        minWidth: "80%",
                        height: "100%",
                        border: "1px solid silver",
                        marginRight: 10,
                      }}
                    ></div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container xs={12} justify="flex-end" item>
            <Button
              disabled={!editImages || loading.loadingDelete}
              style={{
                opacity: !editImages ? 0.5 : 1,
                border: `1px solid ${THEMES.BLUE}`,
                padding: "5px 25px",
                color: THEMES.BLUE,
              }}
              onClick={() => {
                handleUpload();
              }}
            >
              {loading.loadingUpload ? <CircularProgress /> : "Guardar"}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};
