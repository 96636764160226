import { AppThunk } from "../../models/app-thunk";
import * as types from "../../constants";
import { Action } from "../../models/action";
import { firestore } from "../../firebase/firebase";
import firebase from "firebase/app";

const DEFAULT_ORDER_BY = "Nombre";
const DEFAULT_COLLECTION_NAME = "Idiomas";

export const getIdiomas = (): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY)
        .get();

      dispatch(setIdiomas(mapIdiomas(response)));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

const mapIdiomas = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  try {
    let idiomas: any[] = [];
    response.docs.forEach((doc) => {
      let data = mapIdioma(doc);
      idiomas.push(data);
    });
    return idiomas;
  } catch (error) {
    throw error;
  }
};

const mapIdioma = (doc: any) => {
  let data = doc.data();
  data.id = doc.id;
  return data;
};

const setIdiomas = (idiomas: any[]): Action => ({
  type: types.IDIOMA_GET_DOCS,
  payload: idiomas,
});

const isLoading = (isLoading: boolean): Action => ({
  type: types.IDIOMA_LOADING,
  payload: isLoading,
});

const setError = (error: string): Action => ({
  type: types.IDIOMA_FAILURE,
  payload: error,
});
