import Axios from "axios";
import firebase from "firebase/app";
import * as types from "../../constants";
import { firebaseConfig, firestore, storage } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { NewGoogleUserResponse } from "../../models/new_google_user_response";
import { SnackState } from "../../models/snack-state";
import { getTourGuide } from "./tourGuidesActions";
import { openSnack } from "./uiActions";

const API_KEY = firebaseConfig.apiKey;

export const getUsers = (limit: number = 10): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore
        .collection("Usuarios")
        .orderBy("Nombre")
        .get();

      dispatch(setUsers(mapUsers(response)));
      dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getUser = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore.collection("Usuarios").doc(id).get();

      dispatch(setSelectedUser(mapUser(response)));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const createUser = (user: any, business: any): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      //Crear usuario en Authentication
      await Axios.post<NewGoogleUserResponse>(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${API_KEY}`,
        {
          email: user.Email,
          password: "+4Uh#K21", //Test-1234 Anteriormente
          returnSecureToken: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).catch((erroCreateUsr) => {
        if (erroCreateUsr.response.data.error.message === "EMAIL_EXISTS") {
          dispatch(
            openSnack("Este correo ya ha sido registrado", SnackState.ERROR)
          );
        } else if (
          erroCreateUsr.response.data.error.message ===
          "TOO_MANY_ATTEMPTS_TRY_LATER"
        ) {
          dispatch(openSnack("Demasiados intentos", SnackState.ERROR));
        } else {
          dispatch(openSnack("Ha ocurrido un error", SnackState.ERROR));
        }
      });

      //Crear registro en tabla Usuarios
      const response = await firestore.collection("Usuarios").add({
        ...user,
        EmpresaReference: firestore.collection("Empresas").doc(business.id),
        Prioridades: business.Prioridades,
        Recintos: business.Recintos,
        Sectores: business.Sectores,
        Subcontratos: business.Subcontratos,
      });

      //Aumentar el contador
      dispatch(
        openSnack(
          "Usuario creado, se ha enviado enlace al correo",
          SnackState.SUCCESS
        )
      );
      dispatch(setSelectedUser(mapUser(response)));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const updateUser = (user: any, image: any = null): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(editIsLoading(true));
    const selectedUser = getState().usersReducer.edit.selectedUser || "";
    try {
      const data = { ...user };
      delete data.id;
      if (image !== null) {
        user.Perfil = image;
      }

      if (user.Perfil !== "" && user.Perfil !== selectedUser?.Perfil) {
        const imageRef = storage.ref(`${user.id}/profile_photo`);
        const imageUp = imageRef.put(user.Perfil);
        imageUp.on(
          "state_change",
          () => {
            // const progress =
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.log(error);
          },
          () => {
            imageUp.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              user.Perfil = downloadURL;
              data.Perfil = downloadURL;
              firestore.collection("Usuarios").doc(user.id).update(data);
              dispatch(openSnack("Datos Actualizados", SnackState.SUCCESS));
              dispatch(getTourGuide(user.id));
            });
          }
        );
      } else {
        delete data.Perfil;
        await firestore.collection("Usuarios").doc(user.id).update(data);
      }

      dispatch(openSnack("Datos Actualizados", SnackState.SUCCESS));
      dispatch(updateUserStore(user));
      dispatch(updateSelectedUserStore(user));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(editIsLoading(false));
    }
  };
};

export const updateTourGuideUser = (
  user: any,
  image: any = null,
  files?: any
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(editIsLoading(true));
    const selectedUser = getState().tourGuidesReducer.edit.selectedGuide || "";
    try {
      const data = { ...user };
      delete data.id;
      if (image !== null) {
        user.Perfil = image;
      }

      delete data.CertificadoAntecedentes;
      delete data.CedulaIdentidad;
      delete data.CertificadoTributario;
      delete data.CertificadoCompetencias;

      // File upload if exists
      if (files.CertificadoAntecedentes) {
        const fileRef = storage.ref(
          `${user.id}/documentos/CertificadoAntecedentes`
        );

        const fileUpload = await fileRef.put(files.CertificadoAntecedentes);

        const url = await fileUpload.ref.getDownloadURL();

        data.CertificadoAntecedentes = url;
        data.CertificadoAntecedentesRef = `${user.id}/documentos/CertificadoAntecedentes`;
      }

      if (files.CedulaIdentidad) {
        const fileRef = storage.ref(`${user.id}/documentos/CedulaIdentidad`);

        const fileUpload = await fileRef.put(files.CedulaIdentidad);

        const url = await fileUpload.ref.getDownloadURL();

        data.CedulaIdentidad = url;
        data.CedulaIdentidadRef = `${user.id}/documentos/CedulaIdentidad`;
      }

      if (files.CertificadoTributario) {
        const fileRef = storage.ref(
          `${user.id}/documentos/CertificadoTributario`
        );

        const fileUpload = await fileRef.put(files.CertificadoTributario);

        const url = await fileUpload.ref.getDownloadURL();

        data.CertificadoTributario = url;
        data.CertificadoTributarioRef = `${user.id}/documentos/CertificadoTributario`;
      }

      if (files.CertificadoCompetencias) {
        const fileRef = storage.ref(
          `${user.id}/documentos/CertificadoCompetencias`
        );

        const fileUpload = await fileRef.put(files.CertificadoCompetencias);

        const url = await fileUpload.ref.getDownloadURL();

        data.CertificadoCompetencias = url;
        data.CertificadoCompetenciasRef = `${user.id}/documentos/CertificadoCompetencias`;
      }

      // End Files Upload

      if (user.Perfil !== "" && user.Perfil !== selectedUser?.Perfil) {
        const imageRef = storage.ref(`${user.id}/profile_photo`);

        const imageUp = imageRef.put(user.Perfil);

        imageUp.on(
          "state_change",
          () => {
            // const progress =
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.log(error);
          },
          () => {
            imageUp.snapshot.ref
              .getDownloadURL()
              .then(async function (downloadURL) {
                user.Perfil = downloadURL;
                data.Perfil = downloadURL;
                if (user.Email !== selectedUser.Email) {
                  await Axios.post(
                    `https://us-central1-traveapp-fa700.cloudfunctions.net/app/update/user/email`,
                    {
                      user: user.id,
                      email: data.Email,
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                      },
                    }
                  )
                    .then(async () => {
                      await firestore
                        .collection("Usuarios")
                        .doc(user.id)
                        .update(data);
                      dispatch(
                        openSnack("Datos Actualizados", SnackState.SUCCESS)
                      );
                      dispatch(getTourGuide(user.id));
                    })
                    .catch((errorAuth) => {
                      dispatch(
                        openSnack(
                          "No se han podido actualizar los datos",
                          SnackState.ERROR
                        )
                      );
                      console.log(errorAuth);
                    });
                } else {
                  await firestore
                    .collection("Usuarios")
                    .doc(user.id)
                    .update(data);
                  dispatch(openSnack("Datos Actualizados", SnackState.SUCCESS));
                  dispatch(getTourGuide(user.id));
                }
              });
          }
        );
      } else {
        delete data.Perfil;
        if (user.Email !== selectedUser.Email) {
          await Axios.post(
            `https://us-central1-traveapp-fa700.cloudfunctions.net/app/update/user/email`,
            {
              user: user.id,
              email: data.Email,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
            .then(async () => {
              await firestore.collection("Usuarios").doc(user.id).update(data);
              dispatch(openSnack("Datos Actualizados", SnackState.SUCCESS));
            })
            .catch(() => {
              dispatch(
                openSnack(
                  "No se han podido actualizar los datos",
                  SnackState.ERROR
                )
              );
            });
        } else {
          await firestore.collection("Usuarios").doc(user.id).update(data);
          dispatch(openSnack("Datos Actualizados", SnackState.SUCCESS));
        }
      }

      dispatch(updateUserStore({ id: user.id, ...data }));
      dispatch(updateSelectedUserStore({ id: user.id, ...data }));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(editIsLoading(false));
    }
  };
};

export const addUserGuideVideo = (
  video: any,
  id: any,
  idioma: any
): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.USERS_GUIDE_VIDEO_IS_SUBMITTING,
    });
    try {
      const loadVideo = (thisfile: any) =>
        new Promise((resolve, reject) => {
          try {
            let video = document.createElement("video");
            video.preload = "metadata";

            video.onloadedmetadata = function () {
              resolve(this);
            };

            video.onerror = function () {
              reject("Invalid video. Please select a video file.");
            };

            video.src = window.URL.createObjectURL(thisfile);
          } catch (e) {
            reject(e);
          }
        });
      if (video) {
        const videoRef = storage.ref(`${id}/videoPresentacion/${idioma}.mp4`);
        const videoFile: any = await loadVideo(video);

        const videoUp = videoRef.put(video);

        videoUp.on(
          "state_change",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            dispatch({
              type: types.USERS_GUIDE_VIDEO_PERCENTAGE,
              payload: progress.toFixed(3),
            });
          },
          (error) => {
            console.log(error);
            dispatch(openSnack("Ha ocurrido un problema", SnackState.ERROR));
          },
          () => {
            videoUp.snapshot.ref
              .getDownloadURL()
              .then(async function (downloadURL) {
                var idiomas: any = {};
                idiomas[`Idiomas.${idioma}`] = {
                  Nombre: idioma,
                  Link: downloadURL,
                  Duracion: videoFile.duration,
                };
                await firestore
                  .collection("Usuarios")
                  .doc(id)
                  .update(idiomas)
                  .then(() => {
                    dispatch({
                      type: types.USERS_GUIDE_VIDEO_SUCCESS,
                    });

                    dispatch(openSnack("Video Subido", SnackState.SUCCESS));
                    dispatch(getTourGuide(id));
                  });
              })
              .then(() =>
                dispatch({
                  type: types.USERS_GUIDE_VIDEO_PERCENTAGE,
                  payload: 0,
                })
              );
          }
        );
      }
    } catch (error: any) {
      dispatch({
        type: types.USERS_GUIDE_VIDEO_FAILURE,
        payload: error,
      });
      console.log(error);
    }
  };
};

export const deleteUserGuideVideo = (
  id: any,
  data: any,
  idioma: string
): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.USERS_GUIDE_VIDEO_IS_SUBMITTING,
    });
    try {
      const videoRef = storage.ref(`${id}/videoPresentacion/${idioma}.mp4`);
      try {
        await videoRef.delete();
      } catch (errorDeleteVideo) {}
      await firestore.collection("Usuarios").doc(id).update({ Idiomas: data });
      dispatch({
        type: types.USERS_GUIDE_VIDEO_SUCCESS,
      });
      dispatch(openSnack("Video eliminado", SnackState.SUCCESS));
    } catch (error: any) {
      dispatch({
        type: types.USERS_GUIDE_VIDEO_FAILURE,
        payload: error,
      });
      console.log(error);
    }
  };
};

const mapUsers = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  try {
    let users: any[] = [];
    response.docs.forEach((doc) => {
      let data = mapUser(doc);
      users.push(data);
    });
    return users;
  } catch (error) {
    throw error;
  }
};

const mapUser = (doc: any) => {
  let data = doc.data();
  data.id = doc.id;
  return data;
};

export const addMoreUsers = (limit: number = 10): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));

    try {
      const response = await firestore
        .collection("Usuarios")
        .orderBy("Nombre")
        .limit(limit)
        .get();

      dispatch(addUsers(mapUsers(response)));
      dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getTotalDocs = (): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await firestore.collection("Usuarios").get();
      dispatch(setTotalDocs(response.size));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    }
  };
};

export const setSelectedUser = (user: any): Action => ({
  type: types.USERS_SET_SELECTED,
  payload: user,
});

const editIsLoading = (isLoading: boolean): Action => ({
  type: types.USERS_EDIT_LOADING,
  payload: isLoading,
});

const updateUserStore = (user: any): Action => ({
  type: types.USERS_UPDATE_DOC,
  payload: user,
});

const updateSelectedUserStore = (user: any): Action => ({
  type: types.USERS_UPDATE_SELECTED_USER,
  payload: user,
});

const setUsers = (users: any[]): Action => ({
  type: types.USERS_GET_DOCS,
  payload: users,
});

const setLastDoc = (doc: any): Action => ({
  type: types.USERS_SET_LAST_DOC,
  payload: doc,
});

const addUsers = (users: any[]): Action => ({
  type: types.USERS_ADD_DOCS,
  payload: users,
});

const isLoading = (isloading: boolean): Action => ({
  type: types.USERS_LOADING,
  payload: isloading,
});

const setError = (error: string): Action => ({
  type: types.USERS_FAILURE,
  payload: error,
});

const setTotalDocs = (total: number): Action => ({
  type: types.USERS_SET_TOTAL_DOCS,
  payload: total,
});
