import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { Beenhere } from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import { format } from "date-fns";
import { useFormik } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { AlertCircle, Camera as CameraIcon } from "react-feather";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FormState } from "../../models/form_state";
import { getRegions } from "../../redux/actions/regionsActions";
import {
  changeFormState,
  updateBlocked,
  updateTour,
} from "../../redux/actions/toursActions";
import { getTourTypes } from "../../redux/actions/tourTypesActions";
import { IAuthState } from "../../redux/reducers/authReducer";
import { IRegionsState } from "../../redux/reducers/regionsReducer";
import { RootState } from "../../redux/reducers/rootReducer";
import { IToursState } from "../../redux/reducers/toursReducer";
import { ITourTypeState } from "../../redux/reducers/tourTypesReducer";
import { useStyles } from "../../theme/useStyles";
import { timesStampFormattedsimple, timeStampToDate } from "../../utils/utils";
import { AcceptDialog } from "../AcceptDialog";
import { BlockTourDialog } from "../BlockTourDialog";
import { LatLng } from "../Maps/Map";
import { ModificationsDialog } from "../ModificationsDialog";
import { NewConfirmDialog } from "../NewConfirmDialog";

type ObjectType = {
  Nombre: string;
  Region: string;
  TipoTour: string;
  Precio: number;
  Distancia: number;
  Duracion: number;
  Path: any;
  FechaCreacion: any;
  Itinerario: string;
  PortadaTour: any;
  VideoPresentacion: any;
  Codigo: any;
  Estado: any;
  Bloqueo: any;
  Modificaciones: any;
};

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      isNumericString
      prefix="$"
      decimalSeparator=","
    />
  );
}

interface Props {
  onSubmit: (values: any) => void;
  tour?: ObjectType;
}

export const FormCard: React.FC<Props> = ({ onSubmit, tour }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { tourId } = useParams<any>();

  const tourStates = ["Aprobado", "Pendiente"];

  const [tourBlocked, setTourBlocked] = useState<any | null>(tour?.Bloqueo);
  const [modificaciones, setModificaciones] = useState<any[] | boolean>(
    tour?.Modificaciones || []
  );
  const [openBlockDialog, setOpenBlockDialog] = useState(false);
  const [openUnlockDialog, setOpenUnlockDialog] = useState(false);
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  const [openChangesDialog, setOpenChangesDialog] = useState(false);
  const [openPathDialog, setOpenPathDialog] = useState(false);

  // Se agrega nueva variable de estado form state para poder redireccionar automaticamente cuando se
  // termine el loading y este se complete con exito

  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );

  const { regions } = useSelector<RootState, IRegionsState>(
    (state) => state.regionsReducer
  );

  const { tourTypes } = useSelector<RootState, ITourTypeState>(
    (state) => state.tourTypesReducer
  );

  // Se asegura que este este en estado initial cuando se ingresa por primera vez a la pantalla
  // las dependencia deben estar vacias para que solo se ejecute una vez

  useEffect(() => {
    dispatch(changeFormState(FormState.Initial));
    // Tambien se ejecura cuando se abandone la pagina
    return () => {
      dispatch(changeFormState(FormState.Initial));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getRegions());
  }, []);
  useEffect(() => {
    dispatch(getTourTypes());
  }, []);

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      Nombre: "",
      Region: "",
      TipoTour: "",
      Precio: 0,
      Path: [],
      Distancia: 0,
      Duracion: 0,
      PortadaTour: null as any | undefined,
      Estado: "Pendiente",
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const handleChangePath = (path: LatLng[], length: number) => {
    setFieldValue("Path", path);
    setFieldValue("Distancia", length);
  };
  const handleUpdateTour = () => {
    if (values.Estado === "Aprobado") {
      if (tour?.Path.length <= 0) {
        setOpenPathDialog(true);
        return;
      }
      setTourBlocked(null);
      setModificaciones(false);
    }
    const img = tour?.PortadaTour !== values.PortadaTour ? image : null;
    dispatch(updateTour(tour, values, null, img));
    setVideo(null);
    setImage(null);
    setEditable(!editable);
  };

  const handleOpenBlock = () => {
    setOpenBlockDialog(true);
  };

  const handleOpenUnlock = () => {
    setOpenUnlockDialog(true);
  };

  const [editable, setEditable] = useState(false);
  const [video, setVideo] = useState<any | undefined>(null);
  const [image, setImage] = useState<any | undefined>(null);

  const { isLoading } = useSelector<RootState, IToursState>(
    (state) => state.toursReducer
  );

  useEffect(() => {
    setEditable(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (tour) {
      setAllValues();
    } else {
      setEditable(true);
    }
  }, [setValues, tour, regions]);

  const setAllValues = () => {
    if (tour) {
      setValues({
        Nombre: tour?.Nombre,
        Region: tour?.Region,
        TipoTour: tour?.TipoTour,
        Precio: tour?.Precio,
        Distancia: 0,
        Path: tour?.Path,
        Duracion: tour?.Duracion,
        PortadaTour: tour?.PortadaTour,
        Estado: tour?.Estado,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={"Información básica"}
              subheader={tour ? "Código: " + tour.Codigo : ""}
              action={
                tour ? (
                  !editable ? (
                    <>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setEditable(!editable)}
                      >
                        Editar
                      </Button>
                      {user?.tipoUsuario === "Administrador" &&
                        (tourBlocked ? (
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{
                              marginLeft: 15,
                            }}
                            onClick={handleOpenUnlock}
                          >
                            Desbloquear
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{
                              backgroundColor: "#ff392e",
                              marginLeft: 15,
                            }}
                            onClick={handleOpenBlock}
                          >
                            Bloquear
                          </Button>
                        ))}
                    </>
                  ) : (
                    <>
                      <Button
                        style={{ marginInlineEnd: 10 }}
                        color="secondary"
                        variant="outlined"
                        onClick={() => setEditable(!editable)}
                      >
                        Cancelar
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleUpdateTour}
                        disabled={isLoading}
                      >
                        Guardar
                      </Button>
                    </>
                  )
                ) : (
                  <Button type="submit" color="primary" variant="contained">
                    Guardar
                  </Button>
                )
              }
            />
            <CardContent style={{ marginTop: -10, marginBottom: -30 }}>
              {values.Precio <= 0 && tour ? (
                <Chip
                  color="primary"
                  style={{ backgroundColor: "#00e676", marginRight: 10 }}
                  label="Tour Gratuito"
                />
              ) : (
                ""
              )}
              {user?.tipoUsuario !== "Administrador" && (
                <Chip
                  icon={<Beenhere />}
                  label={values.Estado}
                  clickable
                  color="primary"
                />
              )}
            </CardContent>
            <CardContent>
              {isLoading ? (
                <Box style={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                ""
              )}
            </CardContent>
            <CardContent>
              <Grid container spacing={2}>
                {user?.tipoUsuario === "Administrador" ? (
                  <Grid
                    container
                    item
                    justify="flex-end"
                    style={{ marginBottom: 20 }}
                  >
                    {typeof modificaciones !== "boolean" &&
                    modificaciones?.length !== 0 ? (
                      <IconButton
                        color="primary"
                        aria-label="Detalle modificaciones"
                        component="span"
                        onClick={() => setOpenChangesDialog(true)}
                        style={{ marginTop: -5 }}
                      >
                        <AlertCircle color="orange" />
                      </IconButton>
                    ) : null}
                    <Autocomplete
                      id="tour-status"
                      disabled={
                        user?.tipoUsuario === "Administrador" ? !editable : true
                      }
                      value={
                        values.Estado
                          ? tourStates.find((state) => state === values.Estado)
                          : null
                      }
                      options={tourStates}
                      size="small"
                      getOptionLabel={(option) => option}
                      onChange={(_, value) => {
                        if (value) {
                          setFieldValue("Estado", value);
                        }
                      }}
                      style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Estado Tour"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  tourBlocked && (
                    <Grid
                      container
                      item
                      justify="flex-end"
                      style={{ marginBottom: 20 }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{
                          backgroundColor: "#ff392e",
                        }}
                        onClick={() => setOpenAcceptDialog(true)}
                      >
                        Tour bloqueado (Click para más información.)
                      </Button>
                    </Grid>
                  )
                )}

                <Grid item xs={12} md={4}>
                  <TextField
                    id="Nombre"
                    label="Nombre Tour"
                    autoComplete="off"
                    type="text"
                    value={values.Nombre}
                    name="Nombre"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Nombre && Boolean(errors.Nombre)}
                    helperText={touched.Nombre && errors.Nombre}
                    fullWidth
                    inputProps={{ readOnly: !editable }}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Autocomplete
                    id="combo-box-demo"
                    value={
                      values?.TipoTour
                        ? tourTypes.find(
                            (tour) => tour?.Nombre === values?.TipoTour
                          )
                        : null
                    }
                    options={tourTypes}
                    disabled={!editable}
                    getOptionLabel={(option) => option.Nombre}
                    onChange={(event: any, newValue: any | null) => {
                      if (newValue) {
                        setFieldValue("TipoTour", newValue.Nombre);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tipo tour"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    id="combo-box-demo"
                    value={
                      values?.Region
                        ? regions.find((r) => r.Nombre === values?.Region)
                        : null
                    }
                    options={regions}
                    disabled={!editable}
                    getOptionLabel={(option) => option.Nombre}
                    onChange={(event: any, newValue: any | null) => {
                      if (newValue) {
                        setFieldValue("Region", newValue.Nombre);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Regiones"
                      />
                    )}
                  />
                </Grid>
                {user?.tipoUsuario === "Administrador" && (
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="Precio"
                      label="Precio Tour"
                      autoComplete="off"
                      value={values.Precio}
                      disabled={user.tipoUsuario !== "Administrador"}
                      variant="outlined"
                      name="Precio"
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.Precio && Boolean(errors.Precio)}
                      helperText={touched.Precio && errors.Precio}
                      fullWidth
                      inputProps={{ readOnly: !editable }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={4}>
                  <TextField
                    id="Fecha"
                    label="Fecha"
                    autoComplete="off"
                    variant="outlined"
                    type="text"
                    inputProps={{ readOnly: true }}
                    value={
                      tour
                        ? timesStampFormattedsimple(
                            timeStampToDate(tour.FechaCreacion)
                          )
                        : format(new Date(), "dd/MM/yyyy")
                    }
                    name="Nombre"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    id="Duracion"
                    label="Duracion Tour Aprox. (Minutos)"
                    autoComplete="off"
                    type="number"
                    value={values.Duracion}
                    name="Duracion"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Duracion && Boolean(errors.Duracion)}
                    helperText={touched.Duracion && errors.Duracion}
                    fullWidth
                    inputProps={{ readOnly: !editable }}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{ marginTop: 30 }}
                container
                spacing={2}
                justify="center"
              >
                <>
                  <Grid item xs={12} md={12}>
                    <input
                      style={{
                        display: "none",
                      }}
                      accept="image/*"
                      disabled={!editable}
                      onChange={(event) => {
                        if (
                          event.target?.files?.length !== 0 &&
                          event.target?.files
                        ) {
                          if (event.target.files[0].size > 543718) {
                            alert("Archivo demasiado pesado!");
                          } else {
                            setImage(event.target.files[0]);
                            setFieldValue(
                              "PortadaTour",
                              URL.createObjectURL(event.target.files[0])
                            );
                          }
                        }
                      }}
                      id="contained-button-file"
                      type="file"
                    />
                    <Paper
                      elevation={0}
                      variant="outlined"
                      style={{
                        height: 350,
                        width: "100%",
                        marginBottom: 10,
                      }}
                    >
                      <label
                        style={{
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        htmlFor="contained-button-file"
                      >
                        {values.PortadaTour ? (
                          <CardMedia
                            component="img"
                            alt={"UploadImage"}
                            style={{ maxHeight: 350, objectFit: "contain" }}
                            image={
                              image
                                ? URL.createObjectURL(image)
                                : values.PortadaTour
                            }
                            title="Portada del Tour"
                          />
                        ) : (
                          <Typography
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: blue[400],
                            }}
                          >
                            <CameraIcon size={20} style={{ marginRight: 5 }} />
                            Cargar Portada
                          </Typography>
                        )}
                      </label>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} container justify="flex-end">
                    <input
                      type="file"
                      style={{
                        display: "none",
                      }}
                      id="icon-button-file2"
                      accept="image/*"
                      hidden
                      onChange={(event) => {
                        if (
                          event.target?.files?.length !== 0 &&
                          event.target?.files
                        ) {
                          if (event.target.files[0].size > 543718) {
                            alert(
                              "Archivo demasiado pesado. Debe ser menor a 500kb"
                            );
                          } else {
                            setImage(event.target.files[0]);
                            setFieldValue(
                              "PortadaTour",
                              URL.createObjectURL(event.target.files[0])
                            );
                          }
                        }
                      }}
                    />
                    <label htmlFor="icon-button-file2">
                      <div>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          aria-label="upload picture"
                          component="span"
                          disabled={!editable}
                          startIcon={<CameraIcon />}
                        >
                          Seleccionar
                        </Button>
                      </div>
                    </label>
                  </Grid>
                </>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <BlockTourDialog
        open={openBlockDialog}
        title={"¿Desea bloquear el tour?"}
        onClose={() => setOpenBlockDialog(false)}
        onConfirm={(reason) => {
          setFieldValue("Estado", "Pendiente");
          setTourBlocked({ Motivo: reason });
          setOpenBlockDialog(false);
          dispatch(updateBlocked(true, tourId, reason));
        }}
      />
      <NewConfirmDialog
        open={openUnlockDialog}
        onClose={() => setOpenUnlockDialog(false)}
        onConfirm={() => {
          setTourBlocked(null);
          setOpenUnlockDialog(false);
          dispatch(updateBlocked(false, tourId));
        }}
        onConfirmText={"Confimar"}
        title={"¿Desea desbloquear este tour?"}
        content={""}
      />
      <NewConfirmDialog
        open={openPathDialog}
        onClose={() => setOpenPathDialog(false)}
        noConfirm
        onConfirm={() => {
          setOpenPathDialog(false);
          setAllValues();
        }}
        onConfirmText={"Aceptar"}
        title={"Completar Ruta"}
        content={
          "El tour no puede ser aprobado sin tener una ruta dibujada en la sección Mapa"
        }
      />
      <AcceptDialog
        open={openAcceptDialog}
        onClose={() => setOpenAcceptDialog(false)}
        onConfirm={() => setOpenAcceptDialog(false)}
        onConfirmText={"Confimar"}
        title={"Detalle del bloqueo"}
        content={tourBlocked?.Motivo}
      />
      <ModificationsDialog
        open={openChangesDialog}
        onClose={() => setOpenChangesDialog(false)}
        content={!modificaciones ? [] : modificaciones}
      />
    </form>
  );
};
