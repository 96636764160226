import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";

import { ContentCard } from "../components/ClientsComponent/ContentCard";
import { Add as AddIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { IClientsState } from "../redux/reducers/clientsReducer";
import {
  getTotalDocs,
  getClients,
  createClient,
  updateClient,
  deleteClient,
  downloadClientsCSV,
} from "../redux/actions/clientsActions";
import { DownloadCloud } from "react-feather";
import { exportToCsv } from "../utils/utils";
import { openSnack } from "../redux/actions/uiActions";
import { SnackState } from "../models/snack-state";
import { DownloadTypeDialog } from "../components/DownloadTypeDialog";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const Clients = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [separator, setSeparator] = useState(",");
  const { isLoading, clients, totalDocs, filtroNombre } = useSelector<
    RootState,
    IClientsState
  >((state) => state.clientsReducer);

  useEffect(() => {
    dispatch(getClients());
    dispatch(getTotalDocs());
  }, [filtroNombre]);

  const handleConfirmDialog = (type: "all" | "filter") => {
    setOpenDialog(false);
    dispatch(openSnack("Descargando CSV", SnackState.SUCCESS));
    dispatch(downloadClientsCSV(separator, type === "filter" ? true : false));
  };

  return (
    <React.Fragment>
      <Helmet title="Usuarios" />
      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Usuarios
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex">
            <Box order={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setSeparator(",");
                  setOpenDialog(true);
                }}
              >
                <DownloadCloud />
                Descargar CSV
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setSeparator(";");
                  setOpenDialog(true);
                }}
              >
                <DownloadCloud />
                Descargar CSV2
              </Button>
            </Box>
            <Box order={2} ml={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
              >
                <AddIcon />
                Nuevo Usuario
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard
            key="Clientes"
            isLoading={isLoading}
            name="Usuario"
            array={clients}
            totalDocs={totalDocs}
            openNewDialog={open}
            onClose={() => setOpen(false)}
            onAcceptNewEditDialog={(values, action, selected) => {
              if (action === "New") {
                delete values.id;
                dispatch(createClient(values));
              } else if (action === "Edit") {
                dispatch(updateClient(values, selected.id));
              }
            }}
            onFichaDialog={(values, selected) => {}}
            onConfirmDeleteDialog={(selected) => {
              dispatch(deleteClient(selected));
            }}
          />
        </Grid>
      </Grid>
      <DownloadTypeDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirmDialog}
      />
    </React.Fragment>
  );
};
