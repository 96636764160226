import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import { uiReducer } from "./uiReducer";
import { clientsReducer } from "./clientsReducer";
import { tourGuidesReducer } from "./tourGuidesReducer";
import { toursReducer } from "./toursReducer";
import { regionsReducer } from "./regionsReducer";
import { tourTypesReducer } from "./tourTypesReducer";
import { idiomasReducer } from "./idiomasReducer";
import { usersReducer } from "./usersReducer";
import { salesReducer } from "./salesReducer";
import { placesReducer } from "./placesReducer";
import { mapReducer } from "./mapReducer";
import { placesTypesReducer } from "./placesTypesReducer";
import { sumarRestarReducer } from "./sumarRestarReducer";
import { notifsReducer } from "./notificationsReducer";

export const rootReducer = combineReducers({
  themeReducer,
  uiReducer,
  authReducer,
  tourGuidesReducer,
  clientsReducer,
  toursReducer,
  regionsReducer,
  tourTypesReducer,
  idiomasReducer,
  usersReducer,
  salesReducer,
  placesReducer,
  mapReducer,
  placesTypesReducer,
  sumarRestarReducer,
  notifsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
