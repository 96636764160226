import firebase from "firebase/app";
import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";

const DEFAULT_ORDER_BY = "FechaCreacion";
const DEFAULT_COLLECTION_NAME = "Ventas";

export const getSales = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.SALES_ALL_GET_IS_SUBMITTING,
    });
    try {
      const response = await firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY, "desc")
        .limit(limit)
        .get();

      const responseTotal = await firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY, "desc")
        .get();

      const orderedSales = responseTotal.docs.map((doc) => ({
        FechaCreacion: doc.data().FechaCreacion,
        Estado:
          doc.data()?.Tour.Precio === 0 ||
          doc.data()?.Tour?.Precio === "0" ||
          !doc.data()?.Tour?.Precio
            ? "Gratuito"
            : doc.data().Estado,
        Nombre: doc.data().Tour.Nombre,
        Region: doc.data().Tour.Region,
        TipoTour: doc.data().Tour.TipoTour,
        Idioma: doc.data()?.Tour?.Idioma || "Español",
        Precio: doc.data().Tour.Precio,
        PrecioGuia:
          ((Number(doc.data().Tour.Precio) || 0) *
            (doc.data().Tour.Guia.Porcentaje || 50)) /
          100,
        NombreGuia:
          doc.data().Tour.Guia.Nombre + " " + doc.data().Tour.Guia.Apellido,
        EmailGuia: doc.data().Tour.Guia.Email,
        NombreCliente:
          doc.data().Usuario.Nombre + "" + doc.data().Usuario.Apellido,
        EmailCliente: doc.data().Usuario.Email,
      }));

      dispatch({
        type: types.SALES_ALL_GET_SUCCESS,
        payload: {
          totalSales: mapResponse(response),
          totalSalesDocs: orderedSales,
          totalDocs: responseTotal.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.SALES_ALL_GET_FAILURE,
        payload: error,
      });
    }
  };
};

export const getSalesFiltered = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: any
): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.SALES_ALL_GET_IS_SUBMITTING,
    });
    try {
      let query = firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY);

      if (filter) {
        Object.keys(filter).forEach((key) => {
          let value = filter[key];

          if (key === "endAt") {
            const miliDate = Date.parse(value);
            query = query.startAt(new Date(miliDate));
          } else if (key === "startAt") {
            const miliDate = Date.parse(value);
            query = query.endAt(new Date(miliDate));
          } else {
            query = query.where(key, "==", value);
          }
        });
      }

      const response = await query.limit(limit).get();

      const responseTotal = await query.get();

      const orderedSales = responseTotal.docs.map((doc) => ({
        FechaCreacion: doc.data().FechaCreacion,
        Estado:
          doc.data()?.Tour.Precio === 0 ||
          doc.data()?.Tour?.Precio === "0" ||
          !doc.data()?.Tour?.Precio
            ? "Gratuito"
            : doc.data().Estado,
        Nombre: doc.data().Tour.Nombre,
        Region: doc.data().Tour.Region,
        TipoTour: doc.data().Tour.TipoTour,
        Idioma: doc.data()?.Tour?.Idioma || "Español",
        Precio: doc.data().Tour.Precio,
        PrecioGuia:
          ((Number(doc.data().Tour.Precio) || 0) *
            (doc.data().Tour.Guia.Porcentaje || 50)) /
          100,
        NombreGuia:
          doc.data().Tour.Guia.Nombre + " " + doc.data().Tour.Guia.Apellido,
        EmailGuia: doc.data().Tour.Guia.Email,
        NombreCliente:
          doc.data().Usuario.Nombre + "" + doc.data().Usuario.Apellido,
        EmailCliente: doc.data().Usuario.Email,
      }));

      dispatch({
        type: types.SALES_ALL_GET_SUCCESS,
        payload: {
          totalSales: mapResponse(response),
          totalSalesDocs: orderedSales,
          totalDocs: responseTotal.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.SALES_ALL_GET_FAILURE,
        payload: error,
      });
    }
  };
};

export const getMoreSales = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.SALES_ALL_GET_IS_SUBMITTING,
    });
    const { lastDoc, totalDocs, totalSales } = getState().salesReducer;
    try {
      let query = firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY);
      const response = await query.startAfter(lastDoc).limit(limit).get();

      dispatch({
        type: types.SALES_ALL_GET_SUCCESS,
        payload: {
          totalSales: totalSales.concat(mapResponse(response)),
          totalDocs: totalDocs,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.SALES_ALL_GET_FAILURE,
        payload: error,
      });
    }
  };
};

export const getMoreSalesFiltered = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: any
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.SALES_ALL_GET_IS_SUBMITTING,
    });
    const { lastDoc, totalDocs, totalSales } = getState().salesReducer;
    try {
      let query = firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY);

      if (filter) {
        Object.keys(filter).forEach((key) => {
          let value = filter[key];

          if (key === "endAt") {
            const miliDate = Date.parse(value);
            query = query.startAt(new Date(miliDate));
          } else if (key === "startAt") {
            const miliDate = Date.parse(value);
            query = query.endAt(new Date(miliDate));
          } else {
            query = query.where(key, "==", value);
          }
        });
      }
      const response = await query.startAfter(lastDoc).limit(limit).get();

      dispatch({
        type: types.SALES_ALL_GET_SUCCESS,
        payload: {
          totalSales: totalSales.concat(mapResponse(response)),
          totalDocs: totalDocs,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.SALES_ALL_GET_FAILURE,
        payload: error,
      });
    }
  };
};

export const getTourSales = (
  tour: any,
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY)
        .where("Tour.Id", "==", tour)
        .limit(limit)
        .get();

      const responseTotal = await firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY)
        .where("Tour.Id", "==", tour)
        .get();

      const orderedSales = responseTotal.docs.map((doc) => ({
        FechaCreacion: doc.data().FechaCreacion,
        Estado:
          doc.data()?.Tour.Precio === 0 ||
          doc.data()?.Tour?.Precio === "0" ||
          !doc.data()?.Tour?.Precio
            ? "Gratuito"
            : doc.data().Estado,
        Nombre: doc.data().Tour.Nombre,
        Region: doc.data().Tour.Region,
        TipoTour: doc.data().Tour.TipoTour,
        Idioma: doc.data()?.Tour?.Idioma || "Español",
        Precio: doc.data().Tour.Precio,
        PrecioGuia:
          ((Number(doc.data().Tour.Precio) || 0) *
            (doc.data().Tour.Guia.Porcentaje || 50)) /
          100,
        NombreGuia:
          doc.data().Tour.Guia.Nombre + " " + doc.data().Tour.Guia.Apellido,
        EmailGuia: doc.data().Tour.Guia.Email,
        NombreCliente:
          doc.data().Usuario.Nombre + "" + doc.data().Usuario.Apellido,
        EmailCliente: doc.data().Usuario.Email,
      }));

      // dispatch(setTotalDocs(response.size));
      dispatch(
        setTourSales(
          mapResponse(response),
          response.size,
          response.docs[response.docs.length - 1],
          orderedSales
        )
      );
      // dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getMoreTourSales = (
  tour: any,
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const { lastDoc, totalTourSalesDocs } = getState().salesReducer;
    try {
      const response = await firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY)
        .where("Tour.Id", "==", tour)
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      // dispatch(setTotalDocs(response.size));
      dispatch(
        setTourSales(
          mapResponse(response),
          response.size,
          response.docs[response.docs.length - 1],
          totalTourSalesDocs
        )
      );
      // dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getUserSales = (
  user: any,
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY)
        .where("Usuario.Id", "==", user)
        .limit(limit)
        .get();

      const responseTotal = await firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY)
        .where("Usuario.Id", "==", user)
        .get();

      responseTotal.docs.map((doc) => ({
        FechaCreacion: doc.data().FechaCreacion,
        Estado:
          doc.data()?.Tour.Precio === 0 ||
          doc.data()?.Tour?.Precio === "0" ||
          !doc.data()?.Tour?.Precio
            ? "Gratuito"
            : doc.data().Estado,
        Nombre: doc.data().Tour.Nombre,
        Region: doc.data().Tour.Region,
        TipoTour: doc.data().Tour.TipoTour,
        Idioma: doc.data()?.Tour?.Idioma || "Español",
        Precio: doc.data().Tour.Precio,
        PrecioGuia:
          ((Number(doc.data().Tour.Precio) || 0) *
            (doc.data().Tour.Guia.Porcentaje || 50)) /
          100,
        NombreGuia:
          doc.data().Tour.Guia.Nombre + " " + doc.data().Tour.Guia.Apellido,
        EmailGuia: doc.data().Tour.Guia.Email,
        NombreCliente:
          doc.data().Usuario.Nombre + "" + doc.data().Usuario.Apellido,
        EmailCliente: doc.data().Usuario.Email,
      }));

      // dispatch(setTotalDocs(response.size));
      dispatch(
        setUserSales(
          mapResponse(response),
          response.size,
          response.docs[response.docs.length - 1]
        )
      );
      // dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getMoreUserSales = (
  user: any,
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const { lastDoc, totalUserSalesDocs } = getState().salesReducer;
    try {
      const response = await firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY)
        .where("Usuario.Id", "==", user)
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      // dispatch(setTotalDocs(response.size));
      dispatch(
        setUserSales(
          mapResponse(response),
          response.size,
          response.docs[response.docs.length - 1],
          totalUserSalesDocs
        )
      );
      // dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

const mapResponse = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  try {
    let Response: any[] = [];
    response.docs.forEach((doc) => {
      let data = mapData(doc);
      Response.push(data);
    });
    return Response;
  } catch (error: any) {
    throw error;
  }
};

const mapData = (doc: any) => {
  let data = doc.data();
  data.id = doc.id;
  return data;
};

const setTourSales = (
  Sales: any[],
  totalDocs: any,
  lastDoc: any,
  totalSalesDocs?: any[]
): Action => ({
  type: types.SALES_TOUR_GET_DOCS,
  payload: {
    tourSales: Sales,
    totalDocs: totalDocs,
    lastDoc: lastDoc,
    totalTourSalesDocs: totalSalesDocs,
  },
});

export const setAllSalesDocs = (list: any[]): Action => ({
  type: types.SALES_SET_LIST_ALL_DATA,
  payload: list,
});

const setUserSales = (
  Sales: any[],
  totalDocs: any,
  lastDoc: any,
  totalSalesDocs?: any[]
): Action => ({
  type: types.SALES_USER_GET_DOCS,
  payload: {
    userSales: Sales,
    totalDocs: totalDocs,
    lastDoc: lastDoc,
    totalUserSalesDocs: totalSalesDocs,
  },
});

const isLoading = (isloading: boolean): Action => ({
  type: types.SALES_LOADING,
  payload: isloading,
});

const setError = (error: string): Action => ({
  type: types.SALES_FAILURE,
  payload: error,
});

// const setLastDoc = (doc: any): Action => ({
//   type: types.SALES_SET_LAST_DOC,
//   payload: doc,
// });

// const setTotalDocs = (doc: any): Action => ({
//   type: types.SALES_SET_LAST_DOC,
//   payload: doc,
// });
