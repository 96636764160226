import { AppThunk } from "../../models/app-thunk";
import * as types from "../../constants";
import { Action } from "../../models/action";
import { firestore } from "../../firebase/firebase";
import firebase from "firebase/app";
import { openSnack } from "./uiActions";
import { SnackState } from "../../models/snack-state";

const DEFAULT_ORDER_BY = "Nombre";
const DEFAULT_COLLECTION_NAME = "TipoTour";

export const getTourTypes = (): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore
        .collection(DEFAULT_COLLECTION_NAME)
        .orderBy(DEFAULT_ORDER_BY)
        .get();

      dispatch(setTourTypes(mapTourTypes(response)));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const createTourType = (Nombre: string): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.TOUR_TYPES_CREATE_SUBMITTING,
    });
    try {
      if (Nombre) {
        await firestore
          .collection("TipoTour")
          .add({ Nombre: Nombre })
          .then((res) =>
            dispatch({
              type: types.TOUR_TYPES_CREATE_SUCCESS,
              payload: { Nombre: Nombre, id: res.id },
            })
          );
        dispatch(openSnack("¡Se ha creado con éxito!", SnackState.SUCCESS));
      } else {
        dispatch(openSnack("¡No puede ser un campo vacío!", SnackState.ERROR));
        dispatch({
          type: types.TOUR_TYPES_CREATE_FAILURE,
          payload: "Campo Vacío",
        });
      }
    } catch (error: any) {
      dispatch({
        type: types.PLACES_TYPES_CREATE_FAILURE,
        payload: error,
      });
      console.log(error);
    }
  };
};

export const removeTourType = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.TOUR_TYPES_REMOVE_SUBMITTING,
    });
    try {
      await firestore
        .collection("TipoTour")
        .doc(id)
        .delete()
        .then((res) => {
          dispatch(openSnack("¡Se ha eliminado!", SnackState.SUCCESS));
          dispatch({
            type: types.TOUR_TYPES_REMOVE_SUCCESS,
            payload: id,
          });
        });
    } catch (error: any) {
      dispatch({
        type: types.TOUR_TYPES_REMOVE_FAILURE,
        payload: error,
      });
      console.log(error);
    }
  };
};

export const getMediaRecomendations = (): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await firestore
        .collection("AppInfo")
        .doc("Recomendaciones")
        .get();
      if (response.exists) {
        dispatch({
          type: types.GET_MEDIA_RECOMENDATIONS,
          payload: {
            audio: response.data()?.LinkAudio,
            video: response.data()?.LinkVideo,
          },
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };
};

const mapTourTypes = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  try {
    let tourTypes: any[] = [];
    response.docs.forEach((doc) => {
      let data = mapTourType(doc);
      tourTypes.push(data);
    });
    return tourTypes;
  } catch (error) {
    throw error;
  }
};

const mapTourType = (doc: any) => {
  let data = doc.data();
  data.id = doc.id;
  return data;
};

const setTourTypes = (tourTypes: any[]): Action => ({
  type: types.TOUR_TYPES_GET_DOCS,
  payload: tourTypes,
});

const isLoading = (isLoading: boolean): Action => ({
  type: types.TOUR_TYPES_LOADING,
  payload: isLoading,
});

const setError = (error: string): Action => ({
  type: types.TOUR_TYPES_FAILURE,
  payload: error,
});
