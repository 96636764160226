import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  Collapse,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { withStyles } from "@material-ui/styles";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import {
  AlertCircle,
  AlertOctagon,
  DownloadCloud,
  Edit as EditIcon,
  MinusSquare,
  Plus as AddIcon,
  Search,
  Trash2 as RemoveIcon,
} from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { NewConfirmDialog } from "../components/NewConfirmDialog";
import { TableSkeleton } from "../components/TableSkeleton";
import { DialogTiposTours } from "../components/ToursComponents/DialogTiposTours";
import { NewTourDialog } from "../components/ToursComponents/NewTourDialog";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";
import { useTable } from "../hooks/useTable";
import { SnackState } from "../models/snack-state";
import { getRegions } from "../redux/actions/regionsActions";
import {
  addMoreTours,
  addTour,
  deleteTour,
  getMoreToursFiltered,
  getTourByCode,
  getTours,
  getToursFiltered,
  setSelectedTour,
} from "../redux/actions/toursActions";
import {
  getMediaRecomendations,
  getTourTypes,
} from "../redux/actions/tourTypesActions";
import { openSnack } from "../redux/actions/uiActions";
import { IAuthState } from "../redux/reducers/authReducer";
import { IRegionsState } from "../redux/reducers/regionsReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { IToursState } from "../redux/reducers/toursReducer";
import { ITourTypeState } from "../redux/reducers/tourTypesReducer";
import { useStyles } from "../theme/useStyles";
import {
  cleanString,
  exportToCsv,
  timesStampToDateFormatted,
} from "../utils/utils";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#ffffff",
      fontWeight: "bold",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000",
    },
    "& input": {
      color: "#ffffff",
      "& placeholder": { color: "#ffffff" },
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ffffff",
      },
      "&:hover fieldset": {
        borderColor: "#ffffff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ffffff",
      },
    },
  },
})(TextField);

interface CardProps {
  isLoading: boolean;
  array: any[];
}

interface FilterProps {
  onFilterChange: (value: any) => void;
  onSearch: () => void;
}

const ContentCard: React.FC<CardProps> = ({ array, isLoading }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<any>({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<any>({});

  const { totalDocs } = useSelector<RootState, IToursState>(
    (state) => state.toursReducer
  );

  const { limit, page, handleLimitChange, handlePageChange, handleResetPage } =
    useTable({
      limit: TABLE_LIMIT_DEFAULT,
      onLimitChange: (event, newLimit) => {
        if (newLimit > array.length) {
          if (Object.keys(filter).length !== 0) {
            dispatch(getToursFiltered(filter, newLimit));
          } else {
            dispatch(getTours(newLimit));
          }
        }
      },
      onPageChange: (newPage, oldPage, limit) => {
        if (array.length < totalDocs && newPage > oldPage) {
          if (Object.keys(filter).length !== 0) {
            dispatch(getMoreToursFiltered(filter, limit));
          } else {
            dispatch(addMoreTours(limit));
          }
        }
      },
    });

  const handleGoToIndexCard = (tour: any) => {
    dispatch(setSelectedTour(tour));
    history.push(`tours/${tour.id}/ficha`);
  };
  const handleGoToEditTour = (tour: any) => {
    dispatch(setSelectedTour(tour));
    history.push(`tours/${tour.id}/editar`);
  };
  const handleDeleteTour = (tour: any) => {
    dispatch(deleteTour(tour));
    setOpenConfirm(false);
  };
  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );
  const totalPeso = (obj: any) => {
    let sum = 0;
    Object.values(obj).map((value: any) => {
      sum = sum + value.Peso;
    });
    return sum;
  };
  return (
    <>
      <Card mb={6}>
        <FilterSection
          onFilterChange={(filter) => setFilter(filter)}
          onSearch={() => handleResetPage()}
        />
        <Divider />
        <CardContent>
          <TableContainer className={classes.tableContainer}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Codigo</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Creado</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Region</TableCell>
                  <TableCell>Peso Videos(MB)</TableCell>
                  <TableCell>Guia</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="subtitle2"
                      style={{ color: "#004ecb" }}
                    >
                      Acciones
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  array
                    .slice(page * limit, page * limit + limit)
                    .map((data) => (
                      <Fade key={data.id} in={true}>
                        <TableRow hover className={classes.styledRow}>
                          <TableCell>
                            <Box display="flex">
                              <Box>
                                <Typography style={{ fontSize: 12 }}>
                                  {data?.Codigo}
                                </Typography>
                              </Box>
                              <Box>
                                {data?.Bloqueo && (
                                  <Tooltip title="Tour bloqueado">
                                    <AlertOctagon
                                      color="red"
                                      style={{ height: 17 }}
                                    />
                                  </Tooltip>
                                )}
                              </Box>
                              <Box>
                                {user?.tipoUsuario === "Administrador" &&
                                  data?.Modificaciones &&
                                  typeof data.Modificaciones !== "boolean" &&
                                  data.Modificaciones.length > 0 && (
                                    <Tooltip title="Tour con modificaciones">
                                      <AlertCircle
                                        color="orange"
                                        style={{
                                          height: 17,
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontSize: 12 }}>
                              {data?.Nombre}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontSize: 12 }}>
                              {timesStampToDateFormatted(
                                new Date(data.FechaCreacion.seconds * 1000)
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontSize: 12 }}>
                              {data?.TipoTour}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontSize: 12 }}>
                              {data?.Region}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontSize: 12 }}>
                              {data.PesoVideos &&
                                (
                                  totalPeso(data?.PesoVideos) / 1000000
                                )?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Typography style={{ fontSize: 12 }}>
                              {data?.Guia?.Nombre + " " + data?.Guia?.Apellido}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontSize: 12 }}>
                              {data.Estado}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              whiteSpace: "nowrap",
                            }}
                            align="center"
                          >
                            <Tooltip title="Ficha Editar">
                              <IconButton
                                disabled={
                                  data.Guia.Id === user?.id ||
                                  user?.tipoUsuario === "Administrador"
                                    ? false
                                    : true
                                }
                                size="small"
                                onClick={() => handleGoToEditTour(data)}
                                color="primary"
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Eliminar">
                              <span>
                                <IconButton
                                  disabled={
                                    user?.tipoUsuario === "Administrador"
                                      ? false
                                      : true
                                  }
                                  size="small"
                                  onClick={() => {
                                    setDeleteTarget(data);
                                    setOpenConfirm(true);
                                  }}
                                >
                                  <RemoveIcon
                                    color={
                                      user?.tipoUsuario === "Administrador"
                                        ? "red"
                                        : "grey"
                                    }
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))
                ) : (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ color: "#004ecb", fontWeight: "bold" }}
            component="div"
            count={totalDocs}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_LIMITS}
          />
        </CardContent>
      </Card>
      <NewConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="¿Eliminar Tour?"
        onConfirmText="Eliminar"
        onConfirm={() => handleDeleteTour(deleteTarget)}
      />
    </>
  );
};

const FilterSection = React.memo((props: FilterProps) => {
  const { onFilterChange, onSearch, ...other } = props;
  const dispatch = useDispatch();

  const [openFilter, setOpenFilters] = useState(false);
  const [filtersList, setValueFiltersList] = React.useState<any>({});
  const [valueCreacionStart, setValueCreacionStart] = useState<any>(null);
  const [valueCreacionEnd, setValueCreacionEnd] = useState<any>(null);
  const [valueNombre, setValueNombre] = useState("");
  const [valueNombreGuia, setValueNombreGuia] = useState("");
  const [valueApellidoGuia, setValueApellidoGuia] = useState("");
  const [valueTipo, setValueTipo] = React.useState<string | null>(null);
  const [valueRegion, setValueRegion] = React.useState<string | null>(null);
  const [valueEstado, setValueEstado] = React.useState<string | null>(null);
  const [valueCodigo, setValueCodigo] = useState("");

  const estadosTour = ["Aprobado", "Pendiente"];

  const handleChangeNombre = (e: any) => {
    setValueNombre(e.target.value);
  };
  const handleChangeNombreGuia = (e: any) => {
    setValueNombreGuia(e.target.value);
  };
  const handleChangeApellidoGuia = (e: any) => {
    setValueApellidoGuia(e.target.value);
  };
  const handleChangeCodigo = (e: any) => {
    setValueCodigo(e.target.value);
  };

  const { regions } = useSelector<RootState, IRegionsState>(
    (state) => state.regionsReducer
  );

  const { tourTypes } = useSelector<RootState, ITourTypeState>(
    (state) => state.tourTypesReducer
  );

  useEffect(() => {
    dispatch(getRegions());
  }, []);
  useEffect(() => {
    dispatch(getTourTypes());
  }, []);

  useEffect(() => {
    onFilterChange(filtersList);
  }, [Object.keys(filtersList)]);

  const validateTextFields = () => {
    if (!valueNombre) {
      delete filtersList["Nombre_lower"];
    } else {
      filtersList["Nombre_lower"] = cleanString(valueNombre);
    }
    if (valueNombreGuia.length === 0) {
      delete filtersList["Guia.Nombre_lower"];
    } else {
      filtersList["Guia.Nombre_lower"] = cleanString(valueNombreGuia);
    }
    if (valueApellidoGuia.length === 0) {
      delete filtersList["Guia.Apellido_lower"];
    } else {
      filtersList["Guia.Apellido_lower"] = cleanString(valueApellidoGuia);
    }
  };

  return (
    <CardContent style={{ backgroundColor: "#2979ff" }}>
      <Grid container justify="space-between">
        <Grid item sm={2}>
          <CssTextField
            id="outlined-basic"
            size="small"
            fullWidth
            label="Código de Tour"
            InputLabelProps={{
              style: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "100%",
                color: "#ffffff",
              },
            }}
            variant="outlined"
            value={valueCodigo}
            onChange={handleChangeCodigo}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                if (valueCodigo) {
                  dispatch(getTourByCode(valueCodigo));
                } else {
                  dispatch(getTours());
                }

                ev.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item>
          <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
            <Typography variant="subtitle2" style={{ color: "#ffffff" }}>
              Filtros{" "}
            </Typography>
            {openFilter ? (
              <ExpandLess style={{ color: "#ffffff" }} />
            ) : (
              <ExpandMore style={{ color: "#ffffff" }} />
            )}
          </Button>
        </Grid>
      </Grid>
      <Collapse in={openFilter} timeout="auto" unmountOnExit>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2} style={{ marginTop: 6 }}>
            <CssTextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Nombre"
              InputLabelProps={{
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  color: "#ffffff",
                },
              }}
              variant="outlined"
              value={valueNombre}
              onChange={handleChangeNombre}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  filtersList["Nombre_lower"] = cleanString(valueNombre);

                  if (Object.keys(filtersList).length > 0 && valueNombre) {
                    validateTextFields();
                    dispatch(getToursFiltered(filtersList));
                  } else {
                    dispatch(getTours());
                  }

                  ev.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
            <CssTextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Nombre Guia"
              InputLabelProps={{
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  color: "#ffffff",
                },
              }}
              variant="outlined"
              value={valueNombreGuia}
              onChange={handleChangeNombreGuia}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  filtersList["Guia.Nombre_lower"] =
                    cleanString(valueNombreGuia);

                  if (Object.keys(filtersList).length > 0 && valueNombreGuia) {
                    validateTextFields();
                    dispatch(getToursFiltered(filtersList));
                  } else {
                    dispatch(getTours());
                  }

                  ev.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
            <CssTextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Apellido Guia"
              InputLabelProps={{
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  color: "#ffffff",
                },
              }}
              variant="outlined"
              value={valueApellidoGuia}
              onChange={handleChangeApellidoGuia}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  filtersList["Guia.Apellido_lower"] =
                    cleanString(valueApellidoGuia);
                  if (
                    Object.keys(filtersList).length > 0 &&
                    valueApellidoGuia
                  ) {
                    validateTextFields();
                    dispatch(getToursFiltered(filtersList));
                  } else {
                    dispatch(getTours());
                  }

                  ev.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginTop: 6 }}>
            <Autocomplete
              fullWidth
              id="estado-filtro"
              size="small"
              value={valueEstado}
              options={estadosTour}
              getOptionLabel={(option: any) => option}
              onChange={(event: any, newValue: any | null) => {
                setValueEstado(newValue);
                if (newValue) {
                  validateTextFields();
                  filtersList["Estado"] = newValue;
                  dispatch(getToursFiltered(filtersList));
                } else {
                  delete filtersList["Estado"];
                  if (Object.keys(filtersList).length > 0) {
                    validateTextFields();
                    dispatch(getToursFiltered(filtersList));
                  } else {
                    dispatch(getTours());
                  }
                }
              }}
              renderInput={(params: any) => (
                <CssTextField
                  {...params}
                  label="Estado"
                  fullWidth
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      color: "#ffffff",
                    },
                  }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginTop: 6 }}>
            <Autocomplete
              id="tipos-filtro"
              size="small"
              value={valueTipo}
              options={tourTypes}
              getOptionLabel={(option: any) => option.Nombre}
              fullWidth
              onChange={(event: any, newValue: any | null) => {
                setValueTipo(newValue);
                if (newValue) {
                  validateTextFields();
                  filtersList["TipoTour"] = newValue.Nombre;
                  dispatch(getToursFiltered(filtersList));
                } else {
                  delete filtersList["TipoTour"];
                  if (Object.keys(filtersList).length > 0) {
                    validateTextFields();
                    dispatch(getToursFiltered(filtersList));
                  } else {
                    dispatch(getTours());
                  }
                }
              }}
              renderInput={(params: any) => (
                <CssTextField
                  {...params}
                  label="Tipos de tour"
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      color: "#ffffff",
                    },
                  }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginTop: 6 }}>
            <Autocomplete
              id="region-filtro"
              size="small"
              value={valueRegion}
              options={regions}
              getOptionLabel={(option: any) => option.Nombre}
              fullWidth
              onChange={(event: any, newValue: any | null) => {
                setValueRegion(newValue);
                if (newValue) {
                  validateTextFields();
                  filtersList["Region"] = newValue.Nombre;
                  dispatch(getToursFiltered(filtersList));
                } else {
                  delete filtersList["Region"];
                  if (filtersList) {
                    validateTextFields();
                    dispatch(getToursFiltered(filtersList));
                  } else {
                    dispatch(getTours());
                  }
                }
              }}
              renderInput={(params: any) => (
                <CssTextField
                  {...params}
                  label="Región"
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      color: "#ffffff",
                    },
                  }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginTop: 6 }}>
            <CssTextField
              id="creacion-start"
              label="Fecha Creación: Inicio"
              InputLabelProps={{
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  color: "#ffffff",
                },
                shrink: true,
              }}
              size="small"
              type="datetime-local"
              value={valueCreacionStart}
              onChange={(event) => {
                setValueCreacionStart(event.target.value);
                filtersList["endAt"] = event.target.value;
              }}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={2} style={{ marginTop: 6 }}>
            <Box display="flex">
              <Box mt={1} order={1}>
                <Tooltip title="Buscar">
                  <IconButton
                    style={{ color: "#ffffff" }}
                    size="small"
                    aria-label="Filtrar"
                    onClick={() => {
                      validateTextFields();
                      if (!valueCreacionStart) {
                        delete filtersList["endAt"];
                      }

                      onSearch();
                      dispatch(getToursFiltered(filtersList));
                    }}
                  >
                    <Search size={35} style={{ marginTop: -7 }} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box mt={1} order={2}>
                <Tooltip title="Limpiar">
                  <IconButton
                    style={{ color: "#ffffff" }}
                    size="small"
                    aria-label="Borrar filtro"
                    onClick={() => {
                      setValueNombre("");
                      setValueNombreGuia("");
                      setValueApellidoGuia("");
                      setValueTipo(null);
                      setValueRegion(null);
                      setValueEstado(null);
                      setValueCreacionStart("");
                      setValueCreacionEnd("");
                      setValueFiltersList({});
                      onSearch();
                      dispatch(getTours());
                    }}
                  >
                    <MinusSquare size={35} style={{ marginTop: -7 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </CardContent>
  );
});

export const Tours = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const { isLoading, tours, totalDocs } = useSelector<RootState, IToursState>(
    (state) => state.toursReducer
  );

  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );

  const [openTiposTours, setOpenTiposTours] = useState(false);

  const handleOpenNew = () => {
    setOpenDialog(true);
  };
  const handleCreateNew = (values: any) => {
    setOpenDialog(false);
    dispatch(addTour(values));
  };
  const handleGoToNewTour = () => {
    history.push(`tours/nuevo`);
  };

  useEffect(() => {
    if (user?.tipoUsuario === "Lugar") {
      history.push("/Editar/Lugar");
    }
    dispatch(getTours());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Helmet title="Tours" />
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Tours
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex">
            {user?.tipoUsuario === "Administrador" && (
              <Box order={1}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    dispatch(openSnack("Descargando CSV", SnackState.SUCCESS));
                    exportToCsv(
                      "tours.csv",
                      tours,
                      [
                        "Codigo",
                        "Nombre",
                        "Region",
                        "TipoTour",
                        "Precio",
                        "FechaCreacion",
                        "Estado",
                        "Guia.Nombre",
                        "Guia.Apellido",
                      ],
                      [
                        "Codigo",
                        "Nombre",
                        "Region",
                        "Tipo Tour",
                        "Precio",
                        "Fecha de Creacion",
                        "Estado",
                        "Nombre Guia",
                        "Apellido Guia",
                      ]
                    );
                  }}
                >
                  <DownloadCloud />
                  Descargar CSV
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    dispatch(openSnack("Descargando CSV", SnackState.SUCCESS));
                    exportToCsv(
                      "tours.csv",
                      tours,
                      [
                        "Codigo",
                        "Nombre",
                        "Region",
                        "TipoTour",
                        "Precio",
                        "FechaCreacion",
                        "Estado",
                        "Guia.Nombre",
                        "Guia.Apellido",
                      ],
                      [
                        "Codigo",
                        "Nombre",
                        "Region",
                        "Tipo Tour",
                        "Precio",
                        "Fecha de Creacion",
                        "Estado",
                        "Nombre Guia",
                        "Apellido Guia",
                      ],
                      ";"
                    );
                  }}
                >
                  <DownloadCloud />
                  Descargar CSV2
                </Button>
              </Box>
            )}
            <Box order={2} ml={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenNew}
              >
                <AddIcon /> Agregar Tour
              </Button>
            </Box>
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            {user?.tipoUsuario === "Administrador" && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setOpenTiposTours(true)}
              >
                Tipos de Tours
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard array={tours} isLoading={isLoading} />
        </Grid>
      </Grid>
      <DialogTiposTours
        open={openTiposTours}
        onClose={() => setOpenTiposTours(false)}
      />
      <NewTourDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleCreateNew}
      />
    </React.Fragment>
  );
};
