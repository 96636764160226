import React, { useEffect } from "react";
import styled from "styled-components/macro";
import StaticMap, { LatLng } from "../components/Maps/StaticMap";
import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { IToursState } from "../redux/reducers/toursReducer";
import { getTour, updateTourIdioma } from "../redux/actions/toursActions";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import {
  Calendar as CalendarIcon,
  Hash as NumberIcon,
  Search as SearchIcon,
  AtSign as ArrobaIcon,
  Loader as StatusIcon,
  ArrowRightCircle as ArrowIcon,
  Phone as PhoneIcon,
  Feather as NameIcon,
  MapPin as MapPinIcon,
  FileText as CodeIcon,
  BookOpen as BookIcon,
  Edit as EditIcon,
  Download as DownloadIcon,
  Delete,
  Trash,
  ToggleRight as ActiveIcon,
  ToggleLeft as InactiveIcon,
  User as GuideIcon,
  Bookmark,
} from "react-feather";

import { spacing } from "@material-ui/system";
import { Rating, Skeleton } from "@material-ui/lab";
import { timesStampToDateFormatted, timeStampToDate } from "../utils/utils";
import TourDetail from "../components/NewTourComponents/TourDetail";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const idiomas = ["Inglés", "Español", "Portugues"];

interface CardProps {
  tour: any;
  isLoading: boolean;
}

const ContentCard: React.FC<CardProps> = ({ tour }) => {
  const timestamp = timesStampToDateFormatted(
    timeStampToDate(tour?.FechaCreacion)
  );
  const handleChangePath = (path: LatLng[], length: number) => {};

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container justify="flex-start">
          <Grid item sm={6} style={{ marginInlineEnd: -40 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={8}>
                <Box m={1} order={2}>
                  <Typography variant="h6">{tour?.Nombre}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Box display="flex" flexWrap="nowrap">
                  <Box order={1}>
                    <MapPinIcon />
                  </Box>
                  <Box m={1} order={2}>
                    <Typography>{tour?.Region}</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexWrap="nowrap">
                  <Box order={1}>
                    <CalendarIcon />
                  </Box>
                  <Box m={1} order={2}>
                    {timestamp}
                  </Box>
                  <Box order={3}>
                    <GuideIcon />
                  </Box>
                  <Box m={1} order={4}>
                    {tour?.Guia.Nombre + " " + tour?.Guia.Apellido}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={5}>
                <Box display="flex" flexWrap="nowrap">
                  <Box order={1}>
                    <NumberIcon />
                  </Box>
                  <Box m={1} order={2}>
                    Num
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Rating name="read-only" value={3} readOnly />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexWrap="nowrap">
                  <Box order={1}>
                    <StatusIcon />
                  </Box>
                  <Box m={1} order={2}>
                    {tour?.Estado}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            {tour?.Path ? (
              // <StaticMap TourPath={tour?.Path} />
              <Box mt={10} display="flex" justifyContent="flex-start">
                <img
                  src={
                    "https://maps.googleapis.com/maps/api/staticmap?" +
                    `key=AIzaSyD9HmNtNbieIc0zsZLjErteatS3R65HYJg&path=color:0xff0000ff|weight:3|${tour.Path.map(
                      (p: any) => "" + p.lat + "," + p.lng
                    ).join("|")}` +
                    "&autoscale=false&size=580x400" +
                    `&markers=anchor:bottomleft|icon:https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png%7Cshadow:true%7C${tour.Path[0].lat},${tour.Path[0].lng}` +
                    `&markers=anchor:bottomleft|icon:https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png%7Cshadow:true%7C${
                      tour.Path[tour.Path.length - 1].lat
                    },${tour.Path[tour.Path.length - 1].lng}`
                  }
                />
              </Box>
            ) : (
              <Typography>
                <b>No Map</b>
              </Typography>
            )}
          </Grid> */}
        </Grid>
      </CardContent>
      <CardContent></CardContent>
      <Divider />
    </Card>
  );
};

export const IndexCardTour = () => {
  const dispatch = useDispatch();
  const { tourId } = useParams<any>();

  const {
    edit: { selectedTour, isLoading },
  } = useSelector<RootState, IToursState>((state) => state.toursReducer);

  useEffect(() => {
    if (!selectedTour) {
      dispatch(getTour(tourId));
    }
  }, [dispatch, selectedTour, tourId]);

  const handleAddLenguage = (lenguages: any) => {
    console.log(lenguages);
    // dispatch(updateTourIdioma(selectedTour, lenguages));
  };

  return (
    <React.Fragment>
      <Helmet title="Ficha Tour" />
      {selectedTour ? (
        <Typography variant="h3" gutterBottom display="inline">
          Ficha Tour {selectedTour?.Nombre}
        </Typography>
      ) : (
        <Skeleton height={40} variant="rect" />
      )}

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/tours">
          Tour
        </Link>
        <Typography>Ficha</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard tour={selectedTour} isLoading={isLoading} />
        </Grid>
        {selectedTour ? (
          <Grid item xs={12}>
            <Card>
              <TourDetail
                selectedTour={selectedTour}
                onAddLenguage={(value) => handleAddLenguage(value)}
              />
            </Card>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </React.Fragment>
  );
};
