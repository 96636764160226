import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

interface Props {
  open: boolean;
  onClose: () => void;
  content?: any;
}

export const ModificationsDialog: React.FC<Props> = ({
  onClose,
  open,
  content = [],
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Modificaciones registradas"}
      </DialogTitle>
      <DialogContent>
        <List aria-label="secondary media points">
          {typeof content === "boolean"
            ? []
            : content?.map((data: any, index: number) => (
                <ListItem key={index}>
                  <ListItemText primary={data} />
                </ListItem>
              ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
