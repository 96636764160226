import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  handleResetGetEmail,
  handleResetSetPassword,
  resetPassword,
} from "../../redux/actions/authActions";

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import { resetPaswordTourGuide } from "../../redux/actions/tourGuidesActions";
import { FormState } from "../../models/form_state";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function getParameterByName(name, url) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function ChangePassword() {
  const dispatch = useDispatch();

  const history = useHistory();

  const { resetPassStateEmail, resetPassStateSetPass, resetEmail } =
    useSelector((state) => state.authReducer);

  // console.log(location.pathname);
  const requestOobCode = getParameterByName("oobCode");

  useEffect(() => {
    if (requestOobCode && requestOobCode !== undefined) {
      // console.log(requestOobCode);
      dispatch(handleResetGetEmail(requestOobCode));
    }
  }, []);

  useEffect(() => {
    if (resetPassStateSetPass === FormState.Success)
      history.push("/auth/sign-in");
  }, [resetPassStateSetPass]);

  return (
    <Wrapper style={{ maxWidth: "520px" }}>
      <Helmet title="Reset Password" />

      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Nueva Contraseña
      </Typography>
      {resetPassStateEmail !== FormState.Error && (
        <Typography component="h2" variant="body1" align="center">
          Ingresa nueva contraseña
        </Typography>
      )}

      <Formik
        initialValues={{
          password: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().min(6).required("Contraseña es requerida"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            dispatch(handleResetSetPassword(requestOobCode, values.password));
            // history.push("/auth/sign-in");
          } catch (error) {
            const message = error.message || "Ha ocurrido un problema";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}

            {resetPassStateEmail !== FormState.Error ? (
              <>
                <TextField
                  name="password"
                  label="Email"
                  value={
                    resetPassStateEmail === FormState.Success ? resetEmail : ""
                  }
                  fullWidth
                  disabled
                  my={3}
                />
                <TextField
                  type="password"
                  name="password"
                  label="Contraseña"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={
                    isSubmitting || resetPassStateEmail !== FormState.Success
                  }
                >
                  Cambiar contraseña
                </Button>
              </>
            ) : (
              <>
                <Typography>No se puede actualizar</Typography>
                <Button
                  component={Link}
                  to="/auth/sign-in"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {"Volver al Ingreso"}
                </Button>
              </>
            )}
          </form>
        )}
      </Formik>
    </Wrapper>
  );
}

export default ChangePassword;
