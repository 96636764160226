import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  Fade,
  Grid,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  CreditCard,
  Edit,
  Eye,
  FilePlus,
  MousePointer,
  PlayCircle,
  Trash2,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { FormState } from "../../models/form_state";
import {
  deletePlaceVideo,
  getClicksViewsCount,
} from "../../redux/actions/placesActions";
import { PlacesState } from "../../redux/reducers/placesReducer";
import { RootState } from "../../redux/reducers/rootReducer";
import { useStyles } from "../../theme/useStyles";
import { numberWithDot, timesStampFormattedsimple } from "../../utils/utils";
import { TableSkeleton } from "../TableSkeleton";
import { DialogPlaceVideo } from "./DialogPlaceVideo";
import { NewConfirmDialog } from "../NewConfirmDialog";
import { ViewsClicksDialog } from "../ViewsClicksDialog";
import { IAuthState } from "../../redux/reducers/authReducer";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

interface Props {
  videos: any[];
  save: (values: any, type: string) => void;
}

export const PlacesVideos: React.FC<Props> = React.memo(
  ({ save, videos = [] }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [openNewEdit, setOpeNewEdit] = useState(false);
    const [typeDialog, setTypeDialog] = useState("new");
    const [editData, setEditData] = useState<any | undefined>(undefined);

    const [openConfirm, setOpenConfirm] = useState(false);
    const [openMultimediaDialog, setOpenMultimediaDialog] = useState(false);

    const [openVCDialog, setOpenVCDialog] = useState(false);

    const [srcVideo, setSrcVideo] = useState("");

    const { videoUpload, videosState, selectedPlace } = useSelector<
      RootState,
      PlacesState
    >((state) => state.placesReducer);

    const { user } = useSelector<RootState, IAuthState>(
      (state) => state.authReducer
    );

    const handleDelete = () => {
      dispatch(deletePlaceVideo(editData, editData.id));
      setOpenConfirm(!openConfirm);
    };
    return (
      <React.Fragment>
        <CardContent>
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={12} sm={12}>
              <div style={{ width: "100%" }}>
                {videoUpload > 0 ? (
                  <LinearProgressWithLabel value={Number(videoUpload)} />
                ) : (
                  ""
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                fullWidth
                onClick={() => {
                  setOpeNewEdit(true);
                }}
              >
                Agregar Video
              </Button>
            </Grid>
          </Grid>
          <TableContainer className={classes.tableContainer}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>N°</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Fechas</TableCell>
                  <TableCell>Bloque horario</TableCell>
                  <TableCell align="center">Video</TableCell>
                  <TableCell>Duracion</TableCell>
                  <TableCell>Estado</TableCell>
                  {user?.tipoUsuario === "Administrador" && (
                    <TableCell>Clicks/Vistas</TableCell>
                  )}
                  <TableCell>Valor</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {videosState === FormState.Success ? (
                  videos.map((data, index) => (
                    <Fade key={data?.id} in={true}>
                      <TableRow hover className={classes.styledRow}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{data?.Nombre}</TableCell>
                        <TableCell>
                          {data?.Fecha &&
                            timesStampFormattedsimple(data?.Fecha.toDate())}
                          <b> a </b>
                          {data?.FechaFinal &&
                            timesStampFormattedsimple(
                              data?.FechaFinal.toDate()
                            )}
                        </TableCell>
                        <TableCell>
                          {data?.HoraInicio} - {data?.HoraFinal}
                        </TableCell>
                        <TableCell align="center">
                          <Button href={data?.Url} target="_blank">
                            Ver Video
                          </Button>
                        </TableCell>
                        <TableCell>
                          {(data?.Duracion / 60).toFixed(2)} Mins.
                        </TableCell>
                        <TableCell>{data?.Estado}</TableCell>
                        {user?.tipoUsuario === "Administrador" && (
                          <TableCell align="center">
                            <Button
                              variant="outlined"
                              color="primary"
                              startIcon={<FilePlus />}
                              onClick={() => {
                                dispatch(
                                  getClicksViewsCount(selectedPlace.id, data.id)
                                );
                                setOpenVCDialog(true);
                              }}
                            >
                              Detalle
                            </Button>
                          </TableCell>
                        )}
                        {data?.valor ? (
                          <TableCell>${numberWithDot(data?.Valor)}</TableCell>
                        ) : (
                          <TableCell>-</TableCell>
                        )}
                        <TableCell align="center">
                          <Box display="flex" justifyContent="center">
                            <Tooltip title="Editar">
                              <span>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setEditData(data);
                                    setTypeDialog("edit");
                                    setOpeNewEdit(true);
                                  }}
                                  color="primary"
                                >
                                  <Edit />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip title="Reproducir">
                              <span>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    setOpenMultimediaDialog(
                                      !openMultimediaDialog
                                    );
                                    setSrcVideo(data?.Url);
                                  }}
                                >
                                  <PlayCircle />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip title="Eliminar">
                              <span>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setEditData(data);
                                    setOpenConfirm(!openConfirm);
                                  }}
                                >
                                  <Trash2 color="red" />
                                </IconButton>
                              </span>
                            </Tooltip>
                            {!(
                              data.Estado === "Aprobado" ||
                              data.Estado === "Finalizado"
                            ) ? (
                              <Tooltip title="Pagar">
                                <form
                                  style={{ marginTop: "-8px" }}
                                  name="btnPago"
                                  method="post"
                                  action="https://webpay3g.transbank.cl/filtroUnificado/buttonService"
                                  target="_blank"
                                >
                                  <input
                                    type="hidden"
                                    name="buttonId"
                                    value="63e06a78183deb635796610848c288ca"
                                  />
                                  <IconButton
                                    type="submit"
                                    onClick={() =>{}
                                      // console.log("Pendiente de pago"){}
                                    }
                                    // type="image"
                                    // src="https://www.transbank.cl/public/img/botonwebpay.gif"
                                  >
                                    <CreditCard color={"#00b35a"} />
                                  </IconButton>
                                </form>
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </Fade>
                  ))
                ) : (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <DialogPlaceVideo
          open={openNewEdit}
          type={typeDialog}
          data={editData}
          onClose={() => {
            setEditData(undefined);
            setOpeNewEdit(false);
            setTypeDialog("new");
          }}
          onAccept={(values, type) => {
            setEditData(undefined);
            setOpeNewEdit(false);
            setTypeDialog("new");
            save(values, type);
          }}
        />
        <NewConfirmDialog
          open={openConfirm}
          title={"¿Eliminar video promocional?"}
          onClose={() => setOpenConfirm(!openConfirm)}
          onConfirmText={"Eliminar"}
          onConfirm={handleDelete}
        />
        <Dialog
          open={openMultimediaDialog}
          onClose={() => setOpenMultimediaDialog(!openMultimediaDialog)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Card>
            <CardMedia
              component="video"
              style={{ maxHeight: 250 }}
              title="Video Finalizacion"
              controls
              src={srcVideo}
            />
          </Card>
          <Button
            onClick={() => setOpenMultimediaDialog(!openMultimediaDialog)}
            color="primary"
            autoFocus
          >
            Cerrar
          </Button>
        </Dialog>
        <ViewsClicksDialog
          open={openVCDialog}
          title="Detalle"
          onClose={() => setOpenVCDialog(false)}
        />
      </React.Fragment>
    );
  }
);

{
  /* <Card>
              <CardMedia
                component="video"
                style={{ maxHeight: 250 }}
                title="Video Finalizacion"
                controls
                src={tour?.Traducciones[data]?.VideoFinalizacion}
              />
            </Card> */
}
