import { AppThunk } from "../../models/app-thunk";
import * as types from "../../constants";
import { Action } from "../../models/action";
import { DialogAction } from "../../models/dialog-actions";
import {
  firestore,
  firebaseConfig,
  storage,
  auth,
} from "../../firebase/firebase";
import firebase from "firebase/app";
import { cleanString } from "../../utils/utils";
import Axios from "axios";
import { openSnack } from "./uiActions";
import { SnackState } from "../../models/snack-state";
import { deleteTour } from "./toursActions";

export const editCount = async (
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  type: "plus" | "minus"
) => {
  const docSnapshot = await ref.get();
  if (docSnapshot.exists) {
    return ref.update(
      "count",
      firebase.firestore.FieldValue.increment(type === "plus" ? 1 : -1)
    );
  } else {
    return ref.set({ count: 1 });
  }
};

export const editListNotifs = async (
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  texto: string
) => {
  const docSnapshot = await ref.get();
  if (docSnapshot.exists) {
    const dateString = firebase.firestore.Timestamp.now()
      .toDate()
      .toLocaleDateString("es-ES", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    return ref.update(
      "Lista",
      firebase.firestore.FieldValue.arrayUnion(texto + ". Fecha: " + dateString)
    );
  } else {
    return ref.set({ Lista: [texto] });
  }
};

export const SaveCreationNotif = async (texto: string): Promise<string> => {
  try {
    const notifRef = firestore
      .collection("AdminNotifications")
      .doc("CreationGuide");

    await editListNotifs(notifRef, texto);

    return "ok";
  } catch (error) {
    console.log(error);
    // throw error;
    return String(error);
  }
};

export const getTourGuides = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore
        .collection("Usuarios")
        .where("Activo", "==", true)
        .where("TipoUsuario", "==", "Guia")
        .orderBy("Nombre_lower")
        .limit(limit)
        .get();

      const response2 = await firestore
        .collection("Usuarios")
        .where("Activo", "==", true)
        .where("TipoUsuario", "==", "Guia")
        .get();

      const guidesList = mapTourGuides(response);

      await Promise.all(
        guidesList.map(async (guide) => {
          const rateResponse = await firestore
            .collection("Calificaciones")
            .where("GuideId", "==", guide.id)
            .get();

          let rateSum = 5;
          rateResponse.docs.map((rate) => {
            rateSum = rateSum + rate.data()?.Promedio;
          });
          const calificacion = rateSum / (rateResponse.size + 1);
          guide.Calificacion = Number(calificacion?.toFixed(1));
        })
      ).then(() => {
        dispatch(
          setTourGuides(
            guidesList,
            response2.size,
            response.docs[response.docs.length - 1]
          )
        );
      });

      // dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getTourGuidesFiltered = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: any
): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      let query = firestore
        .collection("Usuarios")
        .where("Activo", "==", true)
        .where("TipoUsuario", "==", "Guia");

      if (filter) {
        Object.keys(filter).forEach((key) => {
          let value = filter[key];

          if (key === "endAt") {
            const miliDate = Date.parse(value);
            query = query.startAt(new Date(miliDate));
          } else if (key === "startAt") {
            const miliDate = Date.parse(value);
            query = query.endAt(new Date(miliDate));
          } else {
            query = query.where(key, "==", value);
          }
        });
      }
      const fullResponse = await query.get();
      const response = await query.limit(limit).get();

      const guidesList = mapTourGuides(response);

      await Promise.all(
        guidesList.map(async (guide) => {
          const rateResponse = await firestore
            .collection("Calificaciones")
            .where("GuideId", "==", guide.id)
            .get();

          let rateSum = 5;
          rateResponse.docs.map((rate) => {
            rateSum = rateSum + rate.data()?.Promedio;
          });

          const calificacion = rateSum / (rateResponse.size + 1);
          guide.Calificacion = Number(calificacion?.toFixed(1));
        })
      ).then(() => {
        dispatch(
          setTourGuides(
            guidesList,
            fullResponse.size,
            response.docs[response.docs.length - 1]
          )
        );
      });

      // dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getTourGuide = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore.collection("Usuarios").doc(id).get();
      dispatch(isLoading(false));
      dispatch(setSelectedTourGuide(mapTourGuide(response)));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
      dispatch(isLoading(false));
    }
  };
};

export const getTourGuideRegions = (): AppThunk => {
  return async (dispatch) => {
    dispatch(updateIsLoading(true));
    try {
      const response = await firestore.collection("Regiones").get();

      dispatch(getTourGuideRegionsData(mapTourGuideRegions(response)));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(updateIsLoading(false));
    }
  };
};

export const resetPaswordTourGuide = (data: any): AppThunk => {
  return async (dispatch) => {
    try {
      Axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${firebaseConfig.apiKey}`,
        {
          requestType: "PASSWORD_RESET",
          email: data.Email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(() => {
          dispatch(
            openSnack(
              "Se ha enviado correo para cambiar contraseña",
              SnackState.SUCCESS
            )
          );
        })
        .catch(() => {
          dispatch(
            openSnack("No se ha podido enviar el correo", SnackState.ERROR)
          );
        });
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const createTourGuide = (tourGuide: any): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));

    try {
      const responseAxios = await Axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${firebaseConfig.apiKey}`,
        {
          email: tourGuide.Email,
          password: "#$2021#$",
          returnSecureToken: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).catch((errorReg) => {
        if (errorReg.response.data.error.message === "EMAIL_EXISTS") {
          dispatch(openSnack("Correo ya utilizado", SnackState.ERROR));
        } else {
          dispatch(openSnack("Ha ocurrido un problema", SnackState.ERROR));
        }
      });

      if (responseAxios && responseAxios!.status === 200) {
        // responseAxios!.status === 200
        const id = responseAxios!.data.localId;
        if (tourGuide.CertificadoLaboral) {
          // cert1
          if (tourGuide.CertificadoEspecializacion) {
            const fileRef = storage.ref(
              `${id}/documentos/CertificadoAntecedentes`
            );
            const fileUpload = await fileRef.put(
              tourGuide.CertificadoEspecializacion
            );
            const CertEspURL = await fileUpload.ref.getDownloadURL();
            tourGuide.CertificadoEspecializacion = CertEspURL;
          }

          // cert2
          if (tourGuide.CertificacionSernatur) {
            const fileRef3 = storage.ref(
              `${id}/documentos/CertificadoTributario`
            );
            const fileUpload3 = await fileRef3.put(
              tourGuide.CertificacionSernatur
            );
            const certSerURL = await fileUpload3.ref.getDownloadURL();
            tourGuide.CertificacionSernatur = certSerURL;
          }

          //cert3
          if (tourGuide.CertificadoCompetencias) {
            const fileRef4 = storage.ref(
              `${id}/documentos/CertificadoCompetencias`
            );
            const fileUpload4 = await fileRef4.put(
              tourGuide.CertificadoCompetencias
            );
            const certCompURL = await fileUpload4.ref.getDownloadURL();
            tourGuide.CertificadoCompetencias = certCompURL;
          }

          const fileRef2 = storage.ref(`${id}/documentos/CedulaIdentidad`);
          const fileUpload2 = await fileRef2.put(tourGuide.CertificadoLaboral);
          const certLabURL = await fileUpload2.ref.getDownloadURL();
          tourGuide.CertificadoLaboral = certLabURL;

          tourGuide.CertificadoEspecializacionRef = `${id}/documentos/CertificadoAntecedentes`;
          tourGuide.CertificadoLaboralRef = `${id}/documentos/CedulaIdentidad`;
          tourGuide.CertificacionSernaturRef = `${id}/documentos/CertificadoTributario`;
          tourGuide.CertificadoCompetenciasRef = `${id}/documentos/CertificadoCompetencias`;

          firestore
            .collection("Usuarios")
            .doc(id)
            .set({
              TerminosYCondiciones: true,
              Nombre: tourGuide.Nombre,
              Nombre_lower: cleanString(tourGuide.Nombre),
              Apellido: tourGuide.Apellido,
              Apellido_lower: cleanString(tourGuide.Apellido),
              NombreApellido_lower:
                cleanString(tourGuide.Nombre) +
                " " +
                cleanString(tourGuide.Apellido),

              CertificadoAntecedentes: tourGuide.CertificadoEspecializacion
                ? tourGuide.CertificadoEspecializacion
                : null,
              CertificadoAntecedentesRef: tourGuide.CertificadoEspecializacion
                ? tourGuide.CertificadoEspecializacionRef
                : "",

              CedulaIdentidad: tourGuide.CertificadoLaboral,
              CedulaIdentidadRef: tourGuide.CertificadoLaboralRef,

              CertificadoTributario: tourGuide.CertificacionSernatur
                ? tourGuide.CertificacionSernatur
                : null,
              CertificadoTributarioRef: tourGuide.CertificacionSernatur
                ? tourGuide.CertificacionSernaturRef
                : "",

              CertificadoCompetencias: tourGuide.CertificadoCompetencias
                ? tourGuide.CertificadoCompetencias
                : null,
              CertificadoCompetenciasRef: tourGuide.CertificadoCompetencias
                ? tourGuide.CertificadoCompetenciasRef
                : "",

              Telefono: tourGuide.Telefono,
              Email: tourGuide.Email,
              Descripcion: tourGuide.Descripcion || "",
              Region: tourGuide.Region,
              Activo: true,
              TipoUsuario: "Guia",
            })
            .then(() => {
              Axios.post(
                `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyCDxUc2ZnW5LD2QoM2BoSH0786qJNx04NM`,
                {
                  requestType: "PASSWORD_RESET",
                  email: tourGuide.Email,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              ).then(async () => {
                await SaveCreationNotif("Guia creado: " + tourGuide.Email);
                dispatch(
                  openSnack(
                    "Guia registrado, se ha enviado enlace al correo",
                    SnackState.SUCCESS
                  )
                );
              });
            });
        } else {
          await firestore
            .collection("Usuarios")
            .doc(id) // id
            .set({
              Nombre: tourGuide.Nombre,
              Nombre_lower: cleanString(tourGuide.Nombre),
              Apellido: tourGuide.Apellido,
              Apellido_lower: cleanString(tourGuide.Apellido),
              NombreApellido_lower:
                cleanString(tourGuide.Nombre) +
                " " +
                cleanString(tourGuide.Apellido),
              Telefono: tourGuide.Telefono,
              Email: tourGuide.Email,
              Descripcion: tourGuide.Descripcion || "",
              Region: tourGuide.Region,
              Activo: true,
              TipoUsuario: "Guia",
            })
            .then(() => {
              Axios.post(
                `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${firebaseConfig.apiKey}`,
                {
                  requestType: "PASSWORD_RESET",
                  email: tourGuide.Email,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
                .then(async () => {
                  await SaveCreationNotif("Guia creado: " + tourGuide.Email);
                  dispatch(
                    openSnack(
                      "Guia registrado, se ha enviado enlace al correo",
                      SnackState.SUCCESS
                    )
                  );
                })
                .then(() => dispatch(getTourGuides()));
            });
        }
      }
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const updateTourGuide = (tourGuide: any, id: string): AppThunk => {
  return async (dispatch) => {
    dispatch(updateIsLoading(true));

    try {
      await firestore
        .collection("Usuarios")
        .doc(id)
        .update({
          Nombre: tourGuide.Nombre,
          Nombre_lower: cleanString(tourGuide.Nombre),
          Apellido: tourGuide.Apellido,
          Apellido_lower: cleanString(tourGuide.Apellido),
          NombreApellido_lower:
            cleanString(tourGuide.Nombre) +
            " " +
            cleanString(tourGuide.Apellido),
          Telefono: String(tourGuide.Telefono),
          Codigo: tourGuide.Codigo,
          Descripcion: tourGuide.Descripcion,
          Region: tourGuide.Region,
        });

      dispatch(getTourGuides());
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(updateIsLoading(false));
    }
  };
};

export const updateTourGuideBlock = (
  blockObject: any,
  id: string
): AppThunk => {
  return async (dispatch) => {
    dispatch(updateIsLoading(true));
    try {
      console.log(blockObject);

      await firestore
        .collection("Usuarios")
        .doc(id)
        .update({
          Bloqueo: {
            Bloqueo: blockObject.Bloqueo,
            Inicio: firebase.firestore.FieldValue.serverTimestamp(),
            Termino: blockObject.Termino,
            Causa: blockObject.Causa,
            Descripcion: blockObject.Descripcion,
          },
        });

      dispatch(getTourGuides());
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(updateIsLoading(false));
    }
  };
};

export type ByKey = "Nombre_lower" | "Apellido_lower" | "Region" | "Idiomas";

export const searchTourGuides = (
  query: string | string[],
  byKey: ByKey
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));

    try {
      let response: any;

      if (byKey === "Nombre_lower" || byKey === "Apellido_lower" || "Region") {
        response = await firestore
          .collection("Usuarios")
          .orderBy(byKey)
          .startAt(query)
          .endAt(query + "\uf8ff")
          .where("Activo", "==", true)
          .where("TipoUsuario", "==", "Guia")
          .get();
      } else if (byKey === "Idiomas") {
        response = await firestore
          .collection("Usuarios")
          .where("TipoUsuario", "==", "Guia")
          .where("Idiomas", "array-contains-any", query)
          .get();
      }

      dispatch(
        setTourGuides(
          mapTourGuides(response),
          response.size,
          response.docs[response.docs.length - 1]
        )
      );
      // dispatch(setTotalDocs(response.size));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

const mapTourGuides = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  try {
    let tourGuides: any[] = [];
    response.docs.forEach((doc) => {
      let data = mapTourGuide(doc);
      tourGuides.push(data);
    });
    return tourGuides;
  } catch (error) {
    throw error;
  }
};

export const openNewTourGuidesDialog = (): AppThunk => {
  return (dispatch) => {
    dispatch(setDialogAction(DialogAction.New));
    dispatch(setTourGuidesDialogOpen(true));
  };
};

const mapTourGuide = (doc: any) => {
  let data = doc.data();
  data.id = doc.id;
  return data;
};

const mapTourGuideRegions = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  try {
    let TourRegion: any[] = [];
    response.docs.forEach((doc) => {
      let data = mapRegions(doc);
      TourRegion.push(data);
    });
    return TourRegion;
  } catch (error) {
    throw error;
  }
};

const mapRegions = (doc: any) => {
  let data = doc.data();
  data.id = doc.id;
  return data;
};

export const addMoreTourGuides = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));

    const lastDoc = getState().tourGuidesReducer.lastDoc || "";
    const { totalDocs } = getState().tourGuidesReducer;

    try {
      let query = firestore
        .collection("Usuarios")
        .orderBy("Nombre_lower")
        .where("Activo", "==", true)
        .where("TipoUsuario", "==", "Guia");

      const response = await query.startAfter(lastDoc).limit(limit).get();

      const guidesList = mapTourGuides(response);

      await Promise.all(
        guidesList.map(async (guide) => {
          const rateResponse = await firestore
            .collection("Calificaciones")
            .where("GuideId", "==", guide.id)
            .get();

          let rateSum = 5;
          rateResponse.docs.map((rate) => {
            rateSum = rateSum + rate.data()?.Promedio;
          });
          guide.Calificacion = rateSum / (rateResponse.size + 1);
        })
      ).then(() => {
        dispatch(addTourGuides(guidesList));
        dispatch(setLastDoc(response.docs[response.docs.length - 1]));
      });
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const addMoreTourGuidesFiltered = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: any
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));

    const lastDoc = getState().tourGuidesReducer.lastDoc || "";

    try {
      let query = firestore
        .collection("Usuarios")
        .orderBy("Nombre_lower")
        .where("Activo", "==", true)
        .where("TipoUsuario", "==", "Guia");

      const response = await query.startAfter(lastDoc).limit(limit).get();

      const guidesList = mapTourGuides(response);

      await Promise.all(
        guidesList.map(async (guide) => {
          const rateResponse = await firestore
            .collection("Calificaciones")
            .where("GuideId", "==", guide.id)
            .get();

          let rateSum = 5;
          rateResponse.docs.map((rate) => {
            rateSum = rateSum + rate.data()?.Promedio;
          });
          guide.Calificacion = rateSum / (rateResponse.size + 1);
        })
      ).then(() => {
        dispatch(addTourGuides(guidesList));
        dispatch(setLastDoc(response.docs[response.docs.length - 1]));
      });
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getTotalDocs = (): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await firestore
        .collection("Usuarios")
        .where("Activo", "==", true)
        .where("TipoUsuario", "==", "Guia")
        .get();
      console.log(response.size);

      dispatch(setTotalDocs(response.size));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    }
  };
};
export const getGuideVideosConverted = (
  id: string,
  startDate: any,
  endDate: any
): AppThunk => {
  return async (dispatch) => {
    dispatch({ type: types.TOUR_GUIDE_GET_VIDS_CONVERTED_REQUEST });
    try {
      const miliDateStart = Date.parse(startDate);
      const miliDateEnd = Date.parse(endDate);
      const response = await firestore
        .collection("VideosConvertidos")
        .where("GuideId", "==", id)
        .orderBy("FechaCreacion")
        .startAt(new Date(miliDateStart))
        .endAt(new Date(miliDateEnd))
        .get();

      let data: any[] = [];
      if (response.docs.length !== 0 && response.docs !== undefined) {
        data = response.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
      }
      dispatch({
        type: types.TOUR_GUIDE_GET_VIDS_CONVERTED_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({ type: types.TOUR_GUIDE_GET_VIDS_CONVERTED_FAILURE });
      console.log(error);
    }
  };
};

export const deleteTourGuide = (selected: any): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const id = selected.id;
      await firestore
        .collection("Usuarios")
        .doc(id)
        .update({
          Activo: false,
          Email: firebase.firestore.FieldValue.delete(),
        })
        .catch(() => {
          console.log("Couldn't delete from firestore");
        });

      const responses = await firestore
        .collection("Tours")
        .where("Guia.Id", "==", id)
        .get();

      await Promise.all(
        responses.docs.map(async (doc) => {
          await firestore
            .collection("Tours")
            .doc(doc.id)
            .update({
              Bloqueo: { Motivo: "Guia Eliminado" },
              Estado: "Pendiente",
              Modificaciones: firebase.firestore.FieldValue.arrayUnion(
                `¡GUÍA ELIMINADO: ${id}!`
              ),
            });
        })
      );

      try {
        await Axios.post(
          `https://identitytoolkit.googleapis.com/v1/accounts:delete?key=AIzaSyCDxUc2ZnW5LD2QoM2BoSH0786qJNx04NM`,
          {
            idToken: id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } catch {
        await Axios.post(
          `https://us-central1-traveapp-fa700.cloudfunctions.net/app/delete/user/auth`,
          {
            email: selected.Email,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        ).catch(() => console.log("ERROR"));
      }

      dispatch(removeTourGuideStore({ id }));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

const removeTourGuideStore = (priority: any): Action => ({
  type: types.TOUR_GUIDES_REMOVE_DOC,
  payload: priority,
});

export const restoreGetVidsConvertedData = (): Action => ({
  type: types.TOUR_GUIDE_GET_VIDS_CONVERTED_RESTORE,
});

export const setSelectedTourGuide = (tourGuide: any): Action => ({
  type: types.TOUR_GUIDES_SET_SELECTED,
  payload: tourGuide,
});

const getTourGuideRegionsData = (tourGuideRegions: any): Action => ({
  type: types.TOUR_GUIDES_REGIONS_GET,
  payload: tourGuideRegions,
});

const updateIsLoading = (isLoading: boolean): Action => ({
  type: types.TOUR_GUIDES_UPDATE_LOADING,
  payload: isLoading,
});

// const updateTourGuideStore = (tourGuide: any): Action => ({
//   type: types.TOUR_GUIDES_UPDATE_DOC,
//   payload: tourGuide,
// });

const setTourGuides = (
  tourGuides: any[],
  totalDocs: any,
  lastDoc: any
): Action => ({
  type: types.TOUR_GUIDES_GET_DOCS,
  payload: { tourGuides: tourGuides, totalDocs: totalDocs, lastDoc: lastDoc },
});

const setLastDoc = (doc: any): Action => ({
  type: types.TOUR_GUIDES_SET_LAST_DOC,
  payload: doc,
});

const addTourGuides = (tourGuides: any[]): Action => ({
  type: types.TOUR_GUIDES_ADD_DOCS,
  payload: tourGuides,
});

const isLoading = (isloading: boolean): Action => ({
  type: types.TOUR_GUIDES_LOADING,
  payload: isloading,
});

const setError = (error: string): Action => ({
  type: types.TOUR_GUIDES_FAILURE,
  payload: error,
});

const setTotalDocs = (total: number): Action => ({
  type: types.TOUR_GUIDES_SET_TOTAL_DOCS,
  payload: total,
});

export const setDialogAction = (dialogAction: DialogAction): Action => ({
  type: types.TOUR_GUIDES_CHANGE_DIALOG_ACTION,
  payload: dialogAction,
});

export const setTourGuidesDialogOpen = (isOpen: boolean): Action => ({
  type: types.TOUR_GUIDES_IS_DIALOG_OPEN,
  payload: isOpen,
});
