import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Snackbar,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { Alert as MuiAlert, Autocomplete } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import { Edit3, File, MapPin, Search } from "react-feather";
import * as Yup from "yup";
import { NewConfirmScrolltextDialog } from "../../components/NewConfirmScrolltextDialog";
import { DialogLoad } from "../../components/DialogLoad";
import { CAPTCHA_KEY } from "../../constants";
import { SnackState } from "../../models/snack-state";
import { getRegions } from "../../redux/actions/regionsActions";
import { getPlacesTypes } from "../../redux/actions/placesTypesActions";
import { closeSnack } from "../../redux/actions/uiActions";
import logo from "../../vendor/logoTrave.png";
import * as rutUtils from "rut.js";
import MapDialog from "../../components/PlacesComponents/MapDialog";
import { createPlace } from "../../redux/actions/placesActions";
import { FormState } from "../../models/form_state";
import { signOut } from "../../redux/actions/authActions";
import { Link } from "react-router-dom";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function PlaceRegister() {
  const dispatch = useDispatch();
  const history = useHistory();

  const captcha = useRef(null);

  const [validCaptcha, setValidCaptcha] = useState(false);
  const [validFile, setValidFile] = useState(true);
  const [validFile2, setValidFile2] = useState(true);
  const [validFile3, setValidFile3] = useState(true);
  const [validFile4, setValidFile4] = useState(true);
  const [isOpen, setOpen] = useState(false);
  // const [openSnackbar, setOpenSnackbar] = useState(false);

  const [guideData, setGuideData] = useState({});
  const [placeFiles, setPlaceFiles] = useState({});

  const { regions } = useSelector((state) => state.regionsReducer);
  const { placesTypes } = useSelector((state) => state.placesTypesReducer);
  const { add } = useSelector((state) => state.placesReducer);

  const {
    snack: { snackState, open, text },
  } = useSelector((state) => state.uiReducer);

  const [openMapDialog, setOpenMapDialog] = useState(false);
  const [checkLugar, setCheckLugar] = useState(false);

  const handleCaptchaChange = () => {
    if (captcha.current.getValue()) {
      setValidCaptcha(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // setOpenSnackbar(!openSnackbar);
  };

  const handleAccept = () => {
    // setOpenSnackbar(!openSnackbar);
    dispatch(createPlace(guideData, placeFiles));
    setOpen(!isOpen);
  };
  useEffect(() => {
    dispatch(getRegions());
  }, [dispatch]);
  useEffect(() => {
    if (placesTypes === null) {
      dispatch(getPlacesTypes());
    }
  }, [dispatch, placesTypes]);

  useEffect(() => {
    return () => {
      dispatch(signOut());
    };
  }, []);

  return (
    <Card style={{ width: "100%" }}>
      <CardContent>
        <NewConfirmScrolltextDialog
          open={isOpen}
          isPlace
          title={"¿Acepta términos y condiciones?"}
          content="Ejemplo de Términos y Condiciones..."
          onClose={() => setOpen(!isOpen)}
          onConfirmText="Aceptar"
          onConfirm={handleAccept}
        />
        <Helmet title="Registro de Comercios" />
        <Grid container alignContent="center" spacing={6}>
          <Grid item xs={12} sm={12}>
            <Box m={2} display="flex" justifyContent="center">
              <img src={logo} style={{ maxHeight: 60 }} alt="img-logo" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              Formulario de Registro Comercios
            </Typography>
          </Grid>
        </Grid>
        <Formik
          initialValues={{
            Nombre: "",
            NombreLugar: "",
            TipoLugar: "",
            LugarGoogle: "",
            Apellido: "",
            Email: "",
            Descripcion: "",
            DireccionEscrita: "",
            RazonSocial: "",
            NombreFantasia: "",
            Rut: "",
            Giro: "",
            DireccionFacturacion: "",
            Telefono: 0,
            TelefonoComercio: 0,
            Region: null,
            CertificadoIniciacion: null,
            CertificadoRegistroSernatur: null,
            PatenteComercial: null,
            CedulaRepresentante: null,
            HorarioApertura: "",
            HorarioCierre: "",
          }}
          validationSchema={Yup.object().shape({
            NombreLugar: Yup.string().required("Nombre requerido"),
            TipoLugar: Yup.string().required("Tipo lugar es requerido"),
            Descripcion: Yup.string()
              .max(280, "Maximo 280 caracteres")
              .required("Descripción requerida"),
            RazonSocial: Yup.string().required("Razón requerida"),
            NombreFantasia: Yup.string().required("Nombre requerido"),
            Rut: Yup.string()
              .min(11)
              .required("Rut es requerido")
              .test({
                name: "Rut",
                message: "Rut no válido",
                test: (value) => {
                  if (!value) return false;
                  return rutUtils.validate(value);
                },
              }),
            Giro: Yup.string().required("Giro requerido"),
            DireccionEscrita: Yup.string().required(
              "Dirección del lugar requerida"
            ),
            DireccionFacturacion: Yup.string().required("Dirección requerida"),
            Ciudad: Yup.string().required("Ciudad requerida"),
            Email: Yup.string()
              .email("Debe ser un email válido")
              .max(255)
              .required("Email es requerido"),
            Nombre: Yup.string().required("Nombre es requerido"),
            Apellido: Yup.string().required("Apellido es requerido"),
            Telefono: Yup.number()
              .min(900000000, "Número no válido")
              .max(999999999, "Número no válido")
              .required("Número de teléfono es requerido"),
            TelefonoComercio: Yup.number()
              .min(900000000, "Número no válido")
              .max(999999999, "Número no válido")
              .required("Es requerido")
              .required("Número es requerido"),
            Region: Yup.string("Región es requerida").required(
              "Región es requerida"
            ),
            HorarioApertura: Yup.string().required("Hora requerida"),
            HorarioCierre: Yup.string().required("Hora requerida"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (!values.CertificadoIniciacion) {
                setValidFile(!validFile);
              } else if (!values.CertificadoRegistroSernatur) {
                setValidFile2(!validFile2);
              } else if (!values.PatenteComercial) {
                setValidFile3(!validFile3);
              } else if (!values.CedulaRepresentante) {
                setValidFile4(!validFile4);
              } else {
                setValidFile(true);
                setValidFile2(true);
                setValidFile3(true);
                setValidFile4(true);
                if (values.LugarGoogle === "" || !values.LugarGoogle) {
                  setCheckLugar(!checkLugar);
                } else {
                  setPlaceFiles({
                    CertificadoIniciacion: values.CertificadoIniciacion,
                    CertificadoRegistroSernatur:
                      values.CertificadoRegistroSernatur,
                    PatenteComercial: values.PatenteComercial,
                    CedulaRepresentante: values.CedulaRepresentante,
                  });
                  setGuideData(values);
                  setOpen(true);
                }
              }
              // await dispatch();
            } catch (error) {
              const message = error.message || "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <Grid container spacing={6}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    type="text"
                    name="NombreLugar"
                    label="Nombre Lugar"
                    value={values.NombreLugar}
                    error={Boolean(touched.NombreLugar && errors.NombreLugar)}
                    fullWidth
                    helperText={touched.NombreLugar && errors.NombreLugar}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="DireccionEscrita"
                    name="DireccionEscrita"
                    value={values.DireccionEscrita}
                    label="Direccion del lugar"
                    placeholder="Direccion del lugar"
                    variant="outlined"
                    autoComplete="off"
                    type="text"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.DireccionEscrita &&
                      Boolean(errors.DireccionEscrita)
                    }
                    helperText={
                      touched.DireccionEscrita && errors.DireccionEscrita
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {placesTypes && (
                    <Autocomplete
                      style={{ marginTop: 8 }}
                      id="TipoLugar"
                      value={values.TipoLugar}
                      options={placesTypes}
                      getOptionLabel={(option) =>
                        option.Nombre || values.TipoLugar
                      }
                      filterOptions={(option) =>
                        placesTypes?.filter((x) => x?.Activo)
                      }
                      onChange={(_, value) => {
                        if (value) {
                          setFieldValue("TipoLugar", value.Nombre);
                        }
                      }}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipo"
                          variant="outlined"
                          error={touched.TipoLugar && Boolean(errors.TipoLugar)}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="TelefonoComercio"
                    value={values.TelefonoComercio}
                    label="Número de Contacto"
                    type="number"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+56</InputAdornment>
                      ),
                    }}
                    error={
                      touched.TelefonoComercio &&
                      Boolean(errors.TelefonoComercio)
                    }
                    helperText={
                      touched.TelefonoComercio && errors.TelefonoComercio
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id="combo-box-regions"
                    name="Region"
                    value={values.Region}
                    options={regions}
                    getOptionLabel={(option) => option.Nombre || values.Region}
                    onChange={(event, value) => {
                      if (value) {
                        setFieldValue("Region", value?.Nombre);
                      }
                    }}
                    fullWidth
                    my={2}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Región"
                        variant="outlined"
                        error={Boolean(touched.Region && errors.Region)}
                        helperText={touched.Region && errors.Region}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="outlined"
                    style={{ height: 53 }}
                    fullWidth
                    startIcon={<MapPin color={checkLugar ? "red" : "blue"} />}
                    onClick={() => setOpenMapDialog(!openMapDialog)}
                  >
                    {values.LugarGoogle
                      ? "[" +
                        Number(values?.LugarGoogle.lat).toFixed(6) +
                        "," +
                        Number(values?.LugarGoogle.lng).toFixed(6) +
                        "]"
                      : "Seleccionar Lugar"}
                  </Button>
                  {checkLugar && (
                    <Typography color="error">Lugar es requerido</Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="Descripcion"
                    value={values.Descripcion}
                    label="Descripción"
                    multiline
                    rows={6}
                    variant="outlined"
                    autoComplete="off"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Descripcion && Boolean(errors.Descripcion)}
                    helperText={touched.Descripcion && errors.Descripcion}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="RazonSocial"
                    value={values.RazonSocial}
                    label="Razón Social"
                    variant="outlined"
                    autoComplete="off"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.RazonSocial && Boolean(errors.RazonSocial)}
                    helperText={touched.RazonSocial && errors.RazonSocial}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="NombreFantasia"
                    value={values.NombreFantasia}
                    label="Nombre Fantasia"
                    variant="outlined"
                    autoComplete="off"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.NombreFantasia && Boolean(errors.NombreFantasia)
                    }
                    helperText={touched.NombreFantasia && errors.NombreFantasia}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Rut"
                    value={values.Rut}
                    label="Rut"
                    variant="outlined"
                    autoComplete="off"
                    type="text"
                    fullWidth
                    onChange={(e) => {
                      e.target.value = rutUtils.format(e.target.value);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    error={touched.Rut && Boolean(errors.Rut)}
                    helperText={touched.Rut && errors.Rut}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Giro"
                    value={values.Giro}
                    label="Giro"
                    variant="outlined"
                    autoComplete="off"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Giro && Boolean(errors.Giro)}
                    helperText={touched.Giro && errors.Giro}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="time"
                    label="Horario Apertura"
                    type="time"
                    variant="outlined"
                    value={values.HorarioApertura}
                    onChange={(e) => {
                      setFieldValue("HorarioApertura", e.target.value);
                    }}
                    error={
                      touched.HorarioApertura && Boolean(errors.HorarioApertura)
                    }
                    helperText={
                      touched.HorarioApertura && errors.HorarioApertura
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="time2"
                    label="Horario Cierre"
                    type="time"
                    variant="outlined"
                    value={values.HorarioCierre}
                    onChange={(e) => {
                      setFieldValue("HorarioCierre", e.target.value);
                    }}
                    error={
                      touched.HorarioCierre && Boolean(errors.HorarioCierre)
                    }
                    helperText={touched.HorarioCierre && errors.HorarioCierre}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="DireccionFacturacion"
                    value={values.DireccionFacturacion}
                    label="Dirección Facturación"
                    variant="outlined"
                    autoComplete="off"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.DireccionFacturacion &&
                      Boolean(errors.DireccionFacturacion)
                    }
                    helperText={
                      touched.DireccionFacturacion &&
                      errors.DireccionFacturacion
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Ciudad"
                    value={values.Ciudad}
                    label="Ciudad"
                    variant="outlined"
                    autoComplete="off"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Ciudad && Boolean(errors.Ciudad)}
                    helperText={touched.Ciudad && errors.Ciudad}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Nombre"
                    value={values.Nombre}
                    label="Nombre Representante Legal"
                    variant="outlined"
                    autoComplete="off"
                    placeholder="Nombre del representante legal"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Nombre && Boolean(errors.Nombre)}
                    helperText={touched.Nombre && errors.Nombre}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Apellido"
                    value={values.Apellido}
                    label="Apellido"
                    variant="outlined"
                    autoComplete="off"
                    placeholder="Apellido del representante legal"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Apellido && Boolean(errors.Apellido)}
                    helperText={touched.Apellido && errors.Apellido}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    style={{ marginTop: -1 }}
                    variant="outlined"
                    type="email"
                    name="Email"
                    label="Dirección de Email"
                    value={values.Email}
                    error={Boolean(touched.Email && errors.Email)}
                    fullWidth
                    helperText={touched.Email && errors.Email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                  />
                  * Email para ingreso. Se enviará correo para cambio de
                  contraseña.
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="Telefono"
                    label="Teléfono Representante"
                    value={values.Telefono}
                    error={Boolean(touched.Telefono && errors.Telefono)}
                    fullWidth
                    helperText={touched.Telefono && errors.Telefono}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+569</InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography gutterBottom>
                    * Todos los campos son requeridos.
                  </Typography>
                </Grid>
                {/* <Grid item xs={12} sm={12} style={{ marginTop: -18 }}>
                  * Si eres usuario de la aplicación este correo debe ser
                  diferente a tu correo de usuario.
                </Grid> */}
                <Grid item xs={12} sm={12}>
                  <ReCAPTCHA
                    ref={captcha}
                    sitekey={CAPTCHA_KEY}
                    onChange={handleCaptchaChange}
                    onExpired={() => setValidCaptcha(false)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography>* Sólo se admiten archivos PDF.</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    size="small"
                    variant="contained"
                    component="label"
                    startIcon={<CloudUpload />}
                  >
                    Certificación Incio Actividades o Cartola Tributaria.
                    <input
                      type="file"
                      accept="application/pdf"
                      hidden
                      onChange={(event) => {
                        if (event.target.files) {
                          if (event.target.files[0].size > 943718) {
                            alert("Archivo demasiado pesado!");
                          } else {
                            setFieldValue(
                              "CertificadoIniciacion",
                              event.target.files[0]
                            );
                          }
                        }
                      }}
                    />
                  </Button>
                  {values.CertificadoIniciacion ? (
                    <Typography>{values.CertificadoIniciacion.name}</Typography>
                  ) : !validFile ? (
                    <Typography variant="caption" color="error">
                      Certificado es requerido
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    size="small"
                    variant="contained"
                    component="label"
                    startIcon={<CloudUpload />}
                  >
                    Certificado Registro Sernatur.
                    <input
                      type="file"
                      accept="application/pdf"
                      hidden
                      onChange={(event) => {
                        if (event.target.files) {
                          if (event.target.files[0].size > 943718) {
                            alert("Archivo demasiado pesado!");
                          } else {
                            setFieldValue(
                              "CertificadoRegistroSernatur",
                              event.target.files[0]
                            );
                          }
                        }
                      }}
                    />
                  </Button>
                  {values.CertificadoRegistroSernatur ? (
                    <Typography>
                      {values.CertificadoRegistroSernatur.name}
                    </Typography>
                  ) : !validFile2 ? (
                    <Typography variant="caption" color="error">
                      Este documento es requerido
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    size="small"
                    variant="contained"
                    component="label"
                    startIcon={<CloudUpload />}
                  >
                    Patente Comercial Vigente.
                    <input
                      type="file"
                      accept="application/pdf"
                      hidden
                      onChange={(event) => {
                        if (event.target.files) {
                          if (event.target.files[0].size > 943718) {
                            alert("Archivo demasiado pesado!");
                          } else {
                            setFieldValue(
                              "PatenteComercial",
                              event.target.files[0]
                            );
                          }
                        }
                      }}
                    />
                  </Button>
                  {values.PatenteComercial ? (
                    <Typography>{values.PatenteComercial.name}</Typography>
                  ) : !validFile3 ? (
                    <Typography variant="caption" color="error">
                      Este documento es requerido
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Button
                    size="small"
                    variant="contained"
                    component="label"
                    startIcon={<CloudUpload />}
                  >
                    Cédula Representante Legal (Por Ambos Lados).
                    <input
                      type="file"
                      accept="application/pdf"
                      hidden
                      onChange={(event) => {
                        if (event.target.files) {
                          if (event.target.files[0].size > 943718) {
                            alert("Archivo demasiado pesado!");
                          } else {
                            setFieldValue(
                              "CedulaRepresentante",
                              event.target.files[0]
                            );
                          }
                        }
                      }}
                    />
                  </Button>
                  {values.CedulaRepresentante ? (
                    <Typography>{values.CedulaRepresentante.name}</Typography>
                  ) : !validFile4 ? (
                    <Typography variant="caption" color="error">
                      Este documento es requerido
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={
                      !validCaptcha || add?.state === FormState.Submitting
                    }
                  >
                    {add?.state === FormState.Submitting ? (
                      <CircularProgress color="inherit" size={18} />
                    ) : (
                      "Inscribirse"
                    )}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    component={Link}
                    to="/auth/sign-in"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    {"Volver a Ingreso"}
                  </Button>
                </Grid>
              </Grid>
              <MapDialog
                myPath={values.LugarGoogle}
                open={openMapDialog}
                onClose={() => setOpenMapDialog(!openMapDialog)}
                onChangeLocation={(location) => {
                  setFieldValue("LugarGoogle", {
                    lng: location[0],
                    lat: location[1],
                  });
                  setCheckLugar(false);
                }}
              />
            </form>
          )}
        </Formik>
      </CardContent>

      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          dispatch(closeSnack());
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={
            (snackState === SnackState.SUCCESS && "success") ||
            (snackState === SnackState.ERROR && "error") ||
            "success"
          }
        >
          {text}
        </Alert>
      </Snackbar>
      <DialogLoad
        open={add?.state === FormState.Submitting}
        onClose={() => {}}
      />
    </Card>
  );
}

export default PlaceRegister;
