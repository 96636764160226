import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Search as SearchIcon, X as DeleteIcon } from "react-feather";
import React, { useState } from "react";
import { useFormik } from "formik";
import { withStyles } from "@material-ui/styles";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#ffffff",
      fontWeight: "bold",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000",
    },
    "& input": {
      color: "#ffffff",
      "& placeholder": { color: "#ffffff" },
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ffffff",
      },
      "&:hover fieldset": {
        borderColor: "#ffffff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ffffff",
      },
    },
  },
})(TextField);

interface SearchBoxProps {
  sendSearch: (search: string) => void;
  label?: string;
  name: string;
  placeholder: string;
  startAdornment?: React.ReactNode;
  format?: (value: string) => string;
}

export const SearchInput: React.FC<SearchBoxProps> = ({
  sendSearch,
  name,
  label = "",
  placeholder,
  startAdornment,
  format,
}) => {
  const { handleSubmit, values, handleChange, resetForm } = useFormik({
    initialValues: {
      [name]: "",
    },
    onSubmit: (values) => {
      sendSearch(values[name]);
    },
  });

  const [width, setWidth] = useState(110);

  return (
    <form onSubmit={handleSubmit}>
      <CssTextField
        name={name}
        type="text"
        variant="outlined"
        size="small"
        autoComplete="off"
        value={values[name]}
        style={{ width, transition: "width 300ms" }}
        onFocus={() => {
          setWidth(180);
        }}
        onBlur={() => {
          if (values[name].length <= 5) {
            setWidth(120);
          }
        }}
        onChange={(event) => {
          if (format) {
            event.target.value = format(event.target.value);
          }
          handleChange(event);
        }}
        placeholder={placeholder}
        InputLabelProps={{
          style: {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100%",
            color: "#ffffff",
          },
        }}
        InputProps={{
          startAdornment: startAdornment,
          endAdornment: (
            <InputAdornment position="end">
              {values[name].length >= 1 && (
                <IconButton
                  style={{ color: "#ffffff" }}
                  size="small"
                  onMouseDown={() => {
                    resetForm();
                    setWidth(120);
                    sendSearch("");
                  }}
                >
                  <DeleteIcon size={24} style={{ marginTop: -4 }} />
                </IconButton>
              )}
              <IconButton
                style={{ color: "#ffffff" }}
                size="small"
                onMouseDown={() => sendSearch(values[name])}
              >
                <SearchIcon size={24} style={{ marginTop: -4 }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};
