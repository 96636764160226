import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  Collapse,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { withStyles } from "@material-ui/styles";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import {
  DownloadCloud,
  Edit,
  MinusSquare,
  Plus as AddIcon,
  Search,
  Trash2,
} from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { NewConfirmDialog } from "../components/NewConfirmDialog";
import { DialogTiposLugares } from "../components/PlacesComponents/DialogTiposLugares";
import { TableSkeleton } from "../components/TableSkeleton";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";
import { useTable } from "../hooks/useTable";
import { FormState } from "../models/form_state";
import { SnackState } from "../models/snack-state";
import {
  deletePlace,
  getMorePlaces,
  getMorePlacesFiltered,
  getPlaces,
  getPlacesFiltered,
  setSelectedPlace,
} from "../redux/actions/placesActions";
import { getPlacesTypes } from "../redux/actions/placesTypesActions";
import { getRegions } from "../redux/actions/regionsActions";
import { openSnack } from "../redux/actions/uiActions";
import { PlacesState } from "../redux/reducers/placesReducer";
import { IPlacesTypesState } from "../redux/reducers/placesTypesReducer";
import { IRegionsState } from "../redux/reducers/regionsReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { useStyles } from "../theme/useStyles";
import { exportToCsv, timesStampFormattedsimple } from "../utils/utils";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#ffffff",
      fontWeight: "bold",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000",
    },
    "& input": {
      color: "#ffffff",
      "& placeholder": { color: "#ffffff" },
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ffffff",
      },
      "&:hover fieldset": {
        borderColor: "#ffffff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ffffff",
      },
    },
  },
})(TextField);

interface FilterProps {
  onFilterChange: (value: any) => void;
}

const ContentCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [openDelete, setOpenDelete] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [subjectData, setSubjectData] = useState<any | {}>({ id: "" });

  const handleDeleteAccept = () => {
    dispatch(deletePlace(subjectData));
    setOpenDelete(false);
  };

  const handleGoEdit = (data: any) => {
    dispatch(setSelectedPlace(data));
    history.push(`/places/${data.id}/Editar`);
  };

  const { limit, page, handleLimitChange, handlePageChange } = useTable({
    limit: TABLE_LIMIT_DEFAULT,
    onLimitChange: (e, newLimit) => {
      if (newLimit > places.length) {
        if (filter) {
          dispatch(getPlacesFiltered(filter, newLimit));
        } else {
          dispatch(getPlaces(newLimit));
        }
      }
    },
    onPageChange: (newPage, oldPage, limit) => {
      if (places.length < totalDocs && newPage > oldPage) {
        if (filter) {
          dispatch(getMorePlacesFiltered(filter, limit));
        } else {
          dispatch(getMorePlaces(limit));
        }
      }
    },
  });

  useEffect(() => {
    dispatch(getPlaces());
  }, [dispatch]);

  const { places, totalDocs, state } = useSelector<RootState, PlacesState>(
    (state) => state.placesReducer
  );

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <FilterSection onFilterChange={(filter) => setFilter(filter)} />
        </CardContent>
        <CardContent>
          <TableContainer className={classes.tableContainer}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Fecha de Creación</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Región</TableCell>
                  <TableCell>Dueño</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="subtitle2"
                      style={{ color: "#004ecb" }}
                    >
                      Acciones
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state === FormState.Success ? (
                  places
                    .slice(page * limit, page * limit + limit)
                    .map((data) => (
                      <Fade key={data?.id} in={true}>
                        <TableRow hover className={classes.styledRow}>
                          <TableCell>{data?.NombreLugar}</TableCell>
                          <TableCell>
                            {timesStampFormattedsimple(
                              data?.FechaCreacion?.toDate()
                            )}{" "}
                          </TableCell>
                          <TableCell>{data?.TipoLugar}</TableCell>
                          <TableCell>{data?.Region}</TableCell>
                          <TableCell>
                            {data?.Nombre + " " + data?.Apellido}
                          </TableCell>
                          <TableCell>{data?.Estado}</TableCell>
                          <TableCell align="center">
                            <Box display="flex" justifyContent="center">
                              <Tooltip title="Editar">
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    handleGoEdit(data);
                                  }}
                                  color="primary"
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Eliminar">
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setSubjectData(data);
                                    setOpenDelete(true);
                                  }}
                                >
                                  <Trash2 color="red" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))
                ) : (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ color: "#004ecb", fontWeight: "bold" }}
            component="div"
            count={totalDocs}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_LIMITS}
          />
        </CardContent>
        <NewConfirmDialog
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          title="¿Desea eliminar lugar?"
          onConfirm={handleDeleteAccept}
          onConfirmText={"Eliminar"}
        />
      </Card>
    </>
  );
};

const FilterSection = React.memo((props: FilterProps) => {
  const { onFilterChange, ...other } = props;
  const dispatch = useDispatch();

  const [openFilter, setOpenFilters] = useState(false);
  const [filtersList, setValueFiltersList] = React.useState<any>({});
  const [valueCreacionStart, setValueCreacionStart] = useState<any>(null);
  const [valueCreacionEnd, setValueCreacionEnd] = useState<any>(null);
  const [valueNombre, setValueNombre] = useState("");
  const [valueTipo, setValueTipo] = React.useState<string | null>(null);
  const [valueRegion, setValueRegion] = React.useState<string | null>(null);
  const [valueEstado, setValueEstado] = React.useState<string | null>(null);

  const estadosPromocion = ["Aprobado", "Finalizado", "En Revisión"];
  const promotionTypes = ["Alojamiento", "Restaurant", "Car Rent"];

  const handleChangeNombre = (e: any) => {
    setValueNombre(e.target.value);
  };

  useEffect(() => {
    dispatch(getRegions());
  }, []);
  useEffect(() => {
    // dispatch(getTourTypes());
  }, []);

  useEffect(() => {
    onFilterChange(filtersList);
  }, [Object.keys(filtersList)]);

  const { regions } = useSelector<RootState, IRegionsState>(
    (state) => state.regionsReducer
  );
  const { placesTypes } = useSelector<RootState, IPlacesTypesState>(
    (state) => state.placesTypesReducer
  );
  useEffect(() => {
    if (!placesTypes) {
      dispatch(getPlacesTypes());
    }
  }, [placesTypes]);

  return (
    <CardContent style={{ backgroundColor: "#2979ff" }}>
      <Grid container justify="flex-end">
        <Grid item>
          <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
            <Typography variant="subtitle2" style={{ color: "#ffffff" }}>
              Filtros{" "}
            </Typography>
            {openFilter ? (
              <ExpandLess style={{ color: "#ffffff" }} />
            ) : (
              <ExpandMore style={{ color: "#ffffff" }} />
            )}
          </Button>
        </Grid>
      </Grid>
      <Collapse in={openFilter} timeout="auto" unmountOnExit>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              id="estado-filtro"
              size="small"
              value={valueEstado}
              options={estadosPromocion}
              getOptionLabel={(option: any) => option}
              fullWidth
              onChange={(event: any, newValue: any | null) => {
                setValueEstado(newValue);
                if (newValue) {
                  filtersList["Estado"] = newValue;
                  dispatch(getPlacesFiltered(filtersList));
                } else {
                  delete filtersList["Estado"];
                  if (Object.keys(filtersList).length > 0) {
                    dispatch(getPlacesFiltered(filtersList));
                  } else {
                    dispatch(getPlaces());
                  }
                }
              }}
              renderInput={(params: any) => (
                <CssTextField
                  {...params}
                  label="Estado"
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      color: "#ffffff",
                    },
                  }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              id="tipos-filtro"
              size="small"
              value={valueTipo}
              options={placesTypes}
              getOptionLabel={(option: any) => option.Nombre}
              filterOptions={(option) =>
                placesTypes.filter((x: any) => x?.Activo)
              }
              fullWidth
              onChange={(event: any, newValue: any | null) => {
                setValueTipo(newValue);
                if (newValue) {
                  filtersList["TipoLugar"] = newValue.Nombre;
                  dispatch(getPlacesFiltered(filtersList));
                } else {
                  delete filtersList["TipoLugar"];
                  if (Object.keys(filtersList).length > 0) {
                    dispatch(getPlacesFiltered(filtersList));
                  } else {
                    dispatch(getPlaces());
                  }
                }
              }}
              renderInput={(params: any) => (
                <CssTextField
                  {...params}
                  label="Tipos de Lugar"
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      color: "#ffffff",
                    },
                  }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              id="region-filtro"
              size="small"
              value={valueRegion}
              options={regions}
              getOptionLabel={(option: any) => option.Nombre}
              fullWidth
              onChange={(event: any, newValue: any | null) => {
                setValueRegion(newValue);
                if (newValue) {
                  filtersList["Region"] = newValue.Nombre;
                  dispatch(getPlacesFiltered(filtersList));
                } else {
                  delete filtersList["Region"];
                  if (filtersList) {
                    dispatch(getPlacesFiltered(filtersList));
                  } else {
                    dispatch(getPlaces());
                  }
                }
              }}
              renderInput={(params: any) => (
                <CssTextField
                  {...params}
                  label="Región"
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      color: "#ffffff",
                    },
                  }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <CssTextField
              id="creacion-start"
              label="Fecha Creación: Inicio"
              size="small"
              type="datetime-local"
              value={valueCreacionStart}
              onChange={(event) => {
                setValueCreacionStart(event.target.value);
                filtersList["endAt"] = event.target.value;
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  color: "#ffffff",
                },
                shrink: true,
              }}
            />
          </Grid>
          {/* <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Final"
              size="small"
              type="datetime-local"
              value={valueCreacionEnd}
              onChange={(event) => {
                setValueCreacionEnd(event.target.value);
                filtersList["startAt"] = event.target.value;
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid> */}
          <Grid item xs={12} sm={2}>
            <Box display="flex">
              <Box mt={1} order={1}>
                <Tooltip title="Buscar">
                  <IconButton
                    style={{ color: "#ffffff" }}
                    size="small"
                    aria-label="Filtrar"
                    onClick={() => {
                      if (!valueCreacionStart) {
                        delete filtersList["endAt"];
                      }
                      dispatch(getPlacesFiltered(filtersList));
                    }}
                  >
                    <Search size={35} style={{ marginTop: -7 }} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box mt={1} order={2}>
                <Tooltip title="Limpiar">
                  <IconButton
                    style={{ color: "#ffffff" }}
                    size="small"
                    aria-label="Borrar filtro"
                    onClick={() => {
                      setValueTipo(null);
                      setValueRegion(null);
                      setValueEstado(null);
                      setValueCreacionStart("");
                      setValueCreacionEnd("");
                      setValueFiltersList({});
                      dispatch(getPlaces());
                    }}
                  >
                    <MinusSquare size={35} style={{ marginTop: -7 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </CardContent>
  );
});

export const Places = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [openTiposLugares, setOpenTiposLugares] = useState(false);

  const handleGoCreate = () => {
    history.push("/places/Crear");
  };

  const { places } = useSelector<RootState, PlacesState>(
    (state) => state.placesReducer
  );

  const arrayPlaces = places.map((data) => {});
  return (
    <React.Fragment>
      <Helmet title="Comercios" />
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Comercios
          </Typography>
          {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Comercios</Typography>
          </Breadcrumbs> */}
        </Grid>
        <Grid item>
          <Box display="flex">
            <Box order={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(openSnack("Descargando CSV", SnackState.SUCCESS));
                  exportToCsv(
                    "observaciones.csv",
                    places,
                    [
                      "NombreLugar",
                      "Region",
                      "Ciudad",
                      "DireccionFacturacion",
                      "TipoLugar",
                      "LugarGoogle",
                      "Estado",
                      "Rut",
                      "Nombre",
                      "Apellido",
                      "Email",
                      "Telefono",
                    ],
                    [
                      "Nombre Lugar",
                      "Region",
                      "Ciudad",
                      "Direccion",
                      "Tipo Lugar",
                      "Ubicacion",
                      "Estado",
                      "Rut",
                      "Nombre representante",
                      "Apellido representante",
                      "Email",
                      "Telefono",
                    ]
                  );
                }}
              >
                <DownloadCloud />
                Descargar CSV
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(openSnack("Descargando CSV", SnackState.SUCCESS));
                  exportToCsv(
                    "observaciones.csv",
                    places,
                    [
                      "NombreLugar",
                      "Region",
                      "Ciudad",
                      "DireccionFacturacion",
                      "TipoLugar",
                      "LugarGoogle",
                      "Estado",
                      "Rut",
                      "Nombre",
                      "Apellido",
                      "Email",
                      "Telefono",
                    ],
                    [
                      "Nombre Lugar",
                      "Region",
                      "Ciudad",
                      "Direccion",
                      "Tipo Lugar",
                      "Ubicacion",
                      "Estado",
                      "Rut",
                      "Nombre representante",
                      "Apellido representante",
                      "Email",
                      "Telefono",
                    ],
                    ";"
                  );
                }}
              >
                <DownloadCloud />
                Descargar CSV2
              </Button>
            </Box>
            <Box order={2} ml={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleGoCreate()}
              >
                <AddIcon /> Añadir Comercio
              </Button>
            </Box>
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenTiposLugares(!openTiposLugares)}
            >
              Tipos de Comercios
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard />
        </Grid>
      </Grid>
      <DialogTiposLugares
        open={openTiposLugares}
        onClose={() => setOpenTiposLugares(!openTiposLugares)}
      />
    </React.Fragment>
  );
};
