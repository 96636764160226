import React from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { GetApp } from "@material-ui/icons";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (type: "all" | "filter") => void;
}

export const DownloadTypeDialog: React.FC<Props> = ({
  onClose,
  onConfirm,
  open,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="dialog-confirm-title">
        {"Selección de elementos a descargar"}
      </DialogTitle>
      <List>
        <ListItem autoFocus button onClick={() => onConfirm("all")}>
          <ListItemAvatar>
            <Avatar>
              <GetApp />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Todos" />
        </ListItem>
        <ListItem autoFocus button onClick={() => onConfirm("filter")}>
          <ListItemAvatar>
            <Avatar>
              <GetApp />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Filtrado" />
        </ListItem>
      </List>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
